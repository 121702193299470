import { gql } from '@apollo/client';

const query = gql`
query getHistEventAlert($reqData: ReqGetHistEventAlert) {
  getHistEventAlertInfo(reqData: $reqData) {
    __typename
    result
    data {
      __typename
    	... on RespHistEventAlertInfo {
        eventSetting {
          __typename
          settingId
          projectId
          eventId
          name
          priorityCode
          messageId
        }
        message {
          __typename
          id
          relObjectId
          relObjectType
          sendTypeCode
          version
          title
          message
          useYn
        }
        projectMember {
          __typename
          memberId
          projectId
          roleCode
          alertYn
          memberName
        }
      }
    }
  }
}
`;
export default query;