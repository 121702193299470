import { useEffect, useState } from 'react';
import Button from 'components/UI/Button';
import DeleteChip from 'assets/svgs/ico_delete_chip.svg';
import '../index.scss';
import lazyGetHistEventAlert from 'graphql/queries/getHistEventAlert';
import { HistEventAlertType } from 'graphql/types/HistEventAlertType';
import { enumFormatter } from 'utils/Formatter';
import sendHistEventAlert from 'graphql/mutations/sendHistEventAlert';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';

interface IUserUpdateModalProps{
  onClose: () => void;
  onSubmit: () => void;
  data: { id: number, projectId: number, settingId: number, projectName: string };
}
const SendAlarmModal = ({ onClose, onSubmit, data }: IUserUpdateModalProps) => {
  const [createData, setCreateData] = useState<{
    user: { name: string, value: string }[];
    messageTitle: string;
    messageText: string;
  }>({
    user: [],
    messageTitle: '',
    messageText: ''
  });
  const [alertData, setAlertData] = useState<HistEventAlertType | null>(null);
  const [lazyGetSendAlertData] = lazyGetHistEventAlert();
  const [sendAlert] = sendHistEventAlert();

  const handleSend = () => {
    if (alertData) {
      sendAlert({
        variables: {
          reqData: {
            histEventId: data.id,
            sendTypeCode: alertData.message.sendTypeCode,
            title: createData.messageTitle,
            contents: createData.messageText,
            memberIds: createData.user.map(d => d.value)
          }
        }
      }).then(({ data }) => {
        if (data) {
          if (data.sendHistEventAlert.result === ErrorCode.SUCCESS) {
            useSnackbar(data.sendHistEventAlert.result);
            onSubmit();
            onClose();
          } else {
            useSnackbar(data.sendHistEventAlert.result);
          }
        } else {
          useSnackbar(ErrorCode.UNKNOWN);
        }
      });
    }
  };
  const addTotalUser = () => {
    setCreateData(prev => ({ ...prev, user: alertData?.projectMember.filter(d => d.alertYn).map(d => ({ name: d.memberName, value: d.memberId })) || [] }));
  };
  const deleteUser = (userId: string) => {
    setCreateData(prev => ({ ...prev, user: prev.user.filter(d => d.value !== userId) }));
  };

  /** 초기화 */
  useEffect(() => {
    /* info 데이터 받아와서 기본 정보 삽입 */
    lazyGetSendAlertData({
      variables: {
        reqData: {
          projectId: data.projectId,
          id: data.id,
          settingId: data.settingId
        }
      }
    }).then(({ data }) => {
      const res = data?.getHistEventAlertInfo.data[0];
      setAlertData(res || null);
      setCreateData({ user: res?.projectMember.filter(d => d.alertYn).map(d => ({ name: d.memberName, value: d.memberId })) || [], messageText: res?.message.message || '', messageTitle: res?.message.title || '' });
    });
  },[data]);
  
  if (data === undefined || data === null) return null;

  return ( 
    <div className="modal-bg" onClick={onClose}>
      <div className="modal-wrap alarm" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span/>
          <p>
            알림 발송
          </p>
        </div>
        <div className="contents-wrap">
          <p className="title">수신자 및 알림내용 확인</p>
          <p className="title">알림 수신자 확인</p>
          <div className="alarm-row-title">
            <p className="row-title">{`'${data.projectName}'멤버 대상으로 발송합니다.`}</p>
            <button onClick={addTotalUser}>+ 알림 설정한 모든 멤버</button>
          </div>
          <div className="alarm-member-wrapper">
            <div className="alarm-member-group">
              {
                createData.user.map(d => (
                  <span key={d.value}>
                    <div>{d.name}</div>
                    <img src={DeleteChip} width={20} height={20} onClick={() => deleteUser(d.value)}/>
                  </span>
                ))
              }
            </div>
          </div>
        </div>
        <div className="contents-wrap">
          <p className="title">알림 내용 확인</p>
          <div className="row">
            <p className="row-title">알림 형태</p>
            <p>{enumFormatter(alertData?.message.sendTypeCode || '')}</p>
          </div>
          <div className="row">
            <p className="row-title">이벤트 설정 이름</p>
            <p>{alertData?.eventSetting.name}</p>
          </div>
          <div className="row mb-26">
            <p className="row-title">알림 제목</p>
            <input type="text" className="input" value={createData.messageTitle} onChange={(e) => {setCreateData(prev => ({ ...prev, messageTitle: e.target.value }));}}/>
          </div>
          <div className="row mb-26">
            <p className="row-title">알림 내용</p>
            <textarea className="input textarea" value={createData.messageText} onChange={(e) => {setCreateData(prev => ({ ...prev, messageText: e.target.value }));}}/>
          </div>
          <div className="modal-btn-wrap">
            <Button disabled={createData.messageText === '' || createData.messageTitle === '' || createData.user.length === 0} text={'알림 발송'} handleClick={handleSend}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendAlarmModal;