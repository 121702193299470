import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import VerificationInformationGrid from './components/VerificationInformationGrid';
import VerificationInformationDetail from './components/VerificationInformationDetail';
import { useParams } from 'react-router-dom';
import lazyGetAuthPageByParam from 'graphql/queries/getAuthPageByParam';

const VerificationInformation = () => {

  const { organId } = useParams();
  
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [selected, setSelected] = useState<string>('');

  const [getAuthPageByParam, {loading: loadingGetAuthPageByParm}] = lazyGetAuthPageByParam();

  const getAuthPageByParamData = useMemo(() => ({
    reqData: {
      organId: Number(organId),
      text: search === '' ? undefined : search,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [ 
    tablePagination,
    search,
    isShowing,
    
    organId
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <VerificationInformationGrid
          onSubmitSearch={(readyToSearch:string) => { 
            setSearch(readyToSearch);
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          isLoading={loadingGetAuthPageByParm}
        />
      case 'DETAIL': 
        return <VerificationInformationDetail
          data={rows.find(val => val.authId === Number(selected)) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
        />
    }
  }, [
    tablePagination,
    isShowing, 
    rows, 
    total,
    selected,
    loadingGetAuthPageByParm
  ])

  const getAuthPageByParamRows = () => {
    getAuthPageByParam({ variables: getAuthPageByParamData}).then(({ data }) => {
      if (data) {
        setRows(data.getAuthPageByParam.content.map(val => ({ ...val, id: val.authId })))
        setTotal({
          totalPage: data.getAuthPageByParam.totalPages,
          totalElement: data.getAuthPageByParam.totalElements
        })
      }
    })
  }

  /* 인증정보 rows 받기 */
  useEffect(() => {
    getAuthPageByParamRows();
  }, [getAuthPageByParamData])

  return (
    <div className='event-setting'>
      {renderShowing}
    </div>
  );
}
export default VerificationInformation;