import { useEffect, useState } from 'react';
import './index.scss';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { dateFormatter } from 'utils/Formatter';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface ISelectCalendarCardProps {
  id?: string;
  originValue?: string;
  placeholder?: string;
  error?: boolean;
  baseModalOpen?: boolean;
  updateDateAndTime: (key: string, value: string) => void;
}

const SelectCalendarCard = ({
  id,
  originValue,
  placeholder,
  error,
  baseModalOpen,
  updateDateAndTime
}:ISelectCalendarCardProps) => {

  const [open, setOpen] = useState(false)
  const [bg, setBg] = useState({
    top: 0,
    left: 0
  });

  const [dateValue, setDateValue] = useState<Date>(new Date());
  const [time, setTime] = useState({
    hour: '00',
    minute: '00',
    ampm: 'am'
  });

  const changeDateValue = (value: Value) => {
    setDateValue(value as Date);
  }
  const openMenu = () => {
    const calendarBgEl = document.getElementById(`calendar-bg-${id}`);
    if (!calendarBgEl) return;

    if (calendarBgEl.classList.contains('none')) {
      calendarBgEl.classList.remove('none');
      setOpen(true);
    } else {
      calendarBgEl.classList.remove('none');
    }
  };

  const closeAll = () => {
    const calendarBgEl = document.getElementById(`calendar-bg-${id}`);
    if (!calendarBgEl) return;

    calendarBgEl.classList.add('none');
  };

  useEffect(() => {
    setTime({
      hour: '00',
      minute: '00',
      ampm: 'am'
    });
    setOpen(false);
    setDateValue(new Date());
  }, [baseModalOpen]);

  
  return (
    <div 
      className="select-calendar-card"
      id={`${id}-calendar-card`}
    >
      <button
        type="button"
        id={id}
        className={`${error && 'error'}`}
        onClick={(e) => {
          e.stopPropagation();
          openMenu();

          const calendarCardEl = document.getElementById(`${id}-calendar-card`);
          const calendarEl = document.getElementById('calendar');

          if (!calendarCardEl || !calendarEl) return;
          if (window.innerHeight - e.currentTarget.getBoundingClientRect().bottom > calendarCardEl.offsetHeight + calendarEl.offsetHeight) {
            setBg({
              top: e.currentTarget.getBoundingClientRect().bottom + 8,
              left: e.currentTarget.getBoundingClientRect().left 
            });
          } else {
            setBg({
              top: e.currentTarget.getBoundingClientRect().bottom - (calendarCardEl.offsetHeight + calendarEl.offsetHeight) - 8,
              left: e.currentTarget.getBoundingClientRect().left 
            });
          }
        }}
      > 
        <input 
          type="text" 
          readOnly 
          placeholder={placeholder}
          value={originValue ? dateFormatter(originValue, 'datetime', true) : ''}
        />
        <div className="calendar-icon" />
      </button>
      <div 
        id={`calendar-bg-${id}`} 
        className="calendar-bg none"
        onClick={() => {
          const dateFormat = moment(dateValue).format('YYYY-MM-DD');
          const timeFormat = time.ampm === 'am' ? `${time.hour}:${time.minute}` : `${(Number(time.hour) + 12)}:${time.minute}`;
          updateDateAndTime(String(id), `${dateFormat}T${timeFormat}:00.000Z`);

          closeAll();
        }}
      >
        <div 
          id="calendar"
          className="flex"
          style={{
            top: bg.top,
            left: bg.left
          }}
          onClick={(e) => {e.stopPropagation();}}>
            {
              open &&

          <Calendar 
          onChange={changeDateValue}
          value={dateValue} 
          defaultActiveStartDate={new Date}
          calendarType="gregory"
          minDetail="year"
          locale='ko'
          formatDay={(_locale, date) => moment(date).format('D')}
          />
          }
          <div className="time-wrap flex">
            <div className="hour">
              { Array.from({ length: 12 }, (_, index) => index).map(h => (
                <button 
                  key={`hour-${h}`}
                  className={`${Number(time.hour) === h && 'selected'}`}
                  onClick={() => setTime(prev => ({
                    ...prev,
                    hour: h.toString().length > 1 ? h.toString() : `0${h}`
                  }))}
                >
                  { h.toString().length > 1 ? h.toString() : `0${h}`}
                </button>
              ))}
            </div>
            <div className="minute">
              { Array.from({ length: 60 }, (_, index) => index).map(min => (
                <button 
                  key={`min-${min}`}
                  className={`${Number(time.minute) === min && 'selected'}`}
                  onClick={() => setTime(prev => ({
                    ...prev,
                    minute: min.toString().length > 1 ? min.toString() : `0${min}`
                  }))}
                >
                  { min.toString().length > 1 ? min.toString() : `0${min}` }
                </button>
              ))}
            </div>
            <div className="daytime">
              <button
                className={`${ time.ampm === 'am' && 'selected' }`}
                onClick={() => setTime(prev => ({
                  ...prev,
                  ampm: 'am'
                }))}
              >
                오전
              </button>
              <button
                className={`${ time.ampm === 'pm' && 'selected' }`}
                onClick={() => setTime(prev => ({
                  ...prev,
                  ampm: 'pm'
                }))}
              >
                오후
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SelectCalendarCard;
