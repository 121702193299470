import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { numberRegex } from 'utils/Regex';
import LabelTextarea from 'components/v2/LabelTextarea';
import { useMutation } from 'react-query';
import { IAddCategoryReq } from 'apis/v2/Manage/schema';
import apis from 'apis/v2';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import addCategory from 'graphql/mutations/addCategory';

interface ICreateCategoryModalProps extends IBaseModalProps {
  cloudList: {
    id: number;
    value: string;
    name: string; 
  }[];
  title: () => JSX.Element;
  onCreateSuccess: () => void;
}

const CreateCategoryModal = ({
  cloudList,
  title,
  onCreateSuccess,
  ...baseModalProps
}:ICreateCategoryModalProps) => {

  const [payload, setPayload] = useState({
    cloudId: -1,
    name: '',
    maskCode: '',
    description: '',
    title: '',
    template: ''
  });

  const { mutateAsync: _addCategory } = useMutation((data: IAddCategoryReq) => apis.Manage.addCategory(data));
  const [createCategory] = addCategory();

  const enableButton = useMemo(() => {
    if (
      payload.cloudId === -1 ||
      payload.name === '' ||
      payload.maskCode.length < 8 ||
      payload.description === '' ||
      payload.title === ''
    ) {
      return true;
    } else return false;
  }, [payload])

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload({
        cloudId: -1,
        name: '',
        maskCode: '',
        description: '',
        title: '',
        template: ''
      });
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="create-category-modal">
        <div className="contents-wrap">
          <Labeled
            title="클라우드 정보"
            required={true}
          >
            <DropdownAtom 
              id="cloud-kind"
              placeholder='선택'
              data={cloudList} 
              value={{
                name: cloudList.find(val => val.id === payload.cloudId)?.name 
                ? cloudList.find(val => val.id === payload.cloudId)?.name
                : '',
                value: payload.cloudId
              }}
                handleClick={(val) => {
                setPayload(prev => ({
                  ...prev,
                  cloudId: Number(val.id)
                }))
              }}                
            />
          </Labeled>
          <LabelInput 
            title="카테고리 이름"
            placeholder='카테고리 이름 입력'
            required
            value={payload.name}
            onChangeValue={(val) => setPayload((prev) => ({
              ...prev,
              name: val
            }))}
          />
          <LabelInput 
            title="고유 구분자" 
            required
            placeholder='고유 구분자 입력(마스크코드 8자리 숫자)'
            value={payload.maskCode}
            onChangeValue={(val) => {
              if (val.length > 8) return;

              setPayload((prev) => ({
              ...prev,
              maskCode: val.replace(numberRegex, '')
            }))
            }}
          />
          <LabelInput 
            title="설명" 
            required
            placeholder='설명 입력'
            value={payload.description}
            onChangeValue={(val) => {
              setPayload((prev) => ({
              ...prev,
              description: val
            }))
            }}
          />
          <LabelInput 
            title="문서 제목" 
            required
            placeholder='문서 제목 입력'
            value={payload.title}
            onChangeValue={(val) => {
              setPayload((prev) => ({
              ...prev,
              title: val
            }))
            }}
          />
          <LabelTextarea
            title="문서 서식" 
            placeholder='문서 서식 입력'
            value={payload.template}
            onChangeValue={(val) => {
              setPayload((prev) => ({
              ...prev,
              template: val
            }))
            }}
          />
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center"  
            disabled={enableButton}
            onClick={() => {
              const addCategoryData = {
                reqData: {
                  ...payload,
                }
              }
    
              createCategory({ variables: addCategoryData }).then(({ data }) => {
                if (data) {
                  if (data.addCategory.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, '카테고리가 생성되었습니다.');
                    onCreateSuccess();
                  } else {
                    console.log(data.addCategory.result);
                    useToast(ErrorCode.UNKNOWN, '카테고리 생성을 실패했습니다.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, '카테고리 생성을 실패했습니다.');
                }
              });
            }}
          >
            생성
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateCategoryModal;
