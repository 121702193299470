import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from '../../types/Paging';
import { ResponsePage } from '../../types/ResponsePage';
import { ReportDetail } from '../../types/ReportDetail';

export interface IGetMonthlyReportPageByParamVariables {
  reqData: {
    projectId?: number;
    text?: string;
    pageInfo: Paging;
  };
}

export interface IGetMonthlyReportPageByParamVariablesResponseData {
  getMonthlyReportPageByParam: ResponsePage<ReportDetail>;
}

/** v2 월간 리포트 조회 */
const getMonthlyReportPageByParam = () => useLazyQuery<IGetMonthlyReportPageByParamVariablesResponseData, IGetMonthlyReportPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default getMonthlyReportPageByParam;