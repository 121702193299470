import { useLazyQuery } from '@apollo/client';
import query from './query';
import { MemoInfo } from 'graphql/types/MemoInfo';
import { IGqlResponseData } from 'graphql/types';

export interface IGetMemosVariables {
  reqGetMemo: {
    relObjectId: number;
    relObjectType: string; // (MemoRelObjectType): report_w | report_m | event_ri
  }
}

export interface IGetMemosResponseData {
  getMemos: IGqlResponseData<MemoInfo[]>;
}

/** 요청사항 조회 */
const lazyGetMemos = () => useLazyQuery<IGetMemosResponseData>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMemos;