import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';

export interface IGetProjectPageVariables {
  reqData: {
    organIds: number[];
    pageInfo: Paging;
  }
}

export interface IGetProjectPageResponseData {
  getProjectInfoPage: ResponsePage<ProjectInfo>;
}

/** 프로젝트 조회 (page) */
const lazyGetProjectPage = () => useLazyQuery<IGetProjectPageResponseData, IGetProjectPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetProjectPage;