import { gql } from '@apollo/client';

const query = gql`query searchMyEvent($text: String) {
  searchEvent(text: $text) {
    __typename
    result
    data {
      ... on Event {
        eventId
        maskCode
        name
        createdAt
        createdByName
      }
    }
  }
}`;
export default query;