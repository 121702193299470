import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import Button from 'components/UI/Button';
import addOrgan from 'graphql/mutations/addOrgan';
import updateOrgan from 'graphql/mutations/updateOrgan';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';

interface ICreateCompanyModalProps{
    onClose: () => void;
    type: string;
    defaultData?: {
      id: number;
      name: string;
      description: string;
    }
}

const CreateCompanyModal = ({ onClose, type, defaultData }: ICreateCompanyModalProps) => {
  const [createData, setCreateData] = useState({
    company: '',
    desc: ''
  });
  
  const [createOrgan] = addOrgan();
  const [editOrgan] = updateOrgan();

  /** 생성, 수정 분기 */
  const isCreate = useMemo(() => {
    if (type === 'create') return true;
    else return false;
  },[type]);

  const handleCreate = () => {
    /// api 호출
    if (type === 'create') {
      createOrgan({ variables: { 
        reqAddOrgan: {
          name: createData.company,
          description: createData.desc
        } 
      } }).then(({ data }) => {
        if (data) {
          if (data.addOrgan.result === ErrorCode.SUCCESS) {
            useSnackbar(data.addOrgan.result);
            onClose();
          } else {
            useSnackbar(data.addOrgan.result);
          }
        } else {
          useSnackbar(ErrorCode.UNKNOWN);
        }
      });
    } else {
      if (defaultData) {
        editOrgan({ variables: { organ: {
          organId: defaultData.id,
          name: createData.company,
          description: createData.desc
        } } }).then(({ data }) => {
          if (data) {
            if (data.updateOrgan.result === ErrorCode.SUCCESS) {
              useSnackbar(data.updateOrgan.result);
              onClose();
            } else {
              useSnackbar(data.updateOrgan.result);
            }
          } else {
            useSnackbar(ErrorCode.UNKNOWN);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (defaultData) {
      setCreateData({
        company: defaultData.name,
        desc: defaultData.description
      });
    } else {
      setCreateData({
        company: '',
        desc: ''
      });
    }
  },[open, defaultData]);
  
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="companymodal-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span/>
          <p>
            조직 {isCreate ? '추가' : '수정'}
          </p>
        </div>
        <div className="contents-wrap">
          <p className="title">생성할 조직 정보를 입력하세요.</p>
          <div className="row">
            <p className="row-title">이름</p>
            <input 
              type="text" 
              className="input" 
              value={createData.company}
              onChange={e => setCreateData(prev => ({ ...prev, company: e.target.value }))}
            />
          </div>
          <div className="row">
            <p className="row-title">설명</p>
            <textarea 
              className="input textarea" 
              value={createData.desc}
              onChange={e => setCreateData(prev => ({ ...prev, desc: e.target.value }))}
            />
          </div>
          <div className="modal-btn-wrap">
            <Button disabled={createData.company === '' || createData.desc === ''} text={isCreate ? '추가' : '수정'} handleClick={handleCreate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCompanyModal;