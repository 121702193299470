import { useMemo, useState } from 'react';
import { idRegex, pwRegex } from 'utils/Regex';
import Logo from 'assets/svgs/v2/auth_logo.svg';
import LabelInput from 'components/v2/LabelInput';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import apis from 'apis/v2/index';
import { ErrorCode, ErrorMessage } from '@Types/error';

interface ICheckIdProps {
  inputs: { id: string; pw: string; };
  updateInputs: (key: 'id' | 'pw', value: string) => void;
  goNextStep: () => void;
}

const CheckId = ({ inputs, updateInputs, goNextStep }:ICheckIdProps) => {
  
  const [error, setError] = useState<{ type: 'id' | 'pw', msg: string }>({ type: 'id', msg: '' });
  const { mutateAsync: _checkId } = useMutation(
    (payload:{ userId: string }) => apis.SignUp.checkId(payload)
  );

  const disabledNextBtn = useMemo(() => {
    if (inputs.id.length === 0 || inputs.pw.length === 0 || error.msg !== '') {
      return true;
    } else {
      return false;
    }
  },[inputs, error]);

  const resetError = () => {
    setError(prev => ({ ...prev, msg: '' }));
  };
  const tryJoin = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const id = e.currentTarget['join-id'].value;
    const pw = e.currentTarget['join-pw'].value;

    if (id.length < 2 || id.length > 15) { //todo 글자 수 확인 필요
      setError({ type: 'id', msg: '아이디를 최소 2자부터 최대 15자로 입력해 주세요.' });
    } else if (!idRegex.test(id)) {
      setError({ type: 'id', msg: '아이디는 영문 및 숫자로 입력해 주세요.' });
    } else if (!pwRegex.test(pw) || pw.length > 12) { //todo 피그마 내용과 달리 서버에러는 영대문자, 소문자, 숫자,특수문자의 8자 이상 32글자 이하로 오류 발생함.
      setError({ type: 'pw', msg: '비밀번호는 8자리 이상 12자리 이하의 숫자, 영문자, 특수문자 조합으로 입력해 주세요.' });
    } else {
      _checkId({ userId: id }).then(({ data }) => {
        if (data.result === ErrorCode.SUCCESS) {
          goNextStep();
        } else {
          if (data.result === ErrorCode.USER_ID_DUPLICATED) {
            setError({ type: 'id', msg: '이미 사용중인 아이디입니다. 다른 아이디로 입력해 주세요.' });
          } else {
            setError({ type: 'id', msg: ErrorMessage[data.result] });
          }
        }
      });
    }
  };

  return (
    <article id="join-check-id-article">
      <div className="header">
        <img src={Logo} width={91} height={30} />
      </div>
      <div className="content">
        <h2>회원가입</h2>
        <form onSubmit={tryJoin}>
          <LabelInput
            title="아이디" 
            id="join-id" 
            placeholder="아이디 입력"
            defaultValue={inputs.id} 
            onChangeValue={(value: string) => {
              error.type === 'id' && error.msg !== '' && resetError();
              updateInputs('id', value);
            }} 
            errorMsg={error.type === 'id' ? error.msg : ''}
          />
          <LabelInput 
            title="비밀번호" 
            type="password" 
            id="join-pw" 
            placeholder="비밀번호 입력"
            defaultValue={inputs.pw} 
            onChangeValue={(value: string) => {
              error.type === 'pw' && error.msg !== '' && resetError();
              updateInputs('pw', value);
            }} 
            errorMsg={error.type === 'pw' ? error.msg : ''}
          />
          <button className="submit-btn" disabled={disabledNextBtn}>다음</button>
        </form>
        <Link to={'/auth'}>로그인하기</Link>
      </div>
    </article>
  );
};
export default CheckId;