import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { useOutletContext } from 'react-router-dom';
import { currentStatusFormatter } from 'utils/Formatter';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import DeleteReportModal from 'components/v2/modals/DeleteReportModal';
import Icon from 'components/v2/atoms/Icon';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import Report from 'assets/svgs/v2/ico_redcircle_report.svg';
import CreateReportModal from 'components/v2/modals/CreateReportModal';
import CurrentStatusCard from '../components/CurrentStatusCard';
import ReportDetail from '../components/ReportDetail';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import deleteReport from 'graphql/mutations/deleteReport';
import { timeToKr } from 'utils/timeFormatter';
import addWeeklyReport from 'graphql/mutations/addWeeklyReport';
import IsLoading from 'components/v2/atoms/IsLoading';
import { useAuth } from 'contexts/AuthProvider';
import getProjectInfoByOrgan from 'graphql/queries/getProjectInfoByOrgan';
import getWeeklyReportPageByParam from 'graphql/queries/getWeeklyReportPageByParam';

/**
 * <>주간 리포트</>
*/
const WeeklyReport = () => {
  const { role } = useAuth();
  const { projectList, organizationId } = useOutletContext<{ projectList: ProjectInfo[], organizationId: number | null }>();

  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false,
    create: false,
    report: false 
  });
  const [readyToSearch, setReadyToSearch] = useState({ project: -1, name: '' });
  const [search, setSearch] = useState({ project: -1, name: '' });
  const [total, setTotal] = useState({
    totalElement: 0,
    totalPage: 0
  });
  const [rows, setRows] = useState<RowType[] >([]); /* ReportDetail[] */
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [pjList, setPjList] = useState<{name: string; value: number}[]>([]);
  
  /** 주간 리포트 조회 */
  const [getWeeklyReports, { loading: loadingGetWeeklyReportPage }] = getWeeklyReportPageByParam();
  /** 리포트 삭제 */
  const [deleteReports] = deleteReport();
  /** 주간리포트 생성 */
  const [createWeekly] = addWeeklyReport();
  /* 조직id에 따른 pjlist 가져오기 for lg운영자 */
  const [getProjectListByOrganId] = getProjectInfoByOrgan();

  const getWeeklyReportData = useMemo(() => {
    return {
      reqData: {
        projectId: search.project === -1 ? undefined : search.project,
        text: search.name === '' ? undefined : search.name,
        organId: organizationId,
        pageInfo: {
          page: tablePagination.currentPage - 1,
          size: tablePagination.limit,
          orders: [{
            target: tablePagination.target === 'projectName' ? 'projectId' : tablePagination.target === 'modifiedByName' ? 'modifiedBy' : tablePagination.target,
            direction: tablePagination.direction
          }]
        }
      }
    };
  }, [projectList, search, tablePagination, search]);

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: '프로젝트',
        field: 'projectName',
        sort: true
      },
      {
        label: '리포트 이름',
        field: 'name',
        sort: true
      },
      {
        label: '버전',
        field: 'currentVersion',
        sort: true,
        renderCell: (row:RowType) => <>{!row.currentVersion ? '-' : row.currentVersion}</>
      },
      {
        label: '상태',
        field: 'currentStatus',
        sort: true,
        valueGetter: (row:RowType) => currentStatusFormatter(row.currentStatus),
        renderCell: (row:RowType) => <CurrentStatusCard status={row.currentStatus} />
      },
      {
        label: '수정한 사람',
        field: 'modifiedByName',
        sort: true
      },
      {
        label: '수정 시간',
        field: 'modifiedAt',
        renderCell: (row:RowType) => <>{timeToKr(row.modifiedAt)}</>, 
        sort: true

      }
    ];
  }, [rows]);

  const getWeeklyReport = () => {
    getWeeklyReports({ variables: getWeeklyReportData }).then(({ data }) => {
      if(data) {
        const newRows = data.getWeeklyReportPageByParam.content.map(val => ({ ...val, id: val.reportId }));
        setRows(newRows);
        setTotal({
          totalElement: data.getWeeklyReportPageByParam.totalElements,
          totalPage: data.getWeeklyReportPageByParam.totalPages
        });
      }
    });
  }

  useEffect(() => {
    if (role === 'sy_admin') {
      getProjectListByOrganId({variables: { organId: Number(organizationId)}}).then(({ data }) => {
        if (data) {
          setPjList(data.getProjectInfoByOrganId.data.map(val => ({
            name: val.name,
            value: val.projectId
          })))
        }
      })
    } else {
      setPjList(projectList.map(val => ({
        name: val.name,
        value: val.projectId
      })))
    }
  }, [role, organizationId, projectList]);

  useEffect(() => getWeeklyReport(), [getWeeklyReportData]);


  return (
    <div id="weekly-report-page">
      <div className="header flex j-between a-center">
        <div className="flex j-start a-center">
          주간 리포트
        </div>
      </div>
      <div className="search flex j-start">
        <Labeled title="프로젝트">
          <DropdownAtom 
            id="select-project" 
            data={[
              {
                name: '전체',
                value: -1
              },
              ...pjList
            ]} 
            value={{
              name: readyToSearch.project === -1 
                ? '전체' 
                : (pjList.find(pj => pj.value === readyToSearch.project)?.name 
                  ? pjList.find(pj => pj.value === readyToSearch.project)?.name 
                  : ''),
              value: readyToSearch.project
            }} 
            handleClick={(val:DropdownListDataType) => setReadyToSearch(prev => ({
              ...prev,
              project: Number(val.value)
            }))} 
          />
        </Labeled>
        <form 
          className="flex"
          onSubmit={(e) => {
            e.preventDefault();
            setSearch({
              project: readyToSearch.project,
              name: readyToSearch.name
            });
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
        >
          <LabelInput 
            title="리포트 이름"
            placeholder="리포트 이름 검색"
            value={readyToSearch.name}
            onChangeValue={(str) => setReadyToSearch(prev => ({
              ...prev,
              name: str
            }))}
          />
          <div className="btns">
            <button className="big-main-btn">
            검색
            </button>
          </div>
        </form>
      </div>

      <div className='content-wrap'>
        <div className="content">
          {
            rows.length === 0 && total.totalElement !== 0
              ? <div className="content-head-wrap empty flex col j-center a-center">
                <div className="search-icon" />
                <h5>검색 결과를 찾을 수 없습니다.</h5>
                <p>내용을 변경하여 다시 시도해보세요.</p>
              </div> 
              : <>
                <div className="content-head-wrap flex j-between">
                  <div className="head-title flex a-center">
                    <div className="document-icon" /> 리포트 목록
                  </div>
                  <div className="head-btns flex a-center">
                    {checkedList.length > 0 && `${checkedList.length}개 선택됨`}
                    <button
                      className="big-sub-btn delete"
                      disabled={checkedList.length === 0}
                      onClick={() => setModalIsOpen((prev) => ({
                        ...prev,
                        delete: true
                      }))}
                    >
                    삭제하기
                    </button>
                    <button
                      className="big-main-btn register"
                      onClick={() => setModalIsOpen((prev) => ({
                        ...prev,
                        create: true
                      }))}
                    >
                    등록하기
                    </button>
                    <DropdownAtom
                      id="offset-limit"
                      data={TABLE_OFFSET}
                      value={{
                        name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                        value: Number(tablePagination.limit)
                      }}
                      handleClick={(val) => {
                        setTablePagination(prev => ({
                          ...prev,
                          limit: Number(val.value),
                          currentPage: 1
                        }))
                      }}
                    />
                  </div>
                </div>
                <div className="data-grid-wrap">
                  <Table
                    rows={rows}
                    columns={COLUMNS}
                    reportCheckedList={(list) => setCheckedList(list)}
                    reportSelected={(id) => setSelected(id)}
                    sortOption={{
                      target: tablePagination.target,
                      direction: tablePagination.direction,
                      onChangeSort: (target: string, dir: OrderDirection) => {
                        setTablePagination(prev => ({
                          ...prev,
                          target: target,
                          direction: dir
                        }));
                      }
                    }}
                  />
                  {
                    !loadingGetWeeklyReportPage && rows.length === 0 && total.totalElement === 0
                      ? <div className="empty-rows flex j-center a-center">
                        <div className="flex col a-center">
                          <div />
                          <h5>리포트가 없습니다.</h5>
                          <button 
                            className="big-main-btn flex j-center a-center"
                            onClick={() => setModalIsOpen(prev => ({
                              ...prev,
                              create: true
                            }))}
                          >
                            리포트 생성
                          </button>
                        </div>
                      </div>
                      :
                      <div className="pagination-wrapper flex a-center">
                        <p className="flex a-center">전체 <span>{total.totalElement}개</span></p>
                        <TablePagination
                          currentPage={tablePagination.currentPage}
                          updateCurrentPage={(page) => setTablePagination(prev => ({
                            ...prev,
                            currentPage: page
                          }))}
                          totalPage={total.totalPage}
                        />
                      </div>
                  }
                </div>
              </>
          }
        </div>
        { loadingGetWeeklyReportPage && <IsLoading dimmed={rows.length !== 0 } /> }
      </div>

      <DeleteReportModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))}
        title={() => 
          <>
            <Icon width={32} height={32} src={Report} />
            리포트 삭제
          </>
        } 
        onClose={() => { 
          setModalIsOpen(prev => ({
            ...prev,
            delete: false
          }));
        }}
        onDelete={() => {
          const data = {
            reqDelReport: {
              reportIds: checkedList.map(val => Number(val))
            }
          };

          deleteReports({ variables: data }).then(({ data }) => {
            if(data) {
              if (data.deleteReport.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, '리포트 삭제가 완료되었습니다.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }));
                
                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getWeeklyReport();
                }
              } else {
                console.log(data.deleteReport.result);
                useToast(ErrorCode.SUCCESS, '리포트 삭제를 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.SUCCESS, '리포트 삭제를 실패했습니다.');
            }
          });

        }} 
      />

      <CreateReportModal 
        open={modalIsOpen.create} 
        title={() => 
          <>
            <Icon width={32} height={32} src={Report} />
            주간 리포트 생성
          </>
        }
        placeHolder="예) 24.01.01 ~ 24.01.07 주간리포트"
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          create: false
        }))}
        onCreate={(payload) => {
          const addWeeklyReportData = {
            report: {
              ...payload
            }
          };
          createWeekly({variables: addWeeklyReportData}).then(({ data }) => {
            if (data) {
              if (data.addWeeklyReport.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, '생성 요청이 완료되었습니다.');
                setModalIsOpen(prev => ({
                  ...prev,
                  create: false
                }));
                
                getWeeklyReport();
              } else {
                console.log(data.addWeeklyReport.result);
                useToast(ErrorCode.SUCCESS, '생성 요청을 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.SUCCESS, '생성 요청을 실패했습니다.');
            }
          });
        }} 
      />

      {
        selected !== '' && 
        <ReportDetail 
          data={rows.find(val => val.reportId === Number(selected)) as RowType} 
          getOffPage={() => setSelected('')}
          onPublishSuccess={() => getWeeklyReport()}
          relObjectType="report_w"
        />
      }
    </div>
  );
};

export default WeeklyReport;
