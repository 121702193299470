
import { useEffect, useState } from 'react';
import { SandpackCodeEditor, SandpackLayout, SandpackPreview, useActiveCode } from '@codesandbox/sandpack-react';

interface ISandPackCodeProps {
  value: string;
  onChangeText: (val: string) => void
}
const SandPackCode = ({
  value,
  onChangeText
}: ISandPackCodeProps) => {

  const { code, updateCode } = useActiveCode();
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if(!firstLoad) { updateCode(value)}
    // if (firstLoad) { updateCode(value); }
  },[firstLoad]);

  useEffect(() => { onChangeText(code); },[code]);

  return (
    <SandpackLayout 
      onLoad={() => setFirstLoad(true)} 
    >
      <SandpackCodeEditor 
        showLineNumbers
        showInlineErrors
        className="sandpack-editor"
      />
      <SandpackPreview 
        className="sandpack-editor"
      />
    </SandpackLayout>
  );
};
export default SandPackCode;