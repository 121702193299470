import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { ProjectInfo } from '../../types/ProjectInfo';

export interface IGetProjectInfoByOrganVariables {
  organId: number;
}

export interface IGetMyProjectInfoByOrganResponseData {
  getProjectInfoByOrganId: IGqlResponseData<ProjectInfo[]>;
}

/** 프로젝트 조회 상세 */
const getProjectInfoByOrgan = () => useLazyQuery<IGetMyProjectInfoByOrganResponseData, IGetProjectInfoByOrganVariables>(query, { fetchPolicy: 'no-cache' });
export default getProjectInfoByOrgan;