export enum EnumReportType {
  weekly00 = 'weekly00',
  monthly0 = 'monthly0'
}

/* origin currentStatus */
// export enum EnumCurrentStatus {
//   initial0 = 'initial0',
//   analysis = 'analysis',
//   publish0 = 'publish0'
// }

/* 대기중, 작성중, 검토상태, 생성실패, 분석중, 분석실패, 발행완료 */
export enum EnumCurrentStatus {
  waiting = 'waiting', /* 대기중 */
  writing = 'writing', /* 작성중 */
  review = 'review', /* 검토상태 */
  creationFailed = 'creationFailed', /* 생성실패 */
  analyzing = 'analyzing', /* 분석중 */
  analysisFailed = 'analysisFailed', /* 분석실패 */
  issueCompleted = 'issueCompleted', /* 발행완료 */
  initial0 = 'initial0',
  analysis = 'analysis',
  publish0 = 'publish0'
}

export type ReportDetail = {
  reportId: number;
  projectId: number;
  projectName: string;
  name: string; /* 문서 이름 */
  reportType: EnumReportType; /* 주간/월간 타입 */
  startTime: string;
  endTime: string | null;
  currentVersion: string | null;
  currentStatus: EnumCurrentStatus | null; /* 상태 */
  createdBy: string;
  createdByName: string | null;
  createdAt: string;
  modifiedBy: string | null;
  modifiedByName: string | null;
  modifiedAt: string | null;
  // memoId: 
}

export type MemoInfo = {
  id: number;
  relObjectId: number;
  relObjectType: string;
  text: string;
  checkYn: boolean;
  createdBy: string;
  createdByName: string;
}

export type HistReportFileInfo = {
  id: number;
  reportId: number;
  fileName: string;
  statusCode: EnumCurrentStatus; /* 상태: 출력화면상에 초안 검토 발행 */
  version: string;
  comment: string; /* 요청사항 */
  modifiedByName: string;
  modifiedAt: string;
}

export type HistEventByParamType = {
  eventSetting: {
    settingId: number;
    projectId: number;
    eventId: number;
    name: string;
    priorityCode: string;
  } | null;
  event: {
    eventId: number;
    name: string;
    maskCode: string;
  } | null;
  histEvent: {
    id: number;
    cloudInfoId: number | null;
    projectId: number;
    settingId: number;
    alarmStatus: string;
    histEventStatus: string;
    projectName: string;
    createdAt: string;
    modifiedAt: string;
    detEventMessage: string;
  }
  cloudInfo: {
    id: number;
    projectId: number;
    cloudId: number;
    name: string;
    rootAccount: string;
  } | null;
  cloud: {
    cloudId: number;
    cloudKind: string;
    name: string;
    maskCode: string;
    description: string;
  } | null;
}

export type HistAlertType = {
  id: number;
  histEventId: number;
  sender: string;
  senderName: string;
  sendTypeCode: string;
  sentAt: string;
  receiversName: string;
  contents: string;
};

export type HistEventAlertType = {
  eventSetting: {
    settingId: number;
    projectId: number;
    eventId: number;
    name: string;
    priorityCode: string;
    messageId: number;
  }
  message: {
    id: number;
    relObjectId: number;
    relObjectType: string;
    sendTypeCode: string;
    version: string;
    title: string;
    message: string;
    useYn: boolean;
  }
  projectMember: {
    memberId: string;
    projectId: number;
    roleCode: string;
    alertYn: boolean;
    memberName:string;
  }[];
};
