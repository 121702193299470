import Button from 'components/UI/Button';
import '../index.scss';
import { ReportDetail } from 'graphql/types/ReportDetail';
import { statusFormatter } from 'utils/Formatter';
import deleteReport from 'graphql/mutations/deleteReport';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';
import deleteReportFile from 'graphql/mutations/deleteReportFile';
import Table, { ColumnType } from 'components/atoms/Table';

const columns:ColumnType<ReportDetail>[] = [
  { 
    name: '이름',
    value: 'name'
  },{ 
    name: '프로젝트',
    value: 'projectName',
    width: 120
  },{ 
    name: '최종 상태',
    value: 'currentState',
    width: 120,
    renderCell: (row) => <>{statusFormatter(row.currentStatus)}</>
  },{ 
    name: '현재 작성자',
    value: 'modifiedByName',
    width: 120,
    renderCell: (row) => <>{row.modifiedByName || row.createdByName}</>
  }
];

interface IDeleteReportModalProps {
  data: ReportDetail[];
  onClose: () => void;
  open: boolean;
  callback: () => void;
}

const DeleteReportModal = ({
  data,
  open,
  onClose,
  callback
}: IDeleteReportModalProps) => {

  const [deleteReports] = deleteReport();

  const [deleteReportFiles] = deleteReportFile();

  const handleDelete = () => {
    const idArr = data.map((val, index) => {
      return val.reportId;
    });
    deleteReports({ variables: { reqDelReport: { reportIds: idArr } } }).then(({ data }) => {
      if (data?.deleteReport.result === ErrorCode.SUCCESS) {
        useSnackbar(data.deleteReport.result);
        callback();
      }
      else {
        useSnackbar(data?.deleteReport.result as ErrorCode);
      }
      onClose();
    });
    /* type === reportfiles */
    /*     deleteReportFiles({ variables: { report: { histReportIds: idArr } } });
 */ };
 
  return (
    <>
      {open &&
        <div className="modal-bg" onClick={onClose}>
          <div className="delete-wrap" onClick={e => e.stopPropagation()}>
            <div className="header-wrap">
              <span />
              <p className="title">리포트 삭제</p>
            </div>
            <div className="content-wrap">
              <div className="p subtitle">선택한 리포트를 확인하세요.</div>
              <Table 
                rows={data}
                columns={columns}
              />
              <div className="btn-wrap">
                <Button disabled={false} text={'삭제'} handleClick={handleDelete} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default DeleteReportModal;