import { useMutation } from '@apollo/client';
import query from './query';
import { ErrorCode } from '@Types/error';

export interface IDeleteReportVariables {
  reqDelReport: {
    reportIds: number[];
  }
}

export interface IDeleteReportResponseData {
  deleteReport: {
    result: ErrorCode;
  };
}

/** 리포트 삭제 */
const deleteReport = () => useMutation<IDeleteReportResponseData, IDeleteReportVariables>(query);
export default deleteReport;