import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUpdateMyHistEventVariables {
  req: {
    id: number;
    histEventStatus: string;
  }
}

export interface IUpdateMyHistEventResponseData {
  updateHistEvent: IGqlResponseData<undefined>;
}

const updateHistEvent = () => useMutation<IUpdateMyHistEventResponseData, IUpdateMyHistEventVariables>(query);
export default updateHistEvent;