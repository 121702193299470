import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteMemoVariables {
  memo: { ids: number[]; }
}

export interface IDeleteMemoResponseData {
  deleteMemo: Pick<IGqlResponseData, 'result'>;
}

/** 요청사항 삭제 */
const deleteMemo = () => useMutation<IDeleteMemoResponseData, IDeleteMemoVariables>(query);
export default deleteMemo;