import Labeled from 'components/v2/atoms/Labeled';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import { useMemo, useState } from 'react';
import { numberRegex } from 'utils/Regex';
import InputAtom from 'components/v2/atoms/InputAtom';

/**
 * <>일반유저이자 조직소유자만 접근가능한 "카드관리" </>
 */
const CardManagement = () => {

  const [payload, setPayload] = useState({
    cardNum1: '',
    cardNum2: '',
    cardNum3: '',
    cardNum4: '',
    valid1: '',
    valid2: '',
    cvc: '',
    identificationNum: ''
  });

  const enableRegistraionButton = useMemo(() => {
    if (
      payload.cardNum1.length === 4 &&
      payload.cardNum2.length === 4 &&
      payload.cardNum3.length === 4 &&
      payload.cardNum4.length === 4 &&
      payload.valid1.length === 2 &&
      payload.valid2.length === 2 &&
      payload.cvc.length === 3 &&
      (payload.identificationNum.length === 6 || payload.identificationNum.length === 10)
    ) { return false } else return true;
  }, [payload])
  
  return (
    <div id="card-management-page" className='flex j-center a-center'>
      <div className='card-registration-wrap'>
        <div className='header flex a-center'>
          <div className='card-icon' />
          카드 등록
        </div>
        <div className='contents'>
          <Labeled title="카드번호">
            <div className='input-field-wrap flex'>
            <InputAtom
              id="card-num-1"
              value={payload.cardNum1}
              onChangeValue={str => { 
                if (str.length > 4) return;
                setPayload(prev => ({
                  ...prev,
                  cardNum1: str.replace(numberRegex, '')
                }))
              }}
            />
            <InputAtom
              id="card-num-2"
              value={payload.cardNum2}
              onChangeValue={str => { 
                if (str.length > 4) return;
                setPayload(prev => ({
                  ...prev,
                  cardNum2: str.replace(numberRegex, '')
                }))
              }}
            />
            <InputAtom
              id="card-num-3"
              value={payload.cardNum3}
              onChangeValue={str => { 
                if (str.length > 4) return;
                setPayload(prev => ({
                  ...prev,
                  cardNum3: str.replace(numberRegex, '')
                }))
              }}
            />
            <InputAtom
              id="card-num-4"
              value={payload.cardNum4}
              onChangeValue={str => { 
                if (str.length > 4) return;
                setPayload(prev => ({
                  ...prev,
                  cardNum4: str.replace(numberRegex, '')
                }))
              }}
            />
            </div>
          </Labeled>
          <div className='second-wrap flex'>
            <Labeled title="유효기간">
            <div className='input-field-wrap flex'>
              <InputAtom 
                id="valid-month"
                placeholder='MM' 
                value={payload.valid1}
                onChangeValue={str => { 
                  if (str.length > 2) return;
                  setPayload(prev => ({
                    ...prev,
                    valid1: str.replace(numberRegex, '')
                  }))
                }}
              />
              <InputAtom 
                id="valid-year"
                placeholder='YY' 
                value={payload.valid2}
                onChangeValue={str => { 
                  if (str.length > 2) return;
                  setPayload(prev => ({
                  ...prev,
                  valid2: str.replace(numberRegex, '')
                }))
              }}
              />
            </div>
            </Labeled>
            <Labeled title="CVC">
              <div className='cvc-wrap'>
                <InputAtom 
                  id="cvc-input"
                  className='cvc-input' 
                  value={payload.cvc}
                  onChangeValue={str => {
                    if (str.length > 3) return;
                    setPayload(prev => ({
                      ...prev,
                      cvc: str.replace(numberRegex, '')
                    }))
                  }}
                />
                <div className='card-icon' />
              </div>
            </Labeled>
          </div>
          <LabelInput 
            title="생년월일 6자리 또는 사업자번호 10자리" 
            placeholder='입력'
            value={payload.identificationNum}
            onChangeValue={(str) => {
                if (str.length > 10) return;
                setPayload(prev => ({
                ...prev,
                identificationNum: str.replace(numberRegex, '')
              }))
            }}
          />
        </div>
        <div className='btns flex j-between a-center'>
          <p>세금 계산서 발행을 통한 결제를 원하시면, 문의하세요.</p>
          <button 
            className='big-main-btn'
            disabled={enableRegistraionButton}
            onClick={() => console.log('등록 완료')}
          >
            등록하기
          </button>
        </div>

      </div>
    </div>
  )
}

export default CardManagement;