import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { MessageSelectType } from 'graphql/types/MessageSelect';

export interface ISearchMyEventMessageVariables {
  sendTypeCode: string;
}
export interface ISearchMyEventMessageResponseData {
  getEventMessagesByType: IGqlResponseData<MessageSelectType[]>;
}

const lazySearchMyEventMessage = () => useLazyQuery<ISearchMyEventMessageResponseData, ISearchMyEventMessageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazySearchMyEventMessage;