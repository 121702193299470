import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { SearchMemberType } from 'graphql/types/SearchMember';

export interface IGetSearchMemberVariables {
  text: string;
}

export interface IGetSearchMemberResponseData {
  searchMember: IGqlResponseData<SearchMemberType[]>;
}

const lazyGetSearchMember = () => useLazyQuery<IGetSearchMemberResponseData, IGetSearchMemberVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetSearchMember;