import './index.scss';

import TablePagination from 'components/v2/dataDisplay/TablePagination';

const EmptyFavorite = () => {
  return (
    <div id="empty-favorite-page">
      <div className="header flex j-end a-center">
        <button 
          className="setting-btn" 
          disabled
        />
      </div>
      <div className="content">
        <div className="flex col a-center">
          <div className="favorite-icon" />
          <p>자추 찾는 프로젝트를 즐겨찾기에 추가해보세요.</p>
        </div>
      </div>
      <div className="pagination-wrapper flex a-center">
        <p className="flex a-center">전체 <span>0개</span></p>
        <TablePagination 
          currentPage={1}
          totalPage={1}
        />
      </div>
    </div>
  );
};

export default EmptyFavorite; 
