import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { EventRuleInfo } from 'graphql/types/EventRuleInfo';

export interface IAddCategoryVariables {
  reqData: {
    cloudId: number;
    name: string;
    maskCode: string;
    description: string;
    title: string;
    template?: string;
  }
}

export interface IAddCategoryResponseData {
  addCategory: IGqlResponseData<EventRuleInfo>;
}

const addCategory = () => useMutation<IAddCategoryResponseData, IAddCategoryVariables>(query);
export default addCategory;