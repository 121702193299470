import { useEffect, useState } from 'react';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { numberRegex } from 'utils/Regex';
import { useMutation } from 'react-query';
import { IAddEventReq } from 'apis/v2/Manage/schema';
import apis from 'apis/v2';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import addEventRule from 'graphql/mutations/addEventRule';

interface ICreateEventRuleModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onCreateSuccess: () => void; 
}

const CreateEventRuleModal = ({
  title,
  onCreateSuccess,
  ...baseModalProps
}:ICreateEventRuleModalProps) => {

  const [payload, setPayload] = useState({
    name: '',
    maskCode: ''
  });

  /* 이벤트 규칙 생성 */
  const [createEventRule] = addEventRule();

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload({
        name: '',
        maskCode: ''
      });
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="create-eventRule-modal">
        <div className="contents-wrap flex col">
          <LabelInput 
            title="이벤트 규칙 이름"
            value={payload.name}
            placeholder="이벤트 규칙 이름 입력"
            required={true}
            onChangeValue={(val) => setPayload((prev) => ({
              ...prev,
              name: val
            }))}
          />
          <LabelInput 
            title="고유 구분자(마스크코드 8자리 숫자)"
            required={true}
            placeholder='고유 구분자 입력'
            value={payload.maskCode}
            onChangeValue={(str) => {
              if (str.length > 8) return;

              setPayload(prev => ({
              ...prev,
              maskCode: str.replace(numberRegex, '')
              })) 
            }
          }
          />
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center"  
            disabled={payload.name === '' || payload.maskCode.length < 8}
            onClick={() => {
              const addEventData = {
                reqData: {
                  ...payload
                }
              }
              createEventRule({ variables: addEventData }).then(({ data }) => {
                if (data) {
                  if (data.addEvent.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, '이벤트 규칙이 생성되었습니다.');
                    onCreateSuccess();
                  } else {
                    console.log(data.addEvent.result);
                    useToast(ErrorCode.UNKNOWN, '이벤트 규칙 생성을 실패했습니다.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, '이벤트 규칙 생성을 실패했습니다.');
                }
              });
            }}
          >
            생성
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateEventRuleModal;
