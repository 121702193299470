import { memo, useCallback } from 'react';
import BarChart from './BarChart';
import LineChart from './LineChart';
import TableChart from './TableChart';
import PieChart from './PieChart';
import { EnumViewType, Panel } from '@Types/v2/Chart';

/**
 * @prop viewType - chart type
 * @prop defaultData - chart 만들기 위해 필요한 data
 */
export interface IChartViewProps {
  viewType: EnumViewType;
  defaultData: Panel;
}
/**
 * chart 를 실제로 그리기 전 type 에 따라 render 되는 component 분기를 나눠줌
 */
const ChartView = ({
  viewType,
  defaultData
}:IChartViewProps) => {

  const render = useCallback(() => {
    switch (viewType) {
    case 'LINE': return <LineChart defaultData={defaultData} />;
    case 'BAR': return <BarChart defaultData={defaultData} /> ;
    case 'TABLE': return <TableChart defaultData={defaultData} />;
    case 'PIE': return <PieChart defaultData={defaultData} />;
    default: return <>해당되는 차트가 존재하지 않습니다.</>;
    }
  }, [viewType, defaultData]);

  return render();
};

export default memo(ChartView);