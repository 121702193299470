const monthFormatter = (val: number) => {
  if (val >= 0 && val < 1) return '한달 전';
  else if (val >= 1 && val < 2) return '두달 전';
  else if (val >= 2 && val < 3) return '세달 전';
  else if (val >= 3 && val < 4) return '4개월 전';
  else if (val >= 4 && val < 5) return '5개월 전';
  else if (val >= 5 && val < 6) return '6개월 전';
  else if (val >= 6 && val < 7) return '7개월 전';
  else if (val >= 7 && val < 8) return '8개월 전';
  else if (val >= 8 && val < 9) return '9개월 전';
  else if (val >= 9 && val < 10) return '10개월 전';
  else if (val >= 10 && val < 11) return '11개월 전';
  else if (val >= 11 && val < 12) return '12개월 전';
  else if (val >= 12) return '1년 전';
};

/** 날짜를 한국어로 변환 ex) 2023-05-01 08:05:21 -> 1달 전 */
export const timeToKr = (val: string) => {
  const now = new Date();
  const target = new Date(val);

  const newTime = now.getTime() - target.getTime();

  if ((newTime / (1000 * 60 * 60 )) / 24 >= 1 ) {
    if ((newTime / (1000 * 60 * 60 )) / 24 < 30) {
      return `${Math.floor((newTime / (1000 * 60 * 60 )) / 24)}일 전`;
    }
    else if ((newTime / (1000 * 60 * 60 )) / 24 >= 30) {
      return monthFormatter(Math.floor((newTime / (1000 * 60 * 60 )) / 24 / 30));
    }
  }
  else {
    if ((newTime / (1000 * 60 * 60 )) < 24 && (newTime / (1000 * 60 * 60 )) >= 1) {
      return `${Math.floor(newTime / (1000 * 60 * 60 ))}시간 전`;
    }
    else {
      if ((newTime / (1000 * 60)) > 3) {
        return `${Math.floor(newTime / (1000 * 60))}분 전`;
      }
      else return '방금';
    }
  }

  return '방금';
};