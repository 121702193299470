import { useEffect, useMemo, useState } from 'react';
import { OrderDirection } from '@Types/v2/Table';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import Icon from 'components/v2/atoms/Icon';
import SubCategoryGrid from './components/SubCategoryGrid';
import SubCategoryDetail from './components/SubCategoryDetail';
import DeleteSubCategoryModal from 'components/v2/modals/DeleteSubCategoryModal';
import CreateSubCategoryModal from 'components/v2/modals/CreateSubCategoryModal';
import SubCategoryCirleIcon from 'assets/svgs/v2/ico_redcircle_sub_category.svg';
import { SubCategoryInfo } from 'apis/v2/Manage/schema';
import lazyGetSubCategoryPageByParam from 'graphql/queries/getSubCategoryPageByParam';
import getAllCategory from 'graphql/queries/getAllCategory';
import deleteSubCategory from 'graphql/mutations/deleteSubCategory';

const SubCategory = () => {

  const [modalIsOpen, setModalIsOpen] = useState({
    create: false,
    delete: false
  }); 
  
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<SubCategoryInfo[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  const [categoryList, setCategoryList] = useState<{name: string; value: number; }[]>([]);

  const [getAllCategoryList] = getAllCategory();
  const [getSubCategoryPageByParam, { loading: loadingGetSubCategoryPageByParam}] = lazyGetSubCategoryPageByParam();
  const [delSubCategory] = deleteSubCategory();

  const getSubCategoryPageData = useMemo(() => ({
    reqData: {
      text: search === '' ? undefined : search,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [
    tablePagination, 
    search,
    isShowing
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <SubCategoryGrid
          categoryList={categoryList}
          onSubmitSearch={(readyToSearch: string) => {
            setSearch(readyToSearch);
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openCreateModal={() => setModalIsOpen(prev => ({
            ...prev,
            create: true
          }))}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetSubCategoryPageByParam}
        />
      case 'DETAIL': 
        return <SubCategoryDetail
          categoryList={categoryList}
          data={rows.find(val => val.id === Number(selected)) as SubCategoryInfo} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
          onEditSuccess={() => {
            getSubCategoryPageRows();
          }}
        />
    }
  }, [
    tablePagination,
    isShowing, 
    rows, 
    total,
    checkedList, 
    selected,

    categoryList
  ])

  const getSubCategoryPageRows = () => {
    getSubCategoryPageByParam({ variables: getSubCategoryPageData }).then(({ data }) => {
      if (data) {
        setRows(data.getSubCategoryPageByParam.content)
        setTotal({
          totalPage: data.getSubCategoryPageByParam.totalPages,
          totalElement: data.getSubCategoryPageByParam.totalElements
        })
      }
    })
  }

  /* 서브 크테고리 정보 rows 받기 */
  useEffect(() => {
    getSubCategoryPageRows();
  }, [getSubCategoryPageData]);

  /* 모든 카테고리 리스트 가져오기 */
  useEffect(() => {
    getAllCategoryList().then(({ data }) => {
      if (data) {
        setCategoryList(data.getAllCategory.data.map(val => ({
          name: val.name,
          value: val.categoryId
        })));
      }
    })
  }, [])

  return (
    <div className='sub-category'>
      {renderShowing}
      <CreateSubCategoryModal
        categoryList={categoryList}
        open={modalIsOpen.create}
        title={() => <>
          <Icon width={32} height={32} src={SubCategoryCirleIcon} />
          서브 카테고리 생성
        </>}
        onCreateSuccess={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }))
          getSubCategoryPageRows();
        } }
        onClose={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }));
        } } 
       />

      <DeleteSubCategoryModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={SubCategoryCirleIcon}  />
            서브 카테고리 삭제
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {

          const deleteSubCategoryData = {
            reqData: {
              ids: checkedList.map(val => Number(val))
            }
          }
          delSubCategory({ variables: deleteSubCategoryData }).then(({data}) => {
            if (data) {
              if(data.deleteSubCategory.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, '서브 카테고리가 삭제되었습니다.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))

                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getSubCategoryPageRows();
                }
                
                setCheckedList([]);
              } else {
                console.log(data.deleteSubCategory.result);
                useToast(ErrorCode.UNKNOWN, '서브 카테고리 삭제를 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, '서브 카테고리 삭제를 실패했습니다.');
            }
          })
        }} 
      />
    </div>
  );
}
export default SubCategory;