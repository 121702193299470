import { useEffect, useState } from 'react';
import SelectBox from 'components/UI/SelectBox';
import './index.scss';
import Button from 'components/UI/Button';
import { IUploadReportReq, IUploadReportRes } from 'apis/File/schema';
import { useMutation } from 'react-query';
import { useApiContext } from 'contexts/ApiProvider';
import api from 'apis';
import { useSnackbar } from 'hooks/useSnackbar';
import { useAuth } from 'contexts/AuthProvider';
import { HistReportFileInfo } from 'graphql/types/HistReportFileInfo';

interface ICreateReportModalProps{
    open: boolean;
    onClose: () => void;
    reportId: number; 
    projectId: number;
    onCallback: () => void;
    data: HistReportFileInfo | null;
}
const tempSelectIncome = [{
  name: '검토',
  value: 'analysis'
},{
  name: '발행',
  value: 'publish0'
}];
const CreateReportModal = ({ open, onClose, reportId, onCallback, projectId, data }: ICreateReportModalProps) => {
  const { getInstance } = useApiContext();
  const { token } = useAuth();
  const [createData, setCreateData] = useState<IUploadReportReq>({
    statusCode: 'analysis',
    version: data?.version ? String(+data.version + 1) : '1.00',
    comment: '',
    fileName: '',
    resFormatCode: 'excel000',
    reportId: reportId,
    projectId: projectId
  });
  const [file, setFile] = useState<File>();
  const { mutateAsync: _CreateReport } = useMutation((payload:{ file: File, reqUploadReport: IUploadReportReq }) => getInstance().request<IUploadReportRes>(api.File.uploadReport(payload).config));

  const fileUpload = () => {
    const fileInput = document.getElementById('files') as HTMLInputElement;
    if (fileInput.files) {
      setFile(fileInput.files[0]);
      setCreateData(prev => ( { ...prev, fileName: fileInput.files![0].name }));
    }
  };

  const handleCreate = () => {
    if (file) {
      _CreateReport({ file: file, reqUploadReport: createData }).then(({ data }) => {
        if (data.result === 'sc0000') {
          useSnackbar(data.result, '리포트 파일 생성 성공');
          onCallback();
        }
        else {
          useSnackbar(data.result, '오류가 발생하였습니다.');
        }
      });
    }

    /* api 호출 성공시 */
    onClose();
  };

  useEffect(() => {
    setCreateData({ 
      statusCode: 'analysis',
      version: data?.version ? String(+data.version + 1) : '1.00',
      comment: '',
      fileName: '',
      resFormatCode: 'excel000',
      reportId: reportId,
      projectId: projectId
    });
  },[open]);
  
  return (
    <>
      {open && (
        <div className="modal-bg" onClick={onClose}>
          <div className="crmodal-wrap" onClick={e => e.stopPropagation()}>
            <div className="header-wrap">
              <span/>
              <p>
                리포트 파일 등록
              </p>
            </div>
            <div className="contents-wrap cp">
              <p className="title">추가된 보고서를 업로드 합니다.</p>  
              <div className="row">
                <p className="row-title">문서 이름</p>
                <p >{data?.fileName}</p>
              </div>
              <div className="row">
                <p className="row-title">문서 버전</p>
                <p >v{data?.version ? +data.version + 1 : '1.00'}</p>
              </div>
              <div className="row">
                <p className="row-title">메모</p>
                <textarea className="input textarea" onChange={e => setCreateData(prev => ({ ...prev, comment: e.target.value }))}/>
              </div>
              <p className="title">리포트 파일을 선택해주세요.</p>
              <div className="file-wrap">
                <label htmlFor="files">
                  <div className="choice">파일 선택</div>
                </label>
                <input style={{ display: 'none' }} type="file" name="file" id="files" onChange={fileUpload}/>
                <input type="text" className="input" readOnly value={createData.fileName}/>
              </div>
              <div className="modal-btn-wrap">
                <Button disabled={createData.fileName === '' || createData.version === ''} text={'추가'} handleClick={handleCreate}/>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    
  );
};

export default CreateReportModal;