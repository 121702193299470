import './index.scss';
import { RowType } from '@Types/v2/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { enumFormatter } from 'utils/Formatter';
import { timeToKr } from 'utils/timeFormatter';

interface IDeleteCloudInformationModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteMessageModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteCloudInformationModalProps) => {

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-message-modal">
        <div className="content">
          <div className="content-header flex a-center">
            <div>메세지 용도</div>
            <div>수신 타입</div>
            <div>제목</div>
            <div>수정 시간</div>
          </div>
          <div className="content-main">
            {data.map(val => (
              <div key={val.id} className="report-row flex a-center">
                <div>{enumFormatter(val.msgUseType)}</div>
                <div>{enumFormatter(val.msgSenderType)}</div>
                <div>{val.jemok}</div>
                <div>{timeToKr(val.modifiedAt)}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            취소
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
            삭제
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteMessageModal;
