import { gql } from '@apollo/client';

const query = gql`
query searchOrgan($text: String) {
  searchOrgan(text: $text) {
    result
    data {
      ... on Organ {
        organId
        name
        description
        createdAt
        createdBy
        createdByUserId
        modifiedAt
        modifiedBy
        modifiedByUserId
      }
    }
  }
}
`;
export default query;