import LabelInput from 'components/v2/LabelInput';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import apis from 'apis/v2';

interface IDeleteOrganizationModalProps extends IBaseModalProps {
  organId: number;
  data: string;
  projectListLength: number;
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteOrganizationModal = ({  
  organId,
  data,
  projectListLength,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteOrganizationModalProps) => {

  const [passed, setPassed] = useState({
    emptyPj: false,
    completePay: false
  });
  const [orgName, setOrgName] = useState('');

  /* todo api 조직삭제시 payment status 확인 */
  const { mutateAsync: _getPaymentStatusByOrganId } = useMutation((data: { organId: number; }) => apis.Organ.getPaymentStatusByOrganId(data));

  const enableDeleteButton = useMemo(() => {
    if (orgName !== data || !passed.completePay || !passed.emptyPj) {
      return true;
    } else return false;
  }, [orgName, passed])

  useEffect(() => {
    if (!baseModalProps.open) {
      setOrgName('');

      _getPaymentStatusByOrganId({ organId: organId }).then(({ data }) => {
        if (data) {
          if (data.data) {
            setPassed(prev => ({
              ...prev,
              completePay: data.data.paymentYn
            }))
          }
        }
      })
      
      if (projectListLength === 0) {
        setPassed(prev => ({
          ...prev,
          emptyPj: true
        }))
      }
    }


  }, [baseModalProps.open, projectListLength, organId]);

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-organization-modal">

        <div className="row-1">
          조직을 삭제하면, 프로젝트를 포함하여 모든 데이터가 삭제됩니다.
        </div>
        <div className="row-2">
          <h5>조직 삭제를 위해 아래 두가지 사항을 확인해주세요.</h5>
          <div className="flex a-center">
            <div className={`icon ${passed.emptyPj && 'passed'}`} />
            현재 개설된 프로젝트를 모두 삭제해주세요.
          </div>
          <div className="flex a-center">
            <div className={`icon ${passed.completePay && 'passed'}`} />
            결제하지 않은 요금을 납부해주세요.
          </div>
        </div>
        <div className="row-3">
          <LabelInput 
            title={data}
            placeholder="조직 이름 입력"
            value={orgName}
            onChangeValue={(str) => setOrgName(str)}
          />
        </div>
        <div className="btns flex j-between">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            취소
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
            disabled={ enableDeleteButton }
          >
            삭제
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteOrganizationModal;
