import { EnumCurrentStatus } from '@Types/Apis/Report';
import './index.scss';

import { enumFormatter } from 'utils/Formatter';

interface ICurrentStatusCardProps {
  status: string;
}
const CurrentStatusCard = ({
  status
}:ICurrentStatusCardProps) => {
  return (
    <div className={`current-status-card flex j-center a-center ${status}`}>
      {enumFormatter(status)}
    </div>
  );
};

export default CurrentStatusCard;
