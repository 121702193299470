import { lazy, Suspense, useMemo } from 'react';
import './index.scss';
import IsLoading from 'components/v2/atoms/IsLoading';
import { Panel } from '@Types/v2/Chart';

const DefaultPlot = lazy(() => import('components/v2/Charts/withDefaultPlotlyProps'));
const DataTable = lazy(() => import('components/v2/dataDisplay/Table'));

export interface ITableChartProps {
  defaultData: Panel;
}
/** 실제 그려지는 table chart */
const TableChart = ({
  defaultData
}:ITableChartProps) => {

  const tableColumns = useMemo(() => {
    const dataCols: string[] = defaultData.data.columns;
    const result = dataCols.map(d => ({ field: d }));

    return result;
  }, [defaultData]);
  
  const tableRows = useMemo(() => {
    const dataCols: string[] = defaultData.data.columns;
    const dataRows: (string | number)[][] = defaultData.data.rows;

    const result = dataRows.map(row => Object.fromEntries(dataCols.map((column, index) => [column, row[index]]))); 

    return result;
  }, [defaultData])

  return (
    <Suspense fallback={<IsLoading />}>
      <div className='table-chart-wrap' >
        <div>
          <DataTable
            className='chart-style'
            columns={tableColumns}
            rows={tableRows}
          />
        </div>
      </div>

      {/* fake loading trick */}
      <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
        <DefaultPlot data={[]} layout={{}} />
      </div>
    </Suspense>
  );
};

export default TableChart;