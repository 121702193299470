import { gql } from '@apollo/client';

const query = gql`
query getMemberTermsByMemberId($memberId: UUID!) {
  getMemberTermsByMemberId(memberId: $memberId) {
    __typename
    result
    data {
      ... on MemberTerms {
        memberId
        termsId
        acceptYn
        version
        kindCode
        title
        mandatoryYn
        createdAt
        createdByName
        createdBy
        modifiedAt
        modifiedBy
        modifiedByName
      }
    }
  }
}
`;
export default query;