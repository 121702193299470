import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { MemberTermsInfo } from 'apis/v2/Manage/schema';

export interface IGetMemberTermsByMemberIdVariables {
  memberId: string;
}

export interface IGetMemberTermsByMemberIdResponseData {
  getMemberTermsByMemberId: IGqlResponseData<MemberTermsInfo[]>;
}

const lazyGetMemberTermsByMemberId = () => useLazyQuery<IGetMemberTermsByMemberIdResponseData, IGetMemberTermsByMemberIdVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMemberTermsByMemberId;