import { gql } from '@apollo/client';

const query = gql`query getProjectCloudPage($projectCloud: ReqGetProjectMemberPage) {
  getProjectCloudPage(reqGetProjectCloudPage: $projectCloud) {
    __typename
    first
    number
    numberOfElements
    totalPages
    totalElements
    last
    size
    content {
    	__typename
      ... on ProjectCloud {
        id
        name
        cloudId
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
        rootAccount
        attributes
        attrKey1
        attrValue1
        attrKey2
        attrValue2
        attrKey3
        attrValue3
        attrKey4
        attrValue4
        createdByUserId
        modifiedByUserId
      }
    }
  }
}`;
export default query;