import Button from 'components/UI/Button';
import '../index.scss';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';
import deleteReportFile from 'graphql/mutations/deleteReportFile';
import { HistReportFileInfo } from 'graphql/types/HistReportFileInfo';
import Table, { ColumnType } from 'components/atoms/Table';

const columns:ColumnType<HistReportFileInfo>[] = [
  { 
    name: '문서 이름',
    value: 'fileName'
  },{ 
    name: '문서 상태',
    value: 'statusCode',
    width: 75
  },{ 
    name: '버전',
    value: 'version',
    width: 75
  },{ 
    name: '메모',
    value: 'comment',
    width: 75
  },{
    name: '현재 작성자',
    value: 'modifiedByName',
    width: 85
  }
];

interface IDeleteReportFileModalProps {
  data: HistReportFileInfo[];
  onClose: () => void;
  open: boolean;
  callback: () => void;
}

const DeleteReportFileModal = ({
  data,
  open,
  onClose,
  callback
}: IDeleteReportFileModalProps) => {

  const [deleteReportFiles] = deleteReportFile();

  const handleDelete = () => {
    const idArr = data.map((val) => { 
      return val.id;
    });
    deleteReportFiles({ variables: { reqDelReportFile: { histReportIds: idArr, forcibly: false } } }).then(({ data }) => {
      if (data?.deleteReportFile.result === ErrorCode.SUCCESS) {
        useSnackbar(data?.deleteReportFile.result);
        callback();
      }
      else {
        useSnackbar(data?.deleteReportFile.result as ErrorCode);
      }
      onClose();
    });
  };
 
  return (
    <>
      {open &&
        <div className="modal-bg" onClick={onClose}>
          <div className="delete-wrap" onClick={e => e.stopPropagation()}>
            <div className="header-wrap">
              <span />
              <p className="title">리포트 파일 삭제</p>
            </div>
            <div className="content-wrap">
              <div className="p subtitle">선택한 리포트 파일을 확인하세요.</div>
              <Table 
                rows={data}
                columns={columns}
              />
              <div className="btn-wrap">
                <Button disabled={false} text={'삭제'} handleClick={handleDelete} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default DeleteReportFileModal;