import { gql } from '@apollo/client';

// const query = gql`
//   mutation deleteReportFile($report: ReqDelReportFile) {
//     deleteReportFile(reportFile: $report) {
//       result
//     }
//   }
// `;

const query = gql`
  mutation deleteReportFile($reqDelReportFile: ReqDelReportFile) {
    deleteReportFile(reportFile: $reqDelReportFile) {
      __typename
      result
      messages
      data {
        __typename
      }
    }
  }
`;


export default query;