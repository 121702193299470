import { gql } from '@apollo/client';

// const query = gql`
//   mutation deleteProject($project: ReqDelProject) {
//     deleteProject(project: $project) {
//       __typename
//       result
//     }
//   }
// `;

const query = gql`
  mutation deleteProject($reqDelProject: ReqDelProject) {
    deleteProject(project: $reqDelProject) {
      result
      messages
      __typename
      data {
        __typename
        ... on RespCheckIn {
          userToken
        }
      }
    }
  }
`;

export default query;