import { gql } from '@apollo/client';

const query = gql`
  mutation joinProject($reqData: ReqJoinProject) {
    joinProject(reqData: $reqData) {
      result
      data {
        ... on RespCheckIn {
          userToken
          __typename
        }
      }
    }
  }
`;
export default query;