import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import './index.scss';

export interface IBaseModalProps {
  /** 모달이 켜지는 조건 */
  open: boolean;
  /** 모달이 꺼지는 함수 */
  onClose?: () => void;
  /** className을 통한 외부 css 커스텀 @default 'default' */
  className?: string;
  /** 모달 윗 부분에 보여줄 title  */
  title?: () => JSX.Element;
  /** 모달 내 우상단 닫기아이콘 보이기 유무 @default true */
  closeIcon?: boolean;
  /** 모달 바깥쪽 클릭을 통한 모달종료 @default true */
  closeOnClickBg?: boolean;
  /** 모달 애니메이션 재생시간 100ms단위, 설정범위: 양의정수 1~10 @default 3 */
  duration?: number;
  /** 모달 외부 장막의 opacity값 0.1단위, 설정범위: 양의정수 1~10 @default 3 */
  backgroundOpacity?: number;
  /** 기존에 켜져있는 모달의 중첩(레이어드)모달로써 사용할 경우 border-radius값 조정 필요 */
  borderRadius?: number;
}

const BaseModal = ({
  children,
  open,
  onClose,
  className = 'default',
  title,
  closeIcon = true,
  closeOnClickBg = true,
  duration = 3,
  backgroundOpacity = 3,
  borderRadius
}: PropsWithChildren<IBaseModalProps>) => {

  const modalRef = useRef<HTMLDivElement>(null);

  /** 모달 고유 ID */
  const [id] = useState(Date.now());

  useEffect(() => {
    if (open) {
      document.body.setAttribute(`modal-id-${id}`, 'true');
      modalRef.current?.classList.remove('out', 'active');
      modalRef.current?.classList.add('active');
    } else {
      modalRef.current?.classList.add('out');
      document.body.removeAttribute(`modal-id-${id}`);
    }
  }, [open]);

  return (
    <div
      ref={modalRef}
      className="base-modal-container modal-duration-5"
      data-duration={duration}
      data-bg-opacity={backgroundOpacity}
      data-border-radius={borderRadius}
    >
      <div 
        className="modal-background"
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (!closeOnClickBg) return;
          if (e.target === e.currentTarget) onClose && onClose();
        }}
      >
        <div className={`modal ${className}`}>
          <div className="modal-head flex j-between a-center">
            <div className="modal-title flex a-center">{title && title()}</div>
            { closeIcon && <button className="close-btn" onClick={onClose} /> }
          </div>
          <div className="modal-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseModal;
