import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { EventRuleInfo } from 'graphql/types/EventRuleInfo';

export interface IEventRuleVariables {
  reqData: {
    name: string; /* 이벤트규칙이름 */
    maskCode: string;
  }
}

export interface IAddMessageResponseData {
  addEvent: IGqlResponseData<EventRuleInfo>;
}

const addEventRule = () => useMutation<IAddMessageResponseData, IEventRuleVariables>(query);
export default addEventRule;