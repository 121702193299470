import { gql } from '@apollo/client';

const query = gql`
  mutation deleteSubCategory($reqData: ReqDelSubCategory) {
    deleteSubCategory(reqData: $reqData) {
      result
      messages
    }
  }
`;
export default query;