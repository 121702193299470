import { BaseApiConfig } from '..';
import { IDownloadProfileImageByUserIdReq, IDownloadReportReq, IUploadProfileImageReq, IUploadReportReq, IUploadReportRes } from './schema';
import { AxiosInstance, AxiosResponse } from 'axios';
import { IDeleteReportReq, IGenerateReq, IGenerateRes } from 'apis/File/schema';
import { IDefaultRes } from '../schema';

const uploadReport = (instance: AxiosInstance) => 
  (data: IUploadReportReq, token: string): Promise<AxiosResponse<IUploadReportRes,IUploadReportReq>> => {
    const newFormData = new FormData();
    newFormData.append('file', data.file);
    newFormData.append('reqUploadReport', JSON.stringify(data.reqUploadReport));

    return instance.request({
      method: 'POST',
      url: '/file/uploadReport',
      data: newFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    });
  };
  
const downloadReport = (instance: AxiosInstance) => (params: IDownloadReportReq, token: string): Promise<AxiosResponse<BlobPart>> => {
  return instance.request({
    method: 'GET',
    url: `/file/downloadReport/${params.projectId}/${params.hisReportId}/${params.fileName}`,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Accept': '*/*',
      'Authorization': `Bearer ${token}`
    }
  });
};

export const generate = (data: IGenerateReq): BaseApiConfig<IGenerateRes> => ({
  key: 'generate',
  config: {
    method: 'POST',
    url: '/file/generate',
    data: data
  }
});

export const deleteReport = (data:IDeleteReportReq): BaseApiConfig<IDefaultRes> => ({
  key: 'deleteReport',
  config: {
    method: 'POST',
    url: '/file/deleteReport',
    data: data
  }
});

/** 조직, 프로젝트, 사용자 프로필 썸네일 업로드 (주의: member의 경우 id없이) */
const uploadProfileImage = (instance: AxiosInstance) => ( token: string, data:IUploadProfileImageReq ):Promise<AxiosResponse<IDefaultRes,IUploadProfileImageReq>> => {
  const newFormData = new FormData();
  newFormData.append('file', data.file);
  newFormData.append('reqUploadProfileImage', JSON.stringify(data.reqUploadProfileImage));

  return instance.request({
    method: 'POST',
    url: '/resource/uploadProfileImage',
    data: newFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`
    },
  });
};

/** 조직, 프로젝트, 사용자 프로필 썸네일 다운로드 */
const getDownloadProfileImage = (instance: AxiosInstance) => ( token: string, params:any ):Promise<AxiosResponse> => {
  return instance.request({
    method: 'GET',
    url:  params.id 
    ? `resource/downloadProfileImage/${params.kind}/${params.id}` 
    : `resource/downloadProfileImage/${params.kind}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

/* getMemberPageByParam에서 resourceUrl로 채워보낼 rest api */
const downloadProfileImageByUserId = (instance: AxiosInstance) => (token: string, param:string ):Promise<AxiosResponse> => {
  return instance.request({
    method: 'GET',
    url:  `resource/downloadProfileImageByUserId/member/${param}`,
    headers: { 'Authorization': `Bearer ${token}` }
  });
};

/** 썸네일용 이미지 다운로드 */
const downloadThumbnail = (instance: AxiosInstance) => ( token: string, params:any ):Promise<AxiosResponse<IDefaultRes>> => {
  return instance.request({
    method: 'GET',
    url:  params.id 
      ? `resource/downloadThumbnail/${params.kind}/${params.id}` 
      : `resource/downloadThumbnail/${params.kind}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

/* 파일 삭제 */
const deleteFile = (instance: AxiosInstance) => (token: string, id: number):Promise<AxiosResponse<IDefaultRes, {token: string, id: number}>> => {
  return instance.request({
    method: 'POST',
    url: '/file/deleteFile',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: id /* 리포트 파일 id */
  });
};

const File = (instance: AxiosInstance) => ({
  uploadReport: uploadReport(instance),
  downloadReport: downloadReport(instance),
  uploadProfileImage:uploadProfileImage(instance),
  getDownloadProfileImage: getDownloadProfileImage(instance),
  downloadThumbnail:downloadThumbnail(instance),
  downloadProfileImageByUserId: downloadProfileImageByUserId(instance),
  deleteFile: deleteFile(instance)
});
export default File;