import LabelInput from 'components/v2/LabelInput';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { useEffect, useState } from 'react';

interface IDeleteProjectModalProps extends IBaseModalProps {
  data: string;
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteProjectModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteProjectModalProps) => {

  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    if (!baseModalProps.open) {
      setOrgName('');
    }

  }, [baseModalProps.open]);

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-project-modal">

        <div className="header">
          프로젝트를 삭제하면 모든 데이터가 삭제 됩니다. <br />
          프로젝트 이름을 입력하신 후, 삭제가 가능합니다. 
        </div>
        <div className="content">
          <LabelInput 
            title={data}
            placeholder="프로젝트 이름 동일하게 입력"
            value={orgName}
            onChangeValue={(str) => setOrgName(str)}
          />
        </div>
        <div className="btns flex j-between">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            취소
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
            disabled={orgName !== data}
          >
          삭제
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteProjectModal;
