import { gql } from '@apollo/client';

const query = gql`
  mutation updateTerms($reqData: ReqUpdTerms) {
    updateTerms(reqData: $reqData) {
      result
      data {
        ... on Terms {
          termId
          version
          kindCode
          index
          mandatoryYn
          useYn
          title
          text
          createdAt
          createdByUserId
          modifiedAt
          modifiedByUserId
        }
      }
    }
  }
`;
export default query;