import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { TermsInfo } from 'apis/v2/Manage/schema';

export interface IUpdateTermsVariables {
  reqData: {
    version: string;
    kindCode: string;
    mandatoryYn: boolean; 
    useYn: boolean;
    title: string;
    text: string;
  }
}

export interface IUpdateTermsResponseData {
  updateTerms: IGqlResponseData<TermsInfo>;
}

const updateTerms = () => useMutation<IUpdateTermsResponseData, IUpdateTermsVariables>(query);
export default updateTerms;