import { useEffect, useState } from 'react';
import './index.scss';
import { enumFormatter } from 'utils/Formatter';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import joinProject from 'graphql/mutations/joinProject';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { MemberProject } from 'graphql/types/MemberProject';

interface IInvitedCardProps {
  item: MemberProject
}
const InvitedCard = ({
  item
}:IInvitedCardProps) => {
  const { token, updateToken } = useAuth();
  const navigate = useNavigate();

  const [img, setImg] = useState('');

  /* 프로젝트 참여하기 */
  const [joinPj] = joinProject();
  /* 프로젝트 썸네일 이미지 다운로드 */
  const { mutateAsync: _downloadProfileImg } = useMutation((params:{ kind:string; id:number }) => apis.File.getDownloadProfileImage(token, params))


  useEffect(() => {
    const reqProfileData = {
      kind: 'project',
      id: item.projectId
    }

    _downloadProfileImg(reqProfileData).then(({data}) => {
      if (data) { setImg(data.data[0])}
      else { useToast(ErrorCode.UNKNOWN, '프로젝트 썸네일을 불러오지 못했습니다.'); }
    })
  }, [])

  return (
    <div className="invite-info-wrapper">
      <div className="flex-center max-width">
        <div
          className="invite-info-img" 
          {...(img && img !== '' && { 
            style: { backgroundImage: `url('${img}')`, backgroundSize: 'cover' } 
          })}
        />
        <div className="invite-info-box">
          <div>{`${item.organName}의 ${item.projectName}`}</div>
          <div>권한: {enumFormatter(item.roleCode)}</div>
        </div>
      </div>
      <button 
        type="button" 
        className="big-main-btn" 
        onClick={() => {

          const joinProjectData = {
            reqData: {
              organId: item.organId,
              projectIds: [item.projectId]
            }
          }

          /* 프로젝트 참여하기 */
          joinPj({ variables: joinProjectData }).then(({ data }) => {
            if (data) {
              if (data.joinProject) {
                const token = data.joinProject.data[0].userToken;
                updateToken(data.joinProject.data[0].userToken);
                localStorage.setItem('token', token);
                navigate(`/organ/${item.organId}/project/${item.projectId}`);
              }
            }
          })
        }}
      >
        참여하기
      </button>
    </div>
  )
}

export default InvitedCard;