import { gql } from '@apollo/client';

const query = gql`
query searchItems($reqData: ReqSearchItems) {
  searchItems(reqData: $reqData) {
    result
    messages
    data {
      ... on RespSearchItems {
        id
        name
      }
    }
  }
}
`;
export default query;