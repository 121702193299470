import { ButtonHTMLAttributes } from 'react';
import './index.scss';

interface IIconProps {
  width: number;
  height: number;
  src: string;
}

const Icon = ({
  width,
  height,
  src,
  ...defaultButtonProps
}: IIconProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      style={{
        cursor: defaultButtonProps.onClick ? 'pointer' : 'default',
        width: width,
        height: height,
        backgroundImage: `url(${src})`
      }}
      disabled={defaultButtonProps.disabled}
      onClick={defaultButtonProps.onClick}
      className={`icon-atom ${defaultButtonProps.className}`}
    />
  );
};

export default Icon;
