import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { RowType } from '@Types/v2/Table';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import Register from 'assets/svgs/v2/ico_register.svg';
import Edit from 'assets/svgs/v2/ico_edit.svg';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import MemoIcon from 'assets/svgs/v2/ico_memo_icon.svg';
import CubeIcon from 'assets/images/v2/ico_cube.png';
import FolderIcon from 'assets/svgs/v2/ico_open_folder.svg';
import UseYnIcon from 'assets/svgs/v2/ico_useyn.svg';
import Memo from 'components/v2/Memo';
import { IUpdateMemoReq, MemoInfo } from 'apis/v2/Manage/schema';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import lazyGetMemos from 'graphql/queries/getMemos';
import addMemo from 'graphql/mutations/addMemo';

interface IMemoDetailProps {
  memoRelObjTypeList: {
    name: string;
    value: string;
  }[];
  pjList: {
    name: string;
    value: number;
  }[];
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const MemoDetail = ({
  memoRelObjTypeList,
  pjList,
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: IMemoDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false)

  const [detail, setDetail] = useState({
    id: -1,
    relObjectType: '', /* 메모 종류 */
    name: '', /* 아이템 이름 */
    checkYn: false, /* 사용여부 */
    text: '', /* 본문 */
    projectId: -1, 
  });

  const [memoData, setMemoData] = useState<MemoInfo[]>([]);
  const [memoSaveTrigger, setMemoSaveTrigger] = useState(false);
  const [updateMemoResult, setUpdateMemoResult] = useState(false)

  /* memo 요청사항 조회 */
  const [lazyGetMemo] = lazyGetMemos();
  /* memo 추가 */
  const [addMemos] = addMemo();

  const origin = useMemo(() => {
    return {
      id: Number(data.id),
      relObjectType: data.relObjectType, 
      name: data.name, 
      checkYn: data.checkYn, 
      text: data.text,
      projectId: data.projectId,
    }
  }, []);

  const enableButton = useMemo(() => {
    if (
      detail.relObjectType === '' ||
      detail.name === '' ||
      detail.projectId === -1 ||
      detail.text === ''
    ) {return true } else return false;
  }, [detail])

  /** 리포트 메모 재호출 */
  const refetchMemos = () => {
    const selectReport = data;

    if (selectReport) {
      lazyGetMemo({ variables: { reqGetMemo: { 
        relObjectId: Number(selectReport.relObjectId), 
        relObjectType: String(selectReport.relObjectType)
      }}}).then(({ data }) => {
        if (data) {
          if (data.getMemos.data.length === 0) {
            addMemos({ variables: { memo: {
              relObjectId: selectReport.relObjectId, 
              relObjectType: selectReport.relObjectType,
              text: '', 
              checkYn: true
            }} }).then(({ data }) => {
              if (data) {
                setMemoData(data.addMemo.data);
              }
            });
          }
          else {
            setMemoData(data.getMemos.data);
          }
        }
      });
    }
  };

  useEffect(() => {
    refetchMemos();
    setDetail({
      id: Number(data.id),
      relObjectType: data.relObjectType, 
      name: data.name, 
      checkYn: data.checkYn, 
      text: data.text,
      projectId: data.projectId, 
    });

    /* editor js codex-tooltip dom 삭제 */
    return () => {
      const ct_list = document.querySelectorAll('.ct');

      if (ct_list) {
        ct_list.forEach(val => val.remove());
      }
    }
  }, [data]);

  useEffect(() => {
    if (updateMemoResult) {
      setEdit(false);
      onEditSuccess();
      setUpdateMemoResult(false);
    }
  }, [updateMemoResult])

  return (
    <div className='user-information-detail'>
      <div className='detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? data.name : '메모 수정'}
      </div>
      <div className='detail-contents'> 
      {
        !edit &&
        <>
          <InfoCard
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={MemoIcon}
              />
            } 
            title="메모 종류" 
            // required={edit}
            className={`${edit && 'edit'}`}
            content={() => 
              <>
                {/* { !edit
                  ?  */}
                  {enumFormatter(detail.relObjectType)}
                  {/* : <DropdownAtom 
                      id='memo-object'
                      placeholder='선택'
                      data={memoRelObjTypeList} 
                      value={{
                        name: memoRelObjTypeList.find(val => val.value === detail.relObjectType)?.name
                          ? memoRelObjTypeList.find(val => val.value === detail.relObjectType)?.name
                          : '',
                        value: detail.relObjectType 
                      }} 
                      handleClick={(val) => {setDetail(prev => ({
                          ...prev,
                          relObjectType: String(val.value)
                        })) 
                      }} 
                    />
                } */}
              </>
            } 
          />
          <InfoCard
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={CubeIcon} 
              />
            } 
            title="아이템 이름" 
            // required={edit}
            className={`${edit && 'edit'}`}
            content={() => 
              <>
                {/* { !edit ?  */}
                  {detail.name}
                  {/* : <InputAtom
                    value={detail.name}
                    placeholder='아이템 이름 입력'
                    onChangeValue={(val) => setDetail((prev) => ({
                      ...prev,
                      name: val
                    }))}
                  />
                } */}
              </>
            } 
          />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={FolderIcon}
            />
          } 
          title="프로젝트" 
          // required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              {/* { !edit ?  */}
                { pjList.find(val => val.value === detail.projectId)?.name ? pjList.find(val => val.value === detail.projectId)?.name : '' }
                {/* : <DropdownAtom 
                    id='select-project'
                    placeholder='선택'
                    data={pjList} 
                    value={{
                      name: pjList.find(val => val.value === detail.projectId)?.name
                        ? pjList.find(val => val.value === detail.projectId)?.name
                        : '',
                      value: detail.projectId 
                    }} 
                    handleClick={(val) => {setDetail(prev => ({
                        ...prev,
                        projectId: Number(val.value)
                      })) 
                    }} 
                  />
              } */}
            </>
          } 
        />
        </>
      }
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={UseYnIcon}
            />
          } 
          title="사용 여부" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.checkYn ? '사용' : '미사용'
                : <div className='radio-wrap flex a-center'>
                <div className='flex a-center'>
                  <input 
                    type="radio" 
                    id="checkYn-rd1" 
                    name="checkYn" 
                    checked={detail.checkYn}  
                    onChange={() => setDetail(prev => ({
                      ...prev,
                      checkYn: true
                    }))}
                  />
                  <label 
                    htmlFor="checkYn-rd1" 
                    className={`checkYn-raido ${detail.checkYn && 'checked'}`}
                  />
                  사용
                </div>
                <div className='flex a-center'>
                  <input 
                    type="radio" 
                    id="checkYn-rd2" 
                    name="checkYn" 
                    checked={!detail.checkYn} 
                    onChange={() => setDetail(prev => ({
                      ...prev,
                      checkYn: false
                    }))}
                  />
                  <label 
                    htmlFor="checkYn-rd2" 
                    className={`checkYn-raido ${!detail.checkYn && 'checked'}`}
                  />
                  미사용 
                </div>
              </div>
              }
            </>
          } 
        />
        {
          !edit &&
        <>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Register} 
            />
          } 
          title="등록"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Edit} 
            />
          } 
          title="마지막 수정"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
        </>
        }
        <div className="memo-wrap">
          <div className="memo-header flex a-center">
            <div className="star-icon" />
            <h5>본문</h5>
          </div>
          <div className="memo-content">
            <div className='editor-wrap'>
              <Memo 
                data={memoData} 
                reportId={Number(data.id)} 
                projectId={data.projectId} 
                saveTrigger={memoSaveTrigger} 
                updateMemoResult={(status:boolean) => setUpdateMemoResult(status)}
                checkYn={detail.checkYn}
                resetSaveTrigger={() => {setMemoSaveTrigger(false)}}
              />
            </div>
            { !edit && <div className='prevent-edit-wrap' /> }
          </div>
        </div>
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                수정
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                삭제
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                취소
              </button>
              <button 
                className="big-main-btn"
                onClick={() => { 
                  setMemoSaveTrigger(true);
                }}
              >
                수정하기
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default MemoDetail;