import { ErrorCode, ErrorMessage } from '@Types/error';
import { toast } from 'react-toastify';
import SuccessIcon from 'assets/svgs/v2/ico_toast_success.svg';
import ErrorIcon from 'assets/svgs/v2/ico_toast_error.svg';

export const useToast = (code: ErrorCode, text?: string) => {
  if (code === ErrorCode.SUCCESS) {
    return toast.success(text || ErrorMessage[code], {
      position: 'top-center',
      hideProgressBar: true,
      autoClose: 1000,
      closeButton: false,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: false,
      icon: (props) => {
        if (props.type === 'success') {
          return <img src={SuccessIcon} width={24} height={24} />;
        } else {
          return <img src={ErrorIcon} width={24} height={24} />;
        }
      }
    });
  } else {
    return toast.error(text || ErrorMessage[code], {
      position: 'top-center',
      hideProgressBar: true,
      autoClose: 2000,
      closeButton: false,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: false,
      icon: (props) => {
        if (props.type === 'success') {
          return <img src={SuccessIcon} width={24} height={24} />;
        } else {
          return <img src={ErrorIcon} width={24} height={24} />;
        }
      }
    });
  }
};