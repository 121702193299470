import { gql } from '@apollo/client';

const query = gql`
mutation deleteMember($reqDelMember: ReqDelMember) {
  deleteMember(member: $reqDelMember) {
    __typename
    result
    messages
    data {
      __typename
    }
  }
}
`;
export default query;