import { gql } from '@apollo/client';

const query = gql`query searchMyEventMessage($sendTypeCode: String) {
  getEventMessagesByType(sendTypeCode: $sendTypeCode) {
    __typename
    result
    data {
      __typename
      ... on Message {
        id
        title
        message
        createdAt
        createdByName
      }
    }
  }
}`;
export default query;