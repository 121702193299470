import { ErrorCode } from "@Types/error";
import { BaseDummyRes } from "..";
import { IGetProjectCloudByProjectIdRes, IGetProjectMemberByMemberIdRes, IJoinProjectReq, IJoinProjectRes } from "./schema";

const DummyGetProjectCloudByProjectId = (data: { projectId: number; }): Promise<BaseDummyRes<IGetProjectCloudByProjectIdRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetProjectCloudByProjectIdRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [
            {
              id: 1,
              name: 'dummy 계정',
              cloudId: 0,
              rootAccount: '',
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: '',
              createdByName: '',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: '',
              modifiedByName: '',
              attributes: '',
              attrKey1: '',
              attrValue1: '',
              attrKey2: '',
              attrValue2: '',
              attrKey3: '',
              attrValue3: '',
              attrKey4: '',
              attrValue4: '',
              useYn: false,
              projectId: 1,
            }
          ]
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: [
            {
              id: 1,
              name: 'dummy 계정',
              cloudId: 0,
              rootAccount: '',
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: '',
              createdByName: '',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: '',
              modifiedByName: '',
              attributes: '',
              attrKey1: '',
              attrValue1: '',
              attrKey2: '',
              attrValue2: '',
              attrKey3: '',
              attrValue3: '',
              attrKey4: '',
              attrValue4: '',
              useYn: false,
              projectId: 1
            }
          ]
        }
      });
    }
  });

  return promise;
};

const DummytGetProjectMemberByMemberId = (data: { memberId: string; }): Promise<BaseDummyRes<IGetProjectMemberByMemberIdRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetProjectMemberByMemberIdRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [
            {
              memberId: '1',
              projectId: 5,
              roleCode: 'pj_admin',
              alertYn: false,
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1234',
              createdByName: '더미사원',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1234',
              modifiedByName: '더미사원',
              projectName: 'dummy 아연테스트프로젝트',
              organId: 23,
              organName: 'dummy 조직'
              /* 프로젝트 썸네일은 없는데 ???? */
            },
            {
              memberId: '1',
              projectId: 2,
              roleCode: 'pj_membr',
              alertYn: false,
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1234',
              createdByName: '더미사원',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1234',
              modifiedByName: '더미사원',
              projectName: 'dummy 네모프로젝트',
              organId: 23,
              organName: 'dummy 조직'
            },
            {
              memberId: '1',
              projectId: 1,
              roleCode: 'pj_membr',
              alertYn: false,
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1234',
              createdByName: '더미사원',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1234',
              modifiedByName: '더미사원',
              projectName: 'dummy 동그라미프로젝트',
              organId: 23,
              organName: 'dummy 조직'
            },
          ]
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [
            {
              memberId: '1',
              projectId: 1,
              roleCode: 'pj_membr',
              alertYn: false,
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1234',
              createdByName: '더미사원',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1234',
              modifiedByName: '더미사원',
              projectName: 'dummy 프로젝트',
              organId: 23,
              organName: 'dummy 조직'
            },
            {
              memberId: '1',
              projectId: 2,
              roleCode: 'pj_membr',
              alertYn: false,
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1234',
              createdByName: '더미사원',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1234',
              modifiedByName: '더미사원',
              projectName: 'dummy 프로젝트2',
              organId: 23,
              organName: 'dummy 조직'
            },
            {
              memberId: '1',
              projectId: 3,
              roleCode: 'pj_membr',
              alertYn: false,
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1234',
              createdByName: '더미사원',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1234',
              modifiedByName: '더미사원',
              projectName: 'dummy 프로젝트3',
              organId: 23,
              organName: 'dummy 조직'
            },
          ]
        }
      });
    }
  });

  return promise;
};

const DummyJoinProject = (data:IJoinProjectReq): Promise<BaseDummyRes<IJoinProjectRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IJoinProjectRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            userToken: 'dummy join project usertoken'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            userToken: 'dummy join project usertoken'
          }
        }
      });
    }
  });

  return promise;
};



export const Project = {
  getProjectCloudByProjectId: DummyGetProjectCloudByProjectId,
  getProjectMemberByMemberId: DummytGetProjectMemberByMemberId,
  joinProject: DummyJoinProject
};
export default Project;