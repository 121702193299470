import { ErrorCode } from '@Types/error';
import '../index.scss';
import Button from 'components/UI/Button';
import deleteProjectMember from 'graphql/mutations/deleteProjectMember';
import { ProjectMemberPageType } from 'graphql/types/ProjectMemberPage';
import { useSnackbar } from 'hooks/useSnackbar';
import { enumFormatter } from 'utils/Formatter';
import Table, { ColumnType } from 'components/atoms/Table';

const columns:ColumnType<ProjectMemberPageType>[] = [
  { 
    name: '역할',
    value: 'roleCode',
    renderCell: (row) => <>{enumFormatter(row.roleCode)}</>
  },{ 
    name: '소속',
    value: 'organName'
  },{ 
    name: '이름',
    value: 'fullName'
  },{ 
    name: 'ID',
    value: 'userId'
  }
];

interface IDeleteManagerModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  data: ProjectMemberPageType[];
}
const DeleteManagerModal = ({ open, onClose, onSubmit, data }: IDeleteManagerModalProps) => {
  const [deleteMember] = deleteProjectMember();

  const handleDelete = () => {
    const idArr = data.map((val, index) => {
      return val.id;
    });
    deleteMember({ variables: { reqDelProjectMember: { projectId: data[0].projectId, memberIds: idArr } } }).then(({ data }) => {
      if (data) {
        if (data.deleteProjectMember.result === ErrorCode.SUCCESS) {
          useSnackbar(data.deleteProjectMember.result);
          onSubmit();
          onClose();
        }
        else {
          useSnackbar(data.deleteProjectMember.result as ErrorCode);
        }
      } else {
        useSnackbar(ErrorCode.UNKNOWN);
      }
    });
  };
 
  return (
    <>
      {open &&
        <div className="modal-bg" onClick={onClose}>
          <div className="delete-wrap" onClick={e => e.stopPropagation()}>
            <div className="header-wrap">
              <span />
              <p className="title">담당자 삭제</p>
            </div>
            <div className="content-wrap">
              <div className="p subtitle">선택한 사용자를 확인하세요.</div>
              <Table
                rows={data}
                columns={columns}
              />
              <div className="btn-wrap">
                <Button disabled={false} text={'삭제'} handleClick={handleDelete} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default DeleteManagerModal;