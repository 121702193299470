import { gql } from '@apollo/client';

const query = gql`
mutation addMessage($reqAddMessage: ReqAddMessage) {
  addMessage(reqData: $reqAddMessage) {
    __typename
    result
    data {
      ... on Message {
        id
        relObjectId
        relObjectType
        sendTypeCode
        version
        title
        message
        useYn
      }
    }
  }
}
`;
export default query;