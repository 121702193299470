import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import Icon from 'components/v2/atoms/Icon';
import CategoryDetail from './components/CategoryDetail';
import CategoryGrid from './components/CategoryGrid';
import { lazyGetAllRuleCloud } from 'graphql/queries/getAllRuleCloud';
import DeleteCategoryModal from 'components/v2/modals/DeleteCategoryModal';
import CategoryIcon from 'assets/svgs/v2/ico_redcircle_category.svg';
import CreateCategoryModal from 'components/v2/modals/CreateCategoryModal';
import lazyGetCategoryPageByParam from 'graphql/queries/getCategoryPageByParam';
import deleteCategory from 'graphql/mutations/deleteCategory';

const Category = () => {

  const [modalIsOpen, setModalIsOpen] = useState({
    create: false,
    delete: false
  }); 
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  const [search, setSearch] = useState('');

  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [cloudList, setCloudList] = useState<{id: number; value: string; name: string; }[]>([]);

  const [getCategoryPageByParam, {loading: loadingGetCategoryPageByParam}] = lazyGetCategoryPageByParam();
  const [delCategory] = deleteCategory();  
  const [getAllRuleCloud] = lazyGetAllRuleCloud();

  const getCategoryPageData = useMemo(() => ({
    reqData: {
      text: search === '' ? undefined : search,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [
    tablePagination,
    search,
    isShowing
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <CategoryGrid
          onSubmitSearch={(readyToSearch: string) => { 
            setSearch(readyToSearch);
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          cloudList={cloudList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openCreateModal={() => setModalIsOpen(prev => ({
            ...prev,
            create: true
          }))}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetCategoryPageByParam}
        />
      case 'DETAIL': 
        return <CategoryDetail
          cloudList={cloudList}
          data={rows.find(val => val.categoryId === Number(selected)) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
          onEditSuccess={() => {
            getCategoryPageRows();
          }}
        />
    }
  }, [ 
    tablePagination,
    isShowing, 
    rows, 
    total,
    checkedList, 
    selected,

    cloudList
  ])

  const getCategoryPageRows = () => {
    getCategoryPageByParam({ variables:getCategoryPageData }).then(({ data }) => {
      if (data) {
        setRows(data.getCategoryPageByParam.content.map(val => (
          {
            ...val,
            id: val.categoryId
          }
        )))
        setTotal({
          totalPage: data.getCategoryPageByParam.totalPages,
          totalElement: data.getCategoryPageByParam.totalElements
        })
      }
    })
  }

  /* 카테고리 정보 rows 받기 */
  useEffect(() => {
    getCategoryPageRows();
  }, [getCategoryPageData]);

  useEffect(() => {
    getAllRuleCloud().then(({ data }) => {
      if (data) {
        if (data.getAllRuleCloud.data) {
          setCloudList(data.getAllRuleCloud.data.map(val => ({
            id: val.cloudId,
            name: val.name,
            value: val.cloudKind
          })));
        }
      }
    })
  }, [])

  return (
    <div className='category'>
      {renderShowing}

      <CreateCategoryModal
        cloudList={cloudList}  
        open={modalIsOpen.create}
        title={() => 
          <>
            <Icon width={32} height={32} src={CategoryIcon} />
            카테고리 생성
          </>
        }
        onCreateSuccess={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }))
          getCategoryPageRows();
        } }
        onClose={() => {
          setModalIsOpen(prev => ({
            ...prev,
            create: false
          }));
        } }
      />

      <DeleteCategoryModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={CategoryIcon}  />
            카테고리 삭제
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          
          const deleteCategoryData = {
            reqData: {
              ids: checkedList.map(val => Number(val))
            }
          }
          delCategory({ variables: deleteCategoryData }).then(({data}) => {
            if (data) {
              if(data.deleteCategory.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, '카테고리가 삭제되었습니다.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))

                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getCategoryPageRows();
                }

                setCheckedList([]);
                
              } else {
                console.log(data.deleteCategory.result);
                useToast(ErrorCode.UNKNOWN, '카테고리 삭제를 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, '카테고리 삭제를 실패했습니다.');
            }
          })
        }} 
      />
    </div>
  );
}
export default Category;