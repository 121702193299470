import { atom } from 'recoil';
import { TabType } from '../../@Types/content';
import { LoginType, ProjectListType } from '../../@Types/User';

export const loginState = atom<LoginType | null>({
  key: 'loginState',
  default: null
});

export const projectListState = atom<ProjectListType[] | null>({
  key: 'projectListState',
  default: null
});
