/* eslint-disable @typescript-eslint/no-explicit-any */
import Pagination from 'components/DataTable/Pagination';
import HyperLinkText from 'components/UI/HyperLinkText';
import { useMemo } from 'react';
import './index.scss';

export type ColumnType<T> = { 
  name: string; 
  value: string; 
  width?: number;
  type?: {
    link?: string;
  },
  renderCell?: (row:T) => JSX.Element;
};
interface ITableProps{
  rows: any[];
  columns: ColumnType<any>[];
  style?: string;
  pagenation?: { 
    totalPage: number; 
    page: number; 
    size: number;
    onPageChange: (page: number) => void;
  };
  checkOption?: {
    checkList: number[];
    handleCheck: (val: number) => void;
    handleCheckAll: () => void;
  };
  selectOption?: {
    select: number;
    handleSelect: (val: number) => void;
  }
}

const Table = ({ rows, columns, style, pagenation, checkOption, selectOption }:ITableProps) => {
  const rederBody = useMemo(() => {
    return (
      rows.map((val, idx) => {
        return (
          <tr 
            key={idx} 
            className={`${style} ${selectOption && selectOption.select === idx && 'selected'}`} 
            onClick={() => {
              selectOption && selectOption.handleSelect(idx);
            }}
          >
            {checkOption && (
              <td className="checkbox-cell">
                <div>
                  <input onClick={e => {e.stopPropagation(); checkOption.handleCheck(idx);}} type="checkbox" checked={checkOption.checkList.includes(idx)} readOnly/>
                </div>
              </td>
            )}
            <td 
              onClick={() => {
                selectOption && selectOption.handleSelect(idx);
              }}
              className="w32"
            >
              <div>{pagenation ? (pagenation.page * pagenation.size) + idx + 1 : idx + 1}</div>
            </td>
            {columns?.map((col, index) => (
              <td 
                onClick={() => {
                  selectOption && selectOption.handleSelect(idx);
                }} 
                className={col.type?.link ? 'underline' : ''} 
                key={col.value + idx}
              >
                {col.type?.link ? 
                  <HyperLinkText text={val[col.value]} data={[val[col.value]]} type={'text-cloud'}/> : 
                  col.renderCell ? <span>{col.renderCell(val)}</span> : 
                    <span>{val[col.value]}</span>
                }
              </td>
            ))}
          </tr>
        );})
    );
  },[rows, columns, checkOption, selectOption]
  );
  
  return (
    <div className={`table-contanier ${style}`}>
      <table> 
        <thead>
          <tr className="header">
            {checkOption && (
              <th className="checkbox-cell">
                <div>
                  <input type="checkbox" onClick={checkOption && checkOption.handleCheckAll} checked={checkOption && checkOption.checkList.length === rows.length} readOnly/>
                </div>
              </th>
            )}
            <th scope="col" className="w32">
              <div>No</div>
            </th>
            {columns.map((col, idx) => (
              <th 
                scope="col" 
                key={idx} 
                {...(col.width && { style: { width: col.width + 'px' } })}
              >
                <div>
                  {col.name}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody> 
          {rederBody}
        </tbody>
      </table>
      {pagenation && <Pagination totalPage={pagenation.totalPage} size={pagenation.size} page={pagenation?.page} onPageChange={pagenation.onPageChange} />}
    </div>
  );
};
export default Table;