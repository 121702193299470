import { BaseApiConfig } from '..';
import { IDefaultRes } from '../schema';
import { IDeleteReportReq, IGenerateReq, IGenerateRes, IUploadReportReq, IUploadReportRes } from './schema';

/** 파일 업로드(레포트) */
export const uploadReport = (data: { file: File, reqUploadReport: IUploadReportReq }): BaseApiConfig<IUploadReportRes> => {
  const newFormData = new FormData();
  newFormData.append('file', data.file);
  newFormData.append('reqUploadReport',JSON.stringify(data.reqUploadReport));

  return {
    key: 'uploadReport',
    config: {
      method: 'POST',
      url: '/file/uploadReport',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: newFormData
    }
  };};

export const downloadReport = (): BaseApiConfig<unknown> => ({
  key: 'downloadReport',
  config: {
    method: 'GET',
    url: '/file/downloadReport',
    responseType: 'blob',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Accept': '*/*'
    }
  }
});

export const generate = (data: IGenerateReq): BaseApiConfig<IGenerateRes> => ({
  key: 'generate',
  config: {
    method: 'POST',
    url: '/file/generate',
    data: data
  }
});

export const deleteReport = (data:IDeleteReportReq): BaseApiConfig<IDefaultRes> => ({
  key: 'deleteReport',
  config: {
    method: 'POST',
    url: '/file/deleteReport',
    data: data
  }
});

export default {
  uploadReport,
  downloadReport,
  generate,
  deleteReport
};
