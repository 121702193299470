import { lazy, memo, Suspense, useMemo } from 'react';
import { Data } from 'plotly.js';
import IsLoading from 'components/v2/atoms/IsLoading';
import { Panel } from '@Types/v2/Chart';

const DefaultPlot = lazy(() => import('components/v2/Charts/withDefaultPlotlyProps'));

export interface IBarChartProps {
  defaultData: Panel;
}

/** 실제 그려지는 bar chart */
const BarChart = ({
  defaultData,
}:IBarChartProps) => {

  const data: {
    name: string;
    x: string[];
    y: number[];
    type: string;
    marker: { color: string; }
  }[] = useMemo(() => {

    const dataArr: {
      color: string;
      legendName:string;
      x: string[];
      y: number[];
    }[] = defaultData.data

    const result = dataArr.map(d => ({
      name: d.legendName,
      x: d.x,
      y: d.y,
      type: 'bar',
      marker: { color: d.color }
    }))

    return result;
  }, [defaultData])

  return (
    <Suspense fallback={<IsLoading />}>
      <DefaultPlot
        data={data as Data[]}
        layout={{
          font: {
            family: 'Pretendard',
            size: 15
          }
        }}
      />
    </Suspense>
  );
};

export default memo(BarChart);