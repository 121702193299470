import './index.scss';

import { useNavigate } from 'react-router-dom';
import FooterComponent from 'components/v2/FooterComponent';

const Landing = () => {

  const navigate = useNavigate();

  return (
    <div id="landing-page">
      <header className='flex j-between a-center'>
        <div className='header-logo' />
        <div className='btns flex j-between a-center'>
            <button 
              className='login-btn'
              onClick={() => navigate('/auth')}
            >
              로그인
          </button>
          <button 
            className='singup-btn'
            onClick={() => navigate('/auth/signup')}
          >
            회원가입
          </button>
        </div>
      </header>
      
      <div className='contents flex col a-center'>
        <div className='upper-bg' />
        <div className='signup-wrap flex col a-center'>
          <div className='logo' />
          <p>
            Curidy 서비스는 <br />
            정보 보안을 관리하는 솔루션입니다
          </p>
          <button 
            className='big-main-btn'
            onClick={() => navigate('/auth/signup')}
          >
            회원가입
          </button>
        </div>
        <div className='under-bg' />
      </div>
      <FooterComponent />
    </div>
  )
}
export default Landing;