import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { IGetTermsReq } from 'apis/v2/Terms/schema';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

interface IPolicyProps {
  type: 'Service' | 'Security'
}

const Policy = ({
  type
}: IPolicyProps) => {

  const [contents, setContents] = useState('');

  const isService = useMemo(() => {
    if ( type === 'Service') return true;
    else return false;
  },[type]); 

  const { mutateAsync: _getTerms } = useMutation((payload:IGetTermsReq) => apis.Terms.getTerms(payload));

  const getPolicyTerm = () => {
    _getTerms({ kindCode: isService ? 'service0' : 'privacy0', format: 'all00000' })
    .then(({ data }) => {
      if (data.result === 'sc0000') {
        setContents(data.data[0].text);
      } else {
        useToast(ErrorCode.UNKNOWN, '잠시 후 다시 시도해 주세요.');
      }
    });
  }

  useEffect(() => {
    getPolicyTerm();
  },[type]);

  return (
    <div id="policy-page">
      <div className='header'>
        <div className='header-logo' />
      </div>
      <div className='contents'>
        <div className='preview-wrap'>
          <h5>{ type === 'Service' ? '서비스 약관' : '개인정보 정책' }</h5>
          <div 
            className='content-wrap'
            dangerouslySetInnerHTML={{__html: contents }} 
          />
        </div>
      </div>
    </div>
  );
}

export default Policy;