import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import Icon from 'components/v2/atoms/Icon';
import TermsGrid from './components/TermsGrid';
import TermsDetail from './components/TermsDetail';
import TermIcon from 'assets/svgs/v2/ico_redcircle_term.svg';
import DeleteTermModal from 'components/v2/modals/DeleteTermModal';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import lazyGetTermsPageByParam from 'graphql/queries/getTermsPageByParam';
import deleteTerms from 'graphql/mutations/deleteTerms';
import AddTermModal from 'components/v2/modals/AddTermModal';

const Terms = () => {
  const [modalIsOpen, setModalIsOpen] = useState({
    create: false,
    delete: false
  }); 
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  const [search, setSearch] = useState({
    kindCode: 'all',
    searchText: ''
  });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  const [termsKindList, setTermsKindList] = useState<{name: string; value:string;}[]>([]);

  /* 약관 정보 조회 */
  const [getTermsPageByParam, {loading: loadingGetTerms }] = lazyGetTermsPageByParam();
  /* 약관 삭제 */
  const [delTerms] = deleteTerms();
  /* 약관 종류 조회 */
  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  const getTermsData = useMemo(() => ({
    reqData: {
      kindCode: search.kindCode === 'all' ? undefined : search.kindCode, /* 약관 종류 */
      text: search.searchText === '' ? undefined : search.searchText,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [ 
    tablePagination,
    search,
    isShowing
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <TermsGrid
          termsKindList={termsKindList}
          onSubmitSearch={(readyToSearch: {
            kindCode: string;
            searchText: string;
          }) => { 
            setSearch({ ...readyToSearch });
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openCreateModal={() => setModalIsOpen(prev => ({
            ...prev,
            create: true
          }))}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetTerms}
        />
      case 'DETAIL': 
        return <TermsDetail
          termsKindList={termsKindList}
          data={rows.find(val => val.id === Number(selected)) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
          onEditSuccess={() => {
            getTermsRows();
          }}
        />
    }
  }, [ 
    tablePagination,
    isShowing, 
    rows, 
    total,
    checkedList, 
    selected,

    termsKindList,
    loadingGetTerms
  ])

  const getTermsRows = () => {
    getTermsPageByParam({ variables: getTermsData }).then(({ data }) => {
      if (data) {
        setRows(data.getTermsPageByParam.content.map(val => ({ ...val, id: val.termId }
        )))
        setTotal({
          totalPage: data.getTermsPageByParam.totalPages,
          totalElement: data.getTermsPageByParam.totalElements
        })
      }
    })
  }

  /* 약관 정보 rows 받기 */
  useEffect(() => {
    getTermsRows();
  }, [getTermsData]);

  useEffect(() => {
    getEnumTypeCode({variables: { text: 'TermsKindCode' }}).then(({ data }) => {
      if (data) { setTermsKindList(data.getEnumTypeCode.data.map(val => ({
        name: val.name,
        value: val.value
      }))) }
    })
  }, [])

  return (
    <div className='terms'>
      {renderShowing}
      <AddTermModal
        termsKindList={termsKindList}
        open={modalIsOpen.create}
        title={() => 
          <>
            <Icon width={32} height={32} src={TermIcon} />
            약관 생성
          </>
        }
          onCreateSuccess={() => {
            setModalIsOpen(prev => ({
              ...prev,
              create: false
            }))
            getTermsRows(); 
          }}
          onClose={() => {
            setModalIsOpen(prev => ({
              ...prev,
              create: false
            }));
          }}    
        />

      <DeleteTermModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={TermIcon}  />
            약관 삭제
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const deleteTermsData = { reqData: { ids: checkedList.map(val => Number(val))} }

          delTerms({ variables: deleteTermsData }).then(({ data }) => {
            if (data) {
              if(data.deleteTerms.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, '약관이 삭제되었습니다.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))

                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getTermsRows();
                }

                setCheckedList([]);
              } else {
                console.log(data.deleteTerms.result);
                useToast(ErrorCode.UNKNOWN, '약관 삭제를 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, '약관 삭제를 실패했습니다.');
            }
          })
        }} 
      />
    </div>
  );
}
export default Terms;