import ErrorIcon from 'assets/svgs/v2/ico_input_error.svg';
import './index.scss';

interface INotificationProps {
  text: string;
  icon?: string;
}
const NotificationAtom = ({ text, icon }:INotificationProps) => {
  return (
    <div className="notification-atom">
      <img src={icon ? icon : ErrorIcon} width={16} height={16} />{text}
    </div>
  );
};
export default NotificationAtom;