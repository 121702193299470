import CheckTitleIcon from 'assets/svgs/v2/ico_check_title.svg';
import { useAuth } from 'contexts/AuthProvider';

const ResetDone = () => {
  const { logout } = useAuth();

  return (
    <>
      <article id="reset-pw-done-article">
        <h3><img src={CheckTitleIcon} width={32} height={32} />비밀번호 재설정이 완료됐어요.</h3>
        <h4>지금부터 변경된 비밀번호로 로그인을 할 수 있습니다.</h4>
        <div className="button-wrap">
          <button
            className='big-main-btn'
            onClick={() => logout()}
          >
            로그인 하기
          </button>
        </div>
      </article>
    </>
  );
};
export default ResetDone;