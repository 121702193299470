import { useEffect, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import LabelTextarea from 'components/v2/LabelTextarea';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';

interface ICreateProjectModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onCreate: (payload: {
    name: string;
    thirdPartTypeCode: string;
    attrKey1: string;
    attrValue1 : string;
  }) => void;
}

const CreateProjectModal = ({
  title,
  onCreate,
  ...baseModalProps
}:ICreateProjectModalProps) => {

  const [payload, setPayload] = useState({
    name: '',
    thirdPartTypeCode: '',
    attrKey1: '데이터속성',
    attrValue1: '' 
  });
  const [thirdPartyCodeList, setThirdPartyCodeList] = useState<DropdownListDataType[]>([]); 

  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  
  /* err
    첫번째 에러가 난 입력폼에 포커스
    데이터 속성은 선택 입력값
    프로젝트 이름 중복 - 이미 사용한 프로젝트 이름입니다. 
    프로젝트 이름 미입력 - 프로젝트 이름을 입력하세요.
    데이터 유입 미선택 - 데이터 유입을 선택하세요.
  */


  useEffect(() => {
    if (baseModalProps.open) {
      getEnumTypeCode({variables: { text: 'ThirdPartyCode' }}).then(res => {
        if (res.data) {
          setThirdPartyCodeList(res.data.getEnumTypeCode.data.map(val => ({
            name: val.name,
            value: val.value
          })));
        }
      })

      setPayload({
        name: '',
        thirdPartTypeCode: '',
        attrKey1: '데이터속성',
        attrValue1: '' 
      });
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title}
    >
      <div className="create-project-modal">
        <div className="row-1 flex col">
          <LabelInput 
            title="프로젝트 이름"
            value={payload.name}
            placeholder="프로젝트 이름 입력"
            required={true}
            onChangeValue={(val) => setPayload((prev) => ({
              ...prev,
              name: val
            }))}
          />
          <Labeled
            title="데이터 유입"
            required={true}
          >
            <DropdownAtom 
              id={'data-inflow'} 
              data={thirdPartyCodeList} 
              placeholder="선택"
              value={{
                name: thirdPartyCodeList.find(val => val.value === payload.thirdPartTypeCode)?.name 
                  ? thirdPartyCodeList.find(val => val.value === payload.thirdPartTypeCode)?.name 
                  : '',
                  value: payload.thirdPartTypeCode
              }} 
              handleClick={(val: DropdownListDataType) => 
                setPayload((prev) => ({
                  ...prev,
                  thirdPartTypeCode: String(val.value)
                }))
              } 
            />
          </Labeled>
          <LabelTextarea
            title="데이터 속성"
            placeholder="데이터 속성 입력"
            value={payload.attrValue1}
            onChangeValue={(str) => 
              setPayload((prev) => ({
                ...prev,
                attrValue1: str
              }))
            }
          />
        </div>
        <div className="row-2">
          <button 
            className="big-main-btn flex j-center a-center"  
            disabled={payload.name === '' || payload.thirdPartTypeCode === ''}
            onClick={() => onCreate(payload)}
          >
            프로젝트 만들기
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateProjectModal;
