import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { DictItem } from 'apis/v2/Manage/schema';

export interface ISearchItemsVariables {
  reqData: {
    relObjectType: string;
    text: string;
  }
}
export interface ISearchItemsResponseData {
  searchItems: IGqlResponseData<DictItem[]>;
}

const lazySearchItems = () => useLazyQuery<ISearchItemsResponseData, ISearchItemsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazySearchItems;