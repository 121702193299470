import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteCategoryVariables {
  reqData: {
    ids: number[];
  }
}

export interface IDeleteCategoryResponseData {
  deleteCategory: IGqlResponseData<undefined>;
}

const deleteCategory = () => useMutation<IDeleteCategoryResponseData, IDeleteCategoryVariables>(query);
export default deleteCategory;