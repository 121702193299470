import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';

export interface IGetProjectCloudPageVariables {
  projectCloud: {
    projectId: number;
    pageInfo: Paging;
  }
}

export interface IGetProjectCloudPageResponseData {
  getProjectCloudPage: ResponsePage<ProjectCloudType>;
}

const lazyGetProjectCloudPage = () => useLazyQuery<IGetProjectCloudPageResponseData, IGetProjectCloudPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetProjectCloudPage;