import { gql } from '@apollo/client';

const query = gql`
  query getProjectMemberByMemberId($memberId: UUID!) {
    getProjectMemberByMemberId(memberId: $memberId) {
      result
      data {
        ... on MemberProject {
          memberId
          projectId
          roleCode
          emailAlertYn
          smsAlertYn
          statusCode
          projectName
          organId
          organName
        }
      }
    }
  }
`;

export default query;