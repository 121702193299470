import './index.scss';
import Logo from 'assets/svgs/v2/auth_logo.svg';
import LabelInput from 'components/v2/LabelInput';
import VerticalSplitLinkGroup from 'components/v2/VerticalSplitLinkGroup';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authLinks, termLinks } from 'utils/Links';
import NotificationAtom from 'components/v2/atoms/NotificationAtom';
import { useMutation } from 'react-query';
import { ILoginReq } from 'apis/v2/Auth/schema';
import apis from 'apis/v2/index';
import { ErrorCode } from '@Types/error';
import { useAuth } from 'contexts/AuthProvider';

const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({ id: '', pw: '' });
  const [error, setError] = useState('');
  const { mutateAsync: _login } = useMutation((payload:ILoginReq) => apis.Auth.Login(payload));
  const { mutateAsync: _getStatus } = useMutation((token: string) => apis.User.getStatus({ userToken: token }));

  const disabledLoginBtn = useMemo(() => {
    if (inputs.id.length === 0 || inputs.pw.length === 0) {
      return true;
    } else {
      return false;
    }
  },[inputs]);
  const updateInputs = (key: 'id' | 'pw', value: string) => {
    setInputs(prev => ({ ...prev, [key]: value }));
  };
  const tryLogin = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const id = e.currentTarget['login-id'].value;
    const pw = e.currentTarget['login-pw'].value;
    _login({
      userId: id,
      userPw: pw,
      authTypeCode: 'password'
    }).then(({ data: loginData }) => {
      if (loginData.result === ErrorCode.SUCCESS || loginData.result === ErrorCode.PARTIAL_SUCCESS) {
        //이메일 인증 했는지 확인
        _getStatus(loginData.data[0].userToken).then(({ data }) => {
          if (data.result === ErrorCode.EMAIL_UNAUTHORIZED) {
            //이메일 인증 안했으니까 회원가입 이메일 인증 페이지로 이동, 로그인 상태는 저장
            login(loginData.data[0].userToken, false);
            navigate('/auth/signup-sendemail', { state: { access: true, userId: id }});
          } else {
            //이메일 인증 했으니까 정상적으로 이동
            login(loginData.data[0].userToken, true);
          }
        });
      } else {
        setError('입력한 아이디(이메일) 또는 비밀번호가 올바르지 않습니다.');
      }
    });
  };

  return (
    <>
      <article id="login-page-article">
        <div className="header">
          <img src={Logo} width={91} height={30} />
        </div>
        <div className="content">
          <h2>로그인</h2>
          <form onSubmit={tryLogin}>
            <LabelInput 
              title="아이디 또는 이메일" 
              id="login-id" 
              placeholder="아이디 또는 이메일 입력"
              defaultValue={inputs.id} 
              onChangeValue={(value: string) => updateInputs('id', value)} 
            />
            <LabelInput 
              title="비밀번호" 
              type="password" 
              id="login-pw" 
              placeholder="비밀번호 입력"
              defaultValue={inputs.pw} 
              onChangeValue={(value: string) => updateInputs('pw', value)} 
            />
            {error !== '' && 
            <NotificationAtom text={error} />
            }
            <button className="submit-btn" disabled={disabledLoginBtn}>로그인</button>
          </form>
          <VerticalSplitLinkGroup list={authLinks} />
        </div>
      </article>
      <VerticalSplitLinkGroup list={termLinks} />
    </>
  );
};
export default LoginPage;