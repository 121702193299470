export const termLinks = [
  {
    link: '/terms/secure',
    text: '개인정보 정책',
    target: '_blank'
  }, {
    link: '/terms',
    text: '서비스 약관',
    target: '_blank'
  }
];
export const authLinks = [
  {
    link: '/auth/reset-pw-sendemail',
    text: '비밀번호 재설정'
  }, {
    link: '/auth/signup',
    text: '회원가입'
  }
];