import './index.scss';
import { RowType } from '@Types/v2/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { enumFormatter } from 'utils/Formatter';

interface IDeleteMemoModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteMemoModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteMemoModalProps) => {

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-memo-modal">
        <div className="content">
          <div className="content-header flex a-center">
            <div>메모 종류</div>
            <div>아이템 이름</div>
            <div>본문</div>
            <div>사용 여부</div>
          </div>
          <div className="content-main">
            {data.map(val => (
              <div key={val.id} className="report-row flex a-center">
                <div>{enumFormatter(val.relObjectType)}</div>
                <div>{val.name}</div>
                <div>{val.text}</div>
                <div>{val.checkYn ? '사용' : '미사용'}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            취소
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
            삭제
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteMemoModal;
