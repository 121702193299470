import { gql } from '@apollo/client';

const query = gql`
query getProjectCloudByProjectId($projectId: Long!) {
  getProjectCloudByProjectId(projectId: $projectId) {
    result
    messages
    __typename
    data {
      ... on ProjectCloud {
        id
        projectId
        cloudId
        name
        rootAccount
        attributes
        attrKey1
        attrValue1
        attrKey2
        attrValue2
        attrKey3
        attrValue3
        attrKey4
        attrValue4
        useYn
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
      }
    }
  }
}
`;
export default query;