import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDelegateOwnerVariables {
  memberId: string;
}

export interface IDelegateOwnerResponseData {
  delegateOwner: IGqlResponseData<{ userToken: string; }[]>;
}

/** 소유자 위임 */
const delegateOwner = () => useMutation<IDelegateOwnerResponseData, IDelegateOwnerVariables>(query);
export default delegateOwner;