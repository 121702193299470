import { gql } from '@apollo/client';

const query = gql`
  mutation deleteOrgan($organ: ReqDelOrgan) {
    deleteOrgan(organ: $organ) {
      __typename
      result
    }
  }
`;

export default query;