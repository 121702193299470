import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './styles/reset.scss';
import './index.scss';
import App from './App';
import { RecoilRoot } from 'recoil';
import GraphqlProvider from './contexts/GraphqlProvider';
import ModalProvider from './contexts/ModalProvider';
import ApiProvider from './contexts/ApiProvider';
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from './contexts/AuthProvider';
import { Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import GlobalModalProvider from 'contexts/v2/GlobalModalProvider';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const RenderApp = () => {
  return (
    <RecoilRoot>
      <AuthProvider>
        <ApiProvider>
          <GraphqlProvider>
            <GlobalModalProvider> {/** v2 */}
              <ModalProvider> {/** v1 */}
                <Suspense fallback={<div>...loading</div>}>
                  <App />
                </Suspense>
              </ModalProvider>
            </GlobalModalProvider>
          </GraphqlProvider>
        </ApiProvider>
      </AuthProvider>
    </RecoilRoot>
  );
};

root.render(
  <BrowserRouter>
    <RenderApp />
  </BrowserRouter>
);
