import { IDefaultRes } from 'apis/v2/schema';
import { AxiosInstance, AxiosResponse } from 'axios';
import { IGetMemberByIdRes } from './schema';
import { ErrorCode } from '@Types/error';
import { BaseDummyRes } from '..';


/** 프로젝트 참여 요청 이메일을 통하여 시작되며, 이메일이 유효한지 인증하는 기능 (토큰으로 확인) */
const memberVerify = (instance: AxiosInstance) => (token:string ):Promise<AxiosResponse<IDefaultRes, string>> => {
  return instance.request({
    method: 'POST',
    url: '/project/memberVerify',
    headers: {
      'authToken': `${token}`
    }
  });
};

const DummyGetMemberById = (data: { memberId: string }): Promise<BaseDummyRes<IGetMemberByIdRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetMemberByIdRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'dummy get member by id',
            first: false,
            last: false,
            number: 0,
            size: 1,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1,
            content: [{
              id: 'safdafaf',
              fullName: '스폰지밥',
              userId: 'dummyUserId',
              email: 'spongeBob@gmail.com',
              organId: 23,
              mobileNumber: '+821012345678',
              ranks: '사원',
              retryCount: 22, /* 로그인 실패 횟수 */
              loginTypeCode: 'dummy 로그인유형', /* 로그인유형 */
              systemAuthCode: 'normal00', /* 시스템 권한 코드 */
              statusCode: 'initial0', /* 상태 코드 */
              pictureResourceId: 123214124123123, /* 프로필사진 */
              alertYn: false, /* 알림설정 */
              description: 'dummy 안녕하세요~', /* 소개 */
              createdAt: '2024-01-25T01:57:28.037Z',
              createdBy: 'dummy1234',
              createdByName: 'dummy 사원',
              modifiedAt: '2024-01-25T01:57:28.037Z',
              modifiedBy: 'dummy1234',
              modifiedByName: 'dummy 사원',
              organName: '니모의 꿈 공장' /* 조직이름 */
            }]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'dummy get member by id',
            first: false,
            last: false,
            number: 0,
            size: 1,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1,
            content: [{
              id: 'safdafaf',
              fullName: '스폰지밥',
              userId: 'dummyUserId',
              email: 'spongeBob@gmail.com',
              organId: 23,
              mobileNumber: '+821012345678',
              ranks: '사원',
              retryCount: 22, /* 로그인 실패 횟수 */
              loginTypeCode: 'dummy 로그인유형', /* 로그인유형 */
              systemAuthCode: 'normal00', /* 시스템 권한 코드 */
              statusCode: 'initial0', /* 상태 코드 */
              pictureResourceId: 123214124123123, /* 프로필사진 */
              alertYn: false, /* 알림설정 */
              description: 'dummy 안녕하세요~', /* 소개 */
              createdAt: '2024-01-25T01:57:28.037Z',
              createdBy: 'dummy1234',
              createdByName: 'dummy 사원',
              modifiedAt: '2024-01-25T01:57:28.037Z',
              modifiedBy: 'dummy1234',
              modifiedByName: 'dummy 사원',
              organName: '니모의 꿈 공장' /* 조직이름 */
            }]
          }
        }
      });
    }
  });

  return promise;
};


const Member = (instance: AxiosInstance) => ({
  memberVerify: memberVerify(instance),
  getMemberById: DummyGetMemberById,
});
export default Member;