import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import LabelInput from 'components/v2/LabelInput';
import NotificationAtom from 'components/v2/atoms/NotificationAtom';
import { checkPw } from 'utils/v2/ValidCheck';

interface IResetPwModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onReset: (pw: string) => void;
}

const ResetPwModal = ({
  title,
  onReset,
  ...baseModalProps
}: IResetPwModalProps) => {

  const [pw, setPw] = useState({
    currentPw: '',
    newPw: '',
    checkPw: ''
  });
  const [error, setError] = useState('');

  const ableResetPwMemo = useMemo(() => {
    if (pw.currentPw === '' || pw.newPw !== pw.checkPw || checkPw(pw.newPw) || checkPw(pw.checkPw)) {
      return true;
    } else return false;
  }, [pw])


  useEffect(() => {
    if (!baseModalProps.open) {
      setPw({
        currentPw: '',
        newPw: '',
        checkPw: ''
      });
      setError('');
    }
  }, [baseModalProps.open]);
  
  useEffect(() => {
    if (pw.newPw !== '' && checkPw(pw.newPw)) {
      setError('8자리 이상 32자리 이하의 숫자,영문자,특수문자를 조합해주세요.');
    } else {
      if (pw.newPw !== pw.checkPw) {
        setError('입력한 비밀번호가 일치하지 않습니다.');
      } else {
        setError('');
      }
    }
  }, [pw]);

  return (
    <BaseModal
      title={title} 
      {...baseModalProps}
    >
      <div className="reset-pw-modal">
        <div>
          <LabelInput 
            title="현재 비밀번호" 
            type="password" 
            id="reset-current-pw" 
            placeholder="현재 비밀번호 입력"
            value={pw.currentPw} 
            onChangeValue={(value: string) => {
              if (value.length > 32) return;

              setPw(prev => ({
                ...prev,
                currentPw: value
              }));
            }} 
          />
          <LabelInput 
            title="새로운 비밀번호" 
            type="password" 
            id="reset-new-pw"
            placeholder="새로운 비밀번호 입력"
            value={pw.newPw} 
            onChangeValue={(value: string) => { 
              if (value.length > 32) return;

              setPw(prev => ({
                ...prev,
                newPw: value
              }));
            }}
          />
          <LabelInput 
            title="새로운 비밀번호 확인" 
            type="password" 
            id="reset-pw-check"
            placeholder="새로운 비밀번호 확인 입력"
            value={pw.checkPw} 
            onChangeValue={(value: string) => {
              if (value.length > 32) return;
              setPw(prev => ({
                ...prev,
                checkPw: value
              }));
            }}
          />
          {error !== '' && <NotificationAtom text={error} /> }
        </div>
        <button 
          className="big-main-btn"
          onClick={() => onReset(pw.newPw)}
          disabled={ ableResetPwMemo }
        >
          비밀번호 재설정
        </button>
      </div>
    </BaseModal>
  );
};

export default ResetPwModal;
