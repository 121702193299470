import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { IGetResourcePageByParamReq } from 'apis/v2/Manage/schema';
import { useParams } from 'react-router-dom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { enumFormatter } from 'utils/Formatter';
import ResourceGrid from './components/ResourceGrid';
import ResourceDetail from './components/ResourceDetail';

const Resource = () => {

  const { organId } = useParams();
  
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  const [search, setSearch] = useState({
    relObjectType: 'all',
    searchText: ''
  });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [selected, setSelected] = useState<string>('');
  /* 리소스 종류 리스트 */
  const [relObjTypeList, setRelObjTypeList] = useState<{name: string; value: string}[]>([]);
  /* 파일 형식 리스트 */
  const [resFormatCodeTypeList, setResForamtCodeTypeList] = useState<{name: string; value: string}[]>([]);

  /* todo api (개발예정) *//* 리소스 조회 */
  const { mutateAsync: _getResourcePageByParam } = useMutation((data:IGetResourcePageByParamReq) => apis.Manage.getResourcePageByParam(data));
  /* 리포트 종류 조회 */
  const [getResRelObjectType] = lazyGetEnumTypeCode(); /* ResRelObjectType */
  /* 파일 형식 조회 */
  const [getResFormatCode] = lazyGetEnumTypeCode(); /* getResFormatCode */

  const getResourceData = useMemo(() => ({
    ReqGetResourcePage: {
      relObjectType: search.relObjectType === 'all' ? undefined : search.relObjectType,
      text: search.searchText === '' ? undefined : search.searchText,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target === 'modifiedByName' ? 'modifiedBy' : tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [ 
    organId,
    tablePagination,
    search,
    isShowing
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <ResourceGrid
          relObjTypeList={relObjTypeList}
          onSubmitSearch={(readyToSearch: {
            relObjectType: string;
            searchText: string;
          }) => { 
            setSearch({ ...readyToSearch });
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          // isLoading={} /* todo */
        />
      case 'DETAIL': 
        return <ResourceDetail
          data={rows.find(val => val.id === Number(selected)) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
        />
    }
  }, [
    tablePagination,
    isShowing, 
    rows, 
    total,
    selected,

    relObjTypeList,
    resFormatCodeTypeList
  ])

  const getResourceRows= () => {
    _getResourcePageByParam(getResourceData).then(({ data }) => {
      if (data) {
        if (data.data) {
          setRows(data.data.content)
          setTotal({
            totalPage: data.data.totalPages,
            totalElement: data.data.totalElements
          })
        }
      }
    })
  }

  /* 사용자 정보 rows 받기 */
  useEffect(() => {
    getResourceRows();
  }, [getResourceData])

  useEffect(() => {
    /* 리소스 종류 리스트 */
    getResRelObjectType({ variables: { text: 'ResRelObjectType'}}).then(({ data }) => {
      if (data) {
        setRelObjTypeList(data.getEnumTypeCode.data.map(val => ({
          name: String(enumFormatter(val.value)),
          value: val.value
        })))
      }
    })

    /* 파일형식 리스트 */
    getResFormatCode({ variables: { text: 'ResFormatCode' }}).then(({ data }) => {
      if (data) {
        setResForamtCodeTypeList(data.getEnumTypeCode.data.map(val => ({
          name: String(enumFormatter(val.value)),
          value: val.value
        })));
      }
    })
  }, [])

  return (
    <div className='resource'>
      {renderShowing}
    </div>
  );
}
export default Resource;