import { useEffect } from 'react';
import './index.scss';

export type SelectDataType = {
  name: string,
  value: string | number;
};

interface ISelectBoxProps{
  id: string;
  data: SelectDataType[];
  handleClick: (val: SelectDataType) => void;
  value:SelectDataType;

  style?: string;
  placeholder?: string;
  disabled?: boolean;

  forceClose?: boolean;
}

const SelectBox = ({
  id,
  data,
  handleClick,
  value,
  style,
  placeholder,
  disabled = false,
  forceClose
}: ISelectBoxProps) => {
  const openMenu = (wrapperId: string) => {
    Array.from(document.getElementsByClassName('selectbox-list')).forEach(el => {
      const parentEl = el.parentElement;
      if (parentEl?.id === wrapperId) {
        if (el.classList.contains('none')) {
          el.classList.remove('none');
        } else {
          el.classList.add('none');
        }
      } else {
        el.classList.add('none');
      }
    });
  };
  const closeAll = () => {
    Array.from(document.getElementsByClassName('selectbox-list')).forEach(el => {
      el.classList.add('none');
    });
  };

  useEffect(() => {
    if (forceClose) {
      closeAll();
    }
  },[forceClose]);
  useEffect(() => {
    document.addEventListener('click', closeAll);

    return () => {
      document.removeEventListener('click', closeAll);
    };
  },[]);
    
  return (
    <div className={`selectbox-wrap ${style}`} id={id + '-wrap'}>
      <div className={`selectbox-value ${placeholder && value.name === '' ? 'placeholder' : ''}`} onClick={(e) => {
        e.stopPropagation();
        openMenu(id + '-wrap');
      }} id={id}>
        <p>{value.name === '' ? (placeholder || '') : value.name}</p>
        <span />
      </div>
      {!disabled && <div className="selectbox-list none">
        {data.map((val, idx) => (
          <div className="list-item" key={idx} onClick={(e) => {
            handleClick(val); 
            e.currentTarget.parentElement?.classList.add('none');
          }}>{val.name}</div>
        ))}
      </div>}
    </div>
  );
};
export default SelectBox;