import { gql } from '@apollo/client';

const query = gql`mutation updateProjectCloud($reqUpdProjectCloud: ReqUpdProjectCloud) {
  updateProjectCloud(reqUpdProjectCloud: $reqUpdProjectCloud) {
    __typename
    result
    messages
    data {
      __typename
      ... on ProjectCloud {
        id
        projectId
        cloudId
        name
        attrKey1
        attrValue1
        attrKey2
        attrValue2
      }
    }
  }
}`;
export default query;