import { gql } from '@apollo/client';

const query = gql`mutation addProjectCloud($reqAddProjectCloud: ReqAddProjectCloud) {
  addProjectCloud(reqAddProjectCloud: $reqAddProjectCloud) {
    __typename
    result
    messages
    data {
      ... on ProjectCloud {
        __typename
        id
        cloudId
        projectId
        name
        attrKey1
        attrValue1
        createdByName
      }
    }
  }
}`;
export default query;