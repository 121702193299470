import { useEffect, useState } from 'react';
import './index.scss';
import BaseModal, { IBaseModalProps } from '../BaseModal';
import LabelInput from 'components/v2/LabelInput';
import LabelTextarea from 'components/v2/LabelTextarea';
import Labeled from 'components/v2/atoms/Labeled';
import { byteSizeFormatter } from 'utils/Formatter';

interface IDocRegisterModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  onRegister: (payload: {
    reportName: string;
    version: string;
    memo: string;
    file: File;
  }) => void;
}

const DocRegisterModal = ({
  title,
  onRegister,
  ...baseModalProps
}:IDocRegisterModalProps) => {
  const [payload, setPayload] = useState<{
    reportName: string;
    memo: string;
    version: string;
    file: File | null;
    attachName: string;
  }>({
    reportName: '', /* 리포트 이름 */
    version: '', /* 버전: 문서이름을 기반으로 버전이 붙으며 자동으로 생성 */
    memo: '', /* 메모 */
    file: null, /* 엑셀파일 */
    attachName: '' /* 첨부 */
  });

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload({
        reportName: '',
        version: '',
        memo: '',
        file: null,
        attachName: ''
      });
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="doc-register-modal">
      <div className="border-line" />
        <div className="content-wrap">
          <LabelInput 
            title="리포트 이름"
            placeholder="하단 첨부란에 파일을 첨부해주세요."
            disabled={true}
            value={payload.reportName}
          />
          <LabelInput 
            title="문서 버전"
            placeholder="문서이름을 기반으로 버전이 자동으로 생성됩니다."
            disabled={true}
            value={payload.version}
          />
          <LabelTextarea 
            title="메모" 
            placeholder="메모 입력"
            value={payload.memo}
            onChangeValue={str => setPayload(prev => ({
              ...prev,
              memo: str
            }))}
          />
          <Labeled
            title="첨부"
          > 
            <div className="flex a-center">
              <div className="file-name flex a-center">
                {payload.attachName === '' ? '파일선택' : payload.attachName}
              </div>
              <div className="file-upload-wrap flex col j-center">
                <input 
                  id="file-upload" 
                  type="file"
                  accept=".xls,.xlsx"
                  onChange={(e) => {
                    if (e.target.files) {
                      const file = e.target.files[0];

                      if (!file.name.includes('v')) {
                        alert('파일명에 버전을 명시해주세요. 예) v1.00');
                      } else {
                        setPayload((prev) => ({
                          ...prev,
                          reportName: `${file.name}`,
                          attachName: `${file.name} (${byteSizeFormatter(file.size)})`,
                          version: `v${file.name.split('v')[1].substring(0, 4)}`,
                          file: file
                        }));
                      }
                      
                      e.target.value = '';
                    }
                  }}
                />
                { payload.reportName === '' ?
                  <label 
                    className="file-upload-label" 
                    htmlFor="file-upload"
                  />
                  : <button 
                    className="cancel-btn" 
                    onClick={() => setPayload(prev => ({
                      ...prev,
                      reportName: '',
                      attachName: '',
                      version: '',
                      file: null
                    }))}
                  />
                }
              </div>
            </div>
          </Labeled>
        </div>
        <div className="btns">
          <button 
            className="big-main-btn"
            disabled={payload.reportName === '' || payload.file === null}
            onClick={() => {
              const data = {
                reportName: payload.reportName,
                version: payload.version,
                memo: payload.memo,
                file: payload.file as File
              };

              onRegister(data);  
            }}
          >
            등록하기
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DocRegisterModal;
