import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import CreateProjectModal from 'components/modals/CreateProjectModal';
import CreateCompanyModal from 'components/modals/CreateCompanyModal';
import UserUpdateModal from 'components/modals/UserUpdateModal';
import CreateManagerModal from 'components/modals/CreateManagerModal';
import CreateCloudModal from 'components/modals/CreateCloudModal';
import CreateMessageModal from 'components/modals/CreateMessageModal';
import CreateReportModal from 'components/modals/CreateReportModal';
import CreateDraftModal from 'components/modals/CreateDraftModal';
import CreateEventModal from 'components/modals/CreateEventModal';
import DeleteReportModal from 'components/modals/DeleteReportModal';
import { ReportDetail } from 'graphql/types/ReportDetail';
import { HistReportFileInfo } from 'graphql/types/HistReportFileInfo';
import DeleteReportFileModal from 'components/modals/DeleteReportFileModal';
import DeleteCompanyModal from 'components/modals/DeleteCompanyModal';
import { OrganInfo } from 'graphql/types/OrganInfo';
import { ProjectMemberPageType } from 'graphql/types/ProjectMemberPage';
import UpdateManagerModal from 'components/modals/UpdateManagerModal';
import DeleteManagerModal from 'components/modals/DeleteManagerModal';
import DeleteProjectModal from 'components/modals/DeleteProjectModal';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';
import DeleteCloudModal from 'components/modals/DeleteCloudModal';
import UpdateCloudModal from 'components/modals/UpdateCloudModal';
import { MessagePageType } from 'graphql/types/MessagePage';
import DeleteMessageModal from 'components/modals/DeleteMessageModal';
import { EventSettingPageType } from 'graphql/types/EventSettingPage';
import DeleteEventSettingModal from 'components/modals/DeleteEventSettingModal';
import SendAlarmModal from 'components/modals/SendAlarmModal';
import { ToastContainer } from 'react-toastify';

type ModalContextType = {
  create: {
    createProject: (organ: { id: number, name: string }, onClose: () => void) => void;
    createReport: (type: string, reportId: number, callback:() => void, projectId: number, data: HistReportFileInfo | null) => void;
    createCompany: (type: string, onClose: () => void, defaultData?: {
      id: number;
      name: string;
      description: string;
    }) => void;
    createCloud: (onSubmit: () => void, projectId: number) => void;
    createDraft: (type: string, callback:() => void) => void;
    createEvent: (data: {
      type: 'create' | 'edit';
      companyId: number;
      onSubmit: () => void;
      defaultData?: EventSettingPageType;
    }) => void;
    createMessage: (type: string, onConfirm: () => void, data?: MessagePageType) => void;
    createManager: (projectId: number, refetch: () => void) => void;
  },
  delete: {
    deleteReport: (type: 'week' | 'month', data: ReportDetail[], callback:() => void) => void;
    deleteReportFile: (data: HistReportFileInfo[], callback:() => void) => void;
    deleteCompany: (callback: () => void, data: OrganInfo[]) => void;
    deleteManager: (onSubmit: () => void, data: ProjectMemberPageType[]) => void;
    deleteProject: (onSubmit: () => void, data: { id: number, name: string }) => void;
    deleteCloud: (onSubmit: () => void, data: ProjectCloudType[]) => void;
    deleteMessage: (onSubmit: () => void, data: MessagePageType[]) => void;
    deleteEventSetting: (onSubmit: () => void, data: EventSettingPageType[]) => void;
  },
  update: {
    updateUser: (userId: string) => void;
    updateManager: (val: {
      projectId: number;
      data: ProjectMemberPageType;
      onSubmit: () => void;
    }) => void;
    updateCloud: (val: {
      onSubmit: () => void;
      data: ProjectCloudType;
    }) => void;
  }
  sendAlarm: {
    sendAlarm: (val: {
      onSubmit: () => void;
      data: { id: number, projectId: number, settingId: number, projectName: string };
    }) => void;
  }
}

const ModalContext = createContext<ModalContextType>({
  create: {
    createProject: () => null,
    createCompany: () => null,
    createCloud: () => null,
    createDraft: () => null,
    createEvent: () => null,
    createMessage: () => null,
    createManager: () => null,
    createReport: () => null
  },
  delete: {
    deleteReport: () => null,
    deleteReportFile: () => null,
    deleteCompany: () => null,
    deleteManager: () => null,
    deleteProject: () => null,
    deleteCloud: () => null,
    deleteMessage: () => null,
    deleteEventSetting: () => null
  },
  update: {
    updateUser: () => null,
    updateManager: () => null,
    updateCloud: () => null
  },
  sendAlarm: {
    sendAlarm: () => null
  }
});

/* provider */
const ModalProvider = ({ children }: PropsWithChildren ) => {
  const [createProject, setCreateProject] = useState<{
    open: boolean;
    organ: { id: number, name: string };
    onClose: () => void;
      }>({
        open: false,
        organ: { id: -1, name: '' },
        onClose: () => null
      });

  const [createCompany, setCreateCompany] = useState<{
    open: boolean;
    type: string;
    onClose: () => void;
    defaultData?: {
      id: number;
      name: string;
      description: string;
    }
      }>({
        open: false,
        type: '',
        onClose: () => null
      });

  const [createCloud, setCreateCloud] = useState<{
    open: boolean;
    onSubmit: () => void;
    projectId: number;
      }>({
        open: false,
        onSubmit: () => null,
        projectId: -1
      });

  const [createDraft, setCreateDraft] = useState<{
    open: boolean;
    type: string;
    callback:() => void;
      }>({
        open: false,
        type: '',
        callback: () => null
      });

  const [createEvent, setCreateEvent] = useState<{
    open: boolean;
    type: 'create' | 'edit';
    companyId: number;
    onSubmit: () => void;
    defaultData?: EventSettingPageType
      }>({
        open: false,
        type: 'create',
        companyId: -1,
        onSubmit: () => null
      });

  const [createMessage, setCreateMessage] = useState<{
    open: boolean;
    type: string;
    onConfirm: () => void;
    data?: MessagePageType;
      }>({
        open: false,
        type: '',
        onConfirm: () => null
      });

  const [createManager, setCreateManager] = useState<{
    open: boolean;
    projectId: number;
    refetch: () => void;
      }>({
        open: false,
        projectId: -1,
        refetch: () => null
      });

  const [createReport, setCreateReport] = useState<{
    open: boolean;
    type: string;
    reportId: number;
    projectId: number;
    callback:() => void;
    data: HistReportFileInfo | null;
      }>({
        open: false,
        type: '',
        reportId: 0,
        projectId: 0,
        callback: () => null,
        data: null
      });

  const [ deleteReport, setDeleteReport] = useState<{
    open: boolean,
    type: 'week' | 'month',
    data: ReportDetail[],
    callback:() => void
      }>({
        open: false,
        type: 'week',
        data: [],
        callback: () => null
      });

  const [ deleteReportFile, setDeleteReportFile] = useState<{
        open: boolean,
        data: HistReportFileInfo[],
        callback:() => void
          }>({
            open: false,
            data: [],
            callback: () => null
          });

  const [updateUser, setUpdateUser] = useState<{
    open: boolean;
    userId: string;
  }>({
    open: false,
    userId: ''
  });
  const [deleteCompany, setDeleteCompany] = useState<{
    open: boolean;
    callback: () => void;
    data: OrganInfo[];
      }>({
        open: false,
        callback: () => null,
        data: []
      });
  const [updateManager, setUpdateManager] = useState<{
    projectId: number;
    data?: ProjectMemberPageType;
    onSubmit: () => void;
    open: Boolean;
      }>({
        projectId: -1,
        onSubmit: () => null,
        open: false
      });
  const [deleteManager, setDeleteManager] = useState<{
    open: boolean;
    onSubmit: () => void;
    data: ProjectMemberPageType[];
      }>({
        open: false,
        onSubmit: () => null,
        data: []
      });
  const [deleteProject, setDeleteProject] = useState<{
    open: boolean;
    onSubmit: () => void;
    data: { id: number, name: string };
      }>({
        open: false,
        onSubmit: () => null,
        data: { id: -1, name: '' }
      });
  const [deleteCloud, setDeleteCloud] = useState<{
    open: boolean;
    onSubmit: () => void;
    data: ProjectCloudType[]
      }>({
        open: false,
        onSubmit: () => null,
        data: []
      });
  const [updateCloud, setUpdateCloud] = useState<{
    open: boolean;
    onSubmit: () => void;
    data?: ProjectCloudType
      }>({
        open: false,
        onSubmit: () => null
      });
  const [deleteMessage, setDeleteMessage] = useState<{
    open: boolean;
    onSubmit: () => void;
    data: MessagePageType[]
      }>({
        open: false,
        onSubmit: () => null,
        data: []
      });
  const [deleteEventSetting, setDeleteEventSetting] = useState<{
    open: boolean;
    onSubmit: () => void;
    data: EventSettingPageType[]
      }>({
        open: false,
        onSubmit: () => null,
        data: []
      });
  const [sendAlarm, setSendAlarm] = useState<{
    open: boolean;
    onSubmit: () => void;
    data: { id: number, projectId: number, settingId: number, projectName: string }
      }>({
        open: false,
        onSubmit: () => null,
        data: { id: -1, projectId: -1, settingId: -1, projectName: '' }
      });

  const modal = useMemo<ModalContextType>(() => ({
    create: {
      createProject: (organ: { id: number, name: string }, onClose: () => void) => setCreateProject({ open: true, organ: organ, onClose: onClose }),
      createCompany: (type: string, onClose: () => void, defaultData?: {
        id: number;
        name: string;
        description: string;
      }) => setCreateCompany({ open: true, type: type, onClose: onClose, defaultData: defaultData }),
      createCloud: (onSubmit: () => void, projectId: number) => setCreateCloud({ open: true, onSubmit, projectId }),
      createDraft: (type: string, callback:() => void) => setCreateDraft({ open: true, type: type, callback: callback }),
      createEvent: (data: {
        type: 'create' | 'edit';
        companyId: number;
        onSubmit: () => void;
        defaultData?: EventSettingPageType
      }) => setCreateEvent({ open: true, type: data.type, companyId: data.companyId, onSubmit: data.onSubmit, defaultData: data.defaultData }),
      createMessage: (type: string, onConfirm: () => void, data?: MessagePageType) => setCreateMessage({ open: true, type, onConfirm, data }),
      createManager: (projectId: number, refetch: () => void) => setCreateManager({ 
        open: true, 
        projectId: projectId,
        refetch: refetch
      }),
      createReport: (type: string, reportId: number, callback:() => void, projectId: number, data: HistReportFileInfo | null) => setCreateReport({ open: true, type: type, reportId: reportId, callback: callback, projectId: projectId, data: data })
    },
    delete: {
      deleteReport: (type: 'week' | 'month', data: ReportDetail[], callback:() => void) => setDeleteReport({ open: true, type: type, data: data, callback: callback }),
      deleteReportFile: (data: HistReportFileInfo[], callback: () => void) => setDeleteReportFile({ open: true, data: data, callback: callback }),
      deleteCompany: (callback: () => void, data: OrganInfo[]) => setDeleteCompany({ open: true, callback, data }),
      deleteManager: (onSubmit: () => void, data: ProjectMemberPageType[]) => setDeleteManager({ open: true, onSubmit, data }),
      deleteProject: (onSubmit: () => void, data: { id: number, name: string }) => setDeleteProject({ open: true, onSubmit, data }),
      deleteCloud: (onSubmit: () => void, data: ProjectCloudType[]) => setDeleteCloud({ open: true, onSubmit, data }),
      deleteMessage: (onSubmit: () => void, data: MessagePageType[]) => setDeleteMessage({ open: true, onSubmit, data }),
      deleteEventSetting: (onSubmit: () => void, data: EventSettingPageType[]) => setDeleteEventSetting({ open: true, onSubmit, data })
    },
    update: {
      updateUser: (userId: string) => setUpdateUser({ open: true, userId: userId }),
      updateManager: (val: {
        projectId: number;
        data: ProjectMemberPageType;
        onSubmit: () => void;
      }) => setUpdateManager({ ...val, open: true }),
      updateCloud: (val: {
        data: ProjectCloudType;
        onSubmit: () => void;
      }) => setUpdateCloud({ ...val, open: true })
    },
    sendAlarm: {
      sendAlarm: (val: {
        onSubmit: () => void;
        data: { id: number, projectId: number, settingId: number, projectName: string };
      }) => setSendAlarm({ ...val, open: true })
    }
  }),[]);

  return (
    <ModalContext.Provider value={modal} >
      {children}
      {createProject.open && <CreateProjectModal onClose={() => {
        createProject.onClose();
        setCreateProject({ open: false, organ: { id: -1, name: '' }, onClose: () => null });
      }} organ={createProject.organ}/>}
      {createCompany.open && <CreateCompanyModal 
        onClose={() => {
          createCompany.onClose();
          setCreateCompany({ open: false, type: '', onClose: () => null });
        }} 
        type={createCompany.type}
        defaultData={createCompany.defaultData}
      />}
      {createCloud.open && <CreateCloudModal onClose={() => setCreateCloud(prev => ({ ...prev, open: false }))} onSubmit={createCloud.onSubmit} projectId={createCloud.projectId} />}
      <CreateDraftModal callback={createDraft.callback} open={createDraft.open} onClose={() => setCreateDraft({ open: false, type: '', callback: () => null })}/>
      {createEvent.open && <CreateEventModal 
        onClose={() => setCreateEvent(prev => ({ ...prev, open: false }))}
        type={createEvent.type}
        companyId={createEvent.companyId}
        onSubmit={createEvent.onSubmit}
        defaultData={createEvent.defaultData}
      />}
      {createMessage.open && 
      <CreateMessageModal 
        onClose={() => setCreateMessage(prev => ({ ...prev, open: false }))} 
        type={createMessage.type}
        onConfirm={createMessage.onConfirm}
        data={createMessage.data}
      />}
      {createManager.open && 
      <CreateManagerModal 
        projectId={createManager.projectId}
        onClose={() => setCreateManager(prev => ({ ...prev, open: false }))}
        onSubmit={() => {
          createManager.refetch();
          setCreateManager(prev => ({ ...prev, open: false }));
        }}
      />}
      <CreateReportModal data={createReport.data} open={createReport.open} onClose={() => setCreateReport({ open: false, type: '', reportId: 0, callback: () => null, projectId: 0, data: null })} projectId={createReport.projectId} reportId={createReport.reportId} onCallback={createReport.callback}/>
      {updateUser.open && <UserUpdateModal onClose={() => setUpdateUser(prev => ({ ...prev, open: false }))} userId={updateUser.userId}/>}
      {updateManager.open && <UpdateManagerModal
        onClose={() => setUpdateManager(prev => ({ ...prev, open: false }))} 
        onConfirm={() => {
          updateManager.onSubmit();
          setUpdateManager(prev => ({ ...prev, open: false }));
        }}
        memberData={updateManager.data}
        projectId={updateManager.projectId}
      />}
      {updateCloud.open && <UpdateCloudModal onClose={() => setUpdateCloud(prev => ({ ...prev, open: false }))} onSubmit={updateCloud.onSubmit} data={updateCloud.data} />}
      <DeleteReportModal callback={deleteReport.callback} open={deleteReport.open} onClose={() => setDeleteReport({ open: false, type: 'week', data: [], callback: () => null })} data={deleteReport.data}/>
      <DeleteReportFileModal callback={deleteReportFile.callback} open={deleteReportFile.open} onClose={() => setDeleteReportFile({ open: false, data: [], callback: () => null })} data={deleteReportFile.data}/>
      <DeleteCompanyModal 
        open={deleteCompany.open}
        onClose={() => setDeleteCompany(prev => ({ ...prev, open: false }))}
        callback={deleteCompany.callback}
        data={deleteCompany.data}
      />
      <DeleteProjectModal
        open={deleteProject.open}
        onClose={() => setDeleteProject(prev => ({ ...prev, open: false }))}
        onSubmit={deleteProject.onSubmit}
        data={deleteProject.data}
      />
      <DeleteManagerModal
        open={deleteManager.open}
        onClose={() => setDeleteManager(prev => ({ ...prev, open: false }))}
        onSubmit={deleteManager.onSubmit}
        data={deleteManager.data}
      />
      {deleteCloud.open && <DeleteCloudModal
        onClose={() => setDeleteCloud(prev => ({ ...prev, open: false }))}
        onSubmit={deleteCloud.onSubmit}
        data={deleteCloud.data}
      />}
      {deleteMessage.open && <DeleteMessageModal
        onClose={() => setDeleteMessage(prev => ({ ...prev, open: false }))}
        onSubmit={deleteMessage.onSubmit}
        data={deleteMessage.data}
      />}
      {deleteEventSetting.open && <DeleteEventSettingModal
        onClose={() => setDeleteEventSetting(prev => ({ ...prev, open: false }))}
        onSubmit={deleteEventSetting.onSubmit}
        data={deleteEventSetting.data}
      />}
      {sendAlarm.open && <SendAlarmModal
        onClose={() => setSendAlarm(prev => ({ ...prev, open: false }))}
        onSubmit={sendAlarm.onSubmit}
        data={sendAlarm.data}
      />}
      <ToastContainer />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
export const useModal = () => useContext(ModalContext);
