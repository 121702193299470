import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { HistAlertType } from 'graphql/types/HistAlertType';

export interface IGetHistAlertVariables {
  histEventId: number;
}

export interface IGetHistAlertResponseData {
  getHistAlertByHistEventId: IGqlResponseData<HistAlertType[]>;
}

const lazyGetHistAlert = () => useLazyQuery<IGetHistAlertResponseData, IGetHistAlertVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetHistAlert;