import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';

export interface IGetProjectCloudByProjectIdVariables {
  projectId: number;
}

export interface IGetProjectCloudByProjectIdResponseData {
  getProjectCloudByProjectId: IGqlResponseData<ProjectCloudType[]>;
}

const lazyGetProjectCloudByProjectId = () => useLazyQuery<IGetProjectCloudByProjectIdResponseData, IGetProjectCloudByProjectIdVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetProjectCloudByProjectId;