import { ErrorCode } from '@Types/error';
import '../index.scss';
import Button from 'components/UI/Button';
import deleteOrgan from 'graphql/mutations/deleteOrgan';
import { OrganInfo } from 'graphql/types/OrganInfo';
import { useSnackbar } from 'hooks/useSnackbar';
import Table, { ColumnType } from 'components/atoms/Table';

const columns:ColumnType<OrganInfo>[] = [
  { 
    name: '조직',
    value: 'name'
  },{ 
    name: '프로젝트 개수',
    value: 'projectCnt',
    width: 90
  },{ 
    name: '설명',
    value: 'description'
  },{ 
    name: '등록자',
    value: 'createdByName',
    width: 136
  }
];

interface IDeleteCompanyModalProps {
  open: boolean;
  onClose: () => void;
  callback: () => void;
  data: OrganInfo[];
}
const DeleteCompanyModal = ({ open, onClose, callback, data }: IDeleteCompanyModalProps) => {
  const [removeOrgan] = deleteOrgan();

  const handleDelete = () => {
    const idArr = data.map((val, index) => {
      return val.organId;
    });
    removeOrgan({ variables: { organ: { organIds: idArr } } }).then(({ data }) => {
      if (data) {
        if (data.deleteOrgan.result === ErrorCode.SUCCESS) {
          useSnackbar(data.deleteOrgan.result);
          callback();
          onClose();
        }
        else {
          useSnackbar(data.deleteOrgan.result as ErrorCode);
        }
      } else {
        useSnackbar(ErrorCode.UNKNOWN);
      }
    });
  };
 
  return (
    <>
      {open &&
        <div className="modal-bg" onClick={onClose}>
          <div className="delete-wrap" onClick={e => e.stopPropagation()}>
            <div className="header-wrap">
              <span />
              <p className="title">조직 삭제</p>
            </div>
            <div className="content-wrap">
              <div className="p subtitle">삭제할 조직을 확인하세요.</div>
              <Table
                rows={data}
                columns={columns}
              />
              <div className="btn-wrap">
                <Button disabled={false} text={'삭제'} handleClick={handleDelete} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default DeleteCompanyModal;