import { Data, Layout } from 'plotly.js';
import { lazy, memo, Suspense, useMemo } from 'react';
import IsLoading from 'components/v2/atoms/IsLoading';
import { Panel } from '@Types/v2/Chart';

const DefaultPlot = lazy(() => import('components/v2/Charts/withDefaultPlotlyProps'));

export interface ILineChartProps {
  defaultData: Panel;
}

/** 실제 그려지는 line chart */
const LineChart = ({
  defaultData
}:ILineChartProps) => {

  const data: {
    name: string;
    x: string[];
    y: number[];
    type: string;
    line: { color: string; };
  } = useMemo(() => {
    const result = {
      name: defaultData.data.legendName,
      x: defaultData.data.x,
      y: defaultData.data.y,
      type: 'scatter',
      line: { color: defaultData.data.color }
    }

    return result;
  }, [defaultData])

  return (
    <Suspense fallback={<IsLoading />}>
      <DefaultPlot
        data={[data as Data]}
        layout={{
          font: {
            family: 'Pretendard',
            size: 15
          }
        }}
      /> 
    </Suspense>
  );
};

export default memo(LineChart);