import { gql } from '@apollo/client';

const query = gql`
  mutation addCategory($reqData: ReqAddCategory) {
    addCategory(reqData: $reqData) {
      result
      messages
      data {
        ... on Category {
          categoryId
          cloudId
          name
          maskCode
          description
          title
          template
          createdAt
          createdBy
          createdByName
          modifiedAt
          modifiedBy
          modifiedByName
        }
      }
    }
  }
`;
export default query;