import { gql } from '@apollo/client';

const query = gql`
  mutation addRuleCloud($reqData: ReqAddRuleCloud) {
    addRuleCloud(reqData: $reqData) {
      result
      messages
      data {
        __typename
        ... on Cloud {
          cloudId
          cloudKind
          name
          maskCode
          description
          contactName
          contactEmail
          contactMobile
          createdAt
          createdBy
          createdByName
          modifiedAt
          modifiedBy
          modifiedByName
        }
      }
    }
  }
`;
export default query;