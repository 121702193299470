import { gql } from '@apollo/client';

// const query = gql`
//   mutation deleteMyReport($report: ReqDelReport) {
//     deleteReport(report: $report) {
//       result
//     }
//   }
// `;

const query = gql`
  mutation deleteReport($reqDelReport: ReqDelReport) {
    deleteReport(report: $reqDelReport) {
      __typename
      result
      messages
      data {
        __typename
      }
    }
  }
`;

export default query;