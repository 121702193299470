import { gql } from '@apollo/client';

const query = gql`
query getMemoPageByParam($reqData: ReqGetMemoPage) {
  getMemoPageByParam(reqData: $reqData) {
    __typename
    result
    first
    number
    numberOfElements
    totalPages
    totalElements
    last
    size
    content {
      ... on Memo {
        id
        relObjectId
        relObjectType
        text
        checkYn
        name
        projectId
        projectName
        modifiedAt
        modifiedBy
        modifiedByName
        modifiedByUserId
        createdAt
        createdBy
        createdByName
        createdByUserId
      }
    }
  }
} 
`
export default query;