import UserTooltip from 'components/v2/UserTooltip';
import { createContext, useMemo, useState, PropsWithChildren, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import SuccessIcon from 'assets/svgs/v2/ico_toast_success.svg';
import ErrorIcon from 'assets/svgs/v2/ico_toast_error.svg';
import { UserTooltipDataType } from './types';

interface IContextProps {
  tooltip: {
    close: () => void;
    userTooltip: (props: UserTooltipDataType) => void;
  }
}
const Context = createContext<IContextProps>({} as IContextProps);

/* mark & todo : 추후 실제 해당 유저의 마지막 접속일 바깥으로부터 가져오기 필요 */
const GlobalModalProvider = ({ 
  children 
}: PropsWithChildren) => {
  const [tooltip, setTooltip] = useState<UserTooltipDataType & { open: boolean }>({ open: false, top: 0, left: 0 });

  const modal = useMemo(() => ({
    tooltip: {
      close: () => setTooltip(prev => ({ ...prev, open: false })),
      userTooltip: (props: UserTooltipDataType) => setTooltip({ ...props, open: true })
    }
  }), []);

  return (
    <Context.Provider value={modal}>
      {children}
      { 
        tooltip.open 
        && <UserTooltip 
          top={tooltip.top} 
          left={tooltip.left} 
          userInfo={tooltip.userInfo} 
        /> 
      }
      {/* <ToastContainer
        icon={({ type }) => {
          if (type === 'success') {
            return <img src={SuccessIcon} width={24} height={24} />;
          } else {
            return <img src={ErrorIcon} width={24} height={24} />;
          }
        }}
        position="top-center"
        hideProgressBar
        autoClose={1000}
        closeButton={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        draggable={false} 
      /> // v1 때문에 주석처리 */}
    </Context.Provider>
  );
};

export default GlobalModalProvider;
export const useGModal = () => useContext(Context);
