import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Labeled from 'components/v2/atoms/Labeled';
import LabelTextarea from 'components/v2/LabelTextarea';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import ShareModal from '../../ShareModal';
import DeleteProjectModal from '../../DeleteProjectModal';
import Icon from 'components/v2/atoms/Icon';
import RedCircleFolder from 'assets/svgs/v2/ico_redcircle_folder.svg';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useAuth } from 'contexts/AuthProvider';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import updateProject from 'graphql/mutations/updateProject';
import deleteProject from 'graphql/mutations/deleteProject';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import apis from 'apis/v2';
import { useMutation } from 'react-query';
import { IUploadProfileImageReq } from 'apis/v2/File/schema';
import removeProjectMember from 'graphql/mutations/removeProjectMember';
import { Mb } from 'utils/v2/DummyData/File';

interface IProjectPreferenceProps {
  projectInfo: ProjectInfo;
  onClose: () => void;
}

const ProjectPreference = ({
  projectInfo,
  onClose
}:IProjectPreferenceProps) => {

  const navigate = useNavigate();
  const { 
    token,
    userInfo, 
    role, 
    updateToken 
  } = useAuth();
  const { 
    organizationId,
    projectList,
    editProject: outletEditProject,
    deleteProject: outletDeleteProject
  } = useOutletContext<{
    organizationId: number;
    projectList: ProjectInfo[];
    editProject: (data: ProjectInfo[]) => void,
    deleteProject: (projectId:number) => void;
  }>();

  const [project, setProject] = useState({
    id: -1,
    name: '',
    thirdPartTypeCode: '',
    attrValue1: '' /* 데이터 속성 value */
  });
  const [imgFile, setImgFile] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false,
    leave: false
  }); 
  const [thirdPartyCodeList, setThirdPartyCodeList] = useState<DropdownListDataType[]>([]); 

  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  /* 프로젝트 업데이트 */
  const [editProject] = updateProject();
  /* 프로젝트 삭제 */
  const [deleteProj] = deleteProject();
  /* 프로젝트 나가기 */
  const [removePjMember] = removeProjectMember();
  /* 프로젝트썸네일 다운로드 */
  const { mutateAsync: _downloadProfileImage } = useMutation((data: {kind:string; id:number;}) => apis.File.getDownloadProfileImage(token, data));
  /* 프로젝트썸네일 업로드 */
  const { mutateAsync: _uploadProfileImage } = useMutation((data: IUploadProfileImageReq) => apis.File.uploadProfileImage(token, data));


  const incomeJson = useMemo(() => {
    try {
      const json = JSON.parse(project.attrValue1);

      return JSON.stringify(json, null, 4);
    } catch {
      return project.attrValue1;
    }
  }, [project]);

  const onClickSave = () => {
    if (projectInfo) {
      try {
        // console.log('1');
        // console.log(JSON.stringify(project.attrValue1).replace(/[\s]/g, ''));
        // console.log(JSON.parse(JSON.stringify(project.attrValue1).replace(/[\s]/g, '')));
        
        editProject({
          variables: {
            reqData: {
              organId: organizationId,
              projectId: project.id,
              name:  project.name !== projectInfo.name ? project.name : undefined,
              thirdPartTypeCode: project.thirdPartTypeCode,
              attrValue1: JSON.parse(JSON.stringify(project.attrValue1).replace(/[\s]/g, ''))
            }
          }
        }).then(({ data }) => {
          if (data) {
            if (data.updateProject.result === ErrorCode.SUCCESS) {
              useToast(ErrorCode.SUCCESS, '저장이 완료되었습니다.');
              onClose();
              outletEditProject(data.updateProject.data);
              updateToken(data.updateProject.data[0].userToken);
            } else {
              console.log(data.updateProject.result);
              useToast(ErrorCode.UNKNOWN, '저장을 실패했습니다.');
            }
          } else {
            useToast(ErrorCode.UNKNOWN, '저장을 실패했습니다.');
          }
        });
      }
      /* catch */ 
      catch {
        // console.log('2');
        // console.log(JSON.stringify(project.attrValue1).replace(/[\\n\s\\]/g, ''));
        
        editProject({
          variables: {
            reqData: {
              organId: organizationId,
              projectId: project.id,
              name: project.name !== projectInfo.name ? project.name : undefined,
              thirdPartTypeCode: project.thirdPartTypeCode,
              attrValue1: JSON.stringify(project.attrValue1).replace(/[\\n\s\\]/g, '')
            }
          }
        }).then(({ data }) => {
          if (data) {
            if (data.updateProject.result === ErrorCode.SUCCESS) {
              useToast(ErrorCode.SUCCESS, '저장이 완료되었습니다.');
              updateToken(data.updateProject.data[0].userToken);
              outletEditProject(data.updateProject.data);
            } else {
              console.log(data.updateProject.result);
              useToast(ErrorCode.UNKNOWN, '저장을 실패했습니다.');
            }
          } else {
              useToast(ErrorCode.UNKNOWN, '저장을 실패했습니다.');
          }
        });
      }
    }
  };

  const getPjThumbnail = () => {
    _downloadProfileImage({ kind: 'project', id: projectInfo.projectId })
    .then(({ data }) => {
      if (data) { setImgFile(data.data[0])} 
      else { useToast(ErrorCode.UNKNOWN, '썸네일을 불러오지 못했습니다.'); }}
    )
  }

  useEffect(() => {
    getPjThumbnail();

    getEnumTypeCode({variables: { text: 'ThirdPartyCode' }}).then(res => {
      if (res.data) {
        setThirdPartyCodeList(res.data.getEnumTypeCode.data.map(val => ({
          name: val.name,
          value: val.value
        })));
      }
    })

    setProject({
      id: projectInfo.projectId,
      name: projectInfo.name,
      thirdPartTypeCode: projectInfo.thirdPartTypeCode,
      attrValue1: projectInfo.attrValue1
    });
  }, [projectInfo]);


  
  return (
    <div className="project-preference-container">
      <div className='preference-wrap flex j-between'>
        <div className="pj-profile">
          <Labeled title="프로젝트 프로필 사진">
            <div 
            className="thumbnail-image"
            { ...(imgFile && imgFile !== '' && { 
                style: { backgroundImage: `url('${imgFile}')`, backgroundSize: 'cover' } 
              })
            }
            >
            </div>
            { (role === 'sy_admin' || role === 'pj_owner') &&
            <>
              <input
                id="read-pj-image"
                type="file"
                accept=".jpg, .png, .gif"
                onChange={(e) => {
                  if (e.target.files) {
                    const file = e.target.files[0];
                    if (!file.name.toLowerCase().endsWith('jpg') && !file.name.toLowerCase().endsWith('png') && !file.name.toLowerCase().endsWith('gif')) {
                      useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');

                      return e.target.value ='';
                    }

                    if (file.size > Mb) {
                      useToast(ErrorCode.UNKNOWN, '1MB이하 파일만 첨부해주세요.');

                      return e.target.value = '';
                    }

                    const uploadProfileImageData = {
                      file: file,
                      reqUploadProfileImage : {
                        id: projectInfo.projectId,
                        kind: 'project'
                      }
                    }
            
                    _uploadProfileImage(uploadProfileImageData).then(({data}) => {
                      if (data) {
                        if(data.result === ErrorCode.SUCCESS) {
                          getPjThumbnail();

                          useToast(ErrorCode.SUCCESS, '프로젝트 사진이 등록되었습니다.');
                        } else {
                          if (data.result === ErrorCode.INVALID_EXTENSION) { useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');} 
                          else {useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');}
                          console.log(data.result);
                        }
                      } else {
                        useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');
                      }
                    })
                  }
                }} 
              />
              <label 
                htmlFor="read-pj-image" 
                className="flex a-center j-center"
              >
                <div className="add-btn" />
                이미지 업로드
              </label>
            </>
            }
          </Labeled>
        </div> 
        <div className="content">
          <LabelInput
            title="프로젝트 이름"
            required
            disabled={role === 'pj_membr'}
            placeholder="프로젝트 이름 입력"
            value={project.name}
            onChangeValue={(str) => setProject(prev => ({
              ...prev,
              name: str
            }))}
          />
          <Labeled
            title="데이터 유입"
            required
          >
            <DropdownAtom 
              id="data-property"
              data={thirdPartyCodeList}
              disabled={role === 'pj_membr'}
              placeholder="선택"
              value={{
                name: thirdPartyCodeList.find(inflow => inflow.value === project.thirdPartTypeCode)?.name 
                  ? thirdPartyCodeList.find(inflow => inflow.value === project.thirdPartTypeCode)?.name
                  : '',
                value: project.thirdPartTypeCode
              }} 
              handleClick={(val) => 
                setProject(prev => ({
                  ...prev,
                  thirdPartTypeCode: String(val.value)
                }))}
            />
          </Labeled>
          <LabelTextarea 
            title="데이터 속성" 
            placeholder="데이터 속성 입력"
            value={incomeJson}
            disabled={role === 'pj_membr'}
            onChangeValue={(str) => 
              setProject(prev => ({
              ...prev,
              attrValue1: str
            }))}
          />
        </div>
      </div>

      <div className="btns flex j-between a-end">
        {
          role !== 'pj_membr' &&
          <button 
            className="big-main-btn flex j-center a-center"
            onClick={() => { onClickSave(); }}
          >
            저장하기
          </button>
        }
        { (role === 'sy_admin' || role === 'pj_owner') &&
           <button 
            className="dest-btn flex j-center a-center"
            onClick={() => setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))
            }
          >
            프로젝트 삭제
          </button>
        }
        {
          (role !== 'sy_admin' && role !== 'pj_owner') &&
          <button 
            className="dest-btn flex j-center a-center"
            onClick={() => setModalIsOpen(prev => ({
              ...prev,
              leave: true
            }))}
          >
            프로젝트 나가기
          </button>
        }
      </div>

      <DeleteProjectModal 
        open={modalIsOpen.delete} 
        data={project.name} 
        title={() => 
          <>
            <Icon width={32} height={32} src={RedCircleFolder} />
            해당 프로젝트를 삭제하시겠습니까?
          </>
        } 
        closeIcon={false}
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const data = {
            reqDelProject: {
              projectId: project.id
            }
          };
          
          deleteProj({ variables: data }).then(({ data }) => {  
            if(data) {
              if (data.deleteProject.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, '프로젝트 삭제가 완료되었습니다.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }));
                outletDeleteProject(project.id);
                updateToken(data.deleteProject.data[0].userToken);
                onClose();
                
              } else {
                console.log(data.deleteProject.result);
                useToast(ErrorCode.UNKNOWN, '프로젝트 삭제를 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, '프로젝트 삭제를 실패했습니다.');
            }
          });
        }} 
      />

      <ShareModal 
        open={modalIsOpen.leave} 
        title={() => <>해당 프로젝트에서 나가시겠습니까?</>} 
        content={() => <>프로젝트를 나가시면, 즉시 해당 프로젝트에 접근이 불가합니다.</>} 
        buttonTitle="나가기"
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          leave: false
        }))}
        onConfirm={() => {

          const removePjMemberData ={
            reqData: {
              projectId: projectInfo.projectId,
              memberIds: [String(userInfo?.nemo.memberId)]
            }
          }

          removePjMember({ variables: removePjMemberData}).then(({ data }) => {
            if (data) {
              if (data.removeProjectMember.result === ErrorCode.SUCCESS) {
                updateToken(data.removeProjectMember.data[0].userToken);
                useToast(ErrorCode.SUCCESS, '프로젝트 나가기가 완료되었습니다.');
                setModalIsOpen(prev => ({
                  ...prev,
                  leave: false
                  }));
                  onClose();

                  if (projectList.length > 1) {
                    navigate(`/organ/${organizationId}`)
                  } else {
                    navigate('/join-organ', { replace: true });
                  }
              } else {
                console.log(data.removeProjectMember.result);
                useToast(ErrorCode.UNKNOWN, '프로젝트 나가기를 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, '프로젝트 나가기를 실패했습니다.');
            }
          })
        }} 
      />
    </div>
  );
};

export default ProjectPreference;
