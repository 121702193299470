import { gql } from '@apollo/client';

const query = gql`
  query getTermsById($termsId: Int!) {
    getTermsById(termsId: $termsId) {
      result
      data {
        ... on Terms {
          termId
          version
          kindCode
          index
          mandatoryYn
          useYn
          title
          text
          createdAt
          createdBy
          createdByName
          createdByUserId
          modifiedAt
          modifiedBy
          modifiedByName
          modifiedByUserId
        }
      }
    }
  }
`;

export default query;