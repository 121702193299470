import { useLazyQuery } from '@apollo/client';
import { IGqlResponseData } from '../../types';
import { MemberInfo } from '../../types/MemberInfo';
import query from './query';

export interface IGetMyMemberInfoVariables {
  reqData: string[];
}

export interface IGeyMyMemberInfoResponseData {
  getMemberInfos: IGqlResponseData<MemberInfo[]>
}

/** 멤버 정보 조회 */
const lazyGetMyMemberInfo = () => useLazyQuery<IGeyMyMemberInfoResponseData, IGetMyMemberInfoVariables>(query);
export default lazyGetMyMemberInfo;