import './index.scss';

import { RowType } from '@Types/v2/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import CurrentStatusCard from 'pages/v2/Organ/Report/components/CurrentStatusCard';

interface IDeleteReportFileModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteReportFileModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteReportFileModalProps) => {

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-reportfile-modal">
        <div className="content-wrap">
          <div className="content-header flex a-center">
            <div>리포트 이름</div>
            <div>문서상태</div>
            <div>버전</div>
            <div>수정한 사람</div>
          </div>
          <div className="content-main">
            {data.map(val => (
              <div key={val.id} className="report-row flex a-center">
                <div>{val.fileName}</div>
                <div><CurrentStatusCard status={val.statusCode} /></div>
                <div>{val.version}</div>
                <div>{val.modifiedByName}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            취소
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
          삭제
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteReportFileModal;
