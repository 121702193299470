import { useState } from 'react';
import './index.scss';
import { RowType } from '@Types/v2/Table';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import { dateFormatter } from 'utils/Formatter';
import UserNameIcon from 'assets/svgs/v2/ico_profile_32.svg';
import UserIdIcon from 'assets/svgs/v2/ico_userId.svg';
import AuthIcon from 'assets/svgs/v2/ico_auth_icon.svg';
import AuthType from 'assets/svgs/v2/ico_auth_type.svg';
import CalenderIcon from 'assets/svgs/v2/ico_calender.svg';
import { maskingFormatter } from 'utils/v2/Formatter';

interface IVerificationInfoDetailProps {
  data: RowType;
  convertGridIsShowing: () => void;
}

const VerificatoinInformationDetail = ({
  data,
  convertGridIsShowing,
}: IVerificationInfoDetailProps) => {

  const [hide, setHide] = useState(true)

  return (
    <div className='verification-info-detail'>
      <div className='detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {data.userId}
      </div>
      <div className='detail-contents'>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={UserNameIcon} 
            />
          } 
          title="사용자 이름"
          content={() => <>{data.fullName}</> } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={UserIdIcon} 
            />
          } 
          title="아이디"
          content={() => <>{data.userId}</> } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={AuthIcon} 
            />
          } 
          title="인증 방법"
          content={() => <>{data.authTypeCode === 'password' ? '비밀번호' : '외부인증'}</> } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={AuthType} 
            />
          } 
          title="인증 정보"
          content={() => <div className='auth-token-wrap flex'>
              {maskingFormatter(data.authToken, hide)}
              <button 
                className={`hide-btn ${hide && 'hide'}`} 
                onClick={() => setHide(!hide)}
              />
            </div> 
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={CalenderIcon} 
            />
          } 
          title="인증 일시"
          content={() => <>{dateFormatter(data.createdAt, 'datetime')}</>} 
        />
      </div>
    </div>
  )
}
export default VerificatoinInformationDetail;