/* eslint-disable prefer-const */
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';

/**
 * Axios 인스턴스
 * 
 * 모든 요청은 현재 인스턴스로 처리할 것
 * */
const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: process.env.REACT_APP_NEMO_API
});

interface IApiContext {
  getInstance: () => AxiosInstance;
}
const ApiContext = createContext<IApiContext>({
  getInstance: () => instance 
});

/** react-query client */
const queryClient = new QueryClient();

const ApiProvider = ({ children }:PropsWithChildren) => {
  const instanceRef = useRef(instance);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const requestInterceptor = instanceRef.current.interceptors.request.use(
      config => {
        if (config.url?.includes('register')) return config;

        const accessToken = localStorage.getItem('token');
        if (localStorage.getItem('token')) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        /* config.withCredentials = true; */
        
        return config;
      },
      (error) => {
        return error; 
      }
    );
      
    setIsReady(true);

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  if (!isReady) return null;

  return (
    <ApiContext.Provider value={{ getInstance: () => instance }}>
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
    </ApiContext.Provider>
  );
};

export default ApiProvider;
export const useApiContext = () => useContext(ApiContext);
