import { gql } from '@apollo/client';

const query = gql`
query getMessagesById($messageId: Long) {
  getMessagesById(messageId: $messageId) {
    __typename
    result
    data {
      ... on Message {
        id
        relObjectId
        relObjectType
        sendTypeCode
        version
        title
        message
        useYn
      }
    }
  }
}
`;

export default query;