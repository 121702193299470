import { gql } from '@apollo/client';

const query = gql`
mutation addSubCategory($reqData: ReqAddSubCategory) {
  addSubCategory(reqData: $reqData) {
    result
    data {
      ... on SubCategory {
        id
        categoryId
        name
        maskCode
        description
        title
        template
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
      }
    }
  }
}
`;
export default query;