import { useLazyQuery } from '@apollo/client';
import query from './query';
import { OrganById } from 'apis/v2/Organ/schema';
import { IGqlResponseData } from 'graphql/types';

export interface IGetOrganByIdPageVariables {
  organId: number;
}

export interface IGetOrganByIdResponseData {
  getOrganById: IGqlResponseData<OrganById[]>;
}

const lazyGetOrganById = () => useLazyQuery<IGetOrganByIdResponseData, IGetOrganByIdPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetOrganById;