import { gql } from '@apollo/client';

const query = gql`
mutation updateHistReportFile($reqData: ReqUpdReportFile) {
  updateHistReportFile(reqData: $reqData) {
    result
    data {
      ... on ReportFile {
        id
        reportId
        fileName
        statusCode
        comment
        version
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
      }
    }
  }
}
`;
export default query;