import { gql } from '@apollo/client';

// const query = gql`
//   mutation updateProjectMember($reqUpdProjectMember: ReqUpdProjectMember) {
//     updateProjectMember(reqUpdProjectMember: $reqUpdProjectMember) {
//       __typename
//       result
//       messages
//       data {
//         __typename
//         ... on ProjectMember {
//           memberId
//           projectId
//           roleCode
//           alertYn
//         }
//       }
//     }
//   }
// `;

const query = gql`
mutation updateProjectMember($reqUpdProjectMember: ReqUpdProjectMember) {
  updateProjectMember(reqUpdProjectMember: $reqUpdProjectMember) {
    __typename
    result
    messages
    data {
      __typename
      ... on ProjectMember {
        memberId
        projectId
        roleCode
        alertYn
        memberName
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
        alertYn
        smsAlertYn
        emailAlertYn
        statusCode
      }
    }
  }
}
`;

export default query;