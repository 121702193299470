import { ManageTabType } from '@Types/v2/Manage';
import './index.scss';

interface IManageTabProps {
  selected:string;
  menu: ManageTabType[];
  convertTab: (value:string) => void;
}

const ManageTab = ({
  selected,
  menu,
  convertTab
}:IManageTabProps) => {
  return (
    <div className="manage-tab flex">
      {
        menu.map((val, idx) => (
            <button 
              key={idx}
              disabled={val.value === selected}
              onClick={() => convertTab(val.value)}
            >
            {val.label}
            </button>
        ))
      }
    </div>
  )
}

export default ManageTab;