import { useEffect, useState } from 'react';
import './index.scss';
import Button from 'components/UI/Button';
import { UserType } from '@Types/User';
import lazyGetMyMemberInfo from 'graphql/queries/getMyMemberInfo';
import { ErrorCode } from '@Types/error';
import updateProjectMember from 'graphql/mutations/updateProjectMember';
import { useSnackbar } from 'hooks/useSnackbar';
import { dateFormatter } from 'utils/Formatter';

interface IUserUpdateModalProps{
  onClose: () => void;
  userId: string;
}

const UserUpdateModal = ({ onClose, userId }: IUserUpdateModalProps) => {
  const [createData, setCreateData] = useState<UserType>({
    id: '',
    name: '',
    level: '',
    email: '',
    companyId: -1,
    company: '',
    desc: '',
    password: '',
    createdAt: ''
  });
  
  const [getMemberInfo] = lazyGetMyMemberInfo();
  const [editMemberInfo] = updateProjectMember();

  const handleUpdate = () => {
    // TODO role code 리스트 받아서 수정해야 함.
    editMemberInfo({
      variables: {
        reqUpdProjectMember: {
          memberId: createData.id,
          projectId: createData.companyId,
          roleCode: createData.level,
          // smsAlertYn: false,
          // emailAlertYn: false
          alertYn: false //TODO alert 관련 ui 없음
        }
      }
    }).then(({ data }) => {
      if (data) {
        if (data.updateProjectMember.result === ErrorCode.SUCCESS) {
          useSnackbar(data.updateProjectMember.result);
          onClose();
        } else {
          useSnackbar(data.updateProjectMember.result);
        }
      } else {
        useSnackbar(ErrorCode.UNKNOWN, '오류 발생');
      }
    });
  };

  /** 초기화 */
  useEffect(() => {
    /* info 데이터 받아와서 기본 정보 삽입 */
    if (userId !== '') {
      getMemberInfo({
        variables: {
          reqData: [userId]
        }
      }).then(({ data }) => {
        if (data) {
          // if (data.getMemberInfos.result === ErrorCode.SUCCESS && data.getMemberInfos.data.memberInfos.length > 0) {
          //   setCreateData({
          //     id: data.getMemberInfos.data.memberInfos[0].userId,
          //     name: data.getMemberInfos.data.memberInfos[0].fullName,
          //     level: data.getMemberInfos.data.memberInfos[0].ranks || '',
          //     email: data.getMemberInfos.data.memberInfos[0].email,
          //     companyId: data.getMemberInfos.data.memberInfos[0].organId,
          //     company: data.getMemberInfos.data.memberInfos[0].organName,
          //     desc: '',
          //     password: '',
          //     createdAt: data.getMemberInfos.data.memberInfos[0].createdAt
          //   });
          // }
        }
      });
    }
  },[open, userId]);
  
  return ( 
    <div className="modal-bg" onClick={onClose}>
      <div className="usermodal-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span/>
          <p>
            사용자 정보
          </p>
        </div>
        <div className="contents-wrap">
          <p className="title">기본정보 수정</p>
          <div className="main-wrap">
            <div className="left-side">
              <div className="profile"/>
            </div>
            <div className="right-side">
              <div className="row">
                <p className="row-title">ID</p>
                <p>{createData.id}</p>
              </div>
              <div className="row">
                <p className="row-title">이름</p>
                <p>{createData.name}</p>
              </div>
              <div className="row">
                <p className="row-title">직책</p>
                <input 
                  type="text" 
                  className="input" 
                  value={createData.level}
                  onChange={e => setCreateData(prev => ({ ...prev, level: e.target.value }))}
                />
              </div>
              <div className="row">
                <p className="row-title">이메일</p>
                <span>{createData.email}</span>
              </div>
              <div className="row">
                <p className="row-title">소속</p>
                <span>{createData.company}</span>
              </div>
              <div className="row">
                <p className="row-title">소개</p>
                <textarea 
                  className="input textarea" 
                  value={createData.desc}
                  onChange={e => setCreateData(prev => ({ ...prev, desc: e.target.value }))}
                />
              </div>
              <div className="row">
                <p className="row-title">비밀번호</p>
                <input 
                  type="password" 
                  className="input" 
                  value={createData.password}
                  onChange={e => setCreateData(prev => ({ ...prev, password: e.target.value }))}
                />
              </div>
              <div className="row">
                <p className="row-title">등록 일시</p>
                <span>{dateFormatter(createData.createdAt, 'datetime')}</span>
              </div>
            </div>
          </div>
          <div className="modal-btn-wrap">
            <Button disabled={createData.level === '' || createData.desc === '' || createData.password === ''} text={'저장'} handleClick={handleUpdate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserUpdateModal;