import { Outlet, useLocation } from 'react-router-dom';
import './index.scss';
import Header from 'components/v2/Header';
import { useEffect, useState } from 'react';

const AuthLayout = () => {

  const { state } = useLocation();

  const [signWithInv, setSignWithInv] = useState({
    memberId: '',
    email: '',
    invitation: false
  });

  useEffect(() => {
    if (state) {
      setSignWithInv({
        memberId: state.memberId,
        email: state.email,
        invitation: true
      });
    }
  }, [state])
  
  return (
    <main id="auth-layout">
      <div className='header'>
        <div className='header-logo' />
      </div>
      <section>
        <Outlet context={{ signWithInv }} />
      </section>
    </main>
  );
};
export default AuthLayout;