import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { EventRuleInfo } from 'graphql/types/EventRuleInfo';

export interface IGetEventPageByParamVariables {
  reqData: {
    text?: string;
    pageInfo: Paging;
  }
}

export interface IGetEventPageResponseData {
  getEventPageByParam: ResponsePage<EventRuleInfo>;
}

const lazyGetEventPageByParam = () => useLazyQuery<IGetEventPageResponseData, IGetEventPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetEventPageByParam;