import { IDefaultRes } from 'apis/v2/schema';
import { IRegisterReq, IRegisterWithInvitationReq } from './schema';
import { AxiosInstance, AxiosResponse } from 'axios';
import { BaseDummyRes } from '..';
import { ErrorCode } from '@Types/error';

const checkId = (instance: AxiosInstance) => (data: { userId: string }):Promise<AxiosResponse<IDefaultRes, { userId: string }>> => {
  return instance.request({
    method: 'POST',
    url: '/signup/checkId',
    data: data
  });
};

const register = (instance: AxiosInstance) => (data: IRegisterReq):Promise<AxiosResponse<IDefaultRes, IRegisterReq>> => {
  return instance.request({
    method: 'POST',
    url: '/signup/register',
    data: data
  });
};

const registerWithInvitation = (instance: AxiosInstance) => (data: IRegisterReq):Promise<AxiosResponse<IDefaultRes, IRegisterWithInvitationReq>> => {
  return instance.request({
    method: 'POST',
    url: '/signup/registerWithInvitation',
    data: data
  });
};

const reqEmail = (instance: AxiosInstance) => (token: string):Promise<AxiosResponse<IDefaultRes, string>> => {
  return instance.request({
    method: 'POST',
    url: '/signup/reqEmail',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

/** 회원가입시 인증 이메일 다시 보내기 (유효한 memberId) */
const reqEmailById = (instance: AxiosInstance) => (data: { userId: string }):Promise<AxiosResponse<IDefaultRes, { userId: string }>> => {
  return instance.request({
    method: 'POST',
    url: '/signup/reqEmailById',
    data: data
  });
};

const DummyCheckId = (data: { userId: string }): Promise<BaseDummyRes<IDefaultRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IDefaultRes>) => void) => {
    const idAlreadyExist = ['test','admin','tester'];

    if (idAlreadyExist.includes(data.userId)) {
      resolve({
        data: {
          result: ErrorCode.USER_ID_DUPLICATED
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.SUCCESS
        }
      });
    }
  });

  return promise;
};
const DummyRegister = (data: IRegisterReq): Promise<BaseDummyRes<IDefaultRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IDefaultRes>) => void) => {
    const emailAlreadyExist = ['test@test.com','admin@admin.com','tester@tester.com'];

    if (emailAlreadyExist.includes(data.email)) {
      resolve({
        data: {
          result: ErrorCode.USER_ID_DUPLICATED
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.SUCCESS
        }
      });
    }
  });

  return promise;
};
const DummyReqEmail = (token: string): Promise<BaseDummyRes<IDefaultRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IDefaultRes>) => void) => {
    if (token === 'success') {
      resolve({
        data: {
          result: ErrorCode.SUCCESS
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN
        }
      });
    }
  });

  return promise;
};

export const DummySignUp = {
  checkId: DummyCheckId,
  register: DummyRegister,
  reqEmail: DummyReqEmail
};
const SignUp = (instance: AxiosInstance) => ({
  checkId: checkId(instance),
  register: register(instance),
  registerWithInvitation: registerWithInvitation(instance),
  reqEmail: reqEmail(instance),
  reqEmailById:reqEmailById(instance)
});
export default SignUp;