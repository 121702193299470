import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { EventRuleInfo } from 'graphql/types/EventRuleInfo';
import { DictionaryInfo } from 'apis/v2/Manage/schema';

export interface IAddDictionaryVariables {
  reqData: {
    relObjectId: number; /* 아이템id */
    relObjectType: string; /* 사용 종류 */
    defaultName: string; /* 기준 값 */
    domainName: string; /* 설정값 */
  }
}

export interface IAddDictionaryResponseData {
  addDictionary: IGqlResponseData<DictionaryInfo>;
}

const addDictionary = () => useMutation<IAddDictionaryResponseData, IAddDictionaryVariables>(query);
export default addDictionary;