import { gql } from '@apollo/client';

// const query = gql`
//   mutation addProject($project: ReqAddProject) {
//     addProject(project: $project) {
//       __typename
//       result
//       message
//       data {
//         __typename
//         ... on RespProjectInfo {
//           projectInfos {
//             projectId
//             organId
//             name
//             thirdPartTypeCode
//             attrKey1
//             attrValue1
//             attrKey2
//             attrValue2
//             attrKey3
//             attrValue3
//             useYn
//             createdAt
//             createdBy
//             createdByName
//             modifiedAt
//             modifiedBy
//             modifiedByName
//           }
//         }
//       }
//     }
//   }
// `;

const query = gql`
mutation addProject($reqData: ReqAddProject) {
  addProject(project: $reqData) {
    result
    messages
    data {
      ... on Project {
        projectId
        organId
        name
        thirdPartTypeCode
        attrKey1
        attrValue1
        attrKey2
        attrValue2
        attrKey3
        attrValue3
        useYn
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
        __typename
        userToken
      }
    }
  }
}
`;

export default query;