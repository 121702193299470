import { useEffect, useRef, useState } from "react";
import EditorJS, { OutputBlockData, OutputData } from '@editorjs/editorjs';
import Table from '@editorjs/table';
import Image from '@editorjs/image';
// @ts-ignore
import Header from '@editorjs/header';
// @ts-ignore
import RawTool from '@editorjs/raw';
// @ts-ignore
import CheckList from '@editorjs/checklist';
// @ts-ignore
import List from '@editorjs/list';
// @ts-ignore
import Quote from '@editorjs/quote';
// @ts-ignore
import Warning from '@editorjs/warning';
// @ts-ignore
import Delimiter from '@editorjs/delimiter';
// @ts-ignore
import CodeTool from '@editorjs/code';
import updateMemo from "graphql/mutations/updateMemo";
import { useMutation } from "react-query";
import apis from "apis/v2";
import { MemoInfo } from "graphql/types/MemoInfo";
import axios from "axios";
import { defaultBlock } from "./temp";
import { Buffer } from 'buffer';
import { useAuth } from "contexts/AuthProvider";
import { useToast } from "hooks/v2/useToast";
import { ErrorCode } from "@Types/error";

interface IMemoProps {
  data: MemoInfo[] | null;
  reportId?: number;
  histEventId?: number;
  projectId?: number;
  checkYn?: boolean;
  saveTrigger: boolean;
  resetSaveTrigger: () => void;
  updateMemoResult?: (status: boolean) => void;
}
const Memo = ({
  data, 
  reportId, 
  histEventId, 
  projectId, 
  saveTrigger, 
  resetSaveTrigger,
  checkYn,
  updateMemoResult
}: IMemoProps) => {

  const { token } = useAuth();
  const editorInstance = useRef<EditorJS | null>(null);

  const [updateMemos] = updateMemo();

  const [editorData, setEditorData] = useState<{ relObjectId: Number, editor: OutputData }>({ relObjectId: -1, editor: { blocks: [] } });
  const [originImageFilepath, setOriginImageFilepath] = useState<{ origin: string, encode: string }[]>([]);
  
  const { mutateAsync: _uploadImage } = useMutation((file: File) => apis.Memo.uploadImage({
    file: file,
    reqUploadImage: {
      memoId: data![0].id,
      projectId: projectId!,
      ...(reportId && { reportId: reportId }),
      ...(histEventId && { histEventId: histEventId })
    }
  }, token));

  /** memo 업데이트 */
  const handleUpdateMemo = (val: string) => {
    
    if (data === null) return;
    updateMemos({ variables: {
      memo: { 
        id: data![0].id, 
        text: val,
        checkYn: checkYn === undefined ? true : checkYn
      } 
    } 
  }).then(({ data }) => {
      useToast(ErrorCode.SUCCESS, '저장되었습니다.');
      if (updateMemoResult) { updateMemoResult(true)}
    }).catch((e) => {
      useToast(ErrorCode.UNKNOWN, '잠시 후 다시 시도해주세요.');
      if (updateMemoResult) { updateMemoResult(false)}
      console.log(e);
    });
  };

  const tools = { 
    header: Header,
    delimiter: Delimiter,
    checkList: {
      class: CheckList,
      inlineToolbar: true
    },
    list: {
      class: List,
      inlineToolbar: true,
      config: {
        defaultStyle: 'unordered'
      }
    },
    quote: Quote,
    warning: Warning,
    raw: RawTool,
    code: CodeTool,
    table: Table,
    image: {
      class: Image,
      config: {
        uploader: {
          uploadByFile(file: File) {
            return _uploadImage(file).then(async ({ data }) => {
              let headers = {};
              const accessToken = localStorage.getItem('token');
              if (accessToken) {
                headers = { Authorization: `Bearer ${accessToken}` };
              }
              const res = await axios.get(data.data || '', { headers: headers, responseType: 'arraybuffer' });
              const imageData = Buffer.from(res.data, 'binary').toString('base64');
              const encodeImage = `data:${res.headers['content-type']};base64,${imageData}`;
              setOriginImageFilepath(prev => [...prev, { origin: data.data || '', encode: encodeImage }]);
          
              return {
                success: 1,
                file: {
                  url: encodeImage
                }
              };
            });
          }
        }
      }
    }
  };

  /** json인지 확인 */
  const isJson = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  };

  // 에디터 데이터 업데이트
  const handleEditorChange = async () => {
    if (editorInstance.current) {
      const newEditorData = await editorInstance.current.save();
      const graphqlData = {
        ...newEditorData,
        blocks: newEditorData.blocks.map((bl => {
          if (bl.type === 'image') {
            const findImageData = originImageFilepath.find(image => image.encode === bl.data.file.url);
            if (findImageData) {
              return {
                ...bl,
                data: {
                  ...bl.data,
                  file: {
                    url: findImageData.origin
                  }
                }
              };
            } else {
              return {
                ...bl,
                type: 'paragraph',
                data: {
                  text: ''
                }
              };
            }
          } else {
            return bl;
          }
        }))
      };
      handleUpdateMemo(JSON.stringify(graphqlData));
    }
  };

  /** editor data 불러오기 할때 image 데이터 token 넣어서 다시 받기 */
  const editorDataFetchImage = async (relObjectId: number, newEditorData:OutputData) => {
    if (newEditorData.blocks.findIndex(bl => bl.type === 'image') !== -1) {
      const accessToken = localStorage.getItem('token');
      let headers = {};
      const newBlocks:OutputBlockData[] = [];
      
      for (let i = 0; i < newEditorData.blocks.length; i++) {
        if (newEditorData.blocks[i].type === 'image') {
          if (newEditorData.blocks[i].data.file.url !== '') {
            if (accessToken) {
              headers = { Authorization: `Bearer ${accessToken}` };
            }
            const res = await axios.get(newEditorData.blocks[i].data.file.url, { headers: headers, responseType: 'arraybuffer' });
            const imageData = Buffer.from(res.data, 'binary').toString('base64');
            const encodeImage = `data:${res.headers['content-type']};base64,${imageData}`;

            setOriginImageFilepath(prev => [...prev, { origin: newEditorData.blocks[i].data.file.url, encode: encodeImage }]);
            newBlocks.push({ ...newEditorData.blocks[i], data: {
              ...newEditorData.blocks[i].data,
              file: {
                url: encodeImage
              }
            } });
          }
        } else {
          newBlocks.push(newEditorData.blocks[i]);
        }
      }
      setEditorData({ relObjectId: relObjectId, editor: { ...newEditorData, blocks: newBlocks } });
    } else {
      setEditorData({ relObjectId: relObjectId, editor: newEditorData });
    }
  };

  // v2 에서는 수동업데이트라 주석처리함.
  // useEffect(() => {
  //   const editorEl = document.getElementById('editor');
  //   if (editorEl && editorData.editor.blocks.length > 0) {
  //     editorEl.addEventListener('focusout', handleEditorChange);
  //   }

  //   return () => {
  //     if (editorEl && editorData.editor.blocks.length > 0) {
  //       editorEl.removeEventListener('focusout', handleEditorChange);
  //     }
  //   };
  // }, [editorInstance, editorData, originImageFilepath]);
  
  /* 리포트 바꿀시 데이터 삽입 및 초기화 */
  useEffect(() => {
    // editor 삭제
    setOriginImageFilepath([]);
    const editorEl = document.getElementById('editor');
    if (editorEl && editorEl?.hasChildNodes()) {
      editorEl.removeChild(editorEl.children[0]);
    }
    editorInstance.current = null;
    if (data !== null && projectId && (histEventId || reportId) && data.length > 0) {
      if (!isJson(data[0].text)) {
        setEditorData({ relObjectId: data[0].relObjectId, editor: defaultBlock });
      }
      else {
        const newEditorData = JSON.parse(data[0].text) as OutputData;
        if (newEditorData.time) {
          editorDataFetchImage(data[0].relObjectId, newEditorData);
        } else {
          setEditorData({ relObjectId: data[0].relObjectId, editor: defaultBlock });
        }
      }
    }
  },[data, reportId, histEventId, projectId]);

  useEffect(() => {
    if (!editorInstance.current && editorData.editor.blocks.length > 0) {
      editorInstance.current = new EditorJS({
        holder: 'editor', // 에디터를 렌더링할 요소의 ID 또는 참조
        data: editorData.editor,
        tools: tools,
        // 아래 onChange 는 이미지가 추가되었는지 확인하여 자동 저장 trigger 변경을 위한 코드로 v2 에서는 수동업데이트기 때문에 사용하지 않음.
        // onChange: (api, event) => {
        //   if (!Array.isArray(event)) {
        //     if (event.type === 'block-changed') {
        //       if (event.detail.target.name === 'image') {
        //         setImageChangeTrigger(prev => !prev);
        //       }
        //     }
        //   }
        // }
        // 다른 옵션들...
      });
    }
  }, [editorData]);

  // v2 에서는 수동업데이트라 주석처리함.
  // useEffect(() => {
  //   if (editorData.editor.blocks.length > 0) {
  //     handleEditorChange();
  //   }
  // },[imageChangeTrigger]);

  useEffect(() => {
    if(saveTrigger) {
      resetSaveTrigger();
      handleEditorChange();
    }
  },[saveTrigger])

  return (
    <div id="editor" />
  )
}
export default Memo;