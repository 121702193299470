import './index.scss';

/**
 * <>일반유저이자 조직소유자만 접근가능한 "영수증" </>
 */
const Receipt = () => {
  return (
    <div id="receipt-page">
      일반유저이자 조직소유자만 접근가능한 "영수증"
    </div>
  )
}

export default Receipt;