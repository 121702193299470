import './index.scss';
import { RowType } from '@Types/v2/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';

interface IDeleteDictionaryModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteDictionaryModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteDictionaryModalProps) => {

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-dictionary-modal">
        <div className="content">
          <div className="content-header flex a-center">
            <div>사용 종류</div>
            <div>아이템</div>
            <div>기준 값</div>
            <div>설정 값</div>
          </div>
          <div className="content-main">
            {data.map(val => (
              <div key={val.id} className="report-row flex a-center">
                <div>{val.relObjectType}</div>
                <div>{val.relObjectName}</div>
                <div>{val.defaultName}</div>
                <div>{val.domainName}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            취소
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
          삭제
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteDictionaryModal;
