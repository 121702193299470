import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUpdateHistReportFileVariables {
  reqData: {
    id: number; /* 리포트파일id */
    statusCode: string; /* "publish0" */
  }
}

export interface IUpdateHistReportFileResponseData {
  updateHistReportFile: IGqlResponseData<undefined>;
}

const updateHistReportFile = () => useMutation<IUpdateHistReportFileResponseData, IUpdateHistReportFileVariables>(query);
export default updateHistReportFile;