import './index.scss';
import { RowType } from '@Types/v2/Table';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import ResourceIcon from 'assets/svgs/v2/ico_table_document.svg';
import CubeIcon from 'assets/images/v2/ico_cube.png';
import FileFormatIcon from 'assets/images/v2/ico_file_format.png';
import FileContentIcon from 'assets/svgs/v2/ico_alert_tile.svg';
import UseYnIcon from 'assets/svgs/v2/ico_useyn.svg';
import Register from 'assets/svgs/v2/ico_register.svg';
import Edit from 'assets/svgs/v2/ico_edit.svg';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';

interface IResourceDetailProps {
  data: RowType;
  convertGridIsShowing: () => void;
}

const ResourceDetail = ({
  data,
  convertGridIsShowing,
}: IResourceDetailProps) => {

  const { token } = useAuth();
  const { tooltip } = useGModal();

  /* 리소스 상세조회 (gql 0.5 미존재) */
  // const getResourceDetail 

  /* todo api 리소스썸네일 다운로드 */
  const { mutateAsync: _downloadProfileImage } = useMutation((data: { kind:string; id:number; }) => apis.File.getDownloadProfileImage(token, data));
   
  const download = (params: { projectId: number, hisReportId: number, fileName: string }) => {
    return (apis.File.downloadReport(params, token)).then(p => {
      // blob형태로 데이터 저장 후 download url 연결
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const blob = new Blob([p.data as any], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const objectUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = objectUrl;
      downloadLink.download = params.fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setTimeout(() => {
        URL.revokeObjectURL(objectUrl);
      },1000);
    }).catch((e) => {
      console.log('e', e);
    });
  }; 
  
  return (
    <div className='resource-detail'>
      <div className='detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {data.name}
      </div>
      <div className='detail-contents'>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={ResourceIcon} 
            />
          } 
          title="리소스 종류"
          content={() => <>{enumFormatter(data.relObjectType)}</> } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={CubeIcon} 
            />
          } 
          title="아이템 이름"
          content={() => <>{data.name}</> } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={FileFormatIcon} 
            />
          } 
          title="파일 형식"
          content={() => <>{enumFormatter(data.resFormatCode)}</> } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={FileContentIcon} 
            />
          } 
          title="파일 내용"
          content={() => <div className="download-wrap flex a-center">
            <div> {/* todo: 추후 파일형식이 어떤 것이냐에 따라서 다르게 노출해주어야 함. */}
              dummy file 내용 
            </div>
            <a href="sample.xls" download>
              다운로드
            </a>
            </div> 
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={UseYnIcon} 
            />
          } 
          title="사용 여부"
          content={() => <>{ data.useYn ? '사용' : '미사용' }</>} 
        />
         <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Register} 
            />
          } 
          title="등록"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdBy, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdBy})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Edit} 
            />
          } 
          title="마지막 수정"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedBy, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedBy})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
      </div>
    </div>
  )
}
export default ResourceDetail;