import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IAddMessageVariables {
  reqData: {
    relObjectId: number; //0: no reference, n: reference id
    relObjectType: string;
    sendTypeCode: string;
    title: string;
    version: string;
    message: string;
  }
}

export interface IAddMessageResponseData {
  addMessage: IGqlResponseData<undefined>;
}

const addMessage = () => useMutation<IAddMessageResponseData, IAddMessageVariables>(query);
export default addMessage;