import classNames from 'classnames';
import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';
import ClearErrorIcon from 'assets/svgs/v2/ico_input_clear_error.svg';
import './index.scss';

interface ITextareaAtomProps {
  className?: string;
  placeholder?: string;
  id?: string;
  error?: boolean;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  noClear?: boolean;
  onChangeValue?: (value: string) => void; 
}
const TextareaAtom = ({ 
  className, 
  placeholder = '', 
  id, 
  error = false, 
  value,
  defaultValue = '', 
  required, 
  disabled = false, 
  noClear = false,
  onChangeValue 
}:ITextareaAtomProps) => {
  return (
    <div className="textarea-atom">
      <textarea 
        placeholder={placeholder} 
        className={classNames(className, error && 'error')}
        {...(id && { id: id })}
        {...(required && { required: required })}
        disabled={disabled}
        value={value}
        onChange={(e) => {
          onChangeValue && onChangeValue(e.target.value);
          const siblingClearBtn = e.currentTarget.parentElement?.getElementsByClassName('clear');
          if (siblingClearBtn && siblingClearBtn[0]) {
            if (e.target.value === '') {
              (siblingClearBtn[0] as HTMLButtonElement).classList.remove('show');
            } else {
              (siblingClearBtn[0] as HTMLButtonElement).classList.add('show');
            }
          }
        }}
        {...(defaultValue && { defaultValue: defaultValue })}
      />
      <span className="btn-group">
        {!disabled && !noClear && (value !== '' || defaultValue !== '') && <img 
          src={error ? ClearErrorIcon : ClearIcon} 
          width={24} 
          height={24} 
          className="clear" 
          onClick={(e) => {
            const siblingTextarea = e.currentTarget.parentElement?.parentElement?.getElementsByTagName('textarea');
            if (siblingTextarea && siblingTextarea[0]) {
              (siblingTextarea[0] as HTMLTextAreaElement).value = '';
              e.currentTarget.classList.remove('show');
              onChangeValue && onChangeValue('');
            }
          }} />}
      </span>
    </div>
  );
};
export default TextareaAtom;