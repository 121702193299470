import { gql } from '@apollo/client';

const query = gql`
  mutation deleteMemo($memo: ReqDelMemo) {
    deleteMemo(memo: $memo) {
      result
    }
  }
`;

export default query;