import { ChartType, EnumViewType } from "@Types/v2/Chart";


export const PANEL_SAMPLE: ChartType[] = [
  {
    viewType: 'LINE' as EnumViewType,
    title: '주차별 탐지 이벤트 건수',
    data: {
      legendName: '합계',
      color: '#f7d5c7',
      x: ['1주차', '2주차', '3주차', '4주차'],
      y: [2364, 1667, 3172, 2985]
    }
  }, {
    viewType: 'BAR' as EnumViewType,
    title: '주차별 탐지 이벤트',
    data: [
      {
        legendName:'IAM',
        color: '#3b64ad',
        x: ['1주차', '2주차', '3주차', '4주차'],
        y: [183, 91, 187, 261]
      }, 
      {
        legendName:'콘솔로그인',
        color: '#d26e2a',
        x: ['1주차', '2주차', '3주차', '4주차'],
        y: [2065, 1476, 2745, 2570]
      }, 
      {
        legendName:'네트워크',
        color: '#919191',
        x: ['1주차', '2주차', '3주차', '4주차'],
        y: [16, 21, 36, 66]
      }, 
      {
        legendName:'컴퓨팅',
        color: '#e2aa00',
        x: ['1주차', '2주차','3주차', '4주차'],
        y: [93, 77, 204, 83]
      }, {
        legendName:'스토리지',
        color: '#5089bc',
        x: ['1주차', '2주차', '3주차', '4주차'],
        y: [7, 1, 0, 5]
      }, 
      {
        legendName:'기타',
        color: '#62993e',
        x: ['1주차', '2주차', '3주차', '4주차'],
        y: [0, 1, 0, 0]
      }
    ]
  }, {
    viewType: 'PIE' as EnumViewType,
    title: '유형별 탐지 이벤트',
    data: {
      labels: ['IAM', '콘솔로그인', '네트워크', '컴퓨팅', '스토리지', '기타'],
      values: [722, 8856, 139, 457, 13, 1],
      colors: ['#3b64ad', '#d26e2a', '#919191', '#e2aa00', '#5089bc', '#62993e']
    }
  },
  {
    title: '탐지 유형별 건수 (AWS)',
    viewType: 'TABLE' as EnumViewType,
    data : {
      columns: ['구분','IAM','콘솔로그인','네트워크','컴퓨팅','스토리지','기타','합계'],
      rows: [
        ['A계정',6,508,107,13,4,15,656],
        ['B계정',49,3402,4,13146,10,0,16611],
        ['C계정',59,876,0,0,1,0,936],
        ['D계정',1,369,0,1,0,0,371]
      ]
    }
  }, 
  {
    title: '유형별 탐지 이벤트2',
    viewType: 'PIE',
    data: {
      labels: ['IAM', '콘솔로그인2', '네트워크2', '컴퓨팅2', '스토리지2', '기타2'],
      values: [122, 51, 31, 457,1423, 5],
      colors: ['#5b64aa', '#d26e2a', '#132190', '#e2aa00', '#5089bc', '#62993e']
    }
  }
]