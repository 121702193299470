import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { CloudInfo } from 'apis/v2/Manage/schema';

export interface IGetRuleCloudPageVariables {
  reqData: {
    text?: string;
    pageInfo: Paging;
  }
}

export interface IGetRuleCloudPageResponseData {
  getRuleCloudPageByParam: ResponsePage<CloudInfo>;
}

const lazyGetRuleCloudPage = () => useLazyQuery<IGetRuleCloudPageResponseData, IGetRuleCloudPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetRuleCloudPage;