import { gql } from '@apollo/client';

const query = gql`
  query GetEventSettingsPageByParam($reqGetEventSettingPage: ReqGetEventSettingPage) {
  getEventSettingPageByParam(reqGetEventSettingPage: $reqGetEventSettingPage) {
      __typename
      result
      number
      size
      first
      last
      numberOfElements
      totalPages
      totalElements
      content {
        __typename
        ... on EventSettingDetail {
          settingId
          projectId
          projectName
          name
          messageId
          messageText
          createdBy
          createdByName
          createdAt
          msgSenderTypeCode
          priorityCode
          eventId
          modifiedBy
          modifiedByName
          modifiedAt
          createdByUserId
          modifiedByUserId
        }
      }
    }
  }
`;
export default query;