import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';

interface IEmailSendedModal extends IBaseModalProps {
  title: () => JSX.Element;
}

const EmailSendedModal = ({
  title,
  ...baseModalProps
}: IEmailSendedModal) => {

  return (
    <BaseModal
      title={title} 
      {...baseModalProps}
    >
      <div className="email-sended-modal">
        <h5>
          인증 메일을 보냈어요. <br />
          받은 이메일을 열어 &#39;인증하기&#39; 버튼을 클릭하세요.
        </h5>
        <div className="flex j-between">
          <p>
            혹시 인증 메일을 받지 못하셨나요? <br />
            1&#41; 가입하지 않은 사용자일 경우 이메일이 발송되지 않아요. <br />
            2&#41; 스팸 메일함에서 인증 메일을 확인해 보세요.
          </p>
          <button  
            className="big-main-btn"
            onClick={baseModalProps.onClose}
          >
          확인
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EmailSendedModal;
