import { RowType } from '@Types/v2/Table';
import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import KeyIcon from 'assets/svgs/v2/ico_key.svg';
import Register from 'assets/svgs/v2/ico_register.svg';
import Edit from 'assets/svgs/v2/ico_edit.svg';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { numberRegex } from 'utils/Regex';
import countryCodes from 'country-codes-list';
import Cloud from 'assets/images/v2/ico_cloud.png';
import CloudAccount from 'assets/svgs/v2/ico_cloud_account.svg';
import UserIcon from 'assets/svgs/v2/ico_profile.svg';
import EmailIcon from 'assets/svgs/v2/ico_email.svg';
import PhoneIcon from 'assets/images/v2/ico_phone.png.png';
import { checkEmail } from 'utils/v2/ValidCheck';
import { useMutation } from 'react-query';
import { IUpdateRuleCoudReq } from 'apis/v2/Manage/schema';
import apis from 'apis/v2';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import updateRuleCloud from 'graphql/mutations/updateRuleCloud';

interface ICloudInformationDetailProps {
  cloudKindList: {
    name: string;
    value: string;
  }[];
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const CloudInformationDetail = ({
  cloudKindList,
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: ICloudInformationDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState({
    cloudId: -1,
    cloudKind: '',
    name: '',
    maskCode: '',
    contactName: '',
    contactEmail: '',
    countryCode: '',
    contactMobile: '',
  });

  const [editRuleCloud] = updateRuleCloud();

  const countryCodesList = countryCodes.all().map(d => 
    ({ callingCode: d.countryCallingCode, countryCode: d.countryCode, languageCode: d.officialLanguageCode })
  );
  const codeDropdownData = useMemo(() => {
    return countryCodesList.map(d => ({ value: `+${d.callingCode}`, name: `+${d.callingCode}` }));
  },[countryCodesList]);

  const origin = useMemo(() => {
    return {
      cloudId: data.cloudId,
      cloudKind: data.cloudKind,
      name: data.name,
      maskCode: data.maskCode,
      contactName: data.contactName ? data.contactName : '-',
      contactEmail: data.contactEmail ? data.contactEmail : '',
      countryCode: data.countryCode,
      contactMobile: data.contactMobile ? data.contactMobile : '-'
    }
  }, []);

  const enableButton = useMemo(() => {
    if (
      detail.cloudKind === '' || 
      detail.name === '' || 
      (detail.contactEmail !== '' && checkEmail(detail.contactEmail)) ||
      detail.maskCode.length < 8) {
        return true;
    } else false;
  }, [detail])

  useEffect(() => {
    setDetail({
      cloudId: data.cloudId,
      cloudKind: data.cloudKind,
      name: data.name,
      maskCode: data.maskCode,
      contactName: data.contactName ? data.contactName : '-',
      contactEmail: data.contactEmail ? data.contactEmail : '',
      countryCode: '+82', /* mark todo 추후 국가코드 어떻게 들어오는지에 따라 수정필요 */
      contactMobile: data.contactMobile ? data.contactMobile : '-'
    });
  }, []);

  return (
    <div className='cloud-information-detail'>
      <div className='detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? detail.name : '클라우드 정보 수정'}
      </div>
      <div className='detail-contents'>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Cloud} 
            />
          } 
          title="클라우드 종류" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? enumFormatter(detail.cloudKind)
                : <DropdownAtom 
                  placeholder='선택'
                  id="cloud-kind"
                  data={cloudKindList} 
                  value={{
                    name: cloudKindList.find(val => val.value === detail.cloudKind)?.name,
                    value: detail.cloudKind
                  }} 
                  handleClick={(val) => {
                    setDetail(prev => ({
                      ...prev,
                      cloudKind: String(val.value)
                    }))
                  }} 
              />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={CloudAccount}
            />
          } 
          title="클라우드 이름" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.name
                : <InputAtom
                    placeholder='클라우드 이름 입력'
                    value={detail.name}
                    onChangeValue={(str) => setDetail(prev => ({
                      ...prev,
                      name: str
                    })) }
                  />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={KeyIcon}
            />
          } 
          title="고유 구분자" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.maskCode
                : <InputAtom
                    placeholder='고유 구분자 입력(마스크코드 8자리 숫자)'
                    value={detail.maskCode}
                    onChangeValue={(str) => {
                      if (str.length > 8) return;
                      setDetail(prev => ({
                        ...prev,
                        maskCode: str.replace(numberRegex, '')
                      })) 
                    }
                      
                  }
                  />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={UserIcon}
            />
          } 
          title="담당자 이름" 
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.contactName
                : <InputAtom
                    placeholder='담당자 이름 입력'
                    value={detail.contactName}
                    onChangeValue={(str) => setDetail(prev => ({
                      ...prev,
                      contactName: str
                    })) }
                  />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={EmailIcon}
            />
          } 
          title="담당자 이메일" 
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? !detail.contactEmail ? '-' : detail.contactEmail
                : <InputAtom
                    placeholder='담당자 이메일 입력 (이메일 양식에 맞게 작성)'
                    value={detail.contactEmail}
                    onChangeValue={(str) => setDetail(prev => ({
                      ...prev,
                      contactEmail: str
                    })) }
                  />
              }
            </>
          } 
        />
         <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={PhoneIcon}
            />
          } 
          title="담당자 연락처" 
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? `${detail.countryCode}${detail.contactMobile}`
                : <div className="phone-wrap flex">      
                  <DropdownAtom 
                    id='contact'
                    data={codeDropdownData}
                    value={{
                      name: codeDropdownData.find(code => code.value === detail.countryCode)?.name 
                        ? codeDropdownData.find(code => code.value === detail.countryCode)?.name
                        : '',
                      value: detail.countryCode
                    }} 
                    handleClick={(val) => setDetail(prev => ({
                      ...prev,
                      countryCode: String(val.value)
                    }))} 
                  />
                  <InputAtom
                    value={detail.contactMobile}
                    placeholder="담당자 연락처 입력"
                    onChangeValue={(str) => setDetail(prev => ({
                      ...prev,
                      contactMobile: str.replace(numberRegex, '')
                    }))}
                  />
                </div>
              }
            </>
          } 
        />
        {
          !edit &&
        <>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Register} 
            />
          } 
          title="등록"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Edit} 
            />
          } 
          title="마지막 수정"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
        </>
        }
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                수정
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                삭제
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                취소
              </button>
              <button 
                className="big-main-btn"
                onClick={() => { 
                  const updateRuleCloudData = {
                    reqData: {
                      cloudId: detail.cloudId,
                      cloudKind: detail.cloudKind,
                      name: detail.name,
                      maskCode: detail.maskCode,
                      contactName: detail.contactName,
                      contactMobile: detail.contactMobile
                    }
                  }            
                  editRuleCloud({ variables: updateRuleCloudData }).then(({ data }) => {
                    if (data) {

                      if (data.updateRuleCloud.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, '클라우드 정보가 수정되었습니다.');
                        setEdit(false);
                        onEditSuccess();
                      } else {
                        console.log(data.updateRuleCloud.result);
                        useToast(ErrorCode.UNKNOWN, '클라우드 정보 수정을 실패했습니다.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, '클라우드 정보 수정을 실패했습니다.');
                    }
                  })
                }}
                disabled={ enableButton }
              >
              수정하기
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default CloudInformationDetail;