import { useEffect, useMemo, useState } from 'react';
import CheckId from './CheckId';
import CheckInfo from './CheckInfo';
import './index.scss';
import { useOutletContext } from 'react-router-dom';

/**
 * 회원가입 - 계정정보입력, 회원정보입력, 이메일 확인하세요 까지 내부 step 으로
 * country code lsit => country-codes-list npm 사용
 */
const Signup = () => {  
  const { signWithInv } = useOutletContext<{ signWithInv: { memberId: string; email: string; invitation: boolean; }}>();
  
  const [step, setStep] = useState(0);
  const [idInputs, setIdInputs] = useState({ id: '', pw: '' });
  const [infoInputs, setInfoInputs] = useState({ email: '', name: '', role: '', phone: '', countryCode: '', checked: false });

  useEffect(() => {
    setInfoInputs(prev => ({
      ...prev,
      email: signWithInv.email
    }))
  }, [signWithInv])

  const updateIdInputs = (key: 'id' | 'pw', value: string) => {
    setIdInputs(prev => ({ ...prev, [key]: value }));
  };
  const updateInfoInputs = (key: 'email' | 'name' | 'role' | 'phone' | 'countryCode' | 'checked', value: string | boolean) => [
    setInfoInputs(prev => ({ ...prev, [key]: value }))
  ];
  const goNextStep = () => {
    setStep(prev => prev + 1);
  };
  const goPrevStep = () => {
    setStep(prev => prev - 1);
  };
  
  const renderBody = useMemo(() => {
    switch (step) {
    case 0:
      return <CheckId 
        inputs={idInputs} 
        updateInputs={updateIdInputs} 
        goNextStep={() => {
          if (signWithInv.invitation) {setInfoInputs({ email: signWithInv.email, name: '', role: '', phone: '', countryCode: '', checked: false });} 
          else {setInfoInputs({ email: '', name: '', role: '', phone: '', countryCode: '', checked: false });}

          goNextStep();
        }} 
      />;
    case 1:
      return <CheckInfo 
        idInputs={idInputs} 
        inputs={infoInputs} 
        updateInputs={updateInfoInputs} 
        goPrevStep={goPrevStep} 
      />;
    default:
      return <></>;
    }
  },[step, infoInputs, idInputs, signWithInv]);

  return renderBody;
};
export default Signup;