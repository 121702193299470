import { BaseDummyRes } from '..';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ErrorCode } from '@Types/error';
import { IUploadImageReq, IUploadImageRes } from './schema';

const uploadImage = (instance: AxiosInstance) => 
  (data: IUploadImageReq, token: string): Promise<AxiosResponse<IUploadImageRes,IUploadImageReq>> => {
    const newFormData = new FormData();
    newFormData.append('file', data.file);
    newFormData.append('reqUploadImage', JSON.stringify(data.reqUploadImage));

    return instance.request({
      method: 'POST',
      url: '/memo/uploadImage',
      data: newFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    });
  };

const DummyUploadImage = (data: IUploadImageReq): Promise<BaseDummyRes<IUploadImageRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IUploadImageRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: 'http://sample.com/image.png'
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN
        }
      });
    }
  });

  return promise;
};

export const DummyMemo = {
  uploadImage: DummyUploadImage
};
const Memo = (instance: AxiosInstance) => ({
  uploadImage: uploadImage(instance)
});
export default Memo;