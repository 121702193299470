import { useEffect, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthProvider';
import Header from 'components/v2/Header';

/** 
  <>
    lg 운영자만 접근 가능한 조직 선택 페이지 
    - 이건 페이지 하나밖에 없으니 페이지내에서 권한 확인 해줘야 함. 
    - 권한없으면 로그인으로 돌려보내기
  </> 
*/
const SelectOrgan = () => {

  const { userInfo, role } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (role !== 'sy_admin') {
      if (userInfo?.nemo.organId === null) { navigate('/join-organ')} 
      else {navigate(`/organ/${userInfo?.nemo.organId}`)}
    };
  }, [role, userInfo]);

  const [selectOrganModalIsOpen, setSelectOrganModalIsOpen] = useState(false);
  
  return (
    <main id="select-organ">
      <Header 
        rightType="PROFILE" 
        leftType="ORGAN" 
        selectOrgan
        selectOrganModalIsOpen={selectOrganModalIsOpen}
        closeSelectOrganModal={() => setSelectOrganModalIsOpen(false)}
      />
      <div className='flex j-center a-center'> 
        <div className='notice-wrap flex col a-center'>
          <div className='member-icon' />
          <h5>선택한 조직이 없습니다.</h5>
          <p>조직의 프로젝트에 접근하기 위해서는 조직을 검색해 주세요.</p>
          <button 
            className='big-main-btn'
            onClick={() => setSelectOrganModalIsOpen(true)}
          >
            조직 검색
          </button>
        </div>
      </div>
    </main>
  );
};

export default SelectOrgan;