import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { SendHistEventAlertRespType } from 'graphql/types/SendHistEventAlertRespType';

export interface ISendHistEventAlertVariables {
  reqData: {
    histEventId: number;
    sendTypeCode: string;
    title: string;
    contents: string;
    memberIds: string[];
  }
}

export interface ISendHistEventAlertResponseData {
  sendHistEventAlert: IGqlResponseData<SendHistEventAlertRespType[]>; 
}

const sendHistEventAlert = () => useMutation<ISendHistEventAlertResponseData, ISendHistEventAlertVariables>(query);
export default sendHistEventAlert;