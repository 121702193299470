import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteMessageVariables {
  req: {
    messageIds: number[];
    forcibly: boolean;
  }
}

export interface IDeleteMessageResponseData {
  deleteMessageByIds: IGqlResponseData<undefined>;
}

const deleteMessage = () => useMutation<IDeleteMessageResponseData, IDeleteMessageVariables>(query);
export default deleteMessage;