import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { EventSelectType } from 'graphql/types/EventSelect';

export interface ISearchEventVariables {
  text: string;
}
export interface ISearchEventResponseData {
  searchEvent: IGqlResponseData<EventSelectType[]>;
}

const lazySearchEvent = () => useLazyQuery<ISearchEventResponseData, ISearchEventVariables>(query, { fetchPolicy: 'no-cache' });
export default lazySearchEvent;