import { ErrorCode } from '@Types/error';
import '../index.scss';
import Button from 'components/UI/Button';
import deleteProjectCloud from 'graphql/mutations/deleteProjectCloud';
import getAllRuleCloud from 'graphql/queries/getAllRuleCloud';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';
import { useSnackbar } from 'hooks/useSnackbar';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import Table, { ColumnType } from 'components/atoms/Table';
import { useEffect, useMemo } from 'react';

interface IDeleteCloudModalProps {
  onClose: () => void;
  onSubmit: () => void;
  data: ProjectCloudType[];
}
const DeleteCloudModal = ({ onClose, onSubmit, data }: IDeleteCloudModalProps) => {
  const [deleteCloud] = deleteProjectCloud();
  const { data: cloudList } = getAllRuleCloud();

  const columns:ColumnType<ProjectCloudType>[] = useMemo(() => [
    { 
      name: '연계 계정 이름',
      value: 'name'
    },{ 
      name: '클라우드 종류',
      value: 'cloudId',
      renderCell: (row) => <>{cloudList?.getAllRuleCloud.data.find(d => d.cloudId === row.cloudId)?.name || ''}</>
    },{ 
      name: '등록 일시',
      value: 'createdAt',
      renderCell: (row) => <>{dateFormatter(row.createdAt, 'datetime')}</>
    }
  ], [cloudList]);

  const handleDelete = () => {
    const idArr = data.map((val, index) => {
      return val.id;
    });
    deleteCloud({ variables: { reqDelProjectCloud: idArr } }).then(({ data }) => {
      if (data) {
        if (data.deleteProjectCloud.result === ErrorCode.SUCCESS) {
          useSnackbar(data.deleteProjectCloud.result);
          onSubmit();
          onClose();
        }
        else {
          useSnackbar(data.deleteProjectCloud.result as ErrorCode);
        }
      } else {
        useSnackbar(ErrorCode.UNKNOWN);
      }
    });
  };
 
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="delete-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span />
          <p className="title">연계 계정 삭제</p>
        </div>
        <div className="content-wrap">
          <div className="p subtitle">선택한 계정을 확인하세요.</div>
          <Table
            rows={data}
            columns={columns}
          />
          <div className="btn-wrap">
            <Button disabled={false} text={'삭제'} handleClick={handleDelete} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteCloudModal;