import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteProjectCloudVariables {
  reqDelProjectCloud: number[];
}

export interface IDeleteProjectCloudResponseData {
  deleteProjectCloud: IGqlResponseData<undefined>;
}

const deleteProjectCloud = () => useMutation<IDeleteProjectCloudResponseData, IDeleteProjectCloudVariables>(query);
export default deleteProjectCloud;