import { useEffect, useRef, useState } from 'react';
import ArrowIcon from 'assets/svgs/v2/ico_dropdown_arrow.svg';
import './index.scss';
import classNames from 'classnames';

type ValueType = string | number;

export type DropdownListDataType = {
  id?: number;
  name?: string;
  value: ValueType;
}
interface IDropdownAtomProps {
  id: string;
  data: DropdownListDataType[];
  value: DropdownListDataType;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  upsideDown?: number;
  handleClick: (val: DropdownListDataType) => void;
}

const DropdownAtom = ({
  id,
  data,
  value,
  className,
  placeholder,
  disabled = false,
  error,
  upsideDown,
  handleClick
}:IDropdownAtomProps) => {
  const listRef = useRef<null | HTMLDivElement>(null);

  const [bg, setBg] = useState({
    width: 0,
    top: 0,
    left: 0
  });

  const openMenu = (parentId: string) => {
    Array.from(document.getElementsByClassName('dropdown-list')).forEach(el => {
      const parentEl = el.parentElement;
      if (parentEl?.id === parentId) {
        if (el.classList.contains('none')) {
          el.classList.remove('none');
        } else {
          el.classList.add('none');
        }
      } else {
        el.classList.add('none');
      }
    });
  };
  const closeAll = () => {
    Array.from(document.getElementsByClassName('dropdown-list')).forEach(el => el.classList.add('none'));
  };

  useEffect(() => {
    document.addEventListener('click', closeAll);

    return () => {
      document.removeEventListener('click', closeAll);
    };
  },[]);

  return (
    <div className={classNames('dropdown-atom', className)} id={`${id}-wrap`}>
      <button 
        type="button" 
        id={id}
        disabled={disabled}
        className={classNames(error && 'error')}
        onClick={(e) => {
          e.stopPropagation();
          openMenu(`${id}-wrap`);

          const dropdownAtomEl = document.getElementById(`${id}-wrap`);
          const listWrapEl = document.getElementById(`list-wrap ${id}`);

          if (!dropdownAtomEl || !listWrapEl) return;
          if (window.innerHeight - e.currentTarget.getBoundingClientRect().bottom > dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5) {
            setBg({
              width: e.currentTarget.offsetWidth,
              top: e.currentTarget.getBoundingClientRect().bottom + 5,
              left: e.currentTarget.getBoundingClientRect().left 
            });
          } else {
            setBg({
              width: e.currentTarget.offsetWidth,
              top: e.currentTarget.getBoundingClientRect().bottom - (dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5),
              left: e.currentTarget.getBoundingClientRect().left 
            });
          }
        }}
      >
        <input readOnly placeholder={placeholder} value={value.name} disabled={disabled} />
        <img src={ArrowIcon} width={24} height={24} />
      </button>
      {!disabled && 
      <div className="dropdown-list none" ref={listRef}>
        <div 
          id={`list-wrap ${id}`}
          className="list-wrapper"
          style={{
            top: bg.top,
            left: bg.left,
            width: bg.width
          }}>
          <ul>
            {data.map((d, idx) => 
              <li
                key={`${idx}-${d.value}`}
                className="list-item"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(d);
                  e.currentTarget.parentElement?.parentElement?.parentElement?.classList.add('none');
                  setBg({
                    width: 0,
                    top: 0,
                    left: 0
                  });
                }}
              >{d.name}
              </li>
            )}
          </ul>
        </div>
      </div>
      }
    </div>
  );
};
export default DropdownAtom;