import { ErrorCode } from '@Types/error';
import '../index.scss';
import Button from 'components/UI/Button';
import deleteEventSetting from 'graphql/mutations/deleteMyEventSettingByIds';
import { EventSettingPageType } from 'graphql/types/EventSettingPage';
import { useSnackbar } from 'hooks/useSnackbar';
import { enumFormatter } from 'utils/Formatter';
import { timeToKr } from 'utils/timeFormatter';
import Table, { ColumnType } from 'components/atoms/Table';

const columns:ColumnType<EventSettingPageType>[] = [
  { 
    name: '이벤트 설정 이름',
    value: 'name'
  },{ 
    name: '중요도',
    value: 'priorityCode',
    width: 80,
    renderCell: (row) => <>{enumFormatter(row.priorityCode)}</>
  },{ 
    name: '등록자',
    value: 'createdByName'
  },{ 
    name: '등록일자',
    value: 'createdAt',
    width: 130,
    renderCell: (row) => <>{timeToKr(row.createdAt)}</>
  }
];

interface IDeleteEventSettingModalProps {
  onClose: () => void;
  onSubmit: () => void;
  data: EventSettingPageType[];
}
const DeleteEventSettingModal = ({ onClose, onSubmit, data }: IDeleteEventSettingModalProps) => {
  const [deleteEventSet] = deleteEventSetting();
  
  const handleDelete = () => {
    const idArr = data.map((val) => {
      return val.settingId;
    });
    deleteEventSet({ variables: { 
      eventSettingIds: idArr } }).then(({ data }) => {
      if (data) {
        if (data.deleteEventSettingByIds.result === ErrorCode.SUCCESS) {
          useSnackbar(data.deleteEventSettingByIds.result);
          onSubmit();
          onClose();
        }
        else {
          useSnackbar(data.deleteEventSettingByIds.result as ErrorCode);
        }
      } else {
        useSnackbar(ErrorCode.UNKNOWN);
      }
    });
  };
 
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="delete-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span />
          <p className="title">이벤트 설정 삭제</p>
        </div>
        <div className="content-wrap">
          <div className="p subtitle">선택한 이벤트 설정을 확인하세요.</div>
          <Table
            rows={data}
            columns={columns}
          />
          <div className="btn-wrap">
            <Button disabled={false} text={'삭제'} handleClick={handleDelete} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteEventSettingModal;