import { ErrorCode, ErrorMessage } from '@Types/error';
import apis from 'apis/v2/index';
import PasswordTitleIcon from 'assets/svgs/v2/ico_pw_title.svg';
import NotificationAtom from 'components/v2/atoms/NotificationAtom';
import LabelInput from 'components/v2/LabelInput';
import { useAuth } from 'contexts/AuthProvider';
import { useToast } from 'hooks/v2/useToast';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { pwRegex } from 'utils/Regex';

interface IResetPwProps {
  nextStep: () => void;
}
const ResetPw = ({ nextStep }: IResetPwProps) => {
  const { logout } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const authToken = searchParams.get('authToken');
  
  const { mutateAsync: _resetPw } = useMutation(
    (data: { token: string, userPw: string }) => apis.User.resetPw(data)
  );

  const [inputs, setInputs] = useState({ pw: '', retryPw: '' });
  const [error, setError] = useState('');

  const disabledNextBtn = useMemo(() => {
    if (inputs.pw === '' || inputs.retryPw === '') {
      return true;
    } else {
      return false;
    }
  },[inputs]);

  const changeInputs = (key: 'pw' | 'retryPw', value: string) => {
    setInputs(prev => ({ ...prev, [key]: value }));
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (inputs.pw === inputs.retryPw) {
      if (pwRegex.test(inputs.pw)) {
        if (authToken) {
          _resetPw({ token: authToken, userPw: inputs.pw }).then(({ data }) => {
            if (data.result === ErrorCode.SUCCESS) {
              nextStep();
            } else {
              setError(ErrorMessage[data.result]);
            }
          });
        } else {
          setError('올바르지 않은 접근입니다.');
          useToast(ErrorCode.UNKNOWN, '올바르지 않은 접근입니다.');
          navigate('/auth');
        }
      } else {
        setError('비밀번호는 8자리 이상 12자리 이하의 숫자, 영문자, 특수문자 조합으로 가능합니다.');
      }
    } else {
      setError('입력한 비밀번호가 일치하지 않습니다.');
    }
  };

  return (
    <>
      <article id="reset-pw-new-article">
        <h2><img src={PasswordTitleIcon} width={32} height={32} />비밀번호 재설정</h2>
        <h3>아이디를 입력하면 비밀번호를 다시 설정할 수 있습니다.</h3>
        <form onSubmit={onSubmit}>
          <LabelInput
            title="새로운 비밀번호" 
            id="reset-pw-new" 
            placeholder="새로운 비밀번호 입력"
            type="password"
            defaultValue={inputs.pw} 
            onChangeValue={(value: string) => changeInputs('pw', value)} 
          />
          <LabelInput
            title="새로운 비밀번호 확인" 
            id="reset-pw-retry" 
            placeholder="새로운 비밀번호 재입력"
            type="password"
            noClear={true}
            defaultValue={inputs.retryPw} 
            onChangeValue={(value: string) => changeInputs('retryPw', value)} 
          />
          {error !== '' && 
          <NotificationAtom text={error} />}
          <div className="button-group">
            <button
              className="big-sub-btn"
              onClick={() => logout()}
            >
              로그인으로 돌아가기
            </button>
            <button 
              className="big-main-btn submit-btn" 
              disabled={disabledNextBtn}
            >
              비밀번호 재설정
            </button>
          </div>
        </form>
      </article>
    </>
  );
};
export default ResetPw;