import { useEffect, useMemo, useState } from 'react';
import Button from 'components/UI/Button';
import getAllRuleCloud from 'graphql/queries/getAllRuleCloud';
import { enumFormatter } from 'utils/Formatter';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';
import updateProjectCloud from 'graphql/mutations/updateProjectCloud';

interface IUpdateCloudModalProps {
  onClose: () => void;
  onSubmit: () => void;
  data?: ProjectCloudType;
}
const UpdateCloudModal = ({ onClose, onSubmit, data }: IUpdateCloudModalProps) => {
  const [createData, setCreateData] = useState({
    cloudName: '',
    rootAccount: '',
    attr_1: '',
    attr_2: '',
    attr_3: '',
    attr_4: ''
  });

  const { data: cloudList } = getAllRuleCloud();
  const [updateCloud] = updateProjectCloud();

  const cloudTypeSelectList = useMemo(() => {
    return cloudList?.getAllRuleCloud.data.map(d => ({ name: enumFormatter(d.cloudKind) || d.name, value: d.cloudId })) || [];
  },[cloudList]);

  const handleCreate = () => {
    if (data) {
      /// api 호출
      // updateCloud({
      //   variables: {
      //     reqUpdProjectCloud: {
      //       id: data?.id,
      //       rootAccount: createData.rootAccount === '' ? undefined : createData.rootAccount,
      //       name: createData.cloudName === '' ? '' : createData.cloudName,
      //       attrValue1: createData.attr_1 === '' ? '' : createData.attr_1, 
      //       attrValue2: createData.attr_2 === '' ? undefined : createData.attr_2, 
      //       attrValue3: createData.attr_3 === '' ? undefined : createData.attr_3, 
      //       attrValue4: createData.attr_4 === '' ? undefined : createData.attr_4
      //     }
      //   }
      // }).then(({ data }) => {
      //   if (data) {
      //     if (data.updateProjectCloud.result === ErrorCode.SUCCESS) {
      //       useSnackbar(data.updateProjectCloud.result);
      //       onSubmit();
      //       onClose();
      //     } else {
      //       useSnackbar(data.updateProjectCloud.result);
      //     }
      //   } else {
      //     useSnackbar(ErrorCode.UNKNOWN);
      //   }
      // });
    }
  };

  useEffect(() => {
    if (data) {
      setCreateData({
        cloudName: data.name,
        rootAccount: data.rootAccount,
        attr_1: data.attrValue1,
        attr_2: data.attrValue2,
        attr_3: data.attrValue3,
        attr_4: data.attrValue4
      });
    }
  },[data]);
  
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="cloudmodal-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span/>
          <p>
            연계 클라우드 정보 변경
          </p>
        </div>
        <div className="contents-wrap">
          <p className="title">클라우드 연계 정보를 수정합니다.</p>
          <div className="row">
            <p className="row-title">연계 계정 이름</p>
            <input type="text" className="input" value={createData.cloudName} onChange={e => setCreateData(prev => ({ ...prev, cloudName: e.target.value }))}/>
          </div>
          <div className="row">
            <p className="row-title">클라우드 종류</p>
            <p>{cloudList?.getAllRuleCloud.data.find(d => d.cloudId === data?.cloudId)?.name}</p>
          </div>
          <div className="row">
            <p className="row-title">Root 계정</p>
            <input type="text" className="input" value={createData.rootAccount} onChange={e => setCreateData(prev => ({ ...prev, rootAccount: e.target.value }))}/>
          </div>
          {data?.cloudId === 1 && (
            <><div className="row">
              <p className="row-title">Access key</p>
              <input type="text" className="input" value={createData.attr_1} onChange={e => setCreateData(prev => ({ ...prev, attr_1: e.target.value }))} />
            </div><div className="row">
              <p className="row-title">Secret key</p>
              <input type="text" className="input" value={createData.attr_2} onChange={e => setCreateData(prev => ({ ...prev, attr_2: e.target.value }))} />
            </div></>
          )}
          {data?.cloudId === 2 && (
            <div className="row">
              <p className="row-title">Json key</p>
              <input type="text" className="input" value={createData.attr_1} onChange={e => setCreateData(prev => ({ ...prev, attr_1: e.target.value }))} />
            </div>
          )}
          {data?.cloudId === 3 && (
            <>
              <div className="row">
                <p className="row-title">Directory ID</p>
                <input type="text" className="input" value={createData.attr_1} onChange={e => setCreateData(prev => ({ ...prev, attr_1: e.target.value }))} />
              </div>
              <div className="row">
                <p className="row-title">Application ID</p>
                <input type="text" className="input" value={createData.attr_2} onChange={e => setCreateData(prev => ({ ...prev, attr_2: e.target.value }))} />
              </div>
              <div className="row">
                <p className="row-title">Client secrets</p>
                <input type="text" className="input" value={createData.attr_3} onChange={e => setCreateData(prev => ({ ...prev, attr_3: e.target.value }))} />
              </div>
              <div className="row">
                <p className="row-title">Subscription ID</p>
                <input type="text" className="input" value={createData.attr_4} onChange={e => setCreateData(prev => ({ ...prev, attr_4: e.target.value }))} />
              </div>
            </>
          )}
          <div className="modal-btn-wrap">
            <Button disabled={createData.cloudName === ''} text={'수정'} handleClick={handleCreate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCloudModal;