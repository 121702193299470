import { gql } from '@apollo/client';

const query = gql`
query getMemberPageByParam($reqData: ReqGetMemberPage) {
  getMemberPageByParam(reqData: $reqData) {
    __typename
    result
    first
    number
    numberOfElements
    totalPages
    totalElements
    last
    size
    content {
      ... on Member {
        id
        userId
        fullName
        email
        organId
        mobileNumber
        ranks
        retryCount
        loginTypeCode
        systemAuthCode
        statusCode
        pictureResourceId
        organName
        alertYn
        description
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
      }
    }
  }
}
`
export default query;