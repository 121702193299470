import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteEventSettingVariables {
  eventSettingIds: number[];
}

export interface IDeleteEventSettingResponseData {
  deleteEventSettingByIds: IGqlResponseData<undefined>;
}

const deleteEventSetting = () => useMutation<IDeleteEventSettingResponseData, IDeleteEventSettingVariables>(query);
export default deleteEventSetting;