import './index.scss'; 
import { useAuth } from 'contexts/AuthProvider';

interface IEmptyProjectCardProps {
  className?: string;
  openCreatePJModal?: () => void;
}

const EmptyProjectCard = ({
  className,
  openCreatePJModal
}: IEmptyProjectCardProps) => {
  const { role } = useAuth();
  
  return (
    <div className={`empty-project-card ${className}`}>
      <div> 
        <div className="row-1 flex col a-center">
          <div className="folder-icon" /> 
          생성된 프로젝트가 아직 없습니다.
        </div>
        <div className="row-2 flex col a-center">
          { role === 'pj_owner'
          ? <>
            '프로젝트를 생성하고 멤버를 초대해보세요.'
            <button 
              className="big-main-btn"
              onClick={() => openCreatePJModal && openCreatePJModal()}
            > 
              프로젝트 생성하기
            </button>
          </>
          : '조직소유자가 프로젝트를 생성할 수 있습니다.'
          }
        </div> 
      </div>
    </div>
  );
};

export default EmptyProjectCard;
