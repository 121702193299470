import { useMemo, useState } from 'react';
import ManageHeader from 'components/v2/ManageHeader';
import ManageTab from 'components/v2/ManageTab';
import UserInformation from './UserInformation';
import VerificationInformation from './VerificationInformation';
import Terms from './Terms';

const MENU = [
  {
    label: '사용자 정보',
    value: 'USER_INFORMATION'
  },
  {
    label: '약관',
    value: 'TERMS'
  },
  {
    label: '인증 정보',
    value: 'VERIFICATION_INFORMATION'
  }
]

/**
 * <>lg운영자만 접근 가능한 사용자관리 페이지</>
 */
const Users = () => {
  const [tab, setTab] = useState('USER_INFORMATION');

  const renderContents = useMemo(() => {
    switch (tab) {
      case 'USER_INFORMATION':
        return <UserInformation />;
      case 'TERMS':
        return <Terms />;
      case 'VERIFICATION_INFORMATION':
        return <VerificationInformation />;
    
      default:
        return <UserInformation />;
      }
  }, [tab])

  return (
    <div id="users">
      <ManageHeader 
        title='사용자 관리'
      />
      <ManageTab
        selected={tab}
        menu={MENU}
        convertTab={(value) => setTab(value)}
      />
      {renderContents}
    </div>
  );
}

export default Users;