export const DummyMsgHtml = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en">
<head>
<meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
<meta content="width=device-width, initial-scale=1" name="viewport" />
<meta content="IE=edge" http-equiv="X-UA-Compatible" />
<title>안녕하세요.</title>
<style data-premailer="ignore" type="text/css">
body,table,td,a{-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%}table,td{mso-table-lspace:0pt;mso-table-rspace:0pt}img{-ms-interpolation-mode:bicubic}.hidden{display:none !important;visibility:hidden !important}a[x-apple-data-detectors]{color:inherit !important;text-decoration:none !important;font-size:inherit !important;font-family:inherit !important;font-weight:inherit !important;line-height:inherit !important}div[style*='margin: 16px 0']{margin:0 !important}@media only screen and (max-width: 639px){body,#body{min-width:320px !important}table.wrapper{width:100% !important;min-width:320px !important}table.wrapper td.wrapper-cell{border-left:0 !important;border-right:0 !important;border-radius:0 !important;padding-left:10px !important;padding-right:10px !important}}

</style>

<style>body {
margin: 0 !important; background-color: #fafafa; padding: 0; text-align: center; min-width: 640px; width: 100%; height: 100%; font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
</style></head>
<body style="text-align: center; min-width: 640px; width: 100%; height: 100%; font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; margin: 0; padding: 0;" bgcolor="#fafafa">

<table border="0" cellpadding="0" cellspacing="0" id="body" style="text-align: center; min-width: 640px; width: 100%; margin: 0; padding: 0;" bgcolor="#f4f6f8">
<tbody>
<tr class="line">
<td style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; height: 4px; font-size: 4px; line-height: 4px;" bgcolor="#1A387F"></td>
</tr>
<tr class="header">
<td style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 13px; line-height: 1.6; color: #5c5c5c; padding: 25px 0;">
    <img alt="nemo platform" src="https://nemo-public-storage.s3.ap-northeast-2.amazonaws.com/res/nemo_dev_email_icon.png" width="80" height="60" />
</td>
</tr>
<tr>
<td style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif;">
<table border="0" cellpadding="0" cellspacing="0" class="wrapper" style="width: 640px; border-collapse: separate; border-spacing: 0; margin: 0 auto;">
<tbody>
<tr>
<td class="wrapper-cell" style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; border-radius: 3px; overflow: hidden; padding: 18px 25px; border: 1px solid #ededed;" align="left" bgcolor="#fff">
<table border="0" cellpadding="0" cellspacing="0" class="content" style="width: 100%; border-collapse: separate; border-spacing: 0;">
<tbody>
<tr>
<td class="text-content" style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; color: #333; font-size: 15px; font-weight: 400; line-height: 1.4; padding: 15px 5px;" align="center">
<p>안녕하세요 {1} 님</p>
<p>비밀번호 변경을 위해서 아래 링크를 누르고 다음을 진행해주세요.</p>
<p>
 <a target="_blank" rel="noopener noreferrer" class="highlight" href="{2}" style="color: #3777b0; text-decoration: none; font-weight: 500;">이메일 인증하기</a>
</p>
</td>
</tr>

</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>

<tr class="footer">
<td style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 13px; line-height: 1.6; color: #5c5c5c; padding: 25px 0;">
<img alt="BokchiLab" class="footer-logo"
     src="https://nemo-public-storage.s3.ap-northeast-2.amazonaws.com/res/nemo_dev_email_bottom_line.png" style="display: block; width: 600px; margin: 0 auto 1em;" />
<div>
이메일을 잘 못 받으셨으면, 연락주세요.
</div>
<div>
<a target="_blank" rel="noopener noreferrer" href="https://chewknow.com" style="color: #3777b0; text-decoration: none;">chewknow.com</a>.
</div>
</td>
</tr>


<tr>
<td class="footer-message" style="font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 13px; line-height: 1.6; color: #5c5c5c; padding: 25px 0;">

</td>
</tr>
</tbody>
</table>
</body>
</html>

`;