import { gql } from '@apollo/client';

const query = gql`
  mutation addDictionary($reqData: ReqAddDictionary) {
    addDictionary(reqData: $reqData) {
      result
      data {
        ... on Dictionary {
          id
          relObjectId
          relObjectType
          defaultName
          domainName
          createdAt
          createdBy
          createdByName
          createdByUserId
          modifiedAt
          modifiedBy
          modifiedByName
          modifiedByUserId
        }
      }
    }
  }
`;
export default query;