import './index.scss';

interface IButtonProps{
    disabled: boolean;
    text: string;
    handleClick: () => void;
    
}

const Button = ({
  disabled,
  text,
  handleClick
}: IButtonProps) => {
  return (
    <button className="custom-btn" disabled={disabled} onClick={handleClick}>
      {text}
    </button>
  );
};
export default Button;