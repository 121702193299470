import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface ILeaveOrganVariables {
  reqData: {
    organId: number;
    memberId: string;
  }
}

export interface ILeaveOrganResponseData {
  leaveOrgan: IGqlResponseData<{userToken: string}[]>;
}

const leaveOrgan = () => useMutation<ILeaveOrganResponseData, ILeaveOrganVariables>(query);
export default leaveOrgan;