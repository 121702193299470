import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { HistEventAlertType } from 'graphql/types/HistEventAlertType';

export interface IGetHistEventAlertVariables {
  reqData: {
    projectId: number;
    id: number;
    settingId: number;
  }
}

export interface IGetHistEventAlertResponseData {
  getHistEventAlertInfo: IGqlResponseData<HistEventAlertType[]>;
}

const lazyGetHistEventAlert = () => useLazyQuery<IGetHistEventAlertResponseData, IGetHistEventAlertVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetHistEventAlert;