import { gql } from '@apollo/client';

const query = gql`
  query getDictionaryPageByPara($reqData: ReqGetDictionaryPage) {
    getDictionaryPageByParam(reqData: $reqData) {
      __typename
      result
      number
      size
      first
      last
      numberOfElements
      totalPages
      totalElements
      content {
        ... on Dictionary {
          id
          relObjectId
          relObjectType
          defaultName
          domainName
          relObjectName
          createdAt
          createdBy
          createdByName
          createdByUserId
          modifiedAt
          modifiedBy
          modifiedByName
          modifiedByUserId
        }
      }
    }
  }
`;
export default query;