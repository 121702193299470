import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { MemberProject } from 'graphql/types/MemberProject';

export interface IGetProjectMemberByMemberIdVariables {
  memberId: string;
}

export interface IGetProjectMemberByMemberIdResponseData {
  getProjectMemberByMemberId: IGqlResponseData<MemberProject[]>;
}

/** 프로젝트 조회 상세 */
const lazyGetProjectMemberByMemberId = () => useLazyQuery<IGetProjectMemberByMemberIdResponseData, IGetProjectMemberByMemberIdVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetProjectMemberByMemberId;