import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { AuthenticationInfo } from 'apis/v2/Manage/schema';

export interface IAuthPageByParamVariables {
  reqData: {
    organId?: number;
    text?: string;
    pageInfo: Paging;
  }
}

export interface IAuthPageByParamResponseData {
  getAuthPageByParam: ResponsePage<AuthenticationInfo>;
}

const lazyGetAuthPageByParam = () => useLazyQuery<IAuthPageByParamResponseData, IAuthPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAuthPageByParam;