import { useState } from 'react';
import './index.scss';
import { PLAN_TYPE, PlanTypeEnum } from 'utils/v2/DummyData/Payment';
import PlanCard from './components/PlanCard';

const CURRENT_ACTIVE = PlanTypeEnum.Basic;

/** 
 * <>(일반유저이자 조직소유자)인 사람만 접근가능한 "요금제" </>
*/
const Plan = () => {

  const [selected, setSelected] = useState<PlanTypeEnum>(PlanTypeEnum.Basic);
  const [step, setStep] = useState(1);
  
  return (
    <div id='plan-page' className='flex j-center a-center'>
      <div className='select-plan-wrap flex col j-center'>
        <h5>요금제</h5>
        <div className='card-wrap flex'>
          {PLAN_TYPE.map((val, idx) => (
            <PlanCard
            key={idx}
            data={val}
            currentActive={CURRENT_ACTIVE}
            selected={selected}
            onSelect={(type:PlanTypeEnum) => setSelected(type)}
            />
            ))}
        </div>
        <div className='btns flex j-center'>
          <button 
            disabled={ selected === CURRENT_ACTIVE }
            className='big-main-btn'
            onClick={() => setStep(2)}
          >
            다음
          </button>
        </div>
      </div>
      {/* 다음 스탭으로 넘어 갔을 경우 레이어드 absolute로 보여줄 예정 */}
    </div>
  )
}

export default Plan;