import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { OrganInfo } from 'graphql/types/OrganInfo';

export interface IUpdateOrganVariables {
  organ: {
    organId: number;
    name: string;
    description: string;
  }
}

export interface IUpdateOrganResponseData {
  updateOrgan: IGqlResponseData<OrganInfo[]>;
}

/** 조직 수정 */
const updateOrgan = () => useMutation<IUpdateOrganResponseData, IUpdateOrganVariables>(query);
export default updateOrgan;