import './index.scss';
import { Responsive, WidthProvider } from 'react-grid-layout';
import PanelComp from '../PanelComp';
import { Panel } from '@Types/v2/Chart';

export const ResponsiveGridLayout = WidthProvider(Responsive);

interface ISectionProps {
  panels: Panel[]
}

const Section = ({
  panels
}:ISectionProps) => {

  return (
    <div className="section-wrap">
      <div className="chart-box">
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: panels.map(panel => ({ ...panel.layout })) }}
          autoSize
          useCSSTransforms
          style={{ width: '100%' }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 }}
          rowHeight={111}
          // compactType="horizontal"
          isBounded
          containerPadding={[0, 0]} /* 건들면 dragg이상해짐 */
          isDraggable={true}
          isResizable={true}
          resizeHandles={["sw" , "nw" , "se" , "ne"]}
          onResizeStop={(layout, oldItem, newItem) => {
            const tempPanels = panels.map(val => val);

            for (let i = 0; i < tempPanels.length; i++) {
              const newLayout = layout.find(d => d.i === i.toString());
              
              tempPanels[i].layout = {
                ...tempPanels[i].layout,
                x: i,
                y: 0,
                w: newLayout ? newLayout.w : 3,
                h: newLayout ? newLayout.h : 4,
              };
            }

            const panelEl = document.querySelectorAll('.react-grid-item')
            panelEl.forEach(val => (val.classList.remove('resizing'))) 
          }}
          onDragStop={(layout, oldItem, newItem) => {
            const tempPanels = panels.map(val => val);
            
            for (let i = 0; i < tempPanels.length; i++) {
              const newLayout = layout.find(d => d.i === i.toString());

                tempPanels[i].layout = {
                  ...tempPanels[i].layout,
                  x: i,
                  y: 0,
                  w: newLayout ? newLayout.w : 3,
                  h: newLayout ? newLayout.h : 4,
                };
              }

              const panelEl = document.querySelectorAll('.react-grid-item')
              panelEl.forEach(val => (val.classList.remove('resizing'))) 
          }}
          // onBreakpointChange={(newBreakpoint, newCols) => { }}
        >
          {panels.map(panel => (( <PanelComp key={panel.layout.i} panel={panel} /> ) ))}
        </ResponsiveGridLayout>
      </div>
    </div>
  )
}

export default Section;