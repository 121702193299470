import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import { ACCESS_KEYS } from 'utils/DummyData/Dropdown';
import { checkLinkedCloudName } from 'utils/v2/ValidCheck';
import { AllRuleCloudType } from 'graphql/types/AllRuleCloud';
import addProjectCloud, { IAddProjectCloudVariables } from 'graphql/mutations/addProjectCloud';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

interface ICreateCloudModalProps extends IBaseModalProps {
  cloudTypeList: AllRuleCloudType[];
  doRefecthCloudList: () => void
  projectId: number;
  title: () => JSX.Element;
  onCreateSuccess: () => void;
}

const CreateCloudModal = ({
  cloudTypeList,
  doRefecthCloudList,
  projectId,
  title,
  onCreateSuccess,
  ...baseModalProps
}:ICreateCloudModalProps) => {

  const [payload, setPayload] = useState<{
    name: string;
    cloudId: number;
    rootAccount: string;
    verifyInfo: {
      id: number;
      attrKey: string;
      attrValue: string;
    }[]
  }>({
    name: '', /* 연계 계정 이름 */
    cloudId: -1, /* 클라우드 종류 */
    rootAccount: '', /* 클라우드 계정 */
    verifyInfo: []
  });
  const [accessKeys, setAccessKeys] = useState<{ name: string; value: string; }[]>([]);    

  /** 연계 클라우드 생성 */
  const [addCloud] = addProjectCloud();

  const ableCreateCloud = useMemo(() => {
    if (checkLinkedCloudName(payload.name) ||
    payload.cloudId === -1 || 
    payload.rootAccount === '' || 
    payload.verifyInfo.length === 0 ||
    payload.verifyInfo.some(val => val.attrKey === '' || val.attrValue === '')) {
      return true;
    } else return false;
  }, [payload])

  const accessKeysList = useMemo(() => {
    const attrKeys = payload.verifyInfo.map(val => val.attrKey);
    return accessKeys.filter(acc => !attrKeys.includes(acc.value))
  }, [accessKeys, payload])

  const attrJson = (arr: { id: number, attrKey: string, attrValue: string }[]) => {          
    const outputObject: { [key: string]: string }  = {};
    
    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];
      const currentKey = currentObj.attrKey;
      const currentValue = currentObj.attrValue;
    
      outputObject[currentKey] = currentValue;
    }

    return JSON.stringify(outputObject, null, 0);
  }
  
  useEffect(() => {
    if (baseModalProps.open) {
      setPayload({
        name: '', /* 연계 계정 이름 */
        cloudId: -1,
        rootAccount: '', /* 클라우드 계정 */
        verifyInfo: []
      });
      doRefecthCloudList();
      setAccessKeys(ACCESS_KEYS); //todo
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      {...baseModalProps}
      title={title} 
    >
      <div className="create-cloud-modal">
        <div className={`row-1 flex col ${payload.cloudId !== -1 && 'scroll'}`}>
          <LabelInput 
            title="연계 계정 이름"
            value={payload.name}
            required={true}
            placeholder="연계 계정 이름 입력"
            onChangeValue={(val) => setPayload((prev) => ({
              ...prev,
              name: val
            }))}
          />
          <Labeled
            title="클라우드 종류"
            required={true}
          >
            <DropdownAtom 
              id={'cloud-type'} 
              data={cloudTypeList.map(val => {
                return {
                  id: val.cloudId,
                  name: val.name,
                  value: val.cloudKind
                };
              })} 
              placeholder="선택"
              // error={}
              value={{
                name: cloudTypeList.find(cloud => cloud.cloudId === payload.cloudId)?.name 
                  ? cloudTypeList.find(cloud => cloud.cloudId === payload.cloudId)?.name : '',
                value: String(cloudTypeList.find(cloud => cloud.cloudId === payload.cloudId)?.cloudKind)
              }} 
              handleClick={(val: DropdownListDataType) => 
                setPayload((prev) => ({
                  ...prev,
                  cloudId: Number(val.id)
                }))
              } 
            />
          </Labeled>
          {
            payload.cloudId !== -1 &&
              <>
                <LabelInput
                  title="클라우드 계정"
                  placeholder="클라우드 계정 입력" 
                  defaultValue={payload.rootAccount}
                  required={true}
                  onChangeValue={(val) => setPayload((prev) => ({
                    ...prev,
                    rootAccount: val
                  }))}
                />
                <Labeled
                  title="인증 정보"
                  className="verify-information"
                  required={true}
                >
                  <div className="add-info flex j-end a-center"
                    onClick={() => {
                      const info = {
                        id: payload.verifyInfo.length === 0 
                          ? 1 
                          : payload.verifyInfo[payload.verifyInfo.length - 1].id + 1,
                        attrKey: '',
                        attrValue: ''
                      };
                      setPayload(prev => ({
                        ...prev,
                        verifyInfo: [...payload.verifyInfo, info]
                      }));
                    }}>
                      { payload.verifyInfo.length < 3 && <><button className="add-btn" /> 추가</> }
                  </div>
                  {
                    payload.verifyInfo.length === 0 
                      ? <p className="empty-info">인증 정보를 입력해주세요.</p>
                      : payload.verifyInfo.map(verify => (
                        <div 
                          key={verify.id}
                          className="access-info flex a-center"
                          data-focus={`${verify.id}`}
                        >
                          <DropdownAtom 
                            id={`access-key ${verify.id}`} 
                            data={ accessKeysList.length === 0 
                                ? [{
                                  name: '더 이상 선택가능한 key가 없습니다.',
                                  value: -1
                                }]
                               : accessKeysList }
                            placeholder="선택"
                            value={{
                              name: accessKeys.find(acc => acc.value === verify.attrKey)?.name 
                              ? accessKeys.find(acc => acc.value === verify.attrKey)?.name
                              : '',
                              value: verify.attrKey
                            }} 
                            handleClick={(val: DropdownListDataType) => {
                              if (val.value === -1) return;

                              const copy = payload.verifyInfo.map(val => val);
                              const findIdx = copy.findIndex(data => data.id === verify.id);
                              if (findIdx === -1) return;
                              
                              copy[findIdx].attrKey = String(val.value);
                              setPayload(prev => ({
                                ...prev,
                                verifyInfo: copy
                              }));
                            }} 
                          />
                          <InputAtom
                            placeholder="입력"
                            value={verify.attrValue}
                            onChangeValue={(str) => {
                              const copy = payload.verifyInfo.map(val => val);
                              const findIdx = copy.findIndex(data => data.id === verify.id);

                              copy[findIdx].attrValue = str;
                              setPayload(prev => ({
                                ...prev,
                                verifyInfo: copy
                              }));
                            }}
                          />
                          <button 
                            className="delete-btn" 
                            onClick={() => {
                              const left = payload.verifyInfo.filter(val => val.id !== verify.id);
                              setPayload(prev => ({
                                ...prev,
                                verifyInfo: left
                              }));
                            }} 
                          />
                          {/* {errorMsg && <div className="error-wrapper"><div className="error-icon" />{errorMsg}</div>} */}
                        </div>
                      ))
                  }
                </Labeled>
              </>
          }
        </div>
        <div className="row-2">
          <button 
            className="big-main-btn flex j-center a-center"
            disabled={ ableCreateCloud }
            onClick={() => {
              const createPjCloudData:IAddProjectCloudVariables = {
                reqAddProjectCloud: {
                  projectId: projectId,
                  name: payload.name, /* 연계 계정 이름 */
                  cloudId: payload.cloudId, /* 클라우드 종류 */
                  rootAccount: payload.rootAccount, /* 클라우드 계정 */
                  attrKey1: 'Authentication',
                  attrValue1: attrJson(payload.verifyInfo)
                }
              };

              addCloud({ variables: createPjCloudData }).then(({ data }) => {
                if(data) {
                  if (data.addProjectCloud.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, '등록이 완료되었습니다.');
                    onCreateSuccess();
                  } else {
                    console.log(data.addProjectCloud.result);
                    useToast(ErrorCode.UNKNOWN, '등록을 실패했습니다.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, '등록을 실패했습니다.');
                }
              });
            }}
          >
            등록하기
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateCloudModal;
