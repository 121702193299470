import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteOrganVariables {
  organ: {
    organIds: number[];
  }
}

export interface IDeleteOrganResponseData {
  deleteOrgan: IGqlResponseData<undefined>;
}

/** 조직 삭제 */
const deleteOrgan = () => useMutation<IDeleteOrganResponseData, IDeleteOrganVariables>(query);
export default deleteOrgan;