import './index.scss';
import { RowType } from '@Types/v2/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';

interface IDeleteTermModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteTermModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteTermModalProps) => {

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-term-modal">
        <div className="content">
          <div className="content-header flex a-center">
            <div>약관 이름</div>
            <div>버전</div>
            <div>필수 여부</div>
            <div>사용 여부</div>
          </div>
          <div className="content-main">
            {data.map(val => (
              <div key={val.id} className="report-row flex a-center">
                <div>{val.title}</div>
                <div>{val.version}</div>
                <div>{val.mandatoryYn ? '필수' : '선택'}</div>
                <div>{val.useYn ? '사용' : '미사용'}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            취소
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
            삭제
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteTermModal;
