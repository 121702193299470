import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from '../../types/Paging';
import { ResponsePage } from '../../types/ResponsePage';
import { ReportDetail } from '../../types/ReportDetail';

export interface IGetWeeklyReportPageByParamVariables {
  reqData: {
    projectId?: number;
    text?: string;
    pageInfo: Paging;
  };
}

export interface IGetWeeklyReportPageByParamVariablesResponseData {
  getWeeklyReportPageByParam: ResponsePage<ReportDetail>;
}

/** 주간 리포트 조회 */
const getWeeklyReportPageByParam = () => useLazyQuery<IGetWeeklyReportPageByParamVariablesResponseData, IGetWeeklyReportPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default getWeeklyReportPageByParam;