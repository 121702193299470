import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { MessageInfo } from 'apis/v2/Manage/schema';

export interface IGetMessagesByIdPageVariables {
  messageId: number;
}

export interface IGetMessagesByIdResponseData {
  getMessagesById: IGqlResponseData<MessageInfo[]>;
}

const lazyGetMessagesById = () => useLazyQuery<IGetMessagesByIdResponseData, IGetMessagesByIdPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMessagesById;