import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { MemoInfo } from 'graphql/types/MemoInfo';

export interface IUpdateMemoVariables {
  memo: {
    id: number;
    text: string;
    checkYn: boolean;

    /* mark: lg운영기능 내에서 수정가능한 항목은 아직 논의 필요 */
    relObjectType?: string; /* 메모 종류 */
    name?: string; /* 아이템 이름 */
    projectId?: number; /* 프로젝트id */
  }
}

export interface IUpdateMemoResponseData {
  updateMemo: IGqlResponseData<MemoInfo[]>;
}

/** 요청사항 수정 */
const updateMemo = () => useMutation<IUpdateMemoResponseData, IUpdateMemoVariables>(query);
export default updateMemo;