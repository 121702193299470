import { useEffect, useMemo, useState } from 'react';
import SelectBox, { SelectDataType } from 'components/UI/SelectBox';
import './index.scss';
import Button from 'components/UI/Button';
import getEnumTypeCode from 'graphql/queries/getEnumTypeCode';
import { enumFormatter } from 'utils/Formatter';
import { MessageSelectType } from 'graphql/types/MessageSelect';
import lazySearchMyEventMessage from 'graphql/queries/searchMyEventMessage';
import { EventSelectType } from 'graphql/types/EventSelect';
import lazySearchEvent from 'graphql/queries/searchEvent';
import lazyGetProjectPage, { IGetProjectPageVariables } from 'graphql/queries/getProjectPage';
import insertEventSetting from 'graphql/mutations/insertEventSetting';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';
import SelectBoxCustomList from 'components/UI/SelectBoxCustomList';
import updateEventSetting from 'graphql/mutations/updateEventSetting';
import { EventSettingPageType } from 'graphql/types/EventSettingPage';
import lazyGetMyEventById from 'graphql/queries/getMyEvent';
import { timeToKr } from 'utils/timeFormatter';
import { OrderDirection } from '@Types/v2/Table';

interface ICreateEventModalProps{
  onClose: () => void;
  companyId: number;
  type: 'create' | 'edit';
  defaultData?: EventSettingPageType;
  onSubmit: () => void;
}

const CreateEventModal = ({ onClose, companyId, type, onSubmit, defaultData }: ICreateEventModalProps) => {
  const [createData, setCreateData] = useState<{
    project: SelectDataType,
    name: string,
    event: null | EventSelectType,
    important: SelectDataType,
    sendType: SelectDataType,
    message: null | MessageSelectType | number
  }>({
    project: { name: '', value: -1 },
    name: '',
    event: null,
    important: { name: '', value: '' },
    sendType: { name: '', value: '' },
    message: null
  });
  const [projectList, setprojectList] = useState<ProjectInfo[]>([]);
  const [search, setSearch] = useState('');
  const [eventMessageList, setEventMessageList] = useState<MessageSelectType[]>([]);
  const [eventList, setEventList] = useState<EventSelectType[]>([]);

  const { data: importantList } = getEnumTypeCode({ text: 'PriorityCode' });
  const { data: sendList } = getEnumTypeCode({ text: 'MsgSenderTypeCode' });
  const [lazySearchEventList] = lazySearchEvent();
  const [lazyEventMessageList] = lazySearchMyEventMessage();
  const [lazyGetProjectList] = lazyGetProjectPage();
  const [lazyGetEventById] = lazyGetMyEventById();
  const [addEventSetting] = insertEventSetting();
  const [editEventSetting] = updateEventSetting();

  const getProjectPageVariables:IGetProjectPageVariables = useMemo(() => ({
    reqData: { 
      organIds: [companyId],
      pageInfo: {
        page: 0,
        size: 50, //TODO 전체 리스트 불러오는 방법 답변 아직이라 임시로 50개 적용
        orders: [{
          target: 'createdAt',
          direction: OrderDirection.DES
        }]
      }
    }
  }),[companyId]);
  const memoizeImportantList = useMemo(() => {
    return importantList?.getEnumTypeCode.data.map(d => ({ ...d, name: enumFormatter(d.value) || d.name })) || [];
  },[importantList]);
  const memoizeSendList = useMemo(() => {
    return sendList?.getEnumTypeCode.data.map(d => ({ ...d, name: enumFormatter(d.value) || d.name })) || [];
  },[sendList]);

  const getEventMessageList = (messageId?: number) => {
    lazyEventMessageList({ variables: { sendTypeCode: createData.sendType.value.toString() } }).then(({ data }) => {
      if (data) {
        setEventMessageList(data.getEventMessagesByType.data);
        if (messageId) {
          const messageData = data.getEventMessagesByType.data.find(d => d.id === messageId);
          if (messageData) {
            setCreateData(prev => ({ ...prev, message: messageData }));
          }
        }
      }
    });
  };
  const getSearchEventList = () => {
    lazySearchEventList({ variables: { text: search } }).then(({ data }) => {
      if (data) {
        setEventList(data.searchEvent.data);
      }
    });
  };
  const handleCreate = () => {
    if (type === 'create') {
      const eventId = createData.event?.eventId;
      const messageId = (createData.message as MessageSelectType).id;
      if (eventId && messageId) {
        addEventSetting({
          variables: {
            reqData: {
              projectId: Number(createData.project.value),
              eventId: eventId,
              name: createData.name,
              priorityCode: createData.important.value.toString(),
              messageId: messageId
            }
          }
        }).then(({ data }) => {
          if (data) {
            if (data.insertEventSetting.result === ErrorCode.SUCCESS) {
              useSnackbar(data.insertEventSetting.result);
              onSubmit();
              onClose();
            } else {
              useSnackbar(data.insertEventSetting.result);
            }
          } else {
            useSnackbar(ErrorCode.UNKNOWN);
          }
        });
      }
    } else {
      if (defaultData && typeof createData.message !== 'number') {
        // editEventSetting({
        //   variables: {
        //     reqData: {
        //       settingId: defaultData.settingId,
        //       eventId: createData.event?.eventId,
        //       priorityCode: createData.important.value.toString(),
        //       messageId: createData.message?.id
        //     }
        //   }
        // }).then(({ data }) => {
        //   if (data) {
        //     if (data.updateEventSetting.result === ErrorCode.SUCCESS) {
        //       useSnackbar(data.updateEventSetting.result);
        //       onSubmit();
        //       onClose();
        //     } else {
        //       useSnackbar(data.updateEventSetting.result);
        //     }
        //   } else {
        //     useSnackbar(ErrorCode.UNKNOWN);
        //   }
        // });
      }
    }
  };
  const onChangeSearchText = (text: string) => {
    if (createData.important.value !== '') {
      setCreateData(prev => ({ ... prev, important: { name: '', value: '' } }));
    }
    setSearch(text);
  };
  const onChangeSendType = (val: SelectDataType) => {
    if (createData.message !== null) {
      setCreateData(prev => ({ ... prev, message: null, sendType: val }));
    } else {
      setCreateData(prev => ({ ...prev, sendType: val }));
    }
  };

  //초기세팅1
  useEffect(() => {
    if (defaultData) {
      lazyGetEventById({
        variables: {
          eventId: defaultData.eventId
        }
      }).then(({ data }) => {
        if (data) {
          const importantSelect = importantList?.getEnumTypeCode.data.find(d => d.value === defaultData.priorityCode);
          const sendSelect = sendList?.getEnumTypeCode.data.find(d => d.value === defaultData.msgSenderTypeCode);
          setCreateData({
            project: { name: defaultData.projectName, value: defaultData.projectId },
            name: defaultData.name,
            event: data.getEventById.data[0],
            important: { name: importantSelect ? enumFormatter(importantSelect.value) || '' : '', value: importantSelect?.value || '' },
            sendType: { name: sendSelect ? enumFormatter(sendSelect.value) || '' : '', value: sendSelect?.value || '' },
            message: defaultData.messageId
          });
          setSearch(data.getEventById.data[0].name);
        }
      });
    }
  }, [defaultData, importantList, sendList]);
  useEffect(() => {
    if (companyId !== -1) {
      lazyGetProjectList({ variables: getProjectPageVariables }).then(({ data }) => {
        if (data) {
          setprojectList(data.getProjectInfoPage.content);
        }
      });
    }
  },[companyId]);
  useEffect(() => {
    if (search !== '') {
      getSearchEventList();
    }
  },[search]);
  useEffect(() => {
    if (createData.sendType.value !== '') {
      //초기세팅2-message list 를 찾아와야 함.
      if (typeof createData.message === 'number') {
        getEventMessageList(createData.message);
      } else {
        getEventMessageList();
      }
    }
  },[createData.sendType]);
  
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="cemodal-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span/>
          <p>
            {type === 'create' ? '이벤트 설정 생성' : '이벤트 설정 수정'}
          </p>
        </div>
        <div className="contents-wrap cp">
          <p className="title" style={{ marginBottom: 16 }}>기본정보 입력</p>
          <div className="row" style={{ zIndex: 10 }}>
            <p className="row-title">프로젝트</p>
            {type === 'create' ? <SelectBox 
              id="createeventproj"
              style="message"
              data={projectList.map(d => ({ name: d.name, value: d.projectId }))} 
              handleClick={(val) => setCreateData(prev => ({ ...prev, project: val }))} 
              value={createData.project}/> :
              <p>{createData.project.name}</p>
            }
          </div>
          <div className="row" style={{ marginBottom: 34 }}>
            <p className="row-title">이벤트 설정 이름</p>
            {type === 'create' ? <input 
              type="text" 
              className="input" 
              value={createData.name}
              onChange={e => setCreateData(prev => ({ ...prev, name: e.target.value }))}
            /> : 
              <p>{createData.name}</p>
            }
          </div>
          <p className="title">모니터링 이벤트를 선택하세요.</p>
          <div className="row" style={{ zIndex: 4 }}>
            <p className="row-title">이벤트 검색</p>
            <div className="search-wrapper">
              <input type="text" className="input" value={search} onChange={e => {onChangeSearchText(e.target.value); setCreateData(prev => ({ ...prev, event: null }));}}/>
              {search !== '' && createData.event === null && 
              <div className="search-selectbox-list event">
                {eventList.map((val, idx) => (
                  <div className="list-item" key={idx} onClick={() => {
                    setCreateData(prev => ({ ...prev, event: val }));
                    setSearch(val.name);
                  }}>
                    <div style={{ width: '100%' }}>{val.name}</div>
                    <div style={{ width: '152px', flexShrink: 0 }}>{timeToKr(val.createdAt)}</div>
                  </div>
                ))}
              </div>}
            </div>
          </div>              
          <div className="row" style={{ zIndex: 3, marginBottom: 34 }}>
            <p className="row-title">중요도</p>
            <SelectBox 
              id="createeventimportant"
              placeholder="이벤트를 먼저 선택"
              disabled={createData.event === null}
              style="message"
              data={memoizeImportantList} 
              handleClick={(val) => setCreateData(prev => ({ ...prev, important: val }))} 
              value={createData.important}/>
          </div>
          <p className="title">문구를 선택하세요.</p>
          <div className="row" style={{ zIndex: 2 }}>
            <p className="row-title">수신 타입</p>
            <SelectBox 
              id="createeventtype"
              style="message"
              data={memoizeSendList} 
              handleClick={onChangeSendType} 
              value={createData.sendType}/>
          </div> 
          <div className="row" style={{ zIndex: 1 }}>
            <p className="row-title">선택 문구</p>
            <SelectBoxCustomList
              placeholder="수신 타입을 먼저 선택"
              disabled={createData.sendType.value === ''}
              style="message-select-custom"
              data={eventMessageList} 
              handleClick={(val) => setCreateData(prev => ({ ...prev, message: val }))} 
              value={typeof createData.message === 'number' ? null : createData.message}
              valueComp={(val) => <>{val.message}</>}
              listComp={(val) => (
                <>
                  <div className="text-hidden" style={{ width: '100%' }}>{val.message}</div>
                  <div style={{ width: '72px', flexShrink: 0 }}>{val.createdByName}</div>
                  <div style={{ width: '72px', flexShrink: 0 }}>{timeToKr(val.createdAt)}</div>
                </>
              )}
            />
          </div>
          <div className="modal-btn-wrap">
            <Button disabled={createData.project.value === -1 || createData.name === '' || createData.event === null || createData.important.value === '' || createData.message === null || createData.sendType.value === ''} text={type === 'create' ? '추가' : '수정'} handleClick={handleCreate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEventModal;