import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import Labeled from 'components/v2/atoms/Labeled';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import LabelInput from 'components/v2/LabelInput';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { useOutletContext } from 'react-router-dom';
import SelectCalendarCard from '../../CalendarCard';
import { ProjectInfo } from 'graphql/types/ProjectInfo';

interface ICreateReportModal extends IBaseModalProps {
  placeHolder: string
  onCreate:(payload: {
    name: string;
    projectId: number;
    startTime: string;
    endTime: string;
  }) => void;
}

const CreateReportModal = ({
  placeHolder,
  onCreate,
  title,
  ...baseModalProps
}: ICreateReportModal) => {
  const { projectList, organizationId } = useOutletContext<{ projectList: ProjectInfo[], organizationId: number | null }>();
  // const { projectList } = useOutletContext<{ projectList: ProjectInfo[] }>();

  const [payload, setPayload] = useState({
    name: '',
    projectId: -1,
    startTime: '',
    endTime: '',
    organId: organizationId
  });

  const enableCreateReport = useMemo(() => {
    if (payload.name === '' ||
    payload.startTime === '' ||
    payload.endTime === '' ||
    payload.projectId === -1) {
      return true;
    } else return false;
  }, [payload])

  const updateDateAndTime = (key: string, value: string) => {
    setPayload(prev => ({
      ...prev,
      [key]: value
    }));
  };

  useEffect(() => {
    setPayload({
      name: '',
      projectId: -1,
      startTime: '',
      endTime: '',
      organId: organizationId
    });
  }, [baseModalProps.open]);

  useEffect(() => {
    if (payload.endTime !== '' && (payload.startTime > payload.endTime)) {
      alert('종료일자는 시작 일자보다 빠를 수 없습니다.');
      setPayload(prev => ({
        ...prev,
        endTime: ''
      }))
    }
  }, [payload.startTime, payload.endTime])


  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="create-report-modal">
        <div className="header">
          약간의 시간이 소요될 수 있습니다.
        </div>
        <div className="content-wrap">
          <Labeled 
            title="프로젝트"
            required
          >
            <DropdownAtom
              id={'project-dropdown'}
              placeholder="프로젝트를 선택해주세요" 
              data={projectList.map(val => {
                return {
                  name: val.name,
                  value: val.projectId
                };
              })}
              value={{
                name: projectList.find(val => val.projectId === payload.projectId)?.name 
                  ? projectList.find(val => val.projectId === payload.projectId)?.name
                  : '', 
                value: payload.projectId
              }}
              handleClick={(val:DropdownListDataType) => setPayload(prev => ({
                ...prev,
                projectId: Number(val.value),
                organId: organizationId
              })) }
            />
          </Labeled>
          <Labeled
            title="작성 일시"
            required
          >
            <div className="calendar-sel-wrap flex j-between a-center">
              <SelectCalendarCard 
                id="startTime"
                placeholder="시작 일시 선택" 
                baseModalOpen={baseModalProps.open}
                originValue={payload.startTime}
                updateDateAndTime={updateDateAndTime}
              />
              <SelectCalendarCard 
                id="endTime"
                placeholder="종료 일시 선택"
                baseModalOpen={baseModalProps.open}
                originValue={payload.endTime}
                updateDateAndTime={updateDateAndTime}
              />
            </div>
          </Labeled>
          <LabelInput 
            title="리포트 이름" 
            placeholder={placeHolder} 
            required
            value={payload.name}
            onChangeValue={str => setPayload(prev => ({
              ...prev,
              name: str
            }))}

          />
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center" 
            disabled={ enableCreateReport }
            onClick={() => {
              onCreate(payload);
            }}
          >
            생성 요청
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CreateReportModal;
