import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { ProjectMemberPageType } from 'graphql/types/ProjectMemberPage';

export interface IGetProjectMemberVariables {
  reqGetProjectMemberPage: {
    projectId: number;
    pageInfo: Paging;
  }
}

export interface IGetProjectMemberResponseData {
  getProjectMemberPage: ResponsePage<ProjectMemberPageType>;
}

const lazyGetProjectMember = () => useLazyQuery<IGetProjectMemberResponseData, IGetProjectMemberVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetProjectMember;