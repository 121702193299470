import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IAddMessageSysAdminVariables {
  reqAddMessage: {
    relObjectId: number;
    relObjectType: string;
    sendTypeCode: string;
    version: string;
    title: string;
    message: string;
  }
}

export interface IAddMessageSysAdminResponseData {
  addMessage: IGqlResponseData<undefined>;
}

const addMessageSysAdmin = () => useMutation<IAddMessageSysAdminResponseData, IAddMessageSysAdminVariables>(query);
export default addMessageSysAdmin;