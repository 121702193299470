import { ErrorCode, ErrorMessage } from '@Types/error';
import { toast } from 'react-toastify';

const snackbarStyle = {
  success: {
    backgroundColor: '#75D5BE',
    color: '#fff',
    height: '51px',
    padding: '8px 40px',
    borderRadius: '8px'
  },
  error: {
    backgroundColor: '#D57592',
    color: '#fff',
    height: '51px',
    padding: '8px 40px',
    borderRadius: '8px',
    whitespace: 'nowrap'
  }
};

export const useSnackbar = (code: ErrorCode, text?: string) => {
  if (code === ErrorCode.SUCCESS) {
    return toast(text || '성공',{ 
      style: snackbarStyle.success,
      position: 'bottom-center',
      hideProgressBar: true,
      autoClose: 1000,
      closeButton: false
      
    });
  }
  else return toast(text || ErrorMessage[code],{ 
    style: snackbarStyle.error,
    position: 'bottom-center',
    hideProgressBar: true,
    autoClose: 1000,
    closeButton: false
  });
};