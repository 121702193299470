import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IRemoveProjectMemberVariables {
  reqData: {
    projectId: number;
    memberIds: string[];
  }
}

export interface IRemoveProjectMemberResponseData {
  removeProjectMember: IGqlResponseData<{ userToken: string; }[]>;
}

/* 프로젝트 나가기, 또는 프로젝트 내보내기 */
const removeProjectMember = () => useMutation<IRemoveProjectMemberResponseData, IRemoveProjectMemberVariables>(query);
export default removeProjectMember;