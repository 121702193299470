import { useEffect, useMemo, useState } from 'react';
import SelectBox from 'components/UI/SelectBox';
import './index.scss';
import Button from 'components/UI/Button';
import { useRecoilValue } from 'recoil';
import { projectListState } from 'store/User';
import { useSnackbar } from 'hooks/useSnackbar';
import addWeeklyReport from 'graphql/mutations/addWeeklyReport';
import { ErrorCode } from '@Types/error';
import addMonthlyReport from 'graphql/mutations/addMonthlyReport';
import { useLocation } from 'react-router-dom';

interface ICreateDraftModalProps{
    open: boolean;
    onClose: () => void;
    callback: () => void;
}
type DrafteType = {
  
    name: string;
    projectId: number;
    startTime: string;
    endTime: string;
  
}
const CreateDraftModal = ({ open, onClose, callback }: ICreateDraftModalProps) => {
  const pathname = useLocation().pathname;

  const projectList = useRecoilValue(projectListState);
  
  const [createWeekly] = addWeeklyReport();
  const [createMonthly] = addMonthlyReport();

  const selectItem = useMemo(() => {
    return (
      projectList?.map((val) => {
        return (
          {
            name: val.name,
            value: val.projectId
          }
        );
      })
    );
  },[projectList]);

  const [createData, setCreateData] = useState<DrafteType>({
    name: '',
    projectId: 0,
    startTime: '',
    endTime: ''
  });
  const [forceClose, setForceClose] = useState(false);

  const handleCreate = () => {
    /* 리포트 초안 생성 */
    if (pathname === '/monitor/weekly-report') {
      createWeekly({ variables: { report: createData } }).then(({ data }) => {
        if (data?.addWeeklyReport.result === ErrorCode.SUCCESS) {
          callback();
          useSnackbar(data?.addWeeklyReport.result);
        }
        else {
          useSnackbar(data?.addWeeklyReport.result as ErrorCode, '생성 실패');
        }
      });
    }
    else {
      createMonthly({ variables: { report: createData } }).then(({ data }) => {
        if (data?.addMonthlyReport.result === ErrorCode.SUCCESS) {
          callback();
          useSnackbar(data?.addMonthlyReport.result);
        }
        else {
          useSnackbar(data?.addMonthlyReport.result as ErrorCode, '생성 실패');
        }
      });
    }
    /* api 호출 성공시 */
    onClose();
  };

  useEffect(() => {
    setCreateData({
      name: '',
      projectId: 0,
      startTime: '',
      endTime: ''
    });
  },[open]);
  useEffect(() => {
    if (forceClose) {
      setForceClose(false);
    }
  },[forceClose]);
  
  return (
    <>
      {open && (
        <div className="modal-bg" onClick={onClose}>
          <div className="cdmodal-wrap" onClick={e => {
            e.stopPropagation();
            setForceClose(true);
          }}>
            <div className="header-wrap">
              <span/>
              <p>
                {pathname === '/monitor/weekly-report' ? '주간' : '월간'} 리포트 초안 생성
              </p>
            </div>
            <div className="contents-wrap cp">
              <p className="title">리포트 이름과 정보를 확인해주세요.</p>
              <p className="title" style={{ marginBottom: 16 }}>초안 생성에는 약간의 시간이 소요됩니다.</p>
              <div className="row" style={{ zIndex: 10 }}>
                <p className="row-title">프로젝트</p>
                <SelectBox 
                  id="createdraftproj"
                  data={selectItem!} 
                  handleClick={(val) => setCreateData(prev => ({ ...prev, projectId: +val.value }))} 
                  value={{
                    name: String(selectItem?.filter(a => a.value === createData.projectId)[0]?.name || ''),
                    value: createData.projectId
                  }}
                  forceClose={forceClose}
                />
              </div>
              <div className="row">
                <p className="row-title">작성 기간</p>
                <div className="input-row">
                  <input type="datetime-local" className="input" placeholder="연도-월-일" onChange={e => setCreateData(prev => ({ ...prev, startTime: e.target.value + ':00.000Z' }))}/>
                  <span>~</span>
                  <input type="datetime-local" className="input" placeholder="연도-월-일" onChange={e => setCreateData(prev => ({ ...prev, endTime: e.target.value + ':00.000Z' }))}/>
                </div>
              </div>
              <div className="row">
                <p className="row-title">리포트 이름</p>
                <input type="text" className="input" onChange={e => setCreateData(prev => ({ ...prev, name: e.target.value }))}/>
              </div>
              <div className="modal-btn-wrap">
                <Button disabled={createData.name === '' || createData.endTime === ''} text={'추가'} handleClick={handleCreate}/>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    
  );
};

export default CreateDraftModal;