import { Data } from 'plotly.js';
import { lazy, memo, Suspense, useMemo } from 'react';
import IsLoading from 'components/v2/atoms/IsLoading';
import { Panel } from '@Types/v2/Chart';

const DefaultPlot = lazy(() => import('components/v2/Charts/withDefaultPlotlyProps'));

export interface ILineChartProps {
  defaultData: Panel;
}

/** 실제 그려지는 pie chart */
const PieChart = ({
  defaultData
}:ILineChartProps) => {

  const data: {
    labels: string[];
    values: number[];
    marker: { colors: string[] };
    type: string;
  } = useMemo(() => {
    const result = {
      labels: defaultData.data.labels,
      values: defaultData.data.values,
      marker: { colors: defaultData.data.colors },
      type: 'pie'
    }

    return result;
  }, [defaultData]);

  return (
    <Suspense fallback={<IsLoading />}>
      <DefaultPlot
        data={[data as Data]}
        layout={{ font: { family: 'Pretendard' } }}
      />
    </Suspense>
  );
};

export default memo(PieChart);