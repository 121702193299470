/* eslint-disable no-useless-escape */

/* 이메일 유효성 체크(조건이 하나라도 안맞으면 true리턴) */
export const checkEmail = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const parts = email.split('@');

  /* 이메일 정규식에 맞고, 골뱅이 앞뒤로 알파벳이 작성되어 있어야 한다. */
  if ( emailPattern.test(email) && parts.length === 2) { return false; } 
  else { return true; }
};

export const checkUserName = (userName:string) => {
  /* 한글, 영대문자, 영소문자, 숫자의 2자 이상 32글자 이하 */
  const pwPattern = /^[A-Za-z0-9가-힣 ]{2,30}$/;
  if (pwPattern.test(userName)) { return false; }
  else {return true; }
}

export const checkPw = (pw: string) => {
  /* 영대문자, 소문자, 숫자, 특수문자의 8자 이상 32글자 이하 */
  const pwPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_\-+=\[\]|\\;:'"<>,.?\/]).{8,32}$/;
  if (pwPattern.test(pw)) { return false; }
  else {return true; }
};

/* 연계 클라우드 연계계정 */
/* 
  가능: 한글, 영어, 숫자, 키보드에서 입력할 수 있는 특수문자, 띄어쓰기
  불가능: 이모지
  글자 수 제한: 최소 n자, 최대 n자
  맨 앞자리 또는 맨 뒷자리에 공백 입력 불가
  띄어쓰기로만 이루어진 데이터는 저장 불가
*/
export const checkLinkedCloudName = (name: string) => {
  const linkedCloudNamePattern = /^(?!\s)(?!.*\s$)(?!.*\s\s)[\p{L}\p{N}!@#$%^&*()_+{}\[\]:;<>,.?~\\/ -]+$/u;
  if (linkedCloudNamePattern.test(name)) {return false;}
  else {return true;}
};
