import { RowType } from '@Types/v2/Table';
import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import KeyIcon from 'assets/svgs/v2/ico_key.svg';
import Register from 'assets/svgs/v2/ico_register.svg';
import Edit from 'assets/svgs/v2/ico_edit.svg';
import { numberRegex } from 'utils/Regex';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import Cloud from 'assets/images/v2/ico_cloud.png';
import CategoryIcon from 'assets/svgs/v2/ico_category_squares.svg';
import InformationIcon from 'assets/svgs/v2/ico_information.svg';
import DocTitleIcon from 'assets/svgs/v2/ico_document_title.svg';
import DocFormIcon from 'assets/svgs/v2/ico_document_form.svg';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import updateCategory from 'graphql/mutations/updateCategory';

interface ICategoryDetailProps {
  cloudList: {
    id: number;
    value: string;
    name: string;
  }[]
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const CategoryDetail = ({
  cloudList,
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: ICategoryDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState({
    categoryId: -1,
    cloudId: -1,
    name: '',
    maskCode: '',
    description: '',
    title: '',
    template: '',
  });

  const [editCategory] = updateCategory();

  const origin = useMemo(() => {
    return {
      categoryId: data.categoryId,
      cloudId: data.cloudId,
      name: data.name,
      maskCode: data.maskCode,
      description: data.description,
      title: data.title,
      template: data.template,
    }
  }, []);

  const enableButton = useMemo(() => {
    if (
        detail.cloudId === -1 || 
        detail.name === '' || 
        detail.maskCode.length < 8 || 
        detail.description === '' ||
        detail.title === '') {
          return true;
        } else return false;
  }, [detail])

  useEffect(() => {
    setDetail({
      categoryId: data.categoryId,
      cloudId: data.cloudId,
      name: data.name,
      maskCode: data.maskCode,
      description: data.description,
      title: data.title,
      template: data.template,
    });
  }, []);

  return (
    <div className='category-detail'>
      <div className='detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? detail.name : '카테고리 수정'}
      </div>
      <div className='detail-contents'>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Cloud} 
            />
          } 
          title="클라우드 정보" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? enumFormatter(String(cloudList.find(val => val.id === detail.cloudId)?.value))
                : <DropdownAtom 
                  id="cloud-kind"
                  placeholder='선택'
                  data={cloudList} 
                  value={{
                    name: cloudList.find(val => val.id === detail.cloudId)?.name
                      ? cloudList.find(val => val.id === detail.cloudId)?.name
                      : '',
                    value: detail.cloudId
                  }}
                   handleClick={(val) => {
                    setDetail(prev => ({
                      ...prev,
                      cloudId: Number(val.id)
                    }))
                   }}                
                />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={CategoryIcon}
            />
          } 
          title="카테고리 이름" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.name
                : <InputAtom
                    placeholder='카테고리 이름 입력'
                    value={detail.name}
                    onChangeValue={(val) => setDetail((prev) => ({
                      ...prev,
                      name: val
                    }))}
                  />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={KeyIcon}
            />
          } 
          title="고유 구분자" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.maskCode
                : <InputAtom
                    placeholder='고유 구분자 입력(마스크코드 8자리 숫자)'
                    value={detail.maskCode}
                    onChangeValue={(val) => {
                      if (val.length > 8) return;

                      setDetail((prev) => ({
                      ...prev,
                      maskCode: val.replace(numberRegex, '')
                    }))
                    }
                  }
                  />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={InformationIcon}
            />
          } 
          title="설명" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.description
                : <InputAtom
                    placeholder='설명 입력'
                    value={detail.description}
                    onChangeValue={(val) => setDetail((prev) => ({
                      ...prev,
                      description: val
                    }))}
                  />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={DocTitleIcon}
            />
          } 
          title="문서 제목" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.title
                : <InputAtom
                    placeholder='문서 제목 입력'
                    value={detail.title}
                    onChangeValue={(val) => setDetail((prev) => ({
                      ...prev,
                      title: val
                    }))}
                  />
              }
            </>
          } 
        />
        {
          !edit &&
        <>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Register} 
            />
          } 
          title="등록"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Edit} 
            />
          } 
          title="마지막 수정"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
        </>
        }
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={DocFormIcon}
            />
          } 
          title="문서 서식"
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? !detail.template ? '-' : detail.template
                : <TextareaAtom
                    placeholder='문서 서식 입력'
                    value={detail.template}
                    onChangeValue={(val) => setDetail((prev) => ({
                      ...prev,
                      template: val
                    }))}
                  />
              }
            </>
          } 
        />
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                수정
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                삭제
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                취소
              </button>
              <button 
                className="big-main-btn"
                disabled={ enableButton }
                onClick={() => { 
                  const updateCategoryData = {
                    reqData: {
                      ...detail
                    }
                  }
                  editCategory({ variables: updateCategoryData}).then(({ data }) => {
                    if (data) {
                      if (data.updateCategory.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, '카테고리가 수정되었습니다.');
                        setEdit(false);
                        onEditSuccess(); 
                      } else {
                        console.log(data.updateCategory.result);
                        useToast(ErrorCode.UNKNOWN, '카테고리가 수정을 실패했습니다.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, '카테고리 수정을 실패했습니다.');
                    }
                  })
                }}
              >
              수정하기
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default CategoryDetail;