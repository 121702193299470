import TextareaAtom from '../atoms/TextareaAtom';
import './index.scss';
import ErrorIcon from 'assets/svgs/v2/ico_input_error.svg';

interface ILabelTextareaProps {
  title: string;
  placeholder?: string;
  errorMsg?: string;
  id?: string;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  noClear?: boolean;
  onChangeValue?: (value: string) => void;
}
const LabelTextarea = ({ 
  title, 
  placeholder = '', 
  errorMsg, 
  id, 
  value, 
  defaultValue, 
  required, 
  disabled, 
  onChangeValue, 
  noClear 
}:ILabelTextareaProps) => {
  return (
    <div className="label-textarea-comp">
      <label {...(required !== undefined && { className: required ? 'required' : 'option' })}>{title}</label>
      <TextareaAtom
        placeholder={placeholder} 
        className={errorMsg && 'error'} 
        id={id} 
        error={errorMsg !== undefined && errorMsg !== ''} 
        value={value}
        defaultValue={defaultValue}
        onChangeValue={onChangeValue && onChangeValue}
        required={required}
        disabled={disabled}
        noClear={noClear}
        // {...(defaultValue && { defaultValue: defaultValue })}
        // {...(value && { value: value })}
      />
      {errorMsg && <div className="error-wrapper"><img src={ErrorIcon} width={16} height={16} />{errorMsg}</div>}
    </div>
  );
};
export default LabelTextarea;