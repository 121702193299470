import { gql } from '@apollo/client';

const query = gql`
  mutation delegateOwner($memberId: UUID) {
    delegateOwner(memberId: $memberId) {
      result
      data {
        __typename
        ... on RespCheckIn {
          userToken
        }
      }
    }
  }
`;

export default query;