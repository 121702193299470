import { gql } from '@apollo/client';

const query = gql`mutation updateMyHistEvent($req: ReqUpdateHistEvent) {
  updateHistEvent(reqData: $req) {
    __typename
    result
    data {
      ... on HistEvent {
        __typename
        id
      }
    }
  }
}`;
export default query;