import './index.scss';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import Calendar from 'assets/svgs/v2/ico_calender.svg';
import Clock from 'assets/images/v2/ico_clock_icon.png';
import Members from 'assets/svgs/v2/ico_members.svg';
import Profile from 'assets/svgs/v2/ico_profile.svg';
import ClockContent from 'assets/images/v2/alert_content.png';
import AlertTitle from 'assets/svgs/v2/ico_alert_tile.svg';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { ResultHistEventAlertType } from '@Types/v2/Report';

interface ISendEventAlertInformationModalProps extends IBaseModalProps {
  title: () => JSX.Element;
  data : ResultHistEventAlertType;
}

const SendEventAlertInformationModal = ({
  title,
  data,
  ...baseModalProps
}: ISendEventAlertInformationModalProps) => {
  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="send-alert-modal">
        <div className="border-wrap" />
        <div className="content-wrap">
          <InfoCard 
           icon={
            <Icon 
              width={32} 
              height={32} 
              src={Calendar} 
            />
           } 
           title="알림 발송 일시" 
           content={() => <>{!data.sentAt ? '-' : dateFormatter(data.sentAt, 'datetime')}</>} 
          />
          <InfoCard 
           icon={
            <Icon 
              width={32} 
              height={32} 
              src={AlertTitle}
            />
           } 
           title="알림 제목" 
           content={() => <>{data.title}</>} 
          />
          <InfoCard 
           icon={
            <Icon 
              width={32} 
              height={32} 
              src={ClockContent} 
            />
           } 
           title="알림 내용" 
           content={() => <pre>{data.contents}</pre>} 
          />
          <InfoCard 
           icon={
            <Icon 
              width={32} 
              height={32} 
              src={Clock} 
            />
           } 
           title="알림 형태" 
           content={() => <>{data.sendTypeCode === 'email' ? enumFormatter('email000') :  enumFormatter('sms00000') }</>} 
          />
          <InfoCard 
           icon={
            <Icon 
              width={32} 
              height={32} 
              src={Profile} 
            />
           } 
           title="발송자" 
           content={() => <>{data.senderName}</>} 
          />
          <InfoCard 
           icon={
            <Icon 
              width={32} 
              height={32} 
              src={Members} 
            />
           } 
           title="수신자" 
           content={() => 
            <> 
              {data.receiversInfo.map((val, idx) => (
                <div key={idx}>{val}</div>
              ))}
            </>
           } 
          />
        </div>
        <div className="btns">
          <button 
            className="big-main-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            확인
          </button>
        </div>

      </div>
    </BaseModal>
  );
};

export default SendEventAlertInformationModal;
