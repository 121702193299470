import { enumFormatter } from 'utils/Formatter';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import deleteProjectCloud from 'graphql/mutations/deleteProjectCloud';

interface IDeleteCloudModalProps extends IBaseModalProps {
  data: { 
    name: string; 
    cloudType: string; 
  }[];
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteCloudModal = ({
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteCloudModalProps) => {

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-cloud-modal">
        
        <div className="row-1">
          선택한 연계클라우드를 확인하세요. <br/>
          삭제 시, 즉시 삭제되며 복구가 불가합니다.
        </div>
        <div className="row-2">
          <div className="sub-1 flex">
            <p>계정 이름</p>
            <p>클라우드 종류</p>
          </div>
          <div className="sub-2">
            {
              data.map((val, idx) => (
                <div key={idx} className="flex a-center">
                  <p>{val.name}</p>
                  <p>{val.cloudType}</p>
                </div>
              ))
            }
          </div>
        </div>
        <div className="row-3">
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
            삭제하기
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteCloudModal;
