import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { MemberInfo } from 'apis/v2/Manage/schema';

export interface IGetMemeberPageByParamVariables {
  reqData: {
    organId?: number;
    text?: string;
    pageInfo: Paging;
  }
}

export interface IGetMemberPageByParamResponseData {
  getMemberPageByParam: ResponsePage<MemberInfo>;
}

const lazyGetMemberPageByParam = () => useLazyQuery<IGetMemberPageByParamResponseData, IGetMemeberPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMemberPageByParam;