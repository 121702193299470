/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import LinkedCloudDetail from '../LinkedCloudDetail';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import DeleteCloudModal from 'components/v2/modals/DeleteCloudModal';
import CreateCloudModal from 'components/v2/modals/CreateCloudModal';
import ProjectSettingModal from 'components/v2/modals/ProjectSettingModal';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import DataChips from 'assets/images/v2/ico_data_chips.png';
import Members from 'assets/svgs/v2/ico_members.svg';
import DataProperty from 'assets/images/v2/ico_data_properties.png';
import Register from 'assets/svgs/v2/ico_register.svg';
import Edit from 'assets/svgs/v2/ico_edit.svg';
import RedCircleCloud from 'assets/svgs/v2/ico_redcircle_cloud.svg';
import { dateFormatter } from 'utils/Formatter';
import { useOutletContext } from 'react-router-dom';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import lazyGetProjectCloudPage from 'graphql/queries/getProjectCloudPage';
import deleteProjectCloud from 'graphql/mutations/deleteProjectCloud';
import { lazyGetAllRuleCloud } from 'graphql/queries/getAllRuleCloud';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';
import { AllRuleCloudType } from 'graphql/types/AllRuleCloud';
import InfoCard from '../cards/InfoCard';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import { timeToKr } from 'utils/timeFormatter';
import lazyGetProjectMember from 'graphql/queries/getProjectMemberPage';
import IsLoading from 'components/v2/atoms/IsLoading';

interface IProjectOccupiedProps {
  project: ProjectInfo;
}

const ProjectOccupied = ({
  project
}: IProjectOccupiedProps) => {
  const { tooltip } = useGModal();
  const { 
    favoriteList,
    toggleFavorite
  } = useOutletContext<{ 
    favoriteList: { id:number; name: string }[], 
    toggleFavorite: (id: number, name: string) => void 
  }>();

  const [memberList, setMemberList] = useState<string[]>([]);
  const [cloudTypeList, setCloudTypeList] = useState<AllRuleCloudType[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false,
    create: false,
    project: false
  });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<ProjectCloudType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  /* 연계 클라우드 조회 */
  const [lazyGetCloudList] = lazyGetProjectCloudPage();
  /** 연계 클라우드 삭제 */
  const [deleteCloud] = deleteProjectCloud();

  /** 클라우드 종류 (기준 클라우드 정보 조회) */
  const [getAllRuleCloud, { loading: loadingGetAllRuleCloud }] = lazyGetAllRuleCloud();
  /** 해당 프로젝트 구성원 정보 불러오기 */
  const [lazyGetMemberList] = lazyGetProjectMember();

  /* 프로젝트 연계 클라우드 요청 request payload */
  const getProjectCloudPageData = useMemo(() => {
    return ({
    projectCloud: {
      projectId: project.projectId,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target, 
          direction: tablePagination.direction 
        }]
      }
    }
    
  })}, [project.projectId, tablePagination]);

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: '계정 이름',
        field: 'name',
        sort: true
      },
      {
        label: '클라우드 종류',
        field: 'cloudId',
        renderCell: (row: RowType) => <>{cloudTypeList.find(li => li.cloudId === row.cloudId)?.name}</>,
        sort: true
      },
      {
        label: '클라우드 계정',
        field: 'rootAccount',
        sort: true,
        renderCell: (row: RowType) => <>{!row.rootAccount ? '-' : row.rootAccount}</>,
      },
      {
        label: '수정한 사람',
        field: 'modifiedByName',
        sort: true
      },
      {
        label: '수정시간',
        field: 'modifiedAt',
        renderCell: (row:RowType) => <>{timeToKr(row.modifiedAt)}</>, 
        sort: true
      }
    ];
  }, [cloudTypeList, rows]);

  const incomeJson = useMemo(() => {
    try {
      const json = JSON.parse(project.attrValue1);

      return JSON.stringify(json, null, 4);
    } catch {
      return project.attrValue1;
    }
  }, [project]);

  const doRefecthCloudList = () => {
    getAllRuleCloud().then(res => {
      if (res.data) {
        setCloudTypeList(res.data.getAllRuleCloud.data);
      }
    })
  }

  /* 연계 클라우드 rows 호출 */
  const getCloudRows = () => {
    lazyGetCloudList({ variables: getProjectCloudPageData }).then(({ data }) => {
      if(data) {
        if (data.getProjectCloudPage) {
          setRows(data.getProjectCloudPage.content);
          setTotal({
            totalPage: data.getProjectCloudPage.totalPages,
            totalElement: data.getProjectCloudPage.totalElements
          });
        }
      }
    });
  }

  useEffect(() => {
    if (project.projectId) {
    setSelected('');
    getCloudRows();
    doRefecthCloudList();

    lazyGetMemberList({ variables: {
      reqGetProjectMemberPage: {
        projectId: project.projectId,
        pageInfo: {
          page: 0,
          size: 50,
          orders: [{
            target: 'createdAt',
            direction: OrderDirection.ASC
          }]
        }
      }
    } }).then(({ data }) => {
      if(data) {
        if (data.getProjectMemberPage.totalElements !== 0) {
          lazyGetMemberList({ variables: {
            reqGetProjectMemberPage: {
              projectId: project.projectId,
              pageInfo: {
                page: 0,
                size: data.getProjectMemberPage.totalElements,
                orders: [{
                  target: 'createdAt',
                  direction: OrderDirection.ASC
                }]
              }
            }
          } }).then(({ data }) => {
            if (data) {
              setMemberList(data.getProjectMemberPage.content.map(val => val.roleCode));
            }
          });
        } else {
          setMemberList([]);
        }
      }
    });
    }
  }, [project.projectId]);

  useEffect(() => getCloudRows(), [getProjectCloudPageData]);

  return (
    <div id="project-occupied-page">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center">
          {project.name}
          <button 
            className={`favorite-btn ${favoriteList.find(val => val.id === project.projectId)?.id === project.projectId && 'active'}`}
            onClick={() => { toggleFavorite(project.projectId, project.name); }}
          />
        </div>
        <button 
          className="setting-btn" 
          onClick={() => setModalIsOpen((prev) => ({
            ...prev,
            project: true
          }))}
        />
      </div>
      <div className="row-2 flex j-start">
        <div className="flex col"> 
          <InfoCard
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={DataChips} 
              />
            } 
            title="데이터 유입"
            content={() => <>{project.thirdPartTypeCode}</>} 
          />
          <InfoCard 
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={Members} 
              />
            } 
            title="구성원" 
            content={() => <>{memberList.length}명 <span>(
              {`소유자 ${memberList.filter(val => val === 'pj_owner').length}명`}
              {`, 관리자 ${memberList.filter(val => val === 'pj_admin').length}명`}
              {`, 멤버 ${memberList.filter(val => val === 'pj_membr').length}명`}
               )</span></>} 
          />
          <InfoCard 
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={DataProperty} 
              />
            } 
            title="데이터 유입 속성" 
            content={() => 
              <div className='data-inflow'>
                <pre 
                  style={{ 
                    width: '100%', 
                    whiteSpace: 'pre-wrap', 
                    wordBreak: 'break-all' 
                  }}
                >
                  {incomeJson}
                </pre>
              </div> 
            } 

          />
        </div>
        <div className="flex col">
          <InfoCard 
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={Register} 
              />
            } 
            title="등록" 
            className="column-2" 
            content={() => 
              <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: project.createdByUserId, fullName: project.createdByName, createdAt: !project.createdAt ? '' : project.createdAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {project.createdByName}({(project.createdByUserId)})</u> / {!project.createdAt ? '' :dateFormatter(project.createdAt, 'datetime')}
              </> 
            }
          />
          <InfoCard 
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={Edit} 
              />
            } 
            title="마지막 수정" 
            className="column-2"
            content={() => 
              <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: project.modifiedByUserId, fullName: project.modifiedByName, createdAt: !project.modifiedAt ? '' : project.modifiedAt, thumbnail: '' } /* mark: 추후 실제 이 유저의 마지막 접속일 데이터로 수정필요 */
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {project.modifiedByName}({(project.modifiedByUserId)})</u> / {!project.modifiedAt ? '' :dateFormatter(project.modifiedAt, 'datetime')}
              </> 
            } 
          />
        </div>
      </div>
      <div className='data-contents'>
        <div className="row-3">
          <div className="row-1 flex j-between">
            <div className="col-1 flex a-center">
              <div className="cloud-icon" /> 연계 클라우드
            </div>
            <div className="col-2 flex a-center">
              { checkedList.length > 0 && `${checkedList.length}개 선택됨` }
              <button 
                className="big-sub-btn delete"
                disabled={checkedList.length === 0 }
                onClick={() => setModalIsOpen((prev) => ({
                  ...prev,
                  delete: true
                }))}
              >
                삭제하기
              </button>
              <button 
                className="big-main-btn register"
                onClick={() => setModalIsOpen((prev) => ({
                  ...prev,
                  create: true
                }))}
              >
                등록하기
              </button> 
              <DropdownAtom 
                id="offset-limit"
                data={TABLE_OFFSET}
                value={{ 
                  name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                  value: Number(tablePagination.limit)
                }}
                handleClick={(val) => { 
                  setTablePagination(prev => ({
                    ...prev,
                    limit: Number(val.value),
                    currentPage: 1
                  }));
                }}
              />
            </div>
          </div>
          <div className="row-2">
            <Table /* default sort: 수정시간 최신순(오름차순)  */
              rows={rows} 
              columns={COLUMNS} 
              reportCheckedList={ (list) => setCheckedList(list)}
              reportSelected={(id) => setSelected(id)}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  setTablePagination(prev => ({
                    ...prev,
                    target: target,
                    direction: dir
                  }))
              }
              }}
            />
            { 
              rows.length === 0 
                ? <div className="empty-cloud flex j-center a-center">
                  <div className="flex col a-center">
                    <div />
                    <h5>등록된 연계 클라우드 계정이 없습니다.</h5>
                    <p>등록하기를 통해 연계 클라우드 계정을 등록해주세요.</p>
                  </div>
                </div> 
                : <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center">전체 <span>{total.totalElement}개</span></p>
                  <TablePagination 
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={(page) => setTablePagination(prev => ({
                      ...prev,
                      currentPage: page
                    }))}
                    totalPage={total.totalPage}
                  />
                </div>
            }
          </div>
        </div>
        { loadingGetAllRuleCloud && <IsLoading dimmed={rows.length !== 0} /> }
      </div>

      <DeleteCloudModal
        open={modalIsOpen.delete}
        data={rows.filter(row => checkedList.includes(String(row.id))).map(val => {
          return {
            name: val.name,
            cloudType: String(cloudTypeList.find(li => li.cloudId === val.cloudId)?.name)
          };
        })}
        title= {() => 
          <>
            <Icon width={32} height={32} src={RedCircleCloud} />
            연계 클라우드 정보 삭제
          </>
        } 
        onClose={() => setModalIsOpen((prev) => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {
          const data = {
            reqDelProjectCloud: checkedList.map(id => Number(id))
          };

          deleteCloud({ variables: data }).then(({ data }) => {
            if(data) {
              if (data.deleteProjectCloud.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, '삭제가 완료되었습니다 .');
                setModalIsOpen((prev) => ({
                  ...prev,
                  delete: false
                }));

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getCloudRows();
                }

                setSelected('');
                setCheckedList([]);
              } else {
                console.log(data.deleteProjectCloud.result);
                useToast(ErrorCode.SUCCESS, '삭제를 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.SUCCESS, '삭제를 실패했습니다.');
            }
          });
        }}
      /> 

      <CreateCloudModal 
        open={modalIsOpen.create} 
        cloudTypeList={cloudTypeList}
        doRefecthCloudList={doRefecthCloudList}
        projectId={project.projectId}
        title={() =>  
          <>
            <Icon width={32} height={32} src={RedCircleCloud} />
            연계 클라우드 등록
          </>
        } 
        onClose={() => setModalIsOpen((prev) => ({
          ...prev,
          create: false
        }))}
        onCreateSuccess={() => {
          setModalIsOpen((prev) => ({
            ...prev,
            create: false
          }));
          getCloudRows();
        }} 
      />
      <ProjectSettingModal
        projectInfo={project}
        projectSettingOpen={modalIsOpen.project}
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          project: false
        }))}
      />
      
      {
        selected !== '' &&
        <LinkedCloudDetail
          data={rows.find(val => val.id === Number(selected)) as ProjectCloudType}
          cloudTypeList={cloudTypeList}
          doRefecthCloudList={doRefecthCloudList}
          getOffPage={() => {
            setSelected('');
            setCheckedList([]);
          }} 
          onDelete={() => {
            setCheckedList([selected]);
            setModalIsOpen((prev) => ({
              ...prev,
              delete: true
            }))
          }}
          onEditSuccess={() => {
            setCheckedList([]);
            getCloudRows();
          }}
        />
      }
    </div>
  );
};

export default ProjectOccupied;
