import { gql } from '@apollo/client';

const query = gql`
  mutation deleteMessage($reqDelMessage: ReqDelMessage) {
    deleteMessageByIds(reqData: $reqDelMessage) {
      __typename
      result
      data {
        __typename
      }
    }
  }
`;
export default query;