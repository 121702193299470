import './index.scss';

import { RowType } from '@Types/v2/Table';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import { timeToKr } from 'utils/timeFormatter';

interface IDeleteCategoryModalProps extends IBaseModalProps {
  data: RowType[]
  title: () => JSX.Element;
  onDelete: () => void;
}

const DeleteCategoryModal = ({  
  data,
  title,
  onDelete,
  ...baseModalProps
}:IDeleteCategoryModalProps) => {

  return (
    <BaseModal
      {...baseModalProps}
      title={title}
    >
      <div className="delete-category-modal">
        <div className="content">
          <div className="content-header flex a-center">
            <div>카테고리 이름</div>
            <div>고유 구분자</div>
            <div>문서 제목</div>
            <div>수정 시간</div>
          </div>
          <div className="content-main">
            {data.map(val => (
              <div key={val.id} className="report-row flex a-center">
                <div>{val.name}</div>
                <div>{val.maskCode}</div>
                <div>{val.title}</div>
                <div>{timeToKr(val.modifiedAt)}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="btns flex j-end">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={baseModalProps.onClose}
          >
            취소
          </button>
          <button 
            className="big-main-btn flex j-center a-center" 
            onClick={onDelete}
          >
          삭제
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteCategoryModal;
