import { gql } from '@apollo/client';

const query = gql`
query getTermsPageByParam($reqData: ReqGetTermsPage) {
  getTermsPageByParam(reqData: $reqData) {
    __typename
    result
    number
    size
    first
    last
    numberOfElements
    totalPages
    totalElements
    content {
      ... on Terms {
        termId
        version
        kindCode
        index
        mandatoryYn
        useYn
        title
        text
        createdAt
        createdBy
        createdByName
        createdByUserId
        modifiedAt
        modifiedBy
        modifiedByName
        modifiedByUserId
      }
    }
  }
}
`;

export default query;