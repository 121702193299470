import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { ProjectInfo } from 'graphql/types/ProjectInfo';

export interface IAddProjectVariables {
  reqData: {
    organId: number;
    name: string;
    thirdPartTypeCode: string;
    attrKey1: string;
    attrValue1: string;
  }
}

export interface IAddProjectResponseData {
  addProject: IGqlResponseData<ProjectInfo[]>;
}

/** 프로젝트 추가 */
const addProject = () => useMutation<IAddProjectResponseData, IAddProjectVariables>(query);
export default addProject;