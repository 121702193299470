import React,{ PropsWithChildren } from 'react';
import './index.scss';

interface ILabeledProps {
  title: string;
  className?: string;
  required?: boolean;
  errorMsg?: string;
}

const Labeled = ({
  children,
  title,
  className,
  required,
  errorMsg
}:PropsWithChildren<ILabeledProps>) => {
  return (
    <div className={`label-comp ${className}`}>
      <label {...(required && { className: 'required' })}>{title}</label>
      {children}
      {errorMsg && <div className="error-wrapper"><div className="error-icon" />{errorMsg}</div>}
    </div>
  );
};

export default Labeled;
