import { gql } from '@apollo/client';

const query = gql`
mutation leaveOrgan {
  leaveOrgan {
    result
    data {
      ... on RespCheckIn {
        userToken
        __typename
      }
    }
  }
}
`;
export default query;