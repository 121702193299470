import { useLazyQuery } from '@apollo/client';
import query from './query';
import { HistReportFileInfo } from '../../types/HistReportFileInfo';
import { Paging } from 'graphql/types/Paging';
import { ErrorCode } from '@Types/error';

export interface IGetHistReportFileVariables {
  reqGetReportFileList: {
    reportId: number;
    adminYn: boolean; /* lg운영 권한의 경우  true, 일반사용자의 경우 false */
    pageInfo: Paging;
  };
}

export interface IGetHistReportFileResponseData {
  getHistReportFiles: {
    result: ErrorCode;
    size: number;
    number: number;
    content: HistReportFileInfo[];
  }
}

/** 프로젝트 정보 조회 */
const lazyGetHistReportFile = () => useLazyQuery<IGetHistReportFileResponseData>(query, { fetchPolicy: 'no-cache' });
export default lazyGetHistReportFile;