import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteMemberVariables {
  reqDelMember: { 
    ids: string[];
  }
}

export interface IDeleteMemberResponseData {
  deleteMember: IGqlResponseData<undefined>;
}

const deleteMember = () => useMutation<IDeleteMemberResponseData, IDeleteMemberVariables>(query);
export default deleteMember;