import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IInsertEventSettingVariables {
  reqData: {
    projectId: number;
    eventId: number;
    name: string;
    priorityCode: string;
    messageId: number;
  }
}

export interface IInsertEventSettingResponseData {
  insertEventSetting: IGqlResponseData<undefined>;
}

const insertEventSetting = () => useMutation<IInsertEventSettingResponseData, IInsertEventSettingVariables>(query);
export default insertEventSetting;