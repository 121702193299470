import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { CloudInfo } from 'apis/v2/Manage/schema';

export interface IAddTermsVariables {
  reqData: {
    version: string;
    kindCode: string;
    // index?: number; /* 약관 순서 (default: 1) */
    mandatoryYn: boolean; 
    useYn: boolean;
    title: string;
    text: string;
  }
}

export interface IAddTermsResponseData {
  addTerms: IGqlResponseData<CloudInfo>;
}

const addTerms = () => useMutation<IAddTermsResponseData, IAddTermsVariables>(query);
export default addTerms;