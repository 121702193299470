import { ErrorCode } from "@Types/error";
import { BaseDummyRes } from "..";
import { IAddCategoryReq, IAddCategoryRes, IAddDictionaryReq, IAddDictionaryRes, IAddEventReq, IAddEventRes, IAddMessageReq, IAddMessageRes, IAddRuleCloudReq, IAddRuleCloudRes, IAddSubCategoryReq, IAddSubCategoryRes, IAddTermsReq, IAddTermsRes, IDeleteCategoryReq, IDeleteDictionaryReq, IDeleteEventReq, IDeleteMemberReq, IDeleteMemoReq, IDeleteMessageReq, IDeleteRuleCloudReq, IDeleteSubCategoryReq, IDeleteTermsReq, IGetAllCategoryRes, IGetAuthPageByParamReq, IGetAuthPageByParamRes, IGetCategoryPageByParamReq, IGetCategoryPageByParamRes, IGetDictionaryPageByParamReq, IGetDictionaryPageByParamRes, IGetEventPageByParamReq, IGetEventPageByParamRes, IGetMemberPageByParamReq, IGetMemberPageByParamRes, IGetMemberTermsByMemberIdRes, IGetMemoPageByParamReq, IGetMemoPageByParamRes, IGetMessagePageByParamReq, IGetMessagePageByParamRes, IGetResourcePageByParamReq, IGetResourcePageByParamRes, IGetRuleCloudPageByParamReq, IGetRuleCloudPageByParamRes, IGetSubCategoryPageByParamReq, IGetSubCategoryPageByParamRes, IGetTermsPageByParamReq, IGetTermsPageByParamRes, ISearchItemReq, ISearchItemRes, IUpdateCategoryReq, IUpdateCategoryRes, IUpdateDictionaryReq, IUpdateDictionaryRes, IUpdateEventReq, IUpdateEventRes, IUpdateMemberReq, IUpdateMemberRes, IUpdateMemoReq, IUpdateMemoRes, IUpdateMessageReq, IUpdateMessageRes, IUpdateRuleCloudRes, IUpdateRuleCoudReq, IUpdateSubCategoryReq, IUpdateSubCategoryRes, IUpdateTermsReq, IUpdateTermsRes } from "./schema";

/** 이벤트 규칙 조회 */
const DummyGetEventPageByParam = (data: IGetEventPageByParamReq ): Promise<BaseDummyRes<IGetEventPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetEventPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'event rule',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                eventId: 1,
                name: 'dummy 이벤트 규칙',
                maskCode: '12345678',
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'event rule',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                eventId: 1,
                name: 'dummy 이벤트 규칙',
                maskCode: '12345678',
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              }
            ]
          }
        }
      });
    }
  });

  return promise;
};

/** 이벤트규칙 생성 */
const DummyAddEvent = (data: IAddEventReq ): Promise<BaseDummyRes<IAddEventRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IAddEventRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            eventId: 2,
            name: 'dummy 이벤트 규칙2',
            maskCode: '22345678',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy2',
            createdByName: '더미사원2',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy2',
            modifiedByName: '더미사원2'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            eventId: 2,
            name: 'dummy 이벤트 규칙2',
            maskCode: '22345678',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy2',
            createdByName: '더미사원2',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy2',
            modifiedByName: '더미사원2'
          }
        }
      });
    }
  });

  return promise;
};

/** 이벤트 규칙 삭제  */
const DummyDeleteEvent = (data: IDeleteEventReq ): Promise<BaseDummyRes<{data: undefined}>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<{data: undefined}>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: undefined
        }
      });
    }
  });

  return promise;
};

/** 이벤트규칙 수정 */
const DummyUpdateEvent = (data: IUpdateEventReq ): Promise<BaseDummyRes<IUpdateEventRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IUpdateEventRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            eventId: 1,
            name: '수정된 dummy 이벤트 규칙',
            maskCode: '99999999',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-23T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            eventId: 1,
            name: '수정된 dummy 이벤트 규칙',
            maskCode: '99999999',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-23T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1'
          }
        }
      });
    }
  });

  return promise;
};

const DummyGetRuleCloudsPageByParam = (data: IGetRuleCloudPageByParamReq): Promise<BaseDummyRes<IGetRuleCloudPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetRuleCloudPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'get rule cloud page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                cloudId: 1,
                cloudKind: 'aws00000', /* 클라우드 종류 */
                name: 'AWS', /* 클라우드 이름 */
                maskCode: '12345678',
                contactName: '더미담당자', /* 담당자이름 */
                contactEmail: 'dummy@dummy.com', /* 담당자이메일 */
                contactMobile: '01012345678', /* 담당자연락처 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                createdByUserId: 'dummy123',
                modifiedByUserId:'dummy123'
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'get rule cloud page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                cloudId: 1,
                cloudKind: 'aws00000', /* 클라우드 종류 */
                name: 'AWS', /* 클라우드 이름 */
                maskCode: '12345678',
                contactName: '더미담당자', /* 담당자이름 */
                contactEmail: 'dummy@dummy.com', /* 담당자이메일 */
                contactMobile: '01012345678', /* 담당자연락처 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                createdByUserId: 'dummy123',
                modifiedByUserId:'dummy123'
              }
            ]
          }
        }
      });
    }
  });

  return promise;
};

/** 클라우드 정보 추가 */
const DummyAddRuleCloud = (data: IAddRuleCloudReq ): Promise<BaseDummyRes<IAddRuleCloudRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IAddRuleCloudRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            cloudId: 2,
            cloudKind: 'gcp00000',
            name: 'GCP',
            maskCode: '12345678',
            contactName: '더미담당자2',
            contactEmail: 'dummy2@dummy.com', 
            contactMobile: '01033345678',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId:'dummy123'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            cloudId: 2,
            cloudKind: 'gcp00000',
            name: 'GCP',
            maskCode: '12345678',
            contactName: '더미담당자2',
            contactEmail: 'dummy2@dummy.com', 
            contactMobile: '01033345678',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId:'dummy123'
          }
        }
      });
    }
  });

  return promise;
};

/** 클라우드 정보 삭제 */
const DummyDeleteRuleCloud = (data: IDeleteRuleCloudReq): Promise<BaseDummyRes<{data: undefined}>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<{data: undefined}>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: undefined
        }
      });
    }
  });

  return promise;
};

const DummyUpdateRuleCloud = (data: IUpdateRuleCoudReq ): Promise<BaseDummyRes<IUpdateRuleCloudRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IUpdateRuleCloudRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            cloudId: 1,
            cloudKind: 'gcp00000', /* 클라우드 종류 */
            name: 'GCP', /* 클라우드 이름 */
            maskCode: '98765432',
            contactName: '더미담당자', /* 담당자이름 */
            contactEmail: 'dummy@dummy.com', /* 담당자이메일 */
            contactMobile: '01012345678', /* 담당자연락처 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId:'dummy123'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            cloudId: 1,
            cloudKind: 'gcp00000', /* 클라우드 종류 */
            name: 'GCP', /* 클라우드 이름 */
            maskCode: '98765432',
            contactName: '더미담당자', /* 담당자이름 */
            contactEmail: 'dummy@dummy.com', /* 담당자이메일 */
            contactMobile: '01012345678', /* 담당자연락처 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId:'dummy123'
          }
        }
      });
    }
  });

  return promise;
};


const DummyGetCategoryPageByParam = (data: IGetCategoryPageByParamReq): Promise<BaseDummyRes<IGetCategoryPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetCategoryPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'get category page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                categoryId: 1,
                cloudId: 1,
                name: 'dummy 카테고리 이름', /* 카테고리 이름 */
                maskCode: '33445566',
                description: 'dummy 카테고리 설명',
                title: 'dummy 문서제목', /* 문서제목 */
                template: 'dummy 문서서식', /* 문서서식 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                createdByUserId: 'dummy123',
                modifiedByUserId:'dummy123'
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'get category page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                categoryId: 1,
                cloudId: 1,
                name: 'dummy 카테고리 이름', /* 카테고리 이름 */
                maskCode: '33445566',
                description: 'dummy 카테고리 설명',
                title: 'dummy 문서제목', /* 문서제목 */
                template: 'dummy 문서서식', /* 문서서식 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                createdByUserId: 'dummy123',
                modifiedByUserId:'dummy123'
              }
            ]
          }
        }
      });
    }
  });
  
  return promise;
};

const DummyAddCategory = (data: IAddCategoryReq ): Promise<BaseDummyRes<IAddCategoryRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IAddCategoryRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            categoryId: 2,
            cloudId: 1,
            name: 'dummy 카테고리 이름2', /* 카테고리 이름 */
            maskCode: '33445566',
            description: 'dummy 카테고리 설명2',
            title: 'dummy 문서제목2', /* 문서제목 */
            template: 'dummy 문서서식2', /* 문서서식 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId:'dummy123'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            categoryId: 2,
            cloudId: 1,
            name: 'dummy 카테고리 이름2', /* 카테고리 이름 */
            maskCode: '33445566',
            description: 'dummy 카테고리 설명2',
            title: 'dummy 문서제목2', /* 문서제목 */
            template: 'dummy 문서서식2', /* 문서서식 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId:'dummy123'
          }
        }
      });
    }
  });

  return promise;
};

const DummyDeleteCategory = (data: IDeleteCategoryReq): Promise<BaseDummyRes<{data: undefined}>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<{data: undefined}>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: undefined
        }
      });
    }
  });

  return promise;
};

const DummyUpdateCategory = (data: IUpdateCategoryReq ): Promise<BaseDummyRes<IUpdateCategoryRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IUpdateCategoryRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            categoryId: 1,
            cloudId: 1,
            name: '수정된 dummy 카테고리 이름', /* 카테고리 이름 */
            maskCode: '5555667',
            description: 'dummy 카테고리 설명',
            title: '수정된 dummy 문서제목', /* 문서제목 */
            template: 'dummy 문서서식', /* 문서서식 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId:'dummy123'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            categoryId: 1,
            cloudId: 1,
            name: '수정된 dummy 카테고리 이름', /* 카테고리 이름 */
            maskCode: '5555667',
            description: 'dummy 카테고리 설명',
            title: '수정된 dummy 문서제목', /* 문서제목 */
            template: 'dummy 문서서식', /* 문서서식 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId:'dummy123'
          }
        }
      });
    }
  });

  return promise;
};

const DummyGetAllCategory = (data: undefined):Promise<BaseDummyRes<IGetAllCategoryRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetAllCategoryRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [
            {
              categoryId: 1,
              cloudId: 1,
              name: 'dummy 카테고리 이름', /* 카테고리 이름 */
              maskCode: '33445566',
              description: 'dummy 카테고리 설명',
              title: 'dummy 문서제목', /* 문서제목 */
              template: 'dummy 문서서식', /* 문서서식 */
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1',
              createdByName: '더미사원1',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1',
              modifiedByName: '더미사원1',
              createdByUserId: 'dummy123',
              modifiedByUserId:'dummy123'
            },
            {
              categoryId: 2,
              cloudId: 1,
              name: 'dummy 카테고리 이름2', /* 카테고리 이름 */
              maskCode: '33445566',
              description: 'dummy 카테고리 설명2',
              title: 'dummy 문서제목2', /* 문서제목 */
              template: 'dummy 문서서식2', /* 문서서식 */
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1',
              createdByName: '더미사원1',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1',
              modifiedByName: '더미사원1',
              createdByUserId: 'dummy123',
              modifiedByUserId:'dummy123'
            }
          ]
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: [
            {
              categoryId: 1,
              cloudId: 1,
              name: 'dummy 카테고리 이름', /* 카테고리 이름 */
              maskCode: '33445566',
              description: 'dummy 카테고리 설명',
              title: 'dummy 문서제목', /* 문서제목 */
              template: 'dummy 문서서식', /* 문서서식 */
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1',
              createdByName: '더미사원1',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1',
              modifiedByName: '더미사원1',
              createdByUserId: 'dummy123',
              modifiedByUserId:'dummy123'
            },
            {
              categoryId: 2,
              cloudId: 1,
              name: 'dummy 카테고리 이름2', /* 카테고리 이름 */
              maskCode: '33445566',
              description: 'dummy 카테고리 설명2',
              title: 'dummy 문서제목2', /* 문서제목 */
              template: 'dummy 문서서식2', /* 문서서식 */
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1',
              createdByName: '더미사원1',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1',
              modifiedByName: '더미사원1',
              createdByUserId: 'dummy123',
              modifiedByUserId:'dummy123'
            }
          ]
        }
      });
    }
  });

  return promise;
};

const DuumyGetSubCategoryPageByParam = (data: IGetSubCategoryPageByParamReq): Promise<BaseDummyRes<IGetSubCategoryPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetSubCategoryPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'get sub category page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 1,
                categoryId: 1,
                name: 'dummy 서브카테고리 이름',
                maskCode: '55667788',
                description: 'dummy 서브카테고리 설명',
                title: 'dummy 문서제목',
                template: 'dummy 문서서식',
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                createdByUserId: 'dummy123',
                modifiedByUserId: 'dummy123'
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'get sub category page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 1,
                categoryId: 1,
                name: 'dummy 서브카테고리 이름',
                maskCode: '55667788',
                description: 'dummy 서브카테고리 설명',
                title: 'dummy 문서제목',
                template: 'dummy 문서서식',
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                createdByUserId: 'dummy123',
                modifiedByUserId: 'dummy123'
              }
            ]
          }
        }
      });
    }
  });
  
  return promise;
};

const DummyAddSubCategory = (data: IAddSubCategoryReq ): Promise<BaseDummyRes<IAddSubCategoryRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IAddSubCategoryRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            id: 2,
            categoryId: 2,
            name: 'dummy 서브카테고리 이름2',
            maskCode: '55667788',
            description: 'dummy 서브카테고리 설명2',
            title: 'dummy 문서제목2',
            template: 'dummy 문서서식2',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId: 'dummy123'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            id: 2,
            categoryId: 2,
            name: 'dummy 서브카테고리 이름2',
            maskCode: '55667788',
            description: 'dummy 서브카테고리 설명2',
            title: 'dummy 문서제목2',
            template: 'dummy 문서서식2',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId: 'dummy123'
          }
        }
      });
    }
  });

  return promise;
}; 

const DummyUpdateSubCategory = (data: IUpdateSubCategoryReq ): Promise<BaseDummyRes<IUpdateSubCategoryRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IUpdateSubCategoryRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            id: 1,
            categoryId: 1,
            name: '수정된 dummy 서브카테고리 이름',
            maskCode: '11112222',
            description: '수정된 dummy 서브카테고리 설명',
            title: '수정된 dummy 문서제목',
            template: '수정된 dummy 문서서식',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId: 'dummy123'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            id: 1,
            categoryId: 1,
            name: '수정된 dummy 서브카테고리 이름',
            maskCode: '11112222',
            description: '수정된 dummy 서브카테고리 설명',
            title: '수정된 dummy 문서제목',
            template: '수정된 dummy 문서서식',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedByUserId: 'dummy123'
          }
        }
      });
    }
  });

  return promise;
};

const DummyDeleteSubCategory = (data: IDeleteSubCategoryReq): Promise<BaseDummyRes<{data: undefined}>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<{data: undefined}>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: undefined
        }
      });
    }
  });

  return promise;
};

const DummyGetMemberPageByParam = (data: IGetMemberPageByParamReq):Promise<BaseDummyRes<IGetMemberPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetMemberPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'get member page by param',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 'dummy-member-1', /* 멤버 id */
                fullName: '더미 유저이름',
                userId: 'dummy-user-id',
                email: 'dummy@dummy.com',
                organId: 23,
                mobileNumber: '01012345678',
                ranks: '사원',
                retryCount: 3,
                loginTypeCode: 'email000', /* 로그인 유형 */
                systemAuthCode: 'normal00',
                pictureResourceId: 12341234, /* 프로필 사진 */
                alertYn: false,
                description: 'dummy 사용자 정보 소개',
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                organName: '니모의 꿈 공장'
              }
            ]
          }        
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'get member page by param',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 'dummy-member-1', /* 멤버 id */
                fullName: '더미 유저이름',
                userId: 'dummy-user-id',
                email: 'dummy@dummy.com',
                organId: 23,
                mobileNumber: '01012345678',
                ranks: '사원',
                retryCount: 3,
                loginTypeCode: 'email000', /* 로그인 유형 */
                systemAuthCode: 'normal00',
                pictureResourceId: 12341234, /* 프로필 사진 */
                alertYn: false,
                description: 'dummy 사용자 정보 소개',
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                organName: '니모의 꿈 공장'
              }
            ]
          }    
        }
      });
    }
  });

  return promise;
};

const DummyUpdateMember = (data: IUpdateMemberReq ): Promise<BaseDummyRes<IUpdateMemberRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IUpdateMemberRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            id: 'dummy-member-1', /* 멤버 id */
            fullName: '더미 유저이름',
            userId: 'dummy-user-id',
            email: 'dummy@dummy.com',
            organId: 23,
            mobileNumber: '01012345678',
            ranks: '대리',
            retryCount: 3,
            loginTypeCode: 'email000', /* 로그인 유형 */
            systemAuthCode: 'normal00',
            pictureResourceId: 12341234, /* 프로필 사진 */
            alertYn: false,
            description: 'dummy 사용자 정보 소개 수정',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            organName: '니모의 꿈 공장'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            id: 'dummy-member-1', /* 멤버 id */
            fullName: '더미 유저이름',
            userId: 'dummy-user-id',
            email: 'dummy@dummy.com',
            organId: 23,
            mobileNumber: '01012345678',
            ranks: '대리',
            retryCount: 3,
            loginTypeCode: 'email000', /* 로그인 유형 */
            systemAuthCode: 'normal00',
            pictureResourceId: 12341234, /* 프로필 사진 */
            alertYn: false,
            description: 'dummy 사용자 정보 소개 수정',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            organName: '니모의 꿈 공장'
          }
        }
      });
    }
  });

  return promise;
};

const DummyDeleteMember = (data: IDeleteMemberReq): Promise<BaseDummyRes<{data: undefined}>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<{data: undefined}>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: undefined
        }
      });
    }
  });

  return promise;
};

/** 멤버id로 사용자-약관 관계 테이블 조회 */
const DummyGetMemberTermsByMemberId = (data: { memberId: string }):Promise<BaseDummyRes<IGetMemberTermsByMemberIdRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetMemberTermsByMemberIdRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [
            {
              memberId: '1234',
              termsId: 1, /* 약관 id */
              acceptYn: true, /* 사용자 동의 여부 */
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1',
              createdByName: '더미사원1',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1',
              modifiedByName: '더미사원1',
              version: 'v0.1.1',
              kindCode: 'dummy 약관종류1', /* 약관 종류 */
              title: 'dummy 약관 이름', /* 약관 이름 */
              mandatoryYn: true /* 동의 필수 여부 */
            },
            {
              memberId: '1234',
              termsId: 2, /* 약관 id */
              acceptYn: true, /* 사용자 동의 여부 */
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1',
              createdByName: '더미사원1',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1',
              modifiedByName: '더미사원1',
              version: 'v0.1.1',
              kindCode: 'dummy 약관종류2', /* 약관 종류 */
              title: 'dummy 약관 이름2', /* 약관 이름 */
              mandatoryYn: true /* 동의 필수 여부 */
            }
          ]
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: [
            {
              memberId: '1234',
              termsId: 1, /* 약관 id */
              acceptYn: true, /* 사용자 동의 여부 */
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1',
              createdByName: '더미사원1',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1',
              modifiedByName: '더미사원1',
              version: 'v0.1.1',
              kindCode: 'dummy 약관종류1', /* 약관 종류 */
              title: 'dummy 약관 이름', /* 약관 이름 */
              mandatoryYn: true /* 동의 필수 여부 */
            },
            {
              memberId: '1234',
              termsId: 2, /* 약관 id */
              acceptYn: true, /* 사용자 동의 여부 */
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy1',
              createdByName: '더미사원1',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy1',
              modifiedByName: '더미사원1',
              version: 'v0.1.1',
              kindCode: 'dummy 약관종류2', /* 약관 종류 */
              title: 'dummy 약관 이름2', /* 약관 이름 */
              mandatoryYn: true /* 동의 필수 여부 */
            }
          ]
        }
      });
    }
  });

  return promise;
};

const DummyGetTermsPageByParam = (data: IGetTermsPageByParamReq ): Promise<BaseDummyRes<IGetTermsPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetTermsPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'terms',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                termId: 1,
                version: 'v1.0.0',
                kindCode: 'dummy 약관 종류1',
                index: 1, /* 약관 순서 */
                mandatoryYn: true, /* 필수 여부 */
                useYn: false, /* 사용여부 */
                title: 'dummy 약관 이름', /* 약관 이름 */
                text: 'dummy 약관 본문', /* 약관 본문 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                createdByUserId: 'dummy123',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                modifiedByUserId: 'dummy123'
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'terms',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                termId: 1,
                version: 'v1.0.0',
                kindCode: 'dummy 약관 종류1',
                index: 1, /* 약관 순서 */
                mandatoryYn: true, /* 필수 여부 */
                useYn: false, /* 사용여부 */
                title: 'dummy 약관 이름', /* 약관 이름 */
                text: 'dummy 약관 본문', /* 약관 본문 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                createdByUserId: 'dummy123',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                modifiedByUserId: 'dummy123'
              }
            ]
          }
        }
      });
    }
  });

  return promise;
};

const DummyGetAddTerms = (data: IAddTermsReq ): Promise<BaseDummyRes<IAddTermsRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IAddTermsRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            termId: 2,
            version: 'v1.0.0',
            kindCode: 'dummy 약관 종류2',
            index: 2, /* 약관 순서 */
            mandatoryYn: false, /* 필수 여부 */
            useYn: false, /* 사용여부 */
            title: 'dummy 약관 이름2', /* 약관 이름 */
            text: 'dummy 약관 본문2', /* 약관 본문 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            modifiedByUserId: 'dummy123'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            termId: 2,
            version: 'v1.0.0',
            kindCode: 'dummy 약관 종류2',
            index: 2, /* 약관 순서 */
            mandatoryYn: false, /* 필수 여부 */
            useYn: false, /* 사용여부 */
            title: 'dummy 약관 이름2', /* 약관 이름 */
            text: 'dummy 약관 본문2', /* 약관 본문 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            modifiedByUserId: 'dummy123'
          }
        }
      });
    }
  });

  return promise;
};

const DummyUpdateTerms = (data: IUpdateTermsReq ): Promise<BaseDummyRes<IUpdateTermsRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IUpdateTermsRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            termId: 1,
            version: 'v1.0.0',
            kindCode: 'dummy 약관 종류1',
            index: 1, /* 약관 순서 */
            mandatoryYn: false, /* 필수 여부 */
            useYn: false, /* 사용여부 */
            title: '수정된 dummy 약관 이름', /* 약관 이름 */
            text: '수정된 dummy 약관 본문', /* 약관 본문 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            modifiedByUserId: 'dummy123'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            termId: 1,
            version: 'v1.0.0',
            kindCode: 'dummy 약관 종류1',
            index: 1, /* 약관 순서 */
            mandatoryYn: false, /* 필수 여부 */
            useYn: false, /* 사용여부 */
            title: '수정된 dummy 약관 이름', /* 약관 이름 */
            text: '수정된 dummy 약관 본문', /* 약관 본문 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            createdByUserId: 'dummy123',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            modifiedByUserId: 'dummy123'
          }
        }
      });
    }
  });

  return promise;
};

const DummyDeleteTerms = (data: IDeleteTermsReq ): Promise<BaseDummyRes<{data: undefined}>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<{data: undefined}>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: undefined
        }
      });
    }
  });

  return promise;
};

const DummyGetAuthPageByParam = (data: IGetAuthPageByParamReq ): Promise<BaseDummyRes<IGetAuthPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetAuthPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'get auth page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                authId: 1, /* 인증id */
                memberId: 'dummy-member-id',
                authTypeCode: 'password', /* 인증 방법 */
                authToken: '123412341234',
                createdAt: '2024-02-14T05:28:14.404Z',
                // createdBy: 'dummy1',
                // createdByName: '더미사원1',
                // modifiedAt: '2024-02-14T05:28:14.404Z',
                // modifiedBy: 'dummy1',
                // modifiedByName: '더미사원1',
                userId: 'dummy-user-id', /* 사용자 id */
                fullName: '김더미',
                email: 'dummy@dummy.com',
                organId: 23,
                organName: '니모의 꿈 공장'
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'get auth page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                authId: 1, /* 인증id */
                memberId: 'dummy-member-id',
                authTypeCode: 'password', /* 인증 방법 */
                authToken: '123412341234',
                createdAt: '2024-02-14T05:28:14.404Z',
                // createdBy: 'dummy1',
                // createdByName: '더미사원1',
                // modifiedAt: '2024-02-14T05:28:14.404Z',
                // modifiedBy: 'dummy1',
                // modifiedByName: '더미사원1',
                userId: 'dummyUserId', /* 사용자 id */
                fullName: '김더미',
                email: 'dummy@dummy.com',
                organId: 23,
                organName: '니모의 꿈 공장'
              }
            ]
          }
        }
      });
    }
  });

  return promise;
};

const DummySearchItem = (data: ISearchItemReq ): Promise<BaseDummyRes<ISearchItemRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<ISearchItemRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [
            {
              id: 1,
              name: 'dummy 아이템 이름1',
            },
            {
              id: 2,
              name: 'dummy 아이템 이름2',
            },
            {
              id: 3,
              name: 'dummy 아이템 이름3',
            },
          ]
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: [
            {
              id: 1,
              name: 'dummy 아이템 이름1',
            },
            {
              id: 2,
              name: 'dummy 아이템 이름2',
            },
            {
              id: 3,
              name: 'dummy 아이템 이름3',
            },
          ]
        }
      });
    }
  });

  return promise;
};

const DummyGetDictionaryPageByParam = (data: IGetDictionaryPageByParamReq): Promise<BaseDummyRes<IGetDictionaryPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetDictionaryPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'dictionary page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 1, /* id */
                relObjectId: 1, /* 아이템 id */
                relObjectType: 'category', /* 사용 종류 */
                defaultName: 'dummy 기준값', /* 기준 값 */
                domainName: 'dummy 설정값', /* 설정 값 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                relObjectName: 'dummy 아이템이름' /* 아이템 이름 */
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'dictionary page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 1, /* id */
                relObjectId: 1, /* 아이템 id */
                relObjectType: 'category', /* 사용 종류 */
                defaultName: 'dummy 기준값', /* 기준 값 */
                domainName: 'dummy 설정값', /* 설정 값 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                relObjectName: 'dummy 아이템이름' /* 아이템 이름 */
              }
            ]
          }
        }
      });
    }
  });

  return promise;
};

const DummyAddDictionary = (data: IAddDictionaryReq ): Promise<BaseDummyRes<IAddDictionaryRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IAddDictionaryRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            id: 2, /* id */
            relObjectId: 2, /* 아이템 id */
            relObjectType: 'category', /* 사용 종류 */
            defaultName: 'dummy 기준값2', /* 기준 값 */
            domainName: 'dummy 설정값2', /* 설정 값 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            relObjectName: 'dummy 아이템이름2' /* 아이템 이름 */
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            id: 2, /* id */
            relObjectId: 2, /* 아이템 id */
            relObjectType: 'category', /* 사용 종류 */
            defaultName: 'dummy 기준값2', /* 기준 값 */
            domainName: 'dummy 설정값2', /* 설정 값 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            relObjectName: 'dummy 아이템이름2' /* 아이템 이름 */
          }
        }
      });
    }
  });

  return promise;
};

const DummyUpdateDictionary = (data: IUpdateDictionaryReq ): Promise<BaseDummyRes<IUpdateDictionaryRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IUpdateDictionaryRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            id: 1, /* id */
            relObjectId: 2, /* 아이템 id */
            relObjectType: 'cloud000', /* 사용 종류 */
            defaultName: 'dummy 기준값', /* 기준 값 */
            domainName: 'dummy 설정값', /* 설정 값 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            relObjectName: 'dummy 아이템이름' /* 아이템 이름 */
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            id: 1, /* id */
            relObjectId: 2, /* 아이템 id */
            relObjectType: 'cloud000', /* 사용 종류 */
            defaultName: 'dummy 기준값', /* 기준 값 */
            domainName: 'dummy 설정값', /* 설정 값 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            relObjectName: 'dummy 아이템이름' /* 아이템 이름 */
          }
        }
      });
    }
  });

  return promise;
};

const DummyDeleteDictionary = (data: IDeleteDictionaryReq): Promise<BaseDummyRes<{data: undefined}>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<{data: undefined}>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: undefined
        }
      });
    }
  });

  return promise;
};

const DummyGetResourcePageByParam = (data: IGetResourcePageByParamReq): Promise<BaseDummyRes<IGetResourcePageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetResourcePageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'dictionary page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 1, /* 리소스 id */
                relObjectId: 1, /* 관계 객체 id */
                relObjectType: 'profile0', /* 리소스 종류 */
                resFormatCode: 'excel000', /* 파일 형식 */
                resPath: '리소스경로', /* 리소스 경로 */
                useYn: true,
                name: '아이템 이름', /* 아이템 이름 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              },
              {
                id: 2, /* 리소스 id */
                relObjectId: 2, /* 관계 객체 id */
                relObjectType: 'report00', /* 리소스 종류 */
                resFormatCode: 'picture0', /* 파일 형식 */
                resPath: '리소스경로', /* 리소스 경로 */
                useYn: true,
                name: '아이템 이름', /* 아이템 이름 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              },
              {
                id: 3, /* 리소스 id */
                relObjectId: 3, /* 관계 객체 id */
                relObjectType: "memo_rep", /* 리소스 종류 */
                resFormatCode: "memo0000", /* 파일 형식 */
                resPath: '리소스경로', /* 리소스 경로 */
                useYn: true,
                name: '아이템 이름', /* 아이템 이름 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              },
              {
                id: 4, /* 리소스 id */
                relObjectId: 4, /* 관계 객체 id */
                relObjectType: "memo_evn", /* 리소스 종류 */
                resFormatCode: "memo0000", /* 파일 형식 */
                resPath: '리소스경로', /* 리소스 경로 */
                useYn: true,
                name: '아이템 이름', /* 아이템 이름 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'dictionary page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 1, /* 리소스 id */
                relObjectId: 1, /* 관계 객체 id */
                relObjectType: 'profile0', /* 리소스 종류 */
                resFormatCode: 'excel000', /* 파일 형식 */
                resPath: '리소스경로', /* 리소스 경로 */
                useYn: true,
                name: '아이템 이름', /* 아이템 이름 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              },
              {
                id: 2, /* 리소스 id */
                relObjectId: 2, /* 관계 객체 id */
                relObjectType: 'report00', /* 리소스 종류 */
                resFormatCode: 'picture0', /* 파일 형식 */
                resPath: '리소스경로', /* 리소스 경로 */
                useYn: true,
                name: '아이템 이름', /* 아이템 이름 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              },
              {
                id: 3, /* 리소스 id */
                relObjectId: 3, /* 관계 객체 id */
                relObjectType: "memo_rep", /* 리소스 종류 */
                resFormatCode: "memo0000", /* 파일 형식 */
                resPath: '리소스경로', /* 리소스 경로 */
                useYn: true,
                name: '아이템 이름', /* 아이템 이름 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              },
              {
                id: 4, /* 리소스 id */
                relObjectId: 4, /* 관계 객체 id */
                relObjectType: "memo_evn", /* 리소스 종류 */
                resFormatCode: "memo0000", /* 파일 형식 */
                resPath: '리소스경로', /* 리소스 경로 */
                useYn: true,
                name: '아이템 이름', /* 아이템 이름 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1'
              }
            ]
          }
        }
      });
    }
  });

  return promise;
};

const DummyGetMemoPageByParam =  (data: IGetMemoPageByParamReq): Promise<BaseDummyRes<IGetMemoPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetMemoPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'get memo page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 1,
                relObjectId: 1, /* 관계 객체 id */
                relObjectType: 'report_w', /* 메모 종류 */
                text: '이곳에 내용이 표시됩니다.', /* 본문 */
                checkYn: true, /* 사용여부 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',
                name: 'dummy 아이템이름 보안경고',
                projectId: 5,
                projectName: '아연테스트프로젝트' 
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'get memo page',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 1,
            totalElements: 1,
            numberOfElements: 1, 
            content: [
              {
                id: 1,
                relObjectId: 1, /* 관계 객체 id */
                relObjectType: 'report_w', /* 메모 종류 */
                text: '이곳에 내용이 표시됩니다.', /* 본문 */
                checkYn: true, /* 사용여부 */
                createdAt: '2024-02-14T05:28:14.404Z',
                createdBy: 'dummy1',
                createdByName: '더미사원1',
                modifiedAt: '2024-02-14T05:28:14.404Z',
                modifiedBy: 'dummy1',
                modifiedByName: '더미사원1',  
                name: 'dummy 아이템이름 보안경고',
                projectId: 5,
                projectName: '아연테스트프로젝트' 
              }
            ]
          }
        }
      });
    }
  });

  return promise;
};

const DummyUpdateMemo = (data:IUpdateMemoReq): Promise<BaseDummyRes<IUpdateMemoRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IUpdateMemoRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            id: 1,
            relObjectId: 1, /* 관계 객체 id */
            relObjectType: '"event_ri"', /* 메모 종류 */
            text: '이곳에 내용이 표시됩니다.', /* 본문 */
            checkYn: true, /* 사용여부 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            name: 'dummy 아이템이름 보안경고',
            projectId: 6,
            projectName: '다각형프로젝트' 
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            id: 1,
            relObjectId: 1, /* 관계 객체 id */
            relObjectType: '"event_ri"', /* 메모 종류 */
            text: '이곳에 내용이 표시됩니다.', /* 본문 */
            checkYn: true, /* 사용여부 */
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy1',
            createdByName: '더미사원1',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy1',
            modifiedByName: '더미사원1',
            name: 'dummy 아이템이름 보안경고',
            projectId: 6,
            projectName: '다각형프로젝트' 
          }
        }
      });
    }
  });

  return promise;
};

const DummyDeleteMemo = (data: IDeleteMemoReq ): Promise<BaseDummyRes<{data: undefined}>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<{data: undefined}>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: undefined
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: undefined
        }
      });
    }
  });

  return promise;
};

export const Manage = {
  getEventPageByParam: DummyGetEventPageByParam,
  addEvent: DummyAddEvent,
  deleteEvent: DummyDeleteEvent,
  updateEvent: DummyUpdateEvent,

  getRuleCloudsPageByParam:DummyGetRuleCloudsPageByParam,
  addRuleCloud: DummyAddRuleCloud,
  deleteRuleCloud: DummyDeleteRuleCloud,
  updateRuleCloud: DummyUpdateRuleCloud,

  getCategoryPageByParam: DummyGetCategoryPageByParam, /* 카테고리 rows 조회 */
  addCategory:DummyAddCategory,
  deleteCategory: DummyDeleteCategory,
  updateCategory: DummyUpdateCategory,

  getAllCategory: DummyGetAllCategory, /* 주의 ) 카테고리 rows조회아님. 모든 카테고리 조회임. 서브카테고리 탭에서 필요함 */
  getSubCategoryPageByParam: DuumyGetSubCategoryPageByParam,
  addSubCategory: DummyAddSubCategory,
  updateSubCategory: DummyUpdateSubCategory,
  deleteSubCategory: DummyDeleteSubCategory,

  getMemberPageByParam: DummyGetMemberPageByParam,
  getMemberTermsByMemberId: DummyGetMemberTermsByMemberId,
  updateMember: DummyUpdateMember,
  deleteMember: DummyDeleteMember,

  getTermsPageByParam: DummyGetTermsPageByParam,
  addTerms: DummyGetAddTerms,
  updateTerms: DummyUpdateTerms,
  deleteTerms: DummyDeleteTerms,

  getAuthPageByParam: DummyGetAuthPageByParam,

  searchItem: DummySearchItem,
  getDictionaryPageByParam:DummyGetDictionaryPageByParam,
  addDictionary: DummyAddDictionary,
  updateDictionary: DummyUpdateDictionary,
  deleteDictionary: DummyDeleteDictionary,

  getResourcePageByParam: DummyGetResourcePageByParam,

  getMemoPageByParam: DummyGetMemoPageByParam,
  updateMemo: DummyUpdateMemo,
  deleteMemo: DummyDeleteMemo
};
export default Manage;