import { gql } from '@apollo/client';

const query = gql`
  mutation addMyReport($report: ReqAddReport) {
    addWeeklyReport(report: $report) {
      result
      data {
        ...on RespReportDetail {
          __typename
          reportId
          name
          projectId
          reportType
          startTime
          endTime
          memoId
        }
      }
    }
  }
`;

export default query;