import { gql } from '@apollo/client';

const query = gql`
query getProjectMemberPage($reqGetProjectMemberPage: ReqGetProjectMemberPage) {
  getProjectMemberPage(reqGetProjectMemberPage: $reqGetProjectMemberPage) {
    __typename
    result
    numberOfElements
    totalPages
    totalElements
    content {
      __typename
      ... on RespProjectMemberInfo {
        id
        userId
        fullName
        email
        organId
        organName
        mobileNumber
        ranks
        retryCount
        loginTypeCode
        statusCode
        pictureResourceId
        projectId
        roleCode
        alertYn
        createdAt
        modifiedAt
        smsAlertYn
        emailAlertYn
        ownerYn
      }
    }
  }
}
`;
export default query;