import { useMemo, useState } from 'react';
import ManageHeader from 'components/v2/ManageHeader';
import ManageTab from 'components/v2/ManageTab';
import EventSetting from './EventSetting';
import EventRules from './EventRules';

const MENU = [
  {
    label: '이벤트 설정',
    value: 'EVENT_SETTING'
  },
  {
    label: '이벤트 규칙',
    value: 'EVENT_RULES'
  }
]

/**
 * <>lg 운영자만 접근 가능한 이벤트 관리 페이지</>
 */
const Event = () => {
  const [tab, setTab] = useState('EVENT_SETTING');

  const renderContents = useMemo(() => {
    switch (tab) {
      case 'EVENT_SETTING':
        return <EventSetting />;
      case 'EVENT_RULES':
        return <EventRules />;
      default:
        return <EventSetting />;
      }
  }, [tab])

  return (
    <div id="manage-event">
      <ManageHeader 
        title='이벤트 관리'
      />
      <ManageTab
        selected={tab}
        menu={MENU}
        convertTab={(value) => setTab(value)}
      />
      {renderContents}
    </div>
  );
}

export default Event;