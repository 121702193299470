import { ErrorCode } from "@Types/error";
import { BaseDummyRes } from "..";
import { IGetReportPageByParamReq, IGetReportPageByParamRes, IUpdateHistReportFileReq, IupdateHistReportFileRes } from "./schema";

const DummyGetWeeklyReportPageByParam = (data: IGetReportPageByParamReq): Promise<BaseDummyRes<IGetReportPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetReportPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'weeklyReport',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 3,
            totalElements: 28,
            numberOfElements: 28, 
            content: [
              {
                reportId: 1,
                projectId: 23,
                name: 'dummy weekly report name',
                reportType: 'weekly00',
                startTime: '2023-04-17',
                endTime: '2023-04-24',
                createdAt: '2024-01-25T01:57:28.037Z',
                createdBy: 'dummy-user',
                createdByName: 'dummy 사원',
                modifiedAt: '2024-01-25T01:57:28.037Z',
                modifiedBy: 'dummy-user',
                modifiedByName: 'dummy 사원',
                projectName: '더미 프로젝트',
                currentVersion: 'v0.01',
                currentStatus: 'initial0',
                statusCode: 'initial0',
                createdByUserId:'dummy123',
                modifiedByUserId: 'dummy123'
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'weeklyReport',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 3,
            totalElements: 28,
            numberOfElements: 28, 
            content: [
              {
                reportId: 1,
                projectId: 23,
                name: 'dummy weekly report name',
                reportType: 'weekly00',
                startTime: '2023-04-17',
                endTime: '2023-04-24',
                createdAt: '2024-01-25T01:57:28.037Z',
                createdBy: 'dummy-user',
                createdByName: 'dummy 사원',
                modifiedAt: '2024-01-25T01:57:28.037Z',
                modifiedBy: 'dummy-user',
                modifiedByName: 'dummy 사원',
                projectName: '더미 프로젝트',
                currentVersion: 'v0.01',
                currentStatus: 'initial0',
                statusCode: 'initial0',
                createdByUserId:'dummy123',
                modifiedByUserId: 'dummy123'
              }
            ]
          }
        }
      });
    }
  });

  return promise;
};

const DummyGetMonthlyReportPageByParam = (data: IGetReportPageByParamReq): Promise<BaseDummyRes<IGetReportPageByParamRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetReportPageByParamRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            __typename: 'monthlyReport',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 3,
            totalElements: 28,
            numberOfElements: 28, 
            content: [
              {
                reportId: 2,
                projectId: 23,
                name: 'dummy monthly report name',
                reportType: 'monthly0',
                startTime: '2023-04-17',
                endTime: '2023-05-16',
                createdAt: '2024-01-25T01:57:28.037Z',
                createdBy: 'dummy-user',
                createdByName: 'dummy 사원',
                modifiedAt: '2024-01-25T01:57:28.037Z',
                modifiedBy: 'dummy-user',
                modifiedByName: 'dummy 사원',
                projectName: '더미 프로젝트',
                currentVersion: 'v0.01',
                currentStatus: 'initial0',
                statusCode: 'initial0',
                createdByUserId:'dummy123',
                modifiedByUserId: 'dummy123'
              }
            ]
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            __typename: 'weeklyReport',
            first: false,
            last: false,
            number: 0,
            size: 10,
            totalPages: 3,
            totalElements: 28,
            numberOfElements: 28, 
            content: [
              {
                reportId: 2,
                projectId: 23,
                name: 'dummy monthly report name',
                reportType: 'monthly0',
                startTime: '2023-04-17',
                endTime: '2023-05-16',
                createdAt: '2024-01-25T01:57:28.037Z',
                createdBy: 'dummy-user',
                createdByName: 'dummy 사원',
                modifiedAt: '2024-01-25T01:57:28.037Z',
                modifiedBy: 'dummy-user',
                modifiedByName: 'dummy 사원',
                projectName: '더미 프로젝트',
                currentVersion: 'v0.01',
                currentStatus: 'initial0',
                statusCode: 'initial0',
                createdByUserId:'dummy123',
                modifiedByUserId: 'dummy123'
              }
            ]
          }
        }
      });
    }
  });

  return promise;
};

const DummyUpdateHistReportFile = (data: IUpdateHistReportFileReq): Promise<BaseDummyRes<IupdateHistReportFileRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IupdateHistReportFileRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            id: 1, /* 리포트 파일 id */
            reportId: 33, /* 리포트 id */
            statusCode: 'publish0',
            comment: 'dummy comment',
            fileName: 'dummy 주간 리포트 test',
            resId: 1, /* 리소스 id */
            version: 'v0.01',
            createdAt: '2024-01-25T01:57:28.037Z',
            createdBy: 'dummy-1234',
            createdByName: 'dummy 사원',
            modifiedAt: '2024-01-25T01:57:28.037Z',
            modifiedBy: 'dummy-1234',
            modifiedByName: 'dummy 사원'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            id: 1, /* 리포트 파일 id */
            reportId: 33, /* 리포트 id */
            statusCode: 'publish0',
            comment: 'dummy comment',
            fileName: 'dummy 주간 리포트 test',
            resId: 1, /* 리소스 id */
            version: 'v0.01',
            createdAt: '2024-01-25T01:57:28.037Z',
            createdBy: 'dummy-1234',
            createdByName: 'dummy 사원',
            modifiedAt: '2024-01-25T01:57:28.037Z',
            modifiedBy: 'dummy-1234',
            modifiedByName: 'dummy 사원'
          }
        }
      });
    }
  });

  return promise;
};

export const Report = {
  getWeeklyReportPageByParam: DummyGetWeeklyReportPageByParam,
  getMonthlyReportPageByParam: DummyGetMonthlyReportPageByParam,
  updateHistReportFile: DummyUpdateHistReportFile
};
export default Report;