import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { ReportDetail } from '../../types/ReportDetail';

export interface IAddWeeklyReportVariables {
  report: {
    name: string;
    projectId: number;
    startTime: string;
    endTime: string;
  }
}

export interface IAddWeeklyReportResponseData {
  addWeeklyReport: IGqlResponseData<ReportDetail>;
}

/** 주간 리포트 생성 */
const addWeeklyReport = () => useMutation<IAddWeeklyReportResponseData, IAddWeeklyReportVariables>(query);
export default addWeeklyReport;