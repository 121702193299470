import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import countryCodes from 'country-codes-list';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import Icon from 'components/v2/atoms/Icon';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import ChangeEmailModal from '../../ChangeEmailModal';
import EmailSendedModal from '../../EmailSendModal';
import ToggleSwitchAtom from 'components/v2/atoms/ToggleSwitchAtom';
import ResetPwModal from '../../ResetPwModal';
import LabelTextarea from 'components/v2/LabelTextarea';
import MailTitle from 'assets/svgs/v2/ico_mail_title.svg';
import CheckTitle from 'assets/svgs/v2/ico_check_title.svg';
import RedCirclePw from 'assets/svgs/v2/ico_redcircle_pw.svg';
import { useAuth } from 'contexts/AuthProvider';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { koEnNumSpRegex, numberRegex } from 'utils/Regex';
import { checkEmail, checkUserName } from 'utils/v2/ValidCheck';
import updateMember from 'graphql/mutations/updateMember';
import { IUploadProfileImageReq } from 'apis/v2/File/schema';
import updatePassword from 'graphql/mutations/updatePassword';
import { Mb } from 'utils/v2/DummyData/File';
import { ProfileType } from 'components/v2/Header';
import { UpdateMemberType } from 'graphql/types/UpdateMemberType';

interface IProfileProps {
  organSettingOpen: boolean;
  profileInfo: ProfileType,
  onSuccessUpdateProfileInfo: (data:ProfileType) => void;
  userThumbnail: string;
  onSuccessUploadUserThumbnail: () => void;
}

const Profile = ({
  organSettingOpen,
  profileInfo,
  onSuccessUpdateProfileInfo,
  userThumbnail,
  onSuccessUploadUserThumbnail
}:IProfileProps) => {

  const { token, userInfo } = useAuth();

  const [profile, setProfile] = useState<ProfileType>({
    pictureResourceId: -1,
    id: '',
    fullName: '',
    userId: '',
    email: '',
    organName: '',
    ranks: '',
    description: '',
    countryCode: '',
    mobileNumber: '',
    alertYn: false,
    statusCode: ''
  });
  const [modalIsOpen, setModalIsOpen] = useState({
    email: false,
    emailSended: false,
    pw: false
  });
  
  /* 사용자썸네일 업로드 */
  const { mutateAsync: _uploadProfileImage } = useMutation((data: IUploadProfileImageReq) => apis.File.uploadProfileImage(token, data));
  /* 사용자 비밀번호 수정 */
  const [updatePw] = updatePassword();
  /* 사용자 정보수정 */
  const [updateProfile] = updateMember();

  const countryCodesList = countryCodes.all().map(d => 
    ({ callingCode: d.countryCallingCode, countryCode: d.countryCode, languageCode: d.officialLanguageCode })
  );

  const codeDropdownData = useMemo(() => {
    return countryCodesList.map(d => ({ 
      value: `+${d.callingCode}`, 
      name: `+${d.callingCode}` })
    );
  },[countryCodesList]);

  const enableSaveButton = useMemo(() => {
    if (checkUserName(profile.fullName) || checkEmail(profile.email) || profile.mobileNumber.length < 5 ) {
      return true;
    } else return false;
  }, [profile]);

  useEffect(() => { 
    setProfile(profileInfo);
  }, [profileInfo, organSettingOpen])

  return (
    <div id="profile-container">
      <div className="profile-content">
        <div className="contain-left">
          <div className="profile-photo">
            <Labeled title="프로필 사진">
              <div 
                className="profile-image"
                { ...(userThumbnail && userThumbnail !== '' && { 
                    style: { backgroundImage: `url('${userThumbnail}')`, backgroundSize: 'cover' } 
                  })
                }
              >
              </div>
              <input 
                id="read-profile-image" 
                type="file" 
                accept=".jpg, .png, .gif"
                onChange={(e) => {
                  if (e.target.files) {
                    const file = e.target.files[0];
                    if (!file.name.toLowerCase().endsWith('jpg') && !file.name.toLowerCase().endsWith('png') && !file.name.toLowerCase().endsWith('gif')) {
                      useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');

                      return e.target.value ='';
                    }

                    if (file.size > Mb) {
                      useToast(ErrorCode.UNKNOWN, '1MB이하 파일만 첨부해주세요.');

                      return e.target.value = '';
                    }

                    const uploadProfileImageData = {
                      file: file,
                      reqUploadProfileImage : { 
                        kind: 'member',
                        userId: profile.userId
                      }
                    }
                    _uploadProfileImage(uploadProfileImageData).then(({data}) => {
                      if (data) {
                        if(data.result === ErrorCode.SUCCESS) {
                          useToast(ErrorCode.SUCCESS, '사진이 등록되었습니다.');
                          onSuccessUploadUserThumbnail();
                        } else {
                          if (data.result === ErrorCode.INVALID_EXTENSION) { useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');} 
                          else {useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');}
                          console.log(data.result);
                        }
                      } else {
                        useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');
                      }
                    })
                  }
                }} 
              />
              <label htmlFor="read-profile-image" className="flex a-center j-center">
                <div className="add-btn" />
                이미지 업로드
              </label>
            </Labeled>
          </div>
        </div>
        <div className="contain-right">
          <LabelInput
            title="사용자 이름"
            required
            placeholder="사용자 이름을 입력해주세요. (최소2자, 최대30자)"
            value={profile.fullName}
            onChangeValue={(str) => {
              if (str.length > 30) return;
              if (koEnNumSpRegex.test(str)) { return useToast(ErrorCode.UNKNOWN, '특수문자는 포함할 수 없습니다.');}

              setProfile(prev => ({
                ...prev,
                fullName: str.replace(koEnNumSpRegex, '') 
              }));
            }}
          />
          <LabelInput
            title="아이디"
            disabled
            value={profile.userId}
          />
          <Labeled
            title="이메일"
          >
            <div className="row flex a-center">
              <InputAtom
                value={profile.email}
                disabled
              />
              <button 
                className="big-sub-btn"
                onClick={() => setModalIsOpen(prev => ({
                  ...prev,
                  email: true
                }))}
              >
                이메일 변경하기
              </button>
            </div>
          </Labeled>
          {/* 
            mark todo: 나중에 실제로 값이 어떻게 넘어오는지 확인필요.
            sended 된 이메일로 실제 인증이 완료되기 전까지 화면에 아래 인증중 노출 
          */}
          {
            profile.statusCode  === 'initail0' && /* 일단 이렇게 적어둠. 실제값은 inital0이 맞는가? */
            <h5>이메일 인증 중입니다. 인증이 완료되면 자동으로 변경됩니다.</h5>
          }
          <div className="row flex j-between a-center">
            <p>비밀번호</p>
            <button
              className="big-sub-btn"
              onClick={() => setModalIsOpen(prev => ({
                ...prev,
                pw: true
              }))}
            >
              비밀번호 재설정
            </button>
          </div>
          <LabelInput
            title="조직"
            disabled
            value={profile.organName}
          />
          <LabelInput
            title="직급"
            placeholder="직급을 입력해주세요."
            value={!profile.ranks ? '' : profile.ranks }
            onChangeValue={(str) => {
              if (profile.ranks.length > 30) return;

              setProfile(prev => ({ 
                ...prev,
                ranks: str
              }));
            }}
          />
          <LabelTextarea
            title="소개"
            placeholder="소개를 입력해주세요."
            value={!profile.description ? '' : profile.description}
            onChangeValue={(str) => setProfile(prev => ({
              ...prev,
              description: str
            }))}
          /> 
          <Labeled
            title="연락처"
            className="contact-row"
          >
            <div className="flex">
              <DropdownAtom 
                id={'contact'} 
                data={codeDropdownData}
                value={{
                  name: codeDropdownData.find(code => code.value === profile.countryCode)?.name 
                    ? codeDropdownData.find(code => code.value === profile.countryCode)?.name
                    : '',
                  value: profile.countryCode
                }} 
                handleClick={(val) => setProfile(prev => ({
                  ...prev,
                  countryCode: String(val.value)
                }))} 
              />
              <InputAtom
                value={profile.mobileNumber}
                placeholder="연락처를 입력해 주세요. (최소5자, 최대16자)"
                onChangeValue={(str) => {
                  if (str.length > 16) return;

                  setProfile(prev => ({
                    ...prev,
                    mobileNumber: str.replace(numberRegex, '')
                  }))
                }
              }
              />
            </div>
          </Labeled>
          <div>
            <div className="row-push flex j-between a-center">
              알림설정
              <ToggleSwitchAtom 
                value={profile.alertYn}
                onChange={(toggle) => {
                  setProfile(prev => ({
                    ...prev,
                    alertYn: toggle
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="btns flex j-end a-end">
        <button 
          className="big-main-btn flex j-center a-center"
          disabled={ enableSaveButton }
          onClick={() => {
            const updateMemberData = {
              reqUpdMember: {
                id: String(userInfo?.nemo.memberId),
                fullName: profile.fullName,
                mobileNumber: profile.mobileNumber,
                ranks: profile.ranks,
                description: profile.description,
                alertYn: profile.alertYn,
                systemAuthCode: userInfo?.nemo.role!
              }
            }

            /* 성공시 getMemberInfos로 리패치 했으나, 응답값이 갱신안 된 채로 와서 이api응답값으로 Header에 있는 내 이름표기 갱신 필요 */
            updateProfile({variables:  updateMemberData }).then(({ data }) => {
              if (data) {
                if (data.updateMember.result === ErrorCode.SUCCESS ) {
                  useToast(ErrorCode.SUCCESS, '저장이 완료되었습니다.');
                  const result = data.updateMember.data[0];
                  const newData = {
                    id: result.id,
                    fullName: result.fullName,
                    email: result.email,
                    organName: result.organName,
                    ranks: result.ranks,
                    userId: result.userId,
                    alertYn: result.alertYn,
                    description: !result.description ? '' : result.description,
                    countryCode: '+82', /* temp 임시로 국가코드 이렇게 넣어주기. 실제 값이 어떻게 오는 지에 따라 변경필요! */
                    mobileNumber: result.mobileNumber,
                    statusCode: result.statusCode,
                    pictureResourceId: 123,
                  }
                  onSuccessUpdateProfileInfo(newData);
                } else {
                  console.log(data.updateMember.result);
                  useToast(ErrorCode.UNKNOWN, '저장을 실패했었습니다.');
                }

              } else {
                useToast(ErrorCode.UNKNOWN, '저장을 실패했었습니다.');
              }
            })
          }}
        >
          저장하기
        </button>
      </div>
      
      <ChangeEmailModal
        existingEmail={profile.email}
        open={modalIsOpen.email}
        title={() => 
          <>
            <Icon width={32} height={32} src={MailTitle} />
            이메일 변경
          </>
        }
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          email: false
        }))}
        onSendSuccess={() => {
          setModalIsOpen(prev => ({
            ...prev,
            email: false,
            emailSended: true 
          })); 
        }}
      />

      <EmailSendedModal 
        open={modalIsOpen.emailSended}
        title={() => 
          <>
            <Icon width={32} height={32} src={CheckTitle} />
            이메일을 확인해 주세요
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          emailSended: false
        }))}
      />

      <ResetPwModal 
        open={modalIsOpen.pw}
        title={() => 
          <>
            <Icon width={32} height={32} src={RedCirclePw} />
            비밀번호 재설정
          </>
        }
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          pw: false
        }))}
        onReset={(newPw:string) => {
          const updatePasswordData = {
            reqData:{
              userPw: newPw,
              email: profile.email
            }
          }
          updatePw({ variables: updatePasswordData}).then(({ data }) => {
            if (data) {
              if (data.updatePassword.result === ErrorCode.SUCCESS){
                useToast(ErrorCode.SUCCESS, '비밀번호가 변경되었습니다.');
                setProfile(prev => ({
                  ...prev,
                  pw: newPw
                }));
                setModalIsOpen(prev => ({
                  ...prev,
                  pw: false
                }));    
              } else {
                console.log(data.updatePassword.result);
                useToast(ErrorCode.UNKNOWN, '비밀번호가 변경을 실패했습니다.');
              }
            }
          })
        }} 
      />
    </div>
  );
};

export default Profile;
