import { gql } from '@apollo/client';

const query = gql`
query getCategoryPageByParam($reqData: ReqGetCategoryPage) {
  getCategoryPageByParam(reqData: $reqData) {
    __typename
    result
    number
    size
    first
    last
    numberOfElements
    totalPages
    totalElements
    content {
      ... on Category {
        categoryId
        cloudId
        name
        maskCode
        description
        title
        template
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
        createdByUserId
        modifiedByUserId
      }
    }
  }
}
`;
export default query;