import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUpdateEventSettingVariables {
  reqData: {
    settingId: number;
    projectId: number;
    eventId: number;
    name: string;
    priorityCode: string;
    messageId: number;
  }
}

export interface IUpdateEventSettingResponseData {
  updateEventSetting: IGqlResponseData<undefined>;
}

const updateEventSetting = () => useMutation<IUpdateEventSettingResponseData, IUpdateEventSettingVariables>(query);
export default updateEventSetting;