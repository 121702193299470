import { gql } from '@apollo/client';

const query = gql`query searchMember($text: String) {
  searchMember(text: $text) {
    __typename
    result
    messages
    data {
      __typename
      ... on Member {
        fullName
        userId
        id
        organName
        createdAt
      }
    }
  }
}`;
export default query;