import { gql } from '@apollo/client';

const query = gql`
query getWeeklyReportPageByParam($reqData: ReqGetReportPage) {
  getWeeklyReportPageByParam(reqData: $reqData) {
    __typename
    first
    number
    numberOfElements
    totalPages
    totalElements
    last
    size
    content {
      ... on RespReportDetail {
        reportId
        projectId
        projectName
        name
        reportType
        startTime
        endTime
        currentVersion
        currentStatus
        createdAt
        createdBy
        createdByName
        createdByUserId
        modifiedAt
        modifiedBy
        modifiedByName
        modifiedByUserId
      }
    }
  }
}
`


export default query;