import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { OrganById } from 'apis/v2/Organ/schema';

export interface ISearchOrganVariables {
  text: string;
}
export interface ISearchOrganResponseData {
  searchOrgan: IGqlResponseData<OrganById[]>;
}

const lazySearchOrgan = () => useLazyQuery<ISearchOrganResponseData, ISearchOrganVariables>(query, { fetchPolicy: 'no-cache' });
export default lazySearchOrgan;