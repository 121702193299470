import { gql } from '@apollo/client';

const query = gql`
  query getAuthPageByParam($reqData: ReqGetAuthPage) {
    getAuthPageByParam(reqData: $reqData) {
      __typename
      result
      number
      size
      first
      last
      numberOfElements
      totalPages
      totalElements
      content {
        ... on Authentication {
          authId
          memberId
          authTypeCode
          authToken
          userId
          fullName
          email
          organId
          organName
          createdAt
        }
      }
    }
  }
`;
export default query;