import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteMessageByIdsVariables {
  reqDelMessage: {
    messageIds: number[];
  }
}

export interface IDeleteMessageByIdsResponseData {
  deleteMessageByIds: IGqlResponseData<undefined>;
}

const deleteMessageByIds = () => useMutation<IDeleteMessageByIdsResponseData, IDeleteMessageByIdsVariables>(query);
export default deleteMessageByIds;