import { gql } from '@apollo/client';

const query = gql`query getMyHistEventByParam($reqData: ReqGetHistEventPage) {
  getHistEventPageByParam(reqData: $reqData) {
    __typename
    result
    first
    number
    numberOfElements
    totalPages
    totalElements
    last
    size
    content {
      __typename
      ... on RespHistEventByParamPage {
        eventSetting {
          settingId
          projectId
          eventId
          name
          priorityCode
        }
        event {
          eventId
          name
          maskCode
        }
        histEvent {
          id
          cloudInfoId
          projectId
          settingId
          alarmStatus
          histEventStatus
          projectName
          createdAt
          modifiedAt
          detEventMessage
          detEventName
        }
        cloudInfo {
          id
          projectId
          cloudId
          name
          rootAccount
        }
        cloud {
          cloudId
          cloudKind
          name
          maskCode
          description
        }
      }
    }
  }
}`;
export default query;