import { gql } from '@apollo/client';

const query = gql`query getAllRuleCloud {
  getAllRuleCloud {
    __typename
    result
    data {
      __typename
      ... on Cloud {
        cloudId
        cloudKind
        name
      }
    }
  }
}`;
export default query;