import { useEffect, useState } from 'react';
import './index.scss';

interface ITableCheckboxProps {
  /** 외부 오버라이딩 커스텀 CSS를 위한 className @default 'default' */
  className?: string;
  /** th전용 옵션. 일부만 체크되었을 때 th의 체크박스 상태 출력 */
  mixed?: boolean
  /** checked 또는 unchecked된 상태 @default false */
  defaultValue?: boolean;
  /** checkbox를 클릭하여 checked상태를 바꾸는 함수 */
  onChange?: (checked:boolean) => void;
}

const TableCheckbox = ({
  className = 'default',
  defaultValue = false,
  mixed,
  onChange
}:ITableCheckboxProps) => {
  
  /** 체크박스 고유 ID */ 
  const [id] = useState( `${Date.now() + Math.random()}`);
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => setChecked(defaultValue), [defaultValue]);

  return (
    <span>
      <input 
        type="checkbox"
        id={id}
        className={`table-checkbox-input ${className} ${mixed && 'mixed'}`}
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);

          if (onChange) {
            onChange(e.target.checked);
          }
        }}
      />
      <label htmlFor={id} />
    </span>
  );
};

export default TableCheckbox;
