import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import './index.scss';

interface IVerticalSplitLinkGroupProps {
  list: {
    link: string;
    text: string;
    target?: string;
  }[];
  height?: number;
}
const VerticalSplitLinkGroup = ({ list, height = 22 }:IVerticalSplitLinkGroupProps) => {

  return (
    <div 
      style={{ height: `${height}px` }} 
      className="vertical-split-link-group-comp"
    >
      {list.map((li, idx) => {
        if (idx === 0) {
          return <Link key={idx} to={li.link} rel="noopener noreferrer">{li.text}</Link>;
        } else {
          return (
            <Fragment key={idx}>
              <span />
              <Link 
                to={li.link} 
                rel="noopener noreferrer" 
                target={li.target}
              >
                {li.text}
              </Link>
            </Fragment>
          );
        }
      })}
    </div>
  );
};
export default VerticalSplitLinkGroup;