import { HTMLProps, forwardRef } from 'react';
import './index.scss';
import ChartView from 'components/v2/Charts/ChartView';
import { Panel } from '@Types/v2/Chart';

// eslint-disable-next-line react/display-name
interface IPanelProps {
  panel: Panel
}

const PanelComp = forwardRef<HTMLDivElement, IPanelProps & HTMLProps<HTMLDivElement>>(({
  panel,
  style,
  className,
  onMouseDown,
  onMouseUp,
  onTouchEnd,
  children
}, ref) => {
  return (
    <div
      ref={ref}
      key={panel.layout.i}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      className={className}
      style={style}
    >
      <h5 className='title'>{panel.title}</h5>
      <div><ChartView viewType={panel.viewType} defaultData={panel} /></div>
      {children}
    </div>
  )
})
export default PanelComp;
