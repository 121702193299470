import axios, { AxiosRequestConfig } from 'axios';
import Auth from './Auth';
import User from './User';
import SignUp from './SignUp';
import Terms from './Terms';
import File from './File';
import Memo from './Memo';

const API = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: `${process.env.REACT_APP_NEMO_API}`
});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
export type BaseApiConfig<_T> = {
  key: string;
  config: AxiosRequestConfig;
};

export const getInstance = (baseUrl?:string) => {

  return axios.create({
    headers: {
      'Content-type': 'Application/json'
    },
    baseURL: baseUrl || process.env.REACT_APP_NEMO_API,
    // baseURL: 'http://15.164.11.14:5050',
    timeout: 10000
  });
};

const callNemoApi = () => {
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_NEMO_API}`
  });
  
  return axiosInstance;
};

export default { 
  API, 
  callNemoApi, 
  Auth, 
  User, 
  SignUp, 
  Terms, 
  File, 
  Memo,
};