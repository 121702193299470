import './index.scss';

interface IHyperLinkTextProps{
    text: string;
    type: 'page-link' | 'text-cloud';
    w?: number;
    data?: string[];
}

const HyperLinkText = ({
  text,
  type,
  w,
  data
}: IHyperLinkTextProps) => {

  const handleHyperLink = () => {
    if (type === 'page-link') {
      /* link 이동 */
    }
  };

  return (
    <div className="hyperlink-wrap">
      <span className="hyperlink-text" style={{ width: w }} onClick={handleHyperLink}>
        {text}
      </span>
      {type === 'text-cloud' && (
        <div className="textcloud-wrap">
          {data?.map((val, index) => (
            <span className="textcloud-data" key={index}>{val}</span>
          ))}
        </div>
      )}
    </div>
  );
};

export default HyperLinkText;