/* eslint-disable quotes */
import { useMemo, useState } from 'react';
import './index.scss';

import { useGModal } from 'contexts/v2/GlobalModalProvider';
import MemberDataGrid from './MemberDataGrid';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import EmptyProjectCard from 'pages/v2/Organ/Project/components/cards/EmptyProjectCard';
import InviteMemberModal from '../../InviteMemberModal';
import UserInfo from 'assets/svgs/v2/ico_user_info_title.svg';
import Icon from 'components/v2/atoms/Icon';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import ShareModal from '../../ShareModal';
import updateProjectMember from 'graphql/mutations/updateProjectMember';
import { useAuth } from 'contexts/AuthProvider';
import removeProjectMember from 'graphql/mutations/removeProjectMember';
import delegateOwner from 'graphql/mutations/delegateOwner';

export type selectSettingType = {
  open: {
      id: string;
      fullName: string;
      userId: string;
      roleCode: string;
      alertYn: boolean;
      projectId: number;
      projectName: string;
      rowsLength: number;
      totalPage: number;
  };
  top: number;
  left: number;
}

interface IMemberProps {
  fromOrgan?: boolean;
  organId?: number;
  projectList?: ProjectInfo[];
  projectInfo?: ProjectInfo;
  openCreatePJModal?: () => void
}

const Member = ({
  fromOrgan = false,
  organId,
  projectList,
  projectInfo,
  openCreatePJModal
}:IMemberProps) => {
  const { userInfo, updateToken } = useAuth();
  const { tooltip } = useGModal();

  /* 구성원 역할 변경 (소유자 위임과는 별개) */
  const [updatePjMember] = updateProjectMember();
  /* 프로젝트 내보내기 */
  const [removePjMember] = removeProjectMember();
  /* 조직 소유자 위임하기 */
  const [delegateOrgOwner] = delegateOwner();

  const [modalIsOpen, setModalIsOpen] = useState({
    invite: false,
    select: false,
    changeShareModal: false,
    target: '',
  });
  const [selectSetting, setSelectSetting] = useState<selectSettingType>({
    open: {
      id: '',
      fullName: '',
      userId: '',
      roleCode: '',
      alertYn: false,
      projectId: -1,
      projectName: '',
      rowsLength: -1,
      totalPage: -1
    },
    top: 0,
    left: 0
  });

  const [refetch, setRefetch] = useState({
    refresh: false,
    resetPage: false
  });

  const projectNameAndId = useMemo(() => {
    if (modalIsOpen.invite) {
      const pjId = Number(modalIsOpen.target)

      if (projectInfo?.projectId) {
        const data = {
          projectId: projectInfo.projectId,
          projectName: projectInfo.name
        }
        return data;
      } else {
        const findPj = projectList?.find(val => val.projectId === pjId)
        if (findPj) {
          const data = {
            projectId: findPj.projectId,
            projectName: findPj.name
          }
          return data;
        }
      }
    }
    
    
  }, [modalIsOpen, projectInfo, projectList])

  const changeShareModalData = useMemo(() => {
    if (modalIsOpen.changeShareModal) {
      /* 멤버로 변경 */
      if (modalIsOpen.target === 'member') {
        return {
          title: () => <>구성원 권한으로 변경하시겠습니까?</>,
          buttonTitle: '변경하기',
          content: () => <>{selectSetting.open.fullName} ({selectSetting.open.userId})님을 {selectSetting.open.projectName}에서 구성원 권한으로 변경합니다.</>,
          onConfirm: () => {
            const data = {
              reqUpdProjectMember: {
                memberId: selectSetting.open.id,
                projectId: selectSetting.open.projectId,
                roleCode: 'pj_membr',
                alertYn: selectSetting.open.alertYn
              }
            };
            updatePjMember({ variables: data }).then(({ data }) => {
              if(data) {
                if (data.updateProjectMember.result === ErrorCode.SUCCESS) {
                  useToast(ErrorCode.SUCCESS, '구성원으로 변경되었습니다.');
                  setModalIsOpen(prev => ({
                    ...prev,
                    changeShareModal: false,
                    target: ''
                  }));
                  setSelectSetting({
                    open: {
                      id: '',
                      fullName: '',
                      userId: '',
                      roleCode: '',
                      alertYn: false,
                      projectId: -1,
                      projectName: '',
                      rowsLength: -1,
                      totalPage: -1
                    },
                    top: 0,
                    left: 0
                  });
              
                  setRefetch(prev => ({
                    ...prev,
                    refresh: true
                  }));

                } else {
                  console.log(data.updateProjectMember.result);
                  useToast(ErrorCode.SUCCESS, '구성원으로 변경을 실패했습니다.');
                }
              } else {
                useToast(ErrorCode.SUCCESS, '구성원으로 변경을 실패했습니다.');
              }
            });
          }
        }
      }
      /* 관리자로 변경 */
      else if (modalIsOpen.target === 'admin') {
        return {
          title: () => <>관리자 권한으로 변경하시겠습니까?</>,
          buttonTitle: '변경하기',
          content: () =>  <>
            {selectSetting.open.fullName} ({selectSetting.open.userId})님을 {selectSetting.open.projectName}에서 관리자 권한으로 변경합니다.
          </>,
          onConfirm: () => {
            const data = {
              reqUpdProjectMember: {
                memberId: selectSetting.open.id,
                projectId: selectSetting.open.projectId,
                roleCode: 'pj_admin',
                alertYn: selectSetting.open.alertYn
              }
            };

            updatePjMember({ variables: data }).then(({ data }) => {
              if(data) {
                if (data.updateProjectMember.result === ErrorCode.SUCCESS) {
                  useToast(ErrorCode.SUCCESS, '관리자로 변경되었습니다.');
                  setModalIsOpen(prev => ({
                    ...prev,
                    changeShareModal: false,
                    target:''
                  }));
                  setSelectSetting({
                    open: {
                      id: '',
                      fullName: '',
                      userId: '',
                      roleCode: '',
                      alertYn: false,
                      projectId: -1,
                      projectName: '',
                      rowsLength: -1,
                      totalPage: -1
                    },
                    top: 0,
                    left: 0
                  });

                  setRefetch(prev => ({
                    ...prev,
                    refresh: true
                  }));
                } else {
                  console.log(data.updateProjectMember.result);
                  useToast(ErrorCode.UNKNOWN, '관리자로 변경을 실패했습니다.');
                }
              } else {
                useToast(ErrorCode.UNKNOWN, '관리자로 변경을 실패했습니다.');
              }
            });
          }
        }
      }
      /* 내보내기 */
      else if (modalIsOpen.target === 'discharge') {
        return {
          title: () => <>해당 구성원을 내보내시겠습니까?</>,
          buttonTitle: '내보내기',
          content: () => <>구성원을 내보내면, 구성원은 해당 프로젝트에 접근이 불가합니다.</>,
          onConfirm: () => {
            const removeProjectMemberData = {
              reqData: {
                projectId: selectSetting.open.projectId,
                memberIds: [selectSetting.open.id],
              }
            }

            removePjMember({ variables: removeProjectMemberData }).then(({data}) => {
              if (data) {
                if (data.removeProjectMember.result === ErrorCode.SUCCESS) {
                  useToast(ErrorCode.SUCCESS, '구성원 내보내기가 완료되었습니다.');

                  setRefetch({
                    refresh: true,
                    resetPage: true
                  });
                  
                  setModalIsOpen(prev => ({
                    ...prev,
                    changeShareModal: false,
                    target: ''
                  }));
      
                  setSelectSetting({
                    open: {
                      id: '',
                      fullName: '',
                      userId: '',
                      roleCode: '',
                      alertYn: false,
                      projectId: -1,
                      projectName: '',
                      rowsLength: -1,
                      totalPage: -1
                    },
                    top: 0,
                    left: 0
                  });
                } else {
                  console.log(data.removeProjectMember.result);
                  useToast(ErrorCode.SUCCESS, '구성원 내보내기를 실패했습니다.');
                }
              } else {
                useToast(ErrorCode.SUCCESS, '구성원 내보내기를 실패했습니다.');
              }
            })
          }
        }
      } 
      /* 위임하기 */
      else if (modalIsOpen.target === 'delegate')  {
        return {
          title: () => <>조직 소유자 권한을 위임하시겠습니까?</>,
          buttonTitle: '위임하기',
          content: () => <>
            {selectSetting.open.fullName}({selectSetting.open.userId}) 에게 {userInfo?.nemo.organName} 이름의 조직 소유자 권한이 위임되며, {userInfo?.nemo.fullName}은 관리자로 자동 변경됩니다.
          </>,
          onConfirm: () => {
            const delegateOwnerData = {
              memberId: selectSetting.open.id,
            }

            delegateOrgOwner({ variables: delegateOwnerData }).then(({ data }) => {
              if(data) {
                if (data.delegateOwner.result === ErrorCode.SUCCESS) {
                  const token = data.delegateOwner.data[0].userToken;
                  updateToken(token);
                  useToast(ErrorCode.SUCCESS, '소유자 권한 위임이 완료되었습니다.');
                  setModalIsOpen(prev => ({
                    ...prev,
                    changeShareModal: false,
                    target: ''
                  }));
                  setSelectSetting({
                    open: {
                      id: '',
                      fullName: '',
                      userId: '',
                      roleCode: '',
                      alertYn: false,
                      projectId: -1,
                      projectName: '',
                      rowsLength: -1,
                      totalPage: -1
                    },
                    top: 0,
                    left: 0
                  });

                  setRefetch(prev => ({
                    ...prev,
                    refresh: true
                  }));
                } else {
                  console.log(data.delegateOwner.result);
                  useToast(ErrorCode.UNKNOWN, '소유자 권한 위임을 실패했습니다.');
                }
              } else {
                useToast(ErrorCode.UNKNOWN, '소유자 권한 위임을 실패했습니다.');
              }
            })
          }
        }
      }
    }
  }, [modalIsOpen, selectSetting]);
  

  const renderMemberDataGrid = useMemo(() => {
    if (!fromOrgan) {
      /* 프로젝트에서 들어온 것이라면 => 해당 프로젝트만 렌더 */    
      return <MemberDataGrid 
        projectInfo={projectInfo as ProjectInfo} 
        openModal={(key:string, value: boolean | string ) => setModalIsOpen(prev => ({
          ...prev,
          [key]: value
        }))}
        selectSetting={selectSetting}
        selectSett={(data: selectSettingType) => setSelectSetting({ ...data })}
        refetch={refetch}
        resetRefetch={() => { 
          setRefetch({
            refresh: false,
            resetPage: false
          })
        }}
      />;
    } else {
      /* 조직에서 들어온 것이라면 => 프로젝트가 있는지 판단 후 없다면 프로젝트 생성유도, 프로젝트들이 있다면 해당 조직 하의 모든 프로젝트 map으로 렌더  */ 
      return <> {
        ( projectList?.length === 0 && openCreatePJModal )
          ? <EmptyProjectCard 
              className="modal" 
              openCreatePJModal={openCreatePJModal} 
            />
          : projectList?.map(li => (
            <MemberDataGrid 
              key={li.projectId} 
              projectInfo={li} 
              openModal={(key:string, value: boolean | string ) => setModalIsOpen(prev => ({
                ...prev,
                [key]: value
              }))}
              selectSetting={selectSetting}
              selectSett={(data: selectSettingType) => setSelectSetting({ ...data })}
              refetch={refetch}
              resetRefetch={() => { 
                setRefetch({
                  refresh: false,
                  resetPage: false
                })
            }}  
            />
          )
        )
      } </>;
    }
  }, [
      projectInfo, 
      fromOrgan, 
      organId, 
      projectList,
      selectSetting,
  ]);

  return (
    <div 
      className="member-container" 
      onScroll={tooltip.close}
    >
      {renderMemberDataGrid}

      <InviteMemberModal 
        open={modalIsOpen.invite}
        projectInfo={projectNameAndId}
        title={() => 
          <>
            <Icon width={32} height={32} src={UserInfo} />
            구성원 초대 
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          invite: false,
          target: ''
        }))}
        onInviteSuccess={() => {
          useToast(ErrorCode.SUCCESS, '초대가 완료되었습니다.');
          setModalIsOpen(prev => ({
            ...prev,
            invite: false,
            target: ''
          }));
        }}
      />

      <ShareModal 
        open={modalIsOpen.changeShareModal}
        title={changeShareModalData?.title} 
        content={changeShareModalData?.content} 
        buttonTitle={changeShareModalData?.buttonTitle} 
        onConfirm={changeShareModalData?.onConfirm} 
        onClose={() => {
          setModalIsOpen(prev => ({
            ...prev,
            changeShareModal: false,
            target: ''
          }));
          setSelectSetting({
            open: {
              id: '',
              fullName: '',
              userId: '',
              roleCode: '',
              alertYn: false,
              projectId: -1,
              projectName: '',
              rowsLength: -1,
              totalPage: -1
            },
            top: 0,
            left: 0
          });
        }}
      />
    </div>
  );
}; 

export default Member;
