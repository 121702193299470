import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { MemoInfo } from 'graphql/types/MemoInfo';

export interface IAddMemoVariables {
  memo: {
    relObjectId: number; /* histReportId */
    relObjectType: string; // (MemoRelObjectType): report_w | report_m | event_ri | rep_chrt
    text: string;
    checkYn: boolean;
  }
}

export interface IAddMemoResponseData {
  addMemo: IGqlResponseData<MemoInfo[]>;
}

/** 요청사항 추가 */
const addMemo = () => useMutation<IAddMemoResponseData, IAddMemoVariables>(query);
export default addMemo;