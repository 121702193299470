import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import './index.scss';
import LabelInput from 'components/v2/LabelInput';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import Table from 'components/v2/dataDisplay/Table';
import IsLoading from 'components/v2/atoms/IsLoading';
import { useMemo, useState } from 'react';
import { timeToKr } from 'utils/timeFormatter';

interface ICategoryGridProps {
  cloudList: {
    id: number;
    value: string;
    name: string;
  }[]
  onSubmitSearch: (readyToSearch: string) => void;
  total: {
    totalPage: number;
    totalElement: number;
  }
  tablePagination: {
    limit: number;
    target: string;
    direction: OrderDirection;
    currentPage: number;
  }
  updateTablePagination: (key: string, value: number | string | OrderDirection) => void;
  rows: RowType[];
  checkedList: string[];
  reportCheckedList: (list:string[]) => void;
  sortOption?: {
    target: string;
    direction: OrderDirection;
    onChangeSort: (target: string, dir: OrderDirection) => void;
  }
  reportSelected: (id: string) => void;
  openCreateModal: () => void;
  openDeleteModal: () => void;
  isLoading?: boolean;
}

const CategoryGrid = ({
  tablePagination,
  updateTablePagination,
  cloudList,
  onSubmitSearch,
  total,
  rows,
  checkedList,
  reportCheckedList,
  reportSelected,
  openCreateModal,
  openDeleteModal,
  isLoading
}:ICategoryGridProps) => {

  const [readyToSearch, setReadyToSearch] = useState('');

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: '클라우드종류',
        field: 'cloudId',
        sort: true,
        renderCell: (row:RowType) => <>{cloudList.find(val => val.id === row.cloudId)?.name ? cloudList.find(val => val.id === row.cloudId)?.name : '-'}</>
      },
      { 
        label: '카테고리 이름',
        field: 'name',
        sort: true
      },
      { 
        label: '고유 구분자',
        field: 'maskCode',
        sort: true,
      },
      {
        label: '문서 제목',
        field: 'title',
        sort: true
      },
      {
        label: '수정 시간',
        field: 'modifiedAt',
        renderCell: (row:RowType) => <>{!row.modifiedAt ? '-' : timeToKr(row.modifiedAt)}</>, 
        sort: true
      }
    ];
  }, [rows, cloudList]);
  
  return (
    <div className='category-grid'>
      <form 
        className='search-wrap flex a-center'
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitSearch(readyToSearch);
        }}
      >
        <LabelInput 
          title="검색" 
          placeholder='카테고리 이름, 고유 구분자, 문서 제목'
          value={readyToSearch}
          onChangeValue={str => setReadyToSearch(str)}
        />
        <div className="btns flex a-end">
        <button 
          type="submit"
          className='big-main-btn'
        >
          검색 
        </button>
        </div>
      </form>
      <div className='data-contents'>
        <div className="data-grid-wrap">
          <div className="grid-header flex j-between">
            <div className="col-1 flex a-center">
              <div className="header-icon" /> 전체 <span>{total.totalElement}</span>
            </div>
            <div className="col-2 flex a-center">
              { checkedList.length > 0 && `${checkedList.length}개 선택됨` }
              <button 
                className="big-sub-btn delete"
                disabled={checkedList.length === 0 }
                onClick={openDeleteModal}
              >
                삭제하기
              </button>
              {
                total.totalElement !== 0 &&
                <>
                <button 
                  className="big-main-btn register"
                  onClick={openCreateModal}
                >
                  카테고리 생성
                </button> 
                <DropdownAtom 
                  id="offset-limit"
                  data={TABLE_OFFSET}
                  value={{ 
                    name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                    value: Number(tablePagination.limit)
                  }}
                  handleClick={(val) => { 
                    updateTablePagination('limit', Number(val.value))
                    updateTablePagination('currentPage', 1);
                  }}
                />
                </>
              }
            </div>
          </div>
          <div className="grid-main">
            { 
              rows.length === 0 
                ? <div className="empty-rows flex j-center a-center">
                  <div className="flex col a-center">
                    <div className='empty-icon' />
                    <h5>카테고리가 없습니다.</h5>
                    <p>카테고리를 생성해주세요.</p>
                    <button 
                      className='big-main-btn'
                      onClick={openCreateModal}
                    >
                      카테고리 생성
                    </button>
                  </div>
                </div> 
                : <>
                <Table 
                  rows={rows} 
                  columns={COLUMNS} 
                  reportCheckedList={reportCheckedList}
                  reportSelected={reportSelected}
                  sortOption={{
                    target: tablePagination.target,
                    direction: tablePagination.direction,
                    onChangeSort: (target: string, dir: OrderDirection) => {
                      updateTablePagination('target', target);
                      updateTablePagination('direction', dir);
                    }
                  }}
                />
                <div className="pagination-wrapper flex a-center">
                  <TablePagination 
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={(page) => updateTablePagination('currentPage', page)}
                    totalPage={total.totalPage}
                  />
                </div>
              </>
            }
          </div>
        </div>
        { isLoading && <IsLoading dimmed={rows.length !== 0} /> }
      </div>
    </div>
  )
}
export default CategoryGrid;