import { gql } from '@apollo/client';

const query = gql`
  mutation updateMember($reqUpdMember: ReqUpdMember) {
    updateMember(member: $reqUpdMember) {
      __typename
      result
      messages
      data {
        ... on Member {
          id
          fullName
          userId
          email
          organId
          mobileNumber
          ranks
          retryCount
          description
          alertYn
          statusCode
          createdAt
          createdBy
          createdByName
          modifiedAt
          modifiedBy
          modifiedByName
        }
      }
    }
  }
`;
export default query;