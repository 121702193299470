/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import Icon from 'components/v2/atoms/Icon';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import { ACCESS_KEYS } from 'utils/DummyData/Dropdown';
import LinkedDocument from 'assets/svgs/v2/ico_linked_document.svg';
import Cloud from 'assets/images/v2/ico_cloud.png';
import CloudAccount from 'assets/svgs/v2/ico_cloud_account.svg';
import Register from 'assets/svgs/v2/ico_register.svg';
import Edit from 'assets/svgs/v2/ico_edit.svg';
import LinkIcon from 'assets/svgs/v2/ico_link.svg';
import { dateFormatter } from 'utils/Formatter';
import { checkLinkedCloudName } from 'utils/v2/ValidCheck';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';
import { AllRuleCloudType } from 'graphql/types/AllRuleCloud';
import InfoCard from '../cards/InfoCard';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import updateProjectCloud from 'graphql/mutations/updateProjectCloud';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';

export type CloudDetailType = {
  id: number;
  name: string;
  cloudId: number;
  rootAccount: string;
  attributes: string;
  attrValue1: string;
  verifyInfo: {
    id: number;
    attrKey: string;
    attrValue: string;
  }[];
}

interface ILinkedCloudDetailProps {
  data: ProjectCloudType;
  cloudTypeList: AllRuleCloudType[];
  doRefecthCloudList: () => void
  getOffPage: () => void;
  onDelete: () => void;
  onEditSuccess: () => void;
}

const LinkedCloudDetail = ({
  data,
  cloudTypeList,
  doRefecthCloudList,
  getOffPage,
  onDelete,
  onEditSuccess
}: ILinkedCloudDetailProps) => {
  const { tooltip } = useGModal();
  const [detail, setDetail] = useState<CloudDetailType>({
    id: -1, /* row id */
    name: '', /* 연계계정이름 */
    cloudId: -1, /* 클라우드 종류 */
    rootAccount: '', /* 클라우드 계정 */
    attributes: '',
    attrValue1: '',
    verifyInfo: [] /* 화면단 작업을 통한 dropdown 리스트를 여기에 넣기 */
  }); 
  const [accessKeys, setAccessKeys] = useState<{ name: string; value: string; }[]>([]);
  const [edit, setEdit] = useState(false);

  /* 연계 클라우드 수정 */
  const [updateCloud] = updateProjectCloud();

  const accessKeysList = useMemo(() => {
    const attrKeys = detail.verifyInfo.map(val => val.attrKey);
    return accessKeys.filter(acc => !attrKeys.includes(acc.value))
  }, [accessKeys, detail])

  const ableEditCloudDetail = useMemo(() => {
    if (
      checkLinkedCloudName(detail.name) ||
      detail.cloudId === -1 ||
      detail.rootAccount === '' ||
      detail.verifyInfo.length === 0 ||
      detail.verifyInfo.some(val => val.attrKey === '' || val.attrValue === '')
    ) { 
      return true; 
    } else return false;
  }, [detail]);


  const origin = useMemo(() => {    
    const parsedObject = JSON.parse(data.attrValue1);

    const newArray = Object.keys(parsedObject).map((key, idx) => {
      const id = idx + 1;
      const attrKey = `${key}`;
      const attrValue = parsedObject[key];

      return { 
        id, 
        attrKey, 
        attrValue 
      };
    });

    return {
      id: data.id, 
      name: data.name, 
      cloudId: data.cloudId, 
      rootAccount: data.rootAccount,
      attributes: data.attributes,
      attrValue1: data.attrValue1,
      verifyInfo: [ ...newArray ]
    }
  }, []);

  const attrJson = (arr: { id: number, attrKey: string, attrValue: string }[]) => {          
    const outputObject: { [key: string]: string }  = {};
    
    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];
      const currentKey = currentObj.attrKey;
      const currentValue = currentObj.attrValue;
    
      outputObject[currentKey] = currentValue;
    }

    return JSON.stringify(outputObject, null, 0);
  }

  useEffect(() => { 
    setDetail({ ...origin });
    setAccessKeys(ACCESS_KEYS);
  }, []);

  useEffect(() => {
    const linkedCloudPageEl = document.getElementById('cloud-detail-page');
    const projectPageEl = document.getElementById('project-occupied-page');

    if (linkedCloudPageEl && projectPageEl) {
      if (projectPageEl.offsetHeight < linkedCloudPageEl.scrollHeight) {
        projectPageEl.classList.remove('none-scroll');
      } else {
        projectPageEl.classList.add('none-scroll');
      }
    }

    return () => projectPageEl?.classList.remove('none-scroll');
  }, [detail.verifyInfo.length]);
  
  return (
    <div id="cloud-detail-page">

      <div className="detail-header flex a-center"> 
        <button className="back-btn" onClick={getOffPage} /> 
        연계 클라우드 상세
      </div>

      <div className="detail-content">
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={LinkedDocument} 
            />
          } 
          title="연계 계정 이름" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.name
                : <InputAtom
                  value={detail.name}
                  onChangeValue={(val) => setDetail((prev) => ({
                    ...prev,
                    name: val
                  }))}
                />
              }
            </>
          } 
        />
        <InfoCard 
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Cloud} 
            />
          } 
          title="클라우드 종류" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? cloudTypeList.find(cloud => cloud.cloudId === detail.cloudId)?.name
                : <DropdownAtom 
                  id={'cloud-type'} 
                  data={cloudTypeList.map(val => {
                    return {
                      id: val.cloudId,
                      name: val.name,
                      value: val.cloudKind
                    };
                  })} 
                  placeholder="선택" 
                  // error={}
                  value={{
                    name: cloudTypeList.find(cloud => cloud.cloudId === detail.cloudId)?.name,
                    value: String(cloudTypeList.find(cloud => cloud.cloudId === detail.cloudId)?.cloudKind)
                  }} 
                  handleClick={(val: DropdownListDataType) => 
                    setDetail((prev) => ({
                      ...prev,
                      cloudId: Number(val.id)
                    }))
                  } 
                />
              }
            </>
          } 
        />
        <InfoCard 
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={CloudAccount} 
            />
          } 
          title="클라우드 계정" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.rootAccount
                : <InputAtom
                  value={detail.rootAccount}
                  onChangeValue={(val) => setDetail((prev) => ({
                    ...prev,
                    rootAccount: val
                  }))}
                />
              }
            </>
          } 
        />
        {!edit &&
        <>
          <InfoCard 
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={Register} 
              />
            } 
            title="등록" 
            required={edit} 
            content={() => 
              <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: data.createdAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.createdByName}({(data.createdByUserId)})</u> / {dateFormatter(data.createdAt, 'datetime')}
              </> 
            }
          />
          <InfoCard 
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={Edit} 
              />
            } 
            title="마지막 수정"
            required={edit}
            content={() => 
              <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({(data.modifiedByUserId)})</u> / {dateFormatter(data.modifiedAt, 'datetime')}
              </> 
            }
          />
        </>}
        <InfoCard 
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={LinkIcon} 
            />
          } 
          title="인증 정보"
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              {
                !edit 
                  ? 
                  <div className="ver-info flex col">
                    {
                      detail.verifyInfo.map((val, idx) => (
                        <div className="flex a-start" key={idx}>
                          <span>{accessKeys.find(acc => acc.value === val.attrKey)?.name}</span> 
                          <div className='info-wrap'>
                            <pre>{val.attrValue}</pre>
                          </div> 
                        </div>
                      ))
                    }
                  </div>
                  : <>
                    <div className="add-info flex a-center"
                      onClick={() => {
                        const info = {
                          id: detail.verifyInfo.length === 0 ? 0 : detail.verifyInfo[detail.verifyInfo.length - 1].id + 1,
                          attrKey: '',
                          attrValue: ''
                        };
                        setDetail(prev => ({
                          ...prev,
                          verifyInfo: [...detail.verifyInfo, info]
                        }));
                      }}>
                      { detail.verifyInfo.length < 3 && <><button className="add-btn" /> 추가</> }
                    </div>
                    {detail.verifyInfo.map(verify => (
                      <div 
                        key={verify.id}
                        className="access-info flex a-center"
                        data-focus={`${verify.id}`}
                      >
                        <DropdownAtom 
                          id={`access-key ${verify.id}`} 
                          data={ accessKeysList.length === 0 
                            ? [{
                              name: '더 이상 선택가능한 key가 없습니다.',
                              value: -1
                            }]
                           : accessKeysList }
                          placeholder="선택"
                          value={{
                            name: accessKeys.find(access => access.value === verify.attrKey)?.name 
                              ? accessKeys.find(access => access.value === verify.attrKey)?.name
                              : '',
                            value: verify.attrKey
                          }} 
                          handleClick={(val: DropdownListDataType) => {
                            if (val.value === -1) return;

                            const copy = detail.verifyInfo.map(val => val);
                            const findIdx = copy.findIndex(val => val.id === verify.id);

                            if (findIdx === -1) return;
                          
                            copy[findIdx].attrKey = String(val.value);
                            setDetail(prev => ({
                              ...prev,
                              verifyInfo: copy
                            }));

                          }} 
                        />
                        <InputAtom
                          placeholder="입력"
                          value={verify.attrValue}
                          onChangeValue={(str) => {
                            const copy = detail.verifyInfo.map(val => val);
                            const findIdx = copy.findIndex(data => data.id === verify.id);
                            if (findIdx === -1) return;

                            copy[findIdx].attrValue = str;
                            setDetail(prev => ({
                              ...prev,
                              verifyInfo: copy
                            }));
                          }}
                        />
                        <button 
                          className="delete-btn" 
                          onClick={() => {
                            const left = detail.verifyInfo.filter(data => data.id !== verify.id);
                            setDetail(prev => ({
                              ...prev,
                              verifyInfo: left
                            }));
                          }} 
                        />
                      </div>
                    ))}
                  </>
              }
            </>
          } 
        />
      </div>

      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => {
                  setEdit(true);
                  doRefecthCloudList();
                }}
              >
                수정
              </button>
              <button 
                className="big-main-btn"
                onClick={onDelete}
              >
                삭제
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                취소
              </button>
              <button 
                className="big-main-btn"
                onClick={() => {
                  const updateCloudData = {
                    reqUpdProjectCloud: {
                      id: detail.id,
                      name: detail.name,
                      cloudId: detail.cloudId,
                      rootAccount: detail.rootAccount,
                      attrValue1: attrJson(detail.verifyInfo)
                    }
                  }
      
                  updateCloud({ variables: updateCloudData}).then(({ data }) => {
                    if (data) {
                      if (data.updateProjectCloud.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, '저장이 완료되었습니다 .');
                        setEdit(false);
                        onEditSuccess();
                      } else {
                        useToast(ErrorCode.UNKNOWN, '저장을 실패했습니다 .');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, '저장을 실패했습니다 .');
                    }
                  })
                }}
                disabled={ ableEditCloudDetail }
              >
                수정하기
              </button>
            </>
        }
      </div>
    </div>
  );
};

export default LinkedCloudDetail;
