import { gql } from '@apollo/client';

// const query = gql`
//   query getMemos($reqGetMemo: ReqGetMemo) {
//     getMemos(memo: $reqGetMemo) {
//       result
//       message
//       data {
//           ... on RespMemo {
//             memoInfos {
//             id
//             relObjectId
//             relObjectType
//             text
//             checkYn
//             createdBy
//             createdByName
//           }
//         }
//       }
//     }
//   }
// `;

const query = gql`
  query getMemos($reqGetMemo: ReqGetMemo) {
    getMemos(memo: $reqGetMemo) {
      result
      messages
      data {
          ... on Memo {
            id
            relObjectId
            relObjectType
            text
            checkYn
            createdBy
            createdByName
        }
      }
    }
  }
`;

export default query;