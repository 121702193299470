import { useEffect, useMemo, useState } from 'react';
import SelectBox from 'components/UI/SelectBox';
import './index.scss';
import Button from 'components/UI/Button';
import getEnumTypeCode from 'graphql/queries/getEnumTypeCode';
import { enumFormatter } from 'utils/Formatter';
import addMessage from 'graphql/mutations/addMessage';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';
import { MessagePageType } from 'graphql/types/MessagePage';
import updateMessage from 'graphql/mutations/updateMessage';

interface ICreateMessageModalProps{
    onClose: () => void;
    type: string;
    onConfirm: () => void;
    data?: MessagePageType;
}

const CreateMessageModal = ({ type, onClose, onConfirm, data }: ICreateMessageModalProps) => {
  
  const [sendType, setSendType] = useState<{ name:string, value: string | number }>({ name: '', value: '' });
  const [usageType, setUsageType] = useState<{ name:string, value: string | number }>({ name: '', value: '' });
  const [text, setText] = useState('');
  const [forceClose, setForceClose] = useState(false);

  const { data: usageCodeList } = getEnumTypeCode({ text: 'MsgUseTypeCode' });
  const { data: sendCodeList } = getEnumTypeCode({ text: 'MsgSenderTypeCode' });
  const [addMsg] = addMessage();
  const [updateMsg] = updateMessage();

  const memoizeUsageCodeList = useMemo(() => {
    return usageCodeList?.getEnumTypeCode.data.map(d => ({ ...d, name: enumFormatter(d.value) || d.name })) || [];
  },[usageCodeList]);
  const memoizeSendCodeList = useMemo(() => {
    return sendCodeList?.getEnumTypeCode.data.map(d => ({ ...d, name: enumFormatter(d.value) || d.name })) || [];
  },[sendCodeList]);

  /** 생성, 수정 분기 */
  const isCreate = useMemo(() => {
    if (type === 'create') return true;
    else return false;
  },[type]);

  const handleCreate = () => {
    /// api 호출
    if (isCreate) {
      //add
      addMsg({
        variables: {
          reqData: {
            relObjectId: data?.relObjectId || 0,
            relObjectType: usageType.value.toString(),
            sendTypeCode: sendType.value.toString(),
            version: data?.version || '1.0',
            title: text,
            message: text
          }
        }
      }).then(({ data }) => {
        if (data) {
          if (data.addMessage.result === ErrorCode.SUCCESS) {
            useSnackbar(data.addMessage.result);
            onConfirm();
            onClose();
          } else {
            useSnackbar(data.addMessage.result);
          }
        } else {
          useSnackbar(ErrorCode.UNKNOWN);
        }
      });
    } else {
      //update
      if (data) {
        // updateMsg({
        //   variables: {
        //     reqData: {
        //       id: data.id,
        //       relObjectId: data.relObjectId,
        //       relObjectType: usageType.value.toString(),
        //       sendTypeCode: sendType.value.toString(),
        //       version: data.version,
        //       title: text,
        //       message: text
        //     }
        //   }
        // }).then(({ data }) => {
        //   if (data) {
        //     if (data.updateMessage.result === ErrorCode.SUCCESS) {
        //       useSnackbar(data.updateMessage.result);
        //       onConfirm();
        //       onClose();
        //     } else {
        //       useSnackbar(data.updateMessage.result);
        //     }
        //   } else {
        //     useSnackbar(ErrorCode.UNKNOWN);
        //   }
        // });
      }
    }
  };

  /** 초기값 세팅 */
  useEffect(() => {
    if (data) {
      const findUsage = memoizeUsageCodeList.find(d => d.value === data.relObjectType);
      const findSend = memoizeSendCodeList.find(d => d.value === data.sendTypeCode);
      setUsageType(findUsage || { name: '', value: '' });
      setSendType(findSend || { name: '', value: '' });
      setText(data.message);
    }
  },[data, memoizeUsageCodeList, memoizeSendCodeList]);
  
  useEffect(() => {
    if (forceClose) {
      setForceClose(false);
    }
  },[forceClose]);
  
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="messagemodal-wrap" onClick={e => {
        e.stopPropagation();
        setForceClose(true);
      }}>
        <div className="header-wrap">
          <span/>
          <p>
            문구 {isCreate ? '추가' : '수정'}
          </p>
        </div>
        <div className="contents-wrap">
          <p className="title">생성할 용도/수신타입을 선택하고 문구를 생성하세요.</p>
          <div className="row" style={{ zIndex: 10 }}>
            <p className="row-title">용도</p>
            <SelectBox id="createmsguse" style="message" data={usageCodeList?.getEnumTypeCode.data.map(d => ({ ...d, name: enumFormatter(d.value) || d.name })) || []} handleClick={(val) => setUsageType(val)} value={usageType} forceClose={forceClose}/>
          </div>
          <div className="row">
            <p className="row-title">수신 타입</p>
            <SelectBox id="createmsgtype" style="message" data={sendCodeList?.getEnumTypeCode.data.map(d => ({ ...d, name: enumFormatter(d.value) || d.name })) || []} handleClick={(val) => setSendType(val)} value={sendType} forceClose={forceClose}/>
          </div>
          <div className="row">
            <p className="row-title">알림 문구</p>
            <textarea className="input textarea" value={text} onChange={(e) => setText(e.target.value)}/>
          </div>
          <div className="modal-btn-wrap">
            <Button disabled={sendType.value === '' || usageType.value === '' || text === ''} text={isCreate ? '생성' : '수정'} handleClick={handleCreate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMessageModal;