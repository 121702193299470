/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { ColumnType, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import Icon from 'components/v2/atoms/Icon';
import Calender from 'assets/svgs/v2/ico_calender.svg';
import Cloud from 'assets/images/v2/ico_cloud.png';
import Clock from 'assets/images/v2/ico_clock_icon.png';
import Status from 'assets/svgs/v2/ico_status.svg';
import EventName from 'assets/svgs/v2/ico_event_name.svg';
import Information from 'assets/svgs/v2/ico_information.svg';
import EventSetting from 'assets/svgs/v2/ico_event_setting.svg';
import ClockCircle from 'assets/images/v2/ico_redcircle_cloud.png';
import SendEventAlertModal from 'components/v2/modals/SendEventAlertModal';
import { useToast } from 'hooks/v2/useToast';
import InfoCard from '../../../Project/components/cards/InfoCard';
import { HistEventByParamType } from 'graphql/types/HistEventByParam';
import lazyGetHistAlert from 'graphql/queries/getHistAlert';
import { ErrorCode } from '@Types/error';
import sendHistEventAlert from 'graphql/mutations/sendHistEventAlert';
import updateHistEvent from 'graphql/mutations/updateMyHistEvent';
import Memo from 'components/v2/Memo';
import { MemoInfo } from 'graphql/types/MemoInfo';
import lazyGetMemos from 'graphql/queries/getMemos';
import addMemo from 'graphql/mutations/addMemo';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import CurrentStatusCard from '../CurrentStatusCard';
import { ResultHistEventAlertType, SendHistEventAlertRespData } from '@Types/v2/Report';
import SendEventAlertInformationModal from 'components/v2/modals/SendEventAlertInformationModal';
import { HistAlertType } from 'graphql/types/HistAlertType';

interface IEventHistoryDetailProps {
  data: HistEventByParamType;
  relObjectType: 'report_w' | 'report_m' | 'event_ri';
  getOffPage: () => void;
  onCompleteSuccess: () =>void;
}

const EventHistoryDetail = ({
  data,
  relObjectType,
  getOffPage,
  onCompleteSuccess
}:IEventHistoryDetailProps) => {
  
  const [rows, setRows] = useState<HistAlertType[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState({
    sendEventAlert: false,
    sendResult: false
  });
  const [resultEventAlert, setResultEventAlert] = useState<ResultHistEventAlertType>({
    sentAt: '',
    title: '',
    contents: '',
    receiversInfo: [],
    sendTypeCode: '',
    senderName: '',
  });
  const [memo, setMemo] = useState<MemoInfo[]>([]);
  const [memoSaveTrigger, setMemoSaveTrigger] = useState(false);

  /* 알림 발송 내역 조회 */
  const [lazyGetHistAlertList] = lazyGetHistAlert();
  /* 완료처리 */
  const [updateComplete] = updateHistEvent();
  /* 알림 발송 */
  const [sendAlert] = sendHistEventAlert();
  /* memo 요청사항 조회 */
  const [lazyGetMemo] = lazyGetMemos();
  /* memo 요청사항 저장 */
  const [addMemos] = addMemo();

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: '알림 발송 일시',
        field: 'sentAt',
        renderCell: (row: RowType) => <>{ !row.sentAt ? '-' : dateFormatter(row.sentAt, 'datetime')}</>
      },
      {
        label: '알림 제목',
        field: 'title',
      },
      {
        label: '알림 내용',
        field: 'contents',
      },
      {
        label: '알림 형태',
        field: 'sendTypeCode',
        renderCell: (row: RowType) => <>{enumFormatter(row.sendTypeCode === 'email' ? 'email000' : 'sms00000')}</>
      },
      {
        label: '발송자',
        field: 'senderName',
      }
    ];
  }, []);

  const msgJson = useMemo(() => {
    try {
      const json = JSON.parse(data.histEvent.detEventMessage);

      return JSON.stringify(json, null, 4);
    } catch {
      return data.histEvent.detEventMessage;
    }
  },[data]);

  const getMemo = () => {
  lazyGetMemo({
      variables: {
        reqGetMemo: { relObjectId: data.histEvent.id, relObjectType: relObjectType }
      }
    }).then(({ data:memoData }) => {
      if (memoData) {
        if (memoData.getMemos.data.length === 0) {
          addMemos({ variables: { memo: { relObjectId: data.histEvent.id, relObjectType: relObjectType,
            text: '', checkYn: true
          } } }).then(({ data:newMemoData }) => {
            if (newMemoData) {
              setMemo(newMemoData.addMemo.data);
            }
          });
        }
        else {
          setMemo(memoData.getMemos.data);
        }
      }
    });
  };

  const getHistAlertByHistEventId = () => {
    const getHistAlertByHistEventIdData = {
      histEventId: data.histEvent.id 
    };

    lazyGetHistAlertList({ variables: getHistAlertByHistEventIdData }).then(({ data }) => {
      if (data) {
        if (data.getHistAlertByHistEventId.result === ErrorCode.SUCCESS) {
          setRows(data.getHistAlertByHistEventId.data);
        } else {
          console.log(data.getHistAlertByHistEventId.result);
        }
      }
    });
  }

  useEffect(() => {
    getHistAlertByHistEventId();
    getMemo();

    return () => {
      const ct_list = document.querySelectorAll('.ct');

      if (ct_list) {
        ct_list.forEach(val => val.remove());
      }
    }
  }, []);

  useEffect(() => {
    const eventDetailPageEl = document.getElementById('event-detail-page');
    const eventHistoryPageEl = document.getElementById('event-history-page');

    if (eventDetailPageEl && eventHistoryPageEl) {
      if (eventHistoryPageEl.offsetHeight <= eventDetailPageEl.scrollHeight) {
        eventHistoryPageEl.classList.remove('none-scroll');
      } else {
        eventHistoryPageEl.classList.add('none-scroll');
      }
    }

    return () => eventHistoryPageEl?.classList.remove('none-scroll');
  }, [rows.length]);

  return (
    <div id="event-detail-page">
      
      <div className="header flex a-center">
        <button 
          className="back-btn"
          onClick={() => getOffPage()}
        />
        이벤트 이력 상세
      </div>
      <div className="title-wrap flex j-between">
        <p>{data.eventSetting?.name ? data.eventSetting.name : '이벤트 이력 상세'}</p>
        <div className="flex a-center">
          <button 
            className="big-sub-btn flex j-center a-center"
            onClick={() => setModalIsOpen(prev => ({
              ...prev,
              sendEventAlert: true
            }))}
          >
            알림 발송
          </button>
          <button 
            className="big-main-btn flex j-center a-center"
            onClick={() => {
              const updateHistEventData = {
                req: {
                  id: data.histEvent.id,
                  histEventStatus: 'complete'
                }
              };
              updateComplete({ variables: updateHistEventData}).then(({data}) => {
                if (data) {
                  if (data.updateHistEvent.result === ErrorCode.SUCCESS) {
                    useToast(ErrorCode.SUCCESS, '이벤트를 완료처리 했습니다.');
                    onCompleteSuccess();

                  } else {
                    console.log(data.updateHistEvent.result);
                    useToast(ErrorCode.UNKNOWN, '이벤트 완료처리를 실패했습니다.');
                  }
                } else {
                  useToast(ErrorCode.UNKNOWN, '이벤트 완료처리를 실패했습니다.');
                }
              })
            }}
          >
            완료 처리
          </button>
        </div>
      </div>

      <div className="content-wrap">
        <div className="information flex j-start">
          <div className="flex col">
            <InfoCard 
              icon={
                <Icon 
                  width={32} 
                  height={32} 
                  src={Clock} 
                />
              } 
              title="알림 발송 상태"
              content={() => <>{enumFormatter(data.histEvent.alarmStatus)}</> } 
            />
            <InfoCard 
              icon={
                <Icon 
                  width={32} 
                  height={32} 
                  src={Status} 
                />
              } 
              title="이벤트 상태"
              content={() => <CurrentStatusCard status={data.histEvent.histEventStatus} /> } 
            />
            <InfoCard 
              icon={
                <Icon 
                  width={32} 
                  height={32} 
                  src={EventName} 
                />
              } 
              title="이벤트 이름"
              content={() => <>{data.event?.name ? data.event.name : '-'}</> } 
            />
          </div>
          <div className="flex col">
            <InfoCard 
              icon={
                <Icon 
                  width={32} 
                  height={32} 
                  src={Calender}
                />
              } 
              title="발생 일시"
              content={() => <>{dateFormatter(data.histEvent.createdAt, 'datetime')}</> } 
            />
            <InfoCard 
              icon={
                <Icon 
                  width={32} 
                  height={32} 
                  src={Cloud}  
                />
              } 
              title="클라우드"
              content={() => <>CSP: {data.cloud?.cloudKind ? enumFormatter(data.cloud.cloudKind) : '-'} / 계정: {data.cloudInfo?.rootAccount ? data.cloudInfo.rootAccount : '-' } </> } 
            />
            <InfoCard 
              icon={
                <Icon  
                  width={32} 
                  height={32} 
                  src={EventSetting} 
                />
              } 
              title="이벤트 설정 정보"
              content={() => <>{data.eventSetting?.name ? data.eventSetting.name : '-'}</> } 
            />
          </div>
        </div>
        <InfoCard 
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Information} 
            />
          } 
          title="수집 정보 "
          content={() => 
              <pre className='acq-information'>{msgJson}</pre> 
          } 
        />
        <div className="memo-wrap">
          <div className="memo-header flex a-center">
            <div className="star-icon" />
            <h5>메모</h5>
          </div>
          <div className="memo-content flex a-end">
            <div className='editor-wrap'>
              <Memo 
                data={memo} 
                histEventId={data.histEvent.id} 
                projectId={data.histEvent.projectId} 
                saveTrigger={memoSaveTrigger} 
                resetSaveTrigger={() => {
                  setMemoSaveTrigger(false);
                }}
              />
            </div>
            <button 
              className="big-main-btn"
              onClick={() => {
                setMemoSaveTrigger(true);
              }}
            >
              메모 저장
            </button>
          </div>
        </div>
      </div>  

      <div className="history-wrap">
        <div className="history-header flex a-center">
          <div className="clock-icon" />
          알림 <span>{rows.length}</span>
        </div>
        <div className="content">
          {
            rows.length === 0 
              ? <div className="empty flex col j-center a-center">
                <div className="clock-img" />
                아직 발송된 알림이 없습니다.
              </div> 
              : <div className="data-grid-wrap"> 
                <Table
                  rows={rows}
                  columns={COLUMNS}
                  numberOption={1}
                  reportSelected={id => { 
                    const find = rows.find(val => val.id === Number(id));
                    if(find === undefined) return;

                    const receiv = find.receiversName.split(',')
                    setResultEventAlert({
                      sentAt: find.sentAt,
                      title: find.title,
                      contents: find.contents,
                      receiversInfo: receiv,
                      sendTypeCode: find.sendTypeCode,
                      senderName: find.senderName ? find.senderName : 'null dummy',
                    })
                    setModalIsOpen(prev => ({
                      ...prev,
                      sendResult: true
                    }));
                  }}
                />
              </div> 
          }
        </div>
      </div>

      {/* 알림 발송 모달 */}
      <SendEventAlertModal 
        open={modalIsOpen.sendEventAlert}
        data={{
          reqData: {
            projectId: data.histEvent.projectId,
            id: data.histEvent.id,
            settingId: data.histEvent.settingId
          }
        }}
        projectName={data.histEvent.projectName}
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          sendEventAlert: false
        }))}
        onSend={(dataPayload: {
          sendTypeCode: string;
          title: string;
          contents: string;
          members: {
            memberId: string;
            memberName: string;
          }[];
        }) => {
          const sendHistEventAlertData = {
            reqData: {
                sendTypeCode: dataPayload.sendTypeCode,
                title: dataPayload.title,
                contents: dataPayload.contents,
                histEventId: data.histEvent.id,
                memberIds: dataPayload.members.map(val => val.memberId)
            }
          };

          sendAlert({ variables: sendHistEventAlertData}).then(({ data }) => {
            if (data) {
              if (data.sendHistEventAlert.result === ErrorCode.SUCCESS) {
                getHistAlertByHistEventId();
                setModalIsOpen({
                  sendEventAlert: false,
                  sendResult: true
                });

                if (data.sendHistEventAlert.data) {
                  const dataResult: SendHistEventAlertRespData = data.sendHistEventAlert.data[0];

                  setResultEventAlert({
                    sentAt: dataResult.sentAt,
                    title: dataResult.title,
                    contents: dataResult.contents,
                    receiversInfo: dataResult.receiversName.split(','),
                    sendTypeCode: dataResult.sendTypeCode,
                    senderName: dataResult.senderName
                  })
                }
              } else {
                console.log(data.sendHistEventAlert.result);
                useToast(ErrorCode.UNKNOWN, '알림 발송에 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, '알림 발송에 실패했습니다.');
            }
          });
        }}
        title={() => 
          <>
            <Icon width={32} height={32} src={ClockCircle} />
            알림 발송
          </>
        } 
      />

      {/* 알림 발송 정보 */}
      <SendEventAlertInformationModal
        open={modalIsOpen.sendResult}
        data={resultEventAlert}
        onClose={() => { 
          setModalIsOpen(prev => ({
            ...prev,
            sendResult: false
          }))
          setResultEventAlert({
            sentAt: '',
            title: '',
            contents: '',
            receiversInfo: [],
            sendTypeCode: '',
            senderName: '',
          })
        }} 
        title={ () =>
          <>
            <Icon width={32} height={32} src={ClockCircle} />
            알림 발송 정보
          </>
        }
      />
    </div>
  );
};

export default EventHistoryDetail;
