import { useMemo, useState } from 'react';
import './index.scss';
import Verify from './Verify';
import ResetPw from './ResetPw';
import ResetDone from './ResetDone';

/** <>비밀번호 재설정 이메일 인증 결과 + 비밀번호 재설정 진행</> */
const ResetPwVerify = () => {
  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep(prev => prev + 1);
  };

  const renderBody = useMemo(() => {
    switch (step) {
    case 0:
      return <Verify nextStep={nextStep} />;
    case 1:
      return <ResetPw nextStep={nextStep} />;
    case 2:
      return <ResetDone />;
    default:
      return <></>;
    }
  },[step]);
  
  return renderBody;
};
export default ResetPwVerify;