import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { MemberInfo } from 'apis/v2/Member/schema';

export interface IGetMemberByIdVariables {
  memberId: string;
}

export interface IGetMemberByIdResponseData {
  getMemberById: IGqlResponseData<MemberInfo[]>;
}

const lazyGetMemberById = () => useLazyQuery<IGetMemberByIdResponseData, IGetMemberByIdVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMemberById;