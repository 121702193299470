import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { EventSettingPageType } from 'graphql/types/EventSettingPage';

export interface IGetEventSettingPageVariables {
  reqGetEventSettingPage: {
    organId: number;
    text?: string;
    priority?: string;
    pageInfo: Paging;
  }
}

export interface IGetEventSettingPageResponseData {
  getEventSettingPageByParam: ResponsePage<EventSettingPageType>;
}

const lazyGetEventSettingPage = () => useLazyQuery<IGetEventSettingPageResponseData, IGetEventSettingPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetEventSettingPage;