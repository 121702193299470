import { gql } from '@apollo/client';

const query = gql`query getMyEvent($eventId: Long) {
  getEventById(eventId: $eventId) {
    __typename
    result
    data {
      ... on Event {
        eventId
        maskCode
        name
        createdAt
        createdByName
      }
    }
  }
}`;
export default query;