import { gql } from '@apollo/client';

// const query = gql`
//   query getProjectPage($reqGetProjectPage: ReqGetProjectPage) {
//     getProjectInfoPage(reqGetProjectPage: $reqGetProjectPage) {
//       __typename
//       first
//       last
//       number
//       size
//       numberOfElements
//       totalPages
//       totalElements
//       content {
//         __typename
//         ... on ProjectInfo {
//           __typename
//           projectId
//           organId
//           name
//           thirdPartTypeCode
//           attrKey1
//           attrValue1
//           attrKey2
//           attrValue2
//           attrKey3
//           attrValue3
//           useYn
//           createdAt
//           createdBy
//           createdByName
//           modifiedAt
//           modifiedBy
//           modifiedByName
//         }
//       }
//     }
//   }
// `;

// export default query;

const query = gql`
  query getProjectInfoPage($reqData: ReqGetProjectPage) {
    getProjectInfoPage(reqGetProjectPage: $reqData) {
      __typename
      first
      number
      numberOfElements
      totalPages
      totalElements
      result
      content {
        ... on Project {
          projectId
          organId
          name
          thirdPartTypeCode
          attrKey1
          attrValue1
          attrKey2
          attrValue2
          attrKey3
          attrValue3
          createdAt
          createdBy
          createdByName
          modifiedAt
          modifiedBy
          modifiedByName
          __typename
          createdByUserId
          modifiedByUserId
        }
      }
    }
  }
`;

export default query;