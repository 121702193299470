import './index.scss';

import EmptyProjectCard from '../cards/EmptyProjectCard';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { useOutletContext } from 'react-router-dom';

const EmptyPage = () => {
  const { openCreatePJModal } = useOutletContext<{ openCreatePJModal: () => void }>();

  return (
    <div id="empty-page">
      <div className="row-1 flex j-end a-center">
        <button disabled className="setting-btn" />
      </div>
      <EmptyProjectCard 
        className="page" 
        openCreatePJModal={openCreatePJModal}
      />

      <div className="pagination-wrapper flex a-center">
        <p className="flex a-center">전체 <span>0개</span></p>
        <TablePagination 
          currentPage={1}
          totalPage={1}
        />
      </div>
    </div>
  );
};

export default EmptyPage;
