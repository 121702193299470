import { useEffect, useMemo, useState } from 'react';
import SelectBox from 'components/UI/SelectBox';
import Button from 'components/UI/Button';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';
import getEnumTypeCode from 'graphql/queries/getEnumTypeCode';
import { ProjectMemberPageType } from 'graphql/types/ProjectMemberPage';
import updateProjectMember from 'graphql/mutations/updateProjectMember';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import '../index.scss';

interface IUpdateManagerModalProps{
  projectId: number;
  memberData: ProjectMemberPageType | undefined;
  onClose: () => void;
  onConfirm: () => void;
}

const alertMenu = [
  { name: '예', value: 'true' },
  { name: '아니오', value: 'false' }
];

const UpdateManagerModal = ({ projectId, memberData, onClose, onConfirm }: IUpdateManagerModalProps) => {
  const [selectAlrim, setSelectAlrim] = useState<{ name:string, value: string | number }>({ name: '', value: '' });
  const [selectLevel, setSelectLevel] = useState<{ name:string, value: string | number }>({ name: '', value: '' });

  const { data: roleTypeCodeList } = getEnumTypeCode({
    text: 'RoleCode'
  });
  const [updateMember] = updateProjectMember();

  const memoizedRoleMenu = useMemo(() => {
    return roleTypeCodeList?.getEnumTypeCode.data.map(d => ({ ...d, name: enumFormatter(d.value) || d.name })) || [];
  },[roleTypeCodeList]);
  const handleUpdate = () => {
    if (memberData) {
      updateMember({
        variables: {
          reqUpdProjectMember: {
            memberId: memberData.id,
            projectId: projectId,
            roleCode: selectLevel.value.toString(),
            // smsAlertYn: false,
            // emailAlertYn: false
            alertYn: selectAlrim.value === 'true'
          }
        }
      }).then(({ data }) => {
        if (data) {
          if (data.updateProjectMember.result === ErrorCode.SUCCESS) {
            useSnackbar(data.updateProjectMember.result);
            onConfirm();
          } else {
            useSnackbar(data.updateProjectMember.result);
          }
        } else {
          useSnackbar(ErrorCode.UNKNOWN);
        }
      });
    }
  };
  useEffect(() => {
    setSelectLevel(memoizedRoleMenu.find(d => d.value === memberData?.roleCode) || { name: '', value: '' });
    setSelectAlrim(alertMenu.find(d => d.value === memberData?.alertYn.toString()) || { name: '', value: '' });
  },[memberData, memoizedRoleMenu]);
  
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="modal-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span/>
          <p>
            담당자 변경
          </p>
        </div>
        <div className="contents-wrap">
          <p className="title">담당자의 권한 및 알람 정보를 수정합니다.</p>
          <div className="row">
            <p className="row-title">소속</p>
            <p>{memberData?.organName}</p>
          </div>
          <div className="row">
            <p className="row-title">이름</p>
            <p>{memberData?.fullName}</p>
          </div>
          <div className="row">
            <p className="row-title">권한 설정</p>
            <SelectBox id="updatemanagrole" style="manager" data={memoizedRoleMenu} handleClick={(val) => setSelectLevel(val)} value={selectLevel}/>
          </div>
          <div className="row">
            <p className="row-title">알림 설정</p>
            <SelectBox id="updatemanagalert" style="manager" data={alertMenu} handleClick={(val) => setSelectAlrim(val)} value={selectAlrim}/>
          </div>
          <p className="title" style={{ marginTop: 26 }}>선택한 사용자를 확인하세요.</p>
          
          <div className="row">
            <p className="row-title">ID</p>
            <p>{memberData?.userId}</p>
          </div>
          <div className="row">
            <p className="row-title">등록 일시</p>
            <p>{dateFormatter(memberData?.createdAt || '', 'datetime')}</p>
          </div>
          <div className="modal-btn-wrap">
            <Button disabled={selectAlrim.value === '' || selectLevel.value === ''} text={'수정'} handleClick={handleUpdate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateManagerModal;