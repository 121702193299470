import { useEffect, useState } from 'react';
import './index.scss';
import { useSearchParams } from 'react-router-dom';
import Footer from 'components/v2/Footer';
import EmailIcon from 'assets/svgs/v2/ico_email_verify.svg';
import { useMutation } from 'react-query';
import apis from 'apis/v2/index';
import { ErrorCode } from '@Types/error';
import { IPostVerifyRes } from 'apis/v2/User/schema';
import { useAuth } from 'contexts/AuthProvider';

/** <>회원가입 이메일 인증 결과 -> 이후 랜딩페이지로 이동(signup 상태인 경우) / 이후 새 조직만들기 페이지로 이동(로그인 후 이메일 verify 이어서 하게 될 경우)</> */
const SignupVerify = () => {
  const [searchParams] = useSearchParams();
  const { login, logout } = useAuth();

  const [msg, setMsg] = useState('');
  const [result, setResult] = useState<IPostVerifyRes>();

  const authToken = searchParams.get('authToken');
  
  const { mutateAsync: _checkToken } = useMutation(
    (token: string) => apis.User.verify(token)
  );

  const onClickSubmit = () => {
    if (result) {
      if (result.result === ErrorCode.SUCCESS) {
        login(result.data[0].userToken, true);
      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    if (authToken) {
      _checkToken(authToken).then(({ data }) => {
        if (data.result === ErrorCode.SUCCESS) {
          setMsg('이메일 인증이 완료됐습니다.');
          setResult(data);
        } else if (data.result === ErrorCode.EMAIL_TOKEN_ERROR) {
          setMsg('이미 인증 시간이 만료되었습니다.<br />다시 본인 인증을 진행해 주세요.');
        } else {
          setMsg('올바르지 않은 접근입니다.');
        }
      }).catch(() => setMsg('올바르지 않은 접근입니다.'));
    } else {
      logout();
    }
  },[]);

  return (
    <>
      <article id="signup-verify-article">
        <img src={EmailIcon} width={56} height={56} />
        <h2 dangerouslySetInnerHTML={{ __html: msg }} />
        <button 
          onClick={onClickSubmit} 
          className="big-main-btn"
        >
          확인
        </button>
      </article>
      <Footer />
    </>
  );
};
export default SignupVerify;