import { useLazyQuery, useQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { EventSelectType } from 'graphql/types/EventSelect';

export interface IGetMyEventByIdVariables {
  eventId: number
}

export interface IGetMyEventByIdResponseData {
  getEventById: IGqlResponseData<EventSelectType[]>;
}

const lazyGetMyEventById = () => useLazyQuery<IGetMyEventByIdResponseData>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMyEventById;