import { AxiosInstance, AxiosResponse } from 'axios';
import { IGetTermsReq, IGetTermsRes } from './schema';
import { BaseDummyRes } from '..';
import { ErrorCode } from '@Types/error';

const getTerms = (instance: AxiosInstance) => (data: IGetTermsReq):Promise<AxiosResponse<IGetTermsRes, IGetTermsReq>> => {
  return instance.request({
    method: 'POST',
    url: '/term/getTerms',
    data: data
  });
};

const Terms = (instance: AxiosInstance) => ({
  getTerms: getTerms(instance)
});
export default Terms;