import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { TermsInfo } from 'apis/v2/Manage/schema';

export interface IGetTermsPageByParamVariables {
  reqData: {
    kindCode?: string; /* 약관 종류 */
    text?: string;
    pageInfo: Paging;
  }
}

export interface IGetTermsPageByParamResponseData {
  getTermsPageByParam: ResponsePage<TermsInfo>;
}

const lazyGetTermsPageByParam = () => useLazyQuery<IGetTermsPageByParamResponseData, IGetTermsPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetTermsPageByParam;