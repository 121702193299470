import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IAddProjectMemberVariables {
  reqAddProjectMember: {
    memberId: string;
    projectId: number;
    roleCode: string;
    alertYn: boolean;
  }
}

export interface IAddProjectMemberResponseData {
  addProjectMember: IGqlResponseData<undefined>;
}

const addProjectMember = () => useMutation<IAddProjectMemberResponseData, IAddProjectMemberVariables>(query);
export default addProjectMember;