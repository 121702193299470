import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { HistEventByParamType } from 'graphql/types/HistEventByParam';

export interface IGetMyHistEventByParamVariables {
  reqData: {
    projectId?: number;
    startDateTime?: string;
    endDateTime?: string;
    alarmStatus?: string;
    cloudInfold?: number;
    detEventName?: string;
    pageInfo: Paging;
  }
}

export interface IGetMyHistEventByParamResponseData {
  getHistEventPageByParam: ResponsePage<HistEventByParamType>;
}

const lazyGetMyHistEventByParam = () => useLazyQuery<IGetMyHistEventByParamResponseData, IGetMyHistEventByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMyHistEventByParam;