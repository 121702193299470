import { useState } from 'react';

interface ISelectBoxCustomListProps<T> {
  data: T[];
  handleClick: (val: T) => void;
  value: T | null;
  listComp: (val: T) => JSX.Element;
  valueComp: (val: T) => JSX.Element;

  style?: string;
  placeholder?: string;
  disabled?: boolean;
}

const SelectBoxCustomList = <T,> ({
  data,
  handleClick,
  value,
  listComp,
  valueComp,
  style,
  placeholder,
  disabled = false
}: ISelectBoxCustomListProps<T>) => {
  const [open, setOpen] = useState(false);
    
  return (
    <div className={`selectbox-wrap ${style}`}>
      <div className={`selectbox-value ${placeholder && value === null ? 'placeholder' : ''}`} onClick={() => setOpen(!open)}>
        <p>{value === null ? placeholder || '' : valueComp(value)}</p>
        <span />
      </div>
      {!disabled && open && <div className="selectbox-list">
        {data.map((val, idx) => (
          <div className="list-item" key={idx} onClick={() => {
            handleClick(val); setOpen(false);
          }}>{listComp(val)}</div>
        ))}
      </div>}
    </div>
  );
};
export default SelectBoxCustomList;