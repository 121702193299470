import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IJoinProjectVariables {
  reqData: {
    organId: number;
    projectIds: number[];
  }
}

export interface IJoinProjectResponseData {
  joinProject: IGqlResponseData<{userToken: string}[]>;
}

const joinProject = () => useMutation<IJoinProjectResponseData, IJoinProjectVariables>(query);
export default joinProject;