import { RowType } from '@Types/v2/Table';
import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';
import { dateFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import EventSettingIcon from 'assets/svgs/v2/ico_event_setting.svg';
import KeyIcon from 'assets/svgs/v2/ico_key.svg';
import Register from 'assets/svgs/v2/ico_register.svg';
import Edit from 'assets/svgs/v2/ico_edit.svg';
import { numberRegex } from 'utils/Regex';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import updateEventRule from 'graphql/mutations/updateEventRule';

interface IEventRuleDetailProps {
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const EventRuleDetail = ({
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: IEventRuleDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState({
    eventId: -1,
    name: '', /* 이벤트 설정 이름 */
    maskCode: '' /* 고유 구분자 */
  });

  /* 이벤트 규칙 수정변경 요청 */
  const [editEventRule] = updateEventRule();
  
  const origin = useMemo(() => {
    return {
      eventId: Number(data.id),
      name: data.name,
      maskCode: data.maskCode
    }
  }, []);

  useEffect(() => {
    setDetail({
      eventId: Number(data.id),
      name: data.name,
      maskCode: data.maskCode
    });
  }, []);

  return (
    <div className='eventSetting-detail'>
      <div className='detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? detail.name : '이벤트 규칙 수정'}
      </div>
      <div className='detail-contents'>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={EventSettingIcon} 
            />
          } 
          title="이벤트 설정 이름" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.name
                : <InputAtom
                  value={detail.name}
                  placeholder='이벤트 설정 이름 입력'
                  onChangeValue={(val) => setDetail((prev) => ({
                    ...prev,
                    name: val
                  }))}
                />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={KeyIcon}
            />
          } 
          title="고유 구분자" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.maskCode
                : <InputAtom
                    value={detail.maskCode}
                    placeholder='고유 구분자 입력(마스크코드 8자리 숫자)'
                    onChangeValue={(val) => {
                      if (val.length > 8) return;

                      setDetail((prev) => ({
                        ...prev,
                        maskCode: val.replace(numberRegex, '')
                      }))
                    }
                  }
                  />
              }
            </>
          } 
        />
        {
          !edit &&
        <>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Register} 
            />
          } 
          title="등록"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Edit} 
            />
          } 
          title="마지막 수정"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
        </>
        }
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                수정
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                삭제
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                취소
              </button>
              <button 
                className="big-main-btn"
                disabled={ detail.name === '' || detail.maskCode.length < 8 }
                onClick={() => { 
                  const updEventData = {
                    reqData: {
                      ...detail
                    }
                  }
                  editEventRule({ variables: updEventData }).then(({ data }) => {
                    if (data) {
                      if (data.updateEvent.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, '이벤트 규칙이 수정되었습니다.');
                        setEdit(false);
                        onEditSuccess();
                      } else {
                        console.log(data.updateEvent.result);
                        useToast(ErrorCode.UNKNOWN, '이벤트 규칙 수정을 실패했습니다.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, '이벤트 규칙 수정을 실패했습니다.');
                    }
                  })
                }}
              >
                수정하기
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default EventRuleDetail;