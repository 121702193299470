import { useState } from 'react';
import './index.scss';
import { Favorites } from '@Types/v2/Lnb';
import { useOutletContext } from 'react-router-dom';
import { useAuth } from 'contexts/AuthProvider';

interface INotFoundFavoriteProps {
  paramId?: string;
}

/* 서버에서는 삭제되었으니 로컬에는 잔류했을 경우 보일 페이지 */
const NotFoundFavorite = ({
  paramId
}:INotFoundFavoriteProps) => {

  const { userInfo } = useAuth();
  const { 
    organizationId,
    favoriteList, 
    updateFavoriteList 
  } = useOutletContext<{ 
    organizationId: number;
    favoriteList: { id: number; name: string;}[]
    updateFavoriteList: (arr: { id: number; name: string;}[]) => void
  }>();

  const [btnOff, setBtnOff] = useState(false);
  
  return (
    <div id="notfound-favorite-page">
      <div className="flex col a-center">
        <div className="favorite-icon" />
        <p>{ !btnOff ? '해당 프로젝트는 이미 삭제되었습니다.' : '다른 프로젝트를 선택하거나, 프로젝트를 생성해주세요.'}</p>
        {
          !btnOff &&
          <button 
            disabled={btnOff}
            className="big-main-btn"
            onClick={() => {
              setBtnOff(true);
              
              const getFavorites = localStorage.getItem('favorites');
              if (!getFavorites) return;
              const favorites:Favorites = JSON.parse(getFavorites);
              const findMyOrgan = favorites.find(val => val.organId === organizationId)

              if (findMyOrgan === undefined) return;
              else {
                const findSub = findMyOrgan.sub.find(val => val.memberId === userInfo?.nemo.memberId)
                if (findSub === undefined) return;

                const findPjId = findSub.list.find(val => val.id === Number(paramId))
                if (findPjId === undefined) return;
                
                const leftList = favoriteList.filter(val => val.id !== findPjId.id);
                updateFavoriteList(leftList);
                const copyFindMyOrgan = findMyOrgan.sub.findIndex(val => val.memberId === userInfo?.nemo.memberId);
                findMyOrgan.sub[copyFindMyOrgan].list = leftList
                localStorage.setItem('favorites', JSON.stringify(favorites));
              }
            }}
          >
            즐겨찾기 해제하기
          </button>
        }
      </div>
    </div>
  );
};

export default NotFoundFavorite;