import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { TermsInfo } from 'apis/v2/Manage/schema';

export interface IGetTermsByIdPageVariables {
  termsId: number;
}

export interface IGetTermsByIdResponseData {
  getTermsById: IGqlResponseData<TermsInfo[]>;
}

const lazyGetTermsById = () => useLazyQuery<IGetTermsByIdResponseData, IGetTermsByIdPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetTermsById;