import { useEffect, useMemo, useState } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { ErrorCode } from '@Types/error';
import PasswordTitleIcon from 'assets/svgs/v2/ico_pw_title.svg';
import EmailTitleIcon from 'assets/svgs/v2/ico_mail_title.svg';
import LabelInput from 'components/v2/LabelInput';
import VerticalSplitLinkGroup from 'components/v2/VerticalSplitLinkGroup';
import { useToast } from 'hooks/v2/useToast';
import { termLinks } from 'utils/Links';
import { useMutation } from 'react-query';
import apis from 'apis/v2/index';
import { useAuth } from 'contexts/AuthProvider';

/** <>비밀번호 재설정</> */
const ResetPw = () => {

  const { logout } = useAuth();
  
  const [input, setInput] = useState('');
  const [step, setStep] = useState(0);
  const [timer, setTimer] = useState(0);

  const { mutateAsync: _requestPw } = useMutation((userId: string) => apis.Auth.reqReset(userId));
  
  const disabledNextBtn = useMemo(() => {
    if (input.length === 0) {
      return true;
    } else {
      return false;
    }
  },[input]);
  
  const tryResetPw = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    _requestPw(input).then(({ data }) => {
      if (data.result === ErrorCode.SUCCESS) {
        setStep(1);
      } else {
        useToast(ErrorCode.UNKNOWN, '해당 유저가 존재하지 않습니다.');
      }
    });
  };
  const sendEmail = () => {
    _requestPw(input);
    useToast(ErrorCode.SUCCESS, '인증 메일이 재발송 됐습니다.');
  };
  const startTimer = () => {
    sendEmail();
    setTimer(20);
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {setTimer(prev => prev - 1);},1000);
    }
  },[timer]);

  return (
    <>
      {step === 0 ? 
        <article id="reset-pw-page-article">
          <h2><img src={PasswordTitleIcon} width={32} height={32} />비밀번호 재설정</h2>
          <h3>아이디 또는 이메일을 입력하면, 비밀번호를 다시 설정 할 수 있습니다.</h3>
          <form onSubmit={tryResetPw}>
            <LabelInput
              title="아이디 또는 이메일" 
              id="reset-pw-id" 
              placeholder="아이디 또는 이메일 입력"
              defaultValue={input} 
              onChangeValue={(value: string) => setInput(value)} 
            />
            <button className="big-main-btn submit-btn" disabled={disabledNextBtn}>다음</button>
          </form>
          <Link to={'/auth'}>로그인으로 돌아가기</Link>
        </article> :
        <>
          <article id="reset-pw-page-article" className="step2">
            <h2><img src={EmailTitleIcon} width={32} height={32} />이메일을 확인해 주세요</h2>
            <h3>
              인증 메일을 보냈어요.<br />
              {'받은 이메일을 열어 \'인증하기\' 버튼을 클릭하세요.'}
            </h3>
            <h4>
              혹시 인증 메일을 받지 못하셨나요?<br />
              {'1) 가입하지 않은 사용자일 경우 이메일이 발송되지 않아요.'}<br />
              {'2) 스팸 메일함에서 인증 메일을 확인해 보세요.'}
            </h4>
            <div className="button-group">
              <button 
                className="big-sub-btn" 
                type="button" 
                onClick={startTimer} 
                disabled={timer > 0}
              >
                {timer > 0 ? `인증 메일 다시 보내기 (${timer}s)` : '인증 메일 다시 보내기'}
              </button>
              <button
                className='big-main-btn'
                onClick={() => logout()}
              >
                로그인 하기
              </button>
            </div>
          </article>
          <VerticalSplitLinkGroup list={termLinks} />
        </>
      }
    </>
  );
};
export default ResetPw;