import { useEffect, useState } from 'react';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import LabelInput from 'components/v2/LabelInput';
import { checkEmail } from 'utils/v2/ValidCheck';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';
import { useMutation } from 'react-query';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

interface IChangeEmailModal extends IBaseModalProps {
  existingEmail: string;
  title: () => JSX.Element;
  onSendSuccess: () => void;
}

const ChangeEmailModal = ({
  existingEmail,
  title,
  onSendSuccess,
  ...baseModalProps
}: IChangeEmailModal) => {

  const { token } = useAuth();

  const [email, setEmail] = useState('');
  const [errMsg, _setErrMsg] = useState('');

  /* 이메일 변경 인증 이메일 전송 */
  const { mutateAsync: _reqUpdateEmail } = useMutation((email: string) => apis.User.reqUpdateEmail(token, email));

  useEffect(() => {
    if (!baseModalProps.open) {
      setEmail('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={title} 
      {...baseModalProps}
    >
      <div className="change-email-modal">
        <LabelInput 
          title="이메일 입력" 
          placeholder="변경할 이메일 입력"
          value={email}
          onChangeValue={(str) => setEmail(str)}
          errorMsg={errMsg}
        />
        <button 
          className="big-main-btn"
          disabled={checkEmail(email)}
          onClick={() => {
            if (existingEmail === email) return useToast(ErrorCode.UNKNOWN, '현재 이용중인 동일한 이메일입니다.');
            _reqUpdateEmail(email).then(({ data }) => {
              if (data.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, '이메일 보내기를 성공하였습니다.');
                onSendSuccess();
              } else {
                if (data.result === ErrorCode.ALREADY_EXISTED_EMAIL) {
                  useToast(ErrorCode.UNKNOWN, '이미 사용중인 이메일입니다.');
                } else {
                  useToast(ErrorCode.UNKNOWN, '이메일 보내기를 실패하였습니다.');
                }
              }
            })
          }}
        >
          인증메일 보내기
        </button>
      </div>
    </BaseModal>
  );
};

export default ChangeEmailModal;
