import { useEffect, useMemo, useState } from 'react';
import SelectBox from 'components/UI/SelectBox';
import '../index.scss';
import Button from 'components/UI/Button';
import lazyGetSearchMember from 'graphql/queries/searchMember';
import { SearchMemberType } from 'graphql/types/SearchMember';
import addProjectMember from 'graphql/mutations/addProjectMember';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';
import getEnumTypeCode from 'graphql/queries/getEnumTypeCode';
import { dateFormatter, enumFormatter } from 'utils/Formatter';

interface ICreateManagerModalProps{
  projectId: number;
  onClose: () => void;
  onSubmit: () => void;
}

const alertMenu = [
  { name: '예', value: 'true' },
  { name: '아니오', value: 'false' }
];

const CreateManagerModal = ({ projectId, onClose, onSubmit }: ICreateManagerModalProps) => {
  const [selectAlrim, setSelectAlrim] = useState<{ name:string, value: string | number }>({ name: '', value: '' });
  const [selectLevel, setSelectLevel] = useState<{ name:string, value: string | number }>({ name: '', value: '' });
  const [search, setSearch] = useState('');
  const [userList, setUserList] = useState<SearchMemberType[]>([]);
  const [selectUser, setSelectUser] = useState<SearchMemberType | null>(null);

  const { data: roleTypeCodeList } = getEnumTypeCode({
    text: 'RoleCode'
  });
  const [lazySearchMember] = lazyGetSearchMember();
  const [addMember] = addProjectMember();

  const memoizedRoleMenu = useMemo(() => {
    return roleTypeCodeList?.getEnumTypeCode.data.map(d => ({ ...d, name: enumFormatter(d.value) || d.name })) || [];
  },[roleTypeCodeList]);
  const handleCreate = () => {
    if (selectUser !== null) {
      addMember({
        variables: {
          reqAddProjectMember: {
            memberId: selectUser?.id,
            projectId: projectId,
            roleCode: selectLevel.value.toString(),
            alertYn: selectAlrim.value === 'true'
          }
        }
      }).then(({ data }) => {
        if (data) {
          if (data.addProjectMember.result === ErrorCode.SUCCESS) {
            useSnackbar(data.addProjectMember.result);
            onSubmit();
          } else {
            useSnackbar(data.addProjectMember.result);
          }
        } else {
          useSnackbar(ErrorCode.UNKNOWN);
        }
      });
    }
  };
  useEffect(() => {
    if (search !== '') {
      lazySearchMember({
        variables: {
          text: search
        }
      }).then(({ data }) => {
        if (data && data.searchMember) {
          setUserList(data.searchMember.data);
        }
      });
    }
  },[search]);
  
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="modal-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span/>
          <p>
            담당자 추가
          </p>
        </div>
        <div className="contents-wrap">
          <p className="title">담당자 이름 및 ID로 검색 후에 선택하고 권한을 선택합니다.</p>
          <div className="row">
            <p className="row-title">사용자</p>
            <div className="user-search-wrapper">
              <input type="text" className="input" value={search} onChange={e => {
                setSearch(e.target.value);
                setSelectUser(null);
              }}/>
              {userList.length > 0 && selectUser === null && <div className="search-selectbox-list">
                {userList.map((val, idx) => (
                  <div className="list-item" key={idx} onClick={() => {
                    setSelectUser(val);
                    setSearch('');
                  }}>
                    <div>{val.organName}</div>
                    <div>{val.fullName}</div>
                    <div>{val.userId}</div>
                  </div>
                ))}
              </div>}
            </div>
          </div>
          <div className="row">
            <p className="row-title">권한 설정</p>
            <SelectBox id="createmanagrole" style="manager" data={memoizedRoleMenu} handleClick={(val) => setSelectLevel(val)} value={selectLevel}/>
          </div>
          <div className="row">
            <p className="row-title">알림 설정</p>
            <SelectBox id="createmanagalert" style="manager" data={alertMenu} handleClick={(val) => setSelectAlrim(val)} value={selectAlrim}/>
          </div>
          <p className="title" style={{ marginTop: 26 }}>선택한 사용자를 확인하세요.</p>
          <div className="row">
            <p className="row-title">소속</p>
            <p>{selectUser?.organName}</p>
          </div>
          <div className="row">
            <p className="row-title">이름</p>
            <p>{selectUser?.fullName}</p>
          </div>
          <div className="row">
            <p className="row-title">ID</p>
            <p>{selectUser?.userId}</p>
          </div>
          <div className="row">
            <p className="row-title">등록 일시</p>
            <p>{dateFormatter(selectUser?.createdAt || '', 'datetime')}</p>
          </div>
          <div className="modal-btn-wrap">
            <Button disabled={selectUser === null || selectAlrim.value === '' || selectLevel.value === ''} text={'추가'} handleClick={handleCreate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateManagerModal;