import { useMutation } from '@apollo/client';
import query from './query';
import { ErrorCode } from '@Types/error';

export interface IDeleteReportFileVariables {
  reqDelReportFile: {
    histReportIds: number[];
    forcibly: boolean;
  }
}

export interface IDeleteReportFileResponseData {
  deleteReportFile: {
    result: ErrorCode;
  };
}

/** 리포트 파일 삭제 */
const deleteReportFile = () => useMutation<IDeleteReportFileResponseData, IDeleteReportFileVariables>(query);
export default deleteReportFile;