import { gql } from '@apollo/client';

// const query = gql`
//   mutation addMemo($memo: ReqAddMemo) {
//     addMemo(memo: $memo) {
//       result
//       message
//       data {
//         ... on RespMemo {
//           memoInfos {
//             id
//             relObjectId
//             relObjectType
//             text
//             checkYn
//             createdBy
//             createdByName
//           }
//         }
//       }
//     }
//   }
// `;

const query = gql`
mutation addMemo($memo: ReqAddMemo) {
  addMemo(memo: $memo) {
  	result
    messages
    data {
      ... on Memo {
        id
        relObjectId
        relObjectType
        text
        checkYn
        createdBy
        createdByName
      }
    }
  }
}
`;

export default query;