import { useNavigate } from 'react-router-dom';
import './index.scss';
import FooterComponent from "components/v2/FooterComponent";

const DESC = [
  {
    type: 'service-error',
    title: '서비스에 접속할 수 없습니다.',
    text: () => <>죄송합니다. 지금 이 서비스와 연결할 수 없습니다. <br /> 잠시 후 다시 확인해주세요.</>
  },
  {
    type: 'service-error',
    title: '페이지를 찾을 수 없습니다.',
    text: () => <>지금 입력하신 주소의 페이지는 사라졌거나 다른 페이지로 변경되었습니다. <br /> 주소를 다시 확인해주세요.</>
  }
]


const PageNotFound = () => {

  const navigate = useNavigate();

  return (
    <div id="page-not-found">
      <header className='flex j-between a-center'>
        <div className='header-logo' />
      </header>
      <div className='contents flex j-center a-center'>
        <div className="content-wrap flex col a-center">
          <div className='not-found-icon' />
          <h5>{DESC[1].title}</h5>
          <p>{DESC[1].text()}</p>
          <button 
            className='big-main-btn'
            onClick={() => navigate('/auth')}
          >
            홈으로 이동
          </button>
        </div>
      </div>
      <FooterComponent />
    </div>
  )
}

export default PageNotFound;