import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { SubCategoryInfo } from 'apis/v2/Manage/schema';

export interface IAddSubCategoryVariables {
  reqData: {
    categoryId: number; /* 카테고리 정보 */
    name: string; /* 서브 카테고리 이름 */
    maskCode: string;
    description: string;
    title: string;
    template?: string;
  }
}

export interface IAddSubCategoryResponseData {
  addSubCategory: IGqlResponseData<SubCategoryInfo>;
}

const addSubCategory = () => useMutation<IAddSubCategoryResponseData, IAddSubCategoryVariables>(query);
export default addSubCategory;