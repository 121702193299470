import { PropsWithChildren, useEffect, useState } from 'react';
import './index.scss';

import { ResizableBox } from 'react-resizable';
import Handle from 'assets/svgs/v2/aside_resize_handle.svg';

const AsideLnb = ({ children }:PropsWithChildren) => {

  const [asideWidth, setAsideWidth] = useState<number>(Number(localStorage.getItem('organNavWidth')) || 192);
  const [asideHeight, setAsideHeight] = useState<number>(0);

  const changeAsideWidth = (width: number) => {
    setAsideWidth(width);
    localStorage.setItem('organNavWidth', width.toString());
  };

  useEffect(() => {
    const resizeHandler = () => {
      const element = document.getElementById('organ-aside');

      if (element) {
        setAsideWidth(element.clientWidth);
        setAsideHeight(element.clientHeight);
      }
    };
    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  },[]);
  
  return (
  <aside id="organ-aside"> 
    <ResizableBox
      draggableOpts={{ draggable: true }}
      width={asideWidth}
      height={asideHeight}
      minConstraints={[192, asideHeight]}
      maxConstraints={[400, asideHeight]}
      onResizeStart={e => e.preventDefault()}
      onResize={(_, { size }) => changeAsideWidth(size.width)}
      handle={<img src={Handle} width={8} height={40} className="aside-handle" />}
    >
    <div> 
    <nav>  
      {children}
    </nav>
    </div>
    </ResizableBox>
  </aside>
  )
};

export default AsideLnb;