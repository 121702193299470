import { gql } from '@apollo/client';

const query = gql`mutation updateEventSetting($reqData: ReqUpdateEventSetting) {
  updateEventSetting(reqData: $reqData) {
    __typename
    result
    data {
      __typename
      ... on EventSetting {
        __typename
        
        name
        projectId
        settingId
      }
    }
  }
}`;
export default query;