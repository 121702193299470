import { ErrorCode } from '@Types/error';
import '../index.scss';
import Button from 'components/UI/Button';
import deleteMessage from 'graphql/mutations/deleteMessage';
import { MessagePageType } from 'graphql/types/MessagePage';
import { useSnackbar } from 'hooks/useSnackbar';
import { enumFormatter } from 'utils/Formatter';
import Table, { ColumnType } from 'components/atoms/Table';

const columns:ColumnType<MessagePageType>[] = [
  { 
    name: '문구',
    value: 'message'
  },{ 
    name: '용도',
    value: 'relObjectType',
    width: 80,
    renderCell: (row) => <>{enumFormatter(row.relObjectType)}</>
  },{ 
    name: '수신타입',
    value: 'sendTypeCode',
    renderCell: (row) => <>{enumFormatter(row.sendTypeCode)}</>
  },{ 
    name: '등록자',
    value: 'createdByName',
    width: 130
  }
];

interface IDeleteMessageModalProps {
  onClose: () => void;
  onSubmit: () => void;
  data: MessagePageType[];
}
const DeleteMessageModal = ({ onClose, onSubmit, data }: IDeleteMessageModalProps) => {
  const [deleteMsg] = deleteMessage();
  
  const handleDelete = () => {
    const idArr = data.map((val, index) => {
      return val.id;
    });
    deleteMsg({ variables: { 
      req: {
        messageIds: idArr,
        forcibly: false
      } } }).then(({ data }) => {
      if (data) {
        if (data.deleteMessageByIds.result === ErrorCode.SUCCESS) {
          useSnackbar(data.deleteMessageByIds.result);
          onSubmit();
          onClose();
        }
        else {
          useSnackbar(data.deleteMessageByIds.result as ErrorCode);
        }
      } else {
        useSnackbar(ErrorCode.UNKNOWN);
      }
    });
  };
 
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="delete-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span />
          <p className="title">문구 삭제</p>
        </div>
        <div className="content-wrap">
          <div className="p subtitle">삭제할 문구를 확인하세요.</div>
          <Table 
            rows={data}
            columns={columns}
          />
          <div className="btn-wrap">
            <Button disabled={false} text={'삭제'} handleClick={handleDelete} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteMessageModal;