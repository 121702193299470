import { useMemo } from 'react';
import { ReactComponent as ArrowRight } from 'assets/svgs/ic_arrow_right.svg';
import { ReactComponent as ArrowRightDisabled } from 'assets/svgs/ic_arrow_right_disabled.svg';
import { ReactComponent as ArrowLeft } from 'assets/svgs/ic_arrow_left.svg';
import { ReactComponent as ArrowLeftDisabled } from 'assets/svgs/ic_arrow_left_disabled.svg';
import './index.scss';

/**
 * @prop hidePagination - pagination hide 여부
 * @prop page - 현재 page 숫자(0부터 시작)
 * @prop size - 한 page 에 몇개의row 가 보일 것인지
 * @prop totalPage - table 데이터가 총 몇개의 row 인지
 * @prop onPageChange - page 변경 시 실행되는 함수
 */
export interface IPaginationProps {
  hidePagination?: boolean;
  page?: number;
  size?: number;
  totalPage?: number;
  onPageChange?: (page: number) => void;
}
/**
 * table 이나 list 들의 pagination
 * @returns table 이나 list 들의 pagination JSX.Element
 */
const Pagination = ({
  hidePagination = false,
  page = 0,
  size = 10,
  totalPage = 1,
  onPageChange
}:IPaginationProps) => {
  if (hidePagination) return null;

  const isFirst = useMemo(() => page === 0, [page]);
  const isLast = useMemo(() => ((page + 1) >= totalPage) , [page, totalPage]);

  const onClickButton = (type: 'PREV' | 'NEXT') => () => {
    if (onPageChange) {
      onPageChange(page + (type === 'PREV' ? -1 : 1));
    }
  };

  const { from, to } = useMemo(() => {
    return {
      from: page * size + 1,
      to: (page + 1) * size
    };
  }, [page, size]);
  
  return (
    <div className="datatable-pagination">
      <span>
        {from}-{to} of {totalPage}
      </span>
      <button disabled={isFirst} className="prev" onClick={onClickButton('PREV')}>
        {isFirst ? <ArrowLeftDisabled /> : <ArrowLeft />}
      </button>
      <button disabled={isLast} className="next" onClick={onClickButton('NEXT')}>
        {isLast ? <ArrowRightDisabled /> : <ArrowRight />}
      </button>
    </div>
  );
};

export default Pagination;