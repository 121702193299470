import './index.scss';
import Logo from 'assets/svgs/v2/footer_logo.svg';

/** 
 * 랜딩, 이메일 인증 footer 
*/
const Footer = () => {
  return (
    <footer id="footer">
      <div>
        <img src={Logo} width={120} height={34} />
        <div>
          (주)회사이름  |  대표이사 홍길동<br />
          서울시 강남구 테헤란로 123, 길동타워 1층 | 전화번호: 02-123-4567<br />
          사업자등록번호: 123-45-67890 | 통신판매번호: 2020-서울강남-1234  |  문의: abc@domail.com
        </div>
      </div>
      <div>© 2023 LG CNS, Inc.</div>
    </footer>
  );
};
export default Footer;