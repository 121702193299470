import { useLazyQuery, useQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';

type EnumCodeText = 'DictRelObjectType' |
'MemoRelObjectType' |
'MsgSenderTypeCode' |
'MsgUseTypeCode' |
'ResFormatCode' |
'ResRelObjectType' |
'AlarmStatusCode' |
'FileStatusCode' |
'PriorityCode' |
'ReportTypeCode' |
'HistEventStatusCode' |
'CloudKindCode' |
'RoleCode' |
'ThirdPartyCode' |
'AuthTypeCode' |
'LoginTypeCode' |
'SysAuthCode';

export interface IGetEnumTypeCodeVariables {
  text: EnumCodeText;
}
export interface IGetEnumTypeCodeResponseData {
  getEnumTypeCode: IGqlResponseData<{ name: string; value: string }[]>;
}

const getEnumTypeCode = (variables: IGetEnumTypeCodeVariables) => useQuery<IGetEnumTypeCodeResponseData, IGetEnumTypeCodeVariables>(query, { variables, fetchPolicy: 'no-cache' });
export const lazyGetEnumTypeCode = () => useLazyQuery<IGetEnumTypeCodeResponseData>(query, { fetchPolicy: 'no-cache' });
export default getEnumTypeCode;