import './index.scss';

interface IManageHeaderProps {
  title: string;
}

const ManageHeader = ({
  title
}:IManageHeaderProps) => {
  return (
    <div className="manage-header">
      {title}
    </div>
  );  
}

export default ManageHeader;