import { gql } from '@apollo/client';

const query = gql`
  mutation updateOrgan($organ: ReqUpdOrgan) {
    updateOrgan(organ: $organ) {
      __typename
      result
      messages
      data {
        ... on Organ {
          __typename
          organId
          name
          description
          createdAt
          createdBy
          createdByName
          modifiedAt
          modifiedBy
          modifiedByName
          userToken
        }
      }
    }
  }
`;

export default query;