import { useEffect } from 'react';
import EmptyFavorite from './components/EmptyFavorite';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import { Favorites } from '@Types/v2/Lnb';
import { useAuth } from 'contexts/AuthProvider';

/** 
 * 해당 즐겨찾기가 삭제된 프로젝트일 경우 '삭제된 프로젝트입니다' 화면출력 + 해당 filled된 star삭제를 유도
 * 즐겨찾기에 아무것도 없을 경우 '빈 즐겨찾기' 화면출력

 * <>
    프로젝트 redirect 페이지 
    - 로그인 후 첫 페이지는 즐겨찾기 첫번쨰 프로젝트로 이동, 즐겨찾기 없을 경우 프로젝트의 첫번째 프로젝트로 이동, 둘다 없으면 empty 페이지 
    - 필요없으면 Navigate 로 변경
  </>
*/
const Favorite = () => {

  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { projectList } = useOutletContext<{ projectList: ProjectInfo[] }>();
  const { organId } = useParams();

  useEffect(() => {

    const getFavorites = localStorage.getItem('favorites');
    if (!getFavorites) {
      if (projectList.length === 0) return;

      navigate(`/organ/${organId}/project/${projectList[0].projectId}`);
    } else {
      const favorites:Favorites = JSON.parse(getFavorites);
      const findMyOrgan = favorites.find(val => val.organId === Number(organId));
  
      if (findMyOrgan === undefined) return; 
    
      const findSub = findMyOrgan.sub.find(val => val.memberId === String(userInfo?.nemo.memberId));
      if (findSub === undefined || findSub.list.length === 0 ) return;

    
      if (findSub.list.length === 0) return;

      navigate(`/organ/${organId}/project/${findSub.list[0].id}`);
    }

  }, [projectList, userInfo, organId]);


  return (
    <div id="favorite-page">
      <EmptyFavorite />
    </div>
  );
};

export default Favorite;
