import './index.scss';

interface ICheckboxAtomProps {
  id?: string;
}
const CheckboxAtom = ({ id }:ICheckboxAtomProps) => {
  return (
    <label className="checkbox-atom" >
      <input type="checkbox" {...(id && { id: id })} />
      <span />
    </label>
  );
};
export default CheckboxAtom;