import { useAuth } from 'contexts/AuthProvider';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

const InviteProject = () => {
  const { role, userInfo, loggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      useToast(ErrorCode.UNKNOWN, '올바르지 않은 접근입니다.');
      navigate('/auth');
    }
  }, [state])

  return (
    <main id="invite-project-page">
      <div className="bg" />
      <section>
        <h4>이미 소속된 조직이 있습니다.</h4>
        <h5>
          한 개의 조직만 참여할 수 있습니다.<br />
          다른 조직의 프로젝트에 참여하기 위해서는 기존의 조직에서 나간 후 참여가 가능합니다.
        </h5>
        <button 
          className="big-main-btn confirm-btn"
          onClick={() => {
            if (!loggedIn) logout();
            
            /* 지금 로그인 한 유저의 role상태에 따라 페이지 이동 */
            if (role === 'sy_admin') {
              navigate('/select-organ');
            } else {
              if (!userInfo?.nemo.organId) { navigate('/join-organ'); } 
              else { navigate(`/organ/${userInfo?.nemo.organId}`); }
            }
          }}
        >
          확인
        </button>
      </section>
    </main>);
};
export default InviteProject;