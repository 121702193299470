import './index.scss';
import ErrorIcon from 'assets/svgs/v2/ico_input_error.svg';
import countryCodes from 'country-codes-list';
import { useMemo } from 'react';
import DropdownAtom from '../atoms/DropdownAtom';
import InputAtom from '../atoms/InputAtom';

interface IPhoneInputProps {
  errorMsg?: string;
  id?: string;
  defaultValue?: { countryCode: string; phone: string; };
  onChangePhone?: (value: string) => void;
  onChangeCountryCode?: (value: string) => void;
}

const PhoneInput = ({ errorMsg, id, defaultValue, onChangePhone, onChangeCountryCode }:IPhoneInputProps) => {
  const countryCodesList = countryCodes.all().map(d => 
    ({ callingCode: d.countryCallingCode, countryCode: d.countryCode, languageCode: d.officialLanguageCode })
  );
  const codeDropdownData = useMemo(() => {
    return countryCodesList.map(d => ({ value: `+${d.callingCode}`, name: `+${d.callingCode}` }));
  },[countryCodesList]);
  
  return (
    <div className="phone-input-comp">
      <label>연락처</label>
      <div className="phone-inputs">
        <DropdownAtom
          id="country-code"
          data={codeDropdownData}
          value={{ value: defaultValue?.countryCode || '', name: defaultValue?.countryCode || '' }}
          handleClick={(val) => onChangeCountryCode && onChangeCountryCode(val.value.toString())}
          error={errorMsg !== undefined && errorMsg !== ''}
        />
        <InputAtom
          placeholder="연락처를 입력해주세요" 
          className={errorMsg && 'error'} 
          type="text"
          id={id} 
          error={errorMsg !== undefined && errorMsg !== ''} 
          defaultValue={defaultValue?.phone}
          onChangeValue={onChangePhone}
        />
      </div>
      {errorMsg && <div className="error-wrapper"><img src={ErrorIcon} width={16} height={16} />{errorMsg}</div>}
    </div>
  );
};
export default PhoneInput;