import './index.scss';
import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';
import ClearErrorIcon from 'assets/svgs/v2/ico_input_clear_error.svg';
import PwHideIcon from 'assets/svgs/v2/ico_pw_hide.svg';
import PwShowIcon from 'assets/svgs/v2/ico_pw_show.svg';
import classNames from 'classnames';
import { useState } from 'react';

interface IInputAtomProps {
  className?: string;
  placeholder?: string;
  type?: string;
  id?: string;
  error?: boolean;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  noClear?: boolean;
  onChangeValue?: (value: string) => void;
}
const InputAtom = ({ 
  className, 
  placeholder = '', 
  type = 'text', 
  id, 
  error = false, 
  value,
  defaultValue = '', 
  required, 
  disabled = false, 
  noClear = false,
  onChangeValue 
}:IInputAtomProps) => {
  const [show, setShow] = useState(false);
  
  return (
    <div className="input-atom">
      <input 
        placeholder={placeholder} 
        type={show ? 'text' : type} 
        className={classNames(className, error && 'error')}
        {...(id && { id: id })}
        {...(required && { required: required })}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onChangeValue && onChangeValue(e.target.value);
          const siblingClearBtn = e.currentTarget.parentElement?.getElementsByClassName('clear');
          if (siblingClearBtn && siblingClearBtn[0]) {
            if (e.target.value === '') {
              (siblingClearBtn[0] as HTMLButtonElement).classList.remove('show');
            } else {
              (siblingClearBtn[0] as HTMLButtonElement).classList.add('show');
            }
          }
        }}
        // {...(defaultValue && { defaultValue: defaultValue })}
        style={{ paddingRight: type === 'password' ? '87px' : '51px' }}
      />
      <span className="btn-group">
        {type === 'password' && 
        (show ? 
          <img 
            src={PwShowIcon} 
            width={26} 
            height={26} 
            className="eye" 
            onClick={() => setShow(prev => !prev)}
          /> : 
          <img 
            src={PwHideIcon} 
            width={26} 
            height={26} 
            className="eye" 
            onClick={() => setShow(prev => !prev)}
          />
        )
        }
        {!disabled && !noClear && (value !== '' || defaultValue !== '') && <img 
          src={error ? ClearErrorIcon : ClearIcon} 
          width={24} 
          height={24} 
          className="clear" 
          onClick={(e) => {
            const siblingInput = e.currentTarget.parentElement?.parentElement?.getElementsByTagName('input');
            if (siblingInput && siblingInput[0]) {
              (siblingInput[0] as HTMLInputElement).value = '';
              e.currentTarget.classList.remove('show');
              onChangeValue && onChangeValue('');
            }
          }} />}
      </span>
    </div>
  );
};
export default InputAtom;