import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import jwtDecode from 'jwt-decode';
import Footer from 'components/v2/Footer';
import { AuthTokenType } from '@Types/User';
import EmailIcon from 'assets/svgs/v2/ico_email_verify.svg';
import { useAuth } from 'contexts/AuthProvider';

interface IVerifyProps {
  nextStep: () => void;
}
const Verify = ({ nextStep }:IVerifyProps) => {

  const { logout } = useAuth();

  const [searchParams] = useSearchParams();

  const tokenResult = useMemo(() => {
    const authToken = searchParams.get('authToken');
    if (authToken) {
      try {
        const decodedToken = Base64.decode(authToken);
        const decodedData = jwtDecode(decodedToken) as AuthTokenType;

        if (new Date(decodedData.exp * 1000).getTime() > new Date().getTime()) {
          return 'SUCCESS';
        } else {
          return 'TOKENERROR';
        }
      } catch {
        return 'UNKNOWN';
      }
    } else {
      return 'UNKNOWN';
    }
  },[searchParams]);

  const renderBody = (tokenResult: 'SUCCESS' | 'TOKENERROR' | 'UNKNOWN') => {
    if (tokenResult === 'SUCCESS') {
      return (
        <>
          <h2>이메일 인증이 완료됐습니다.</h2>
          <button onClick={nextStep} className="big-main-btn">비밀번호 재설정하기</button>
        </>
      );
    } else if (tokenResult === 'TOKENERROR') {
      return (
        <>
          <h2>
          이미 인증 시간이 만료되었습니다.<br />
          다시 본인 인증을 진행해 주세요.
          </h2>
          <Link to="/auth/reset-pw-sendemail" className="big-main-btn" replace>확인</Link>
        </>
      );
    } else {
      return (
        <>
          <h2>올바르지 않은 접근입니다.</h2>
          <button 
            className='big-main-btn'
            onClick={() => logout()}
          >
            확인
          </button>
        </>
      );
    }
  };

  return (
    <>
      <article id="reset-pw-verify-article">
        <img src={EmailIcon} width={56} height={56} />
        {renderBody(tokenResult)}
      </article>
      <Footer />
    </>
  );
};
export default Verify;