import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUpdateMessageVariables {
  reqUpdMessage: {
    id: number;
    relObjectId: number; //0: no reference, n: reference id
    relObjectType: string;
    sendTypeCode: string;
    title: string;
    version: string;
    message: string;
  }
}

export interface IUpdateMessageResponseData {
  updateMessage: IGqlResponseData<undefined>;
}

const updateMessage = () => useMutation<IUpdateMessageResponseData, IUpdateMessageVariables>(query);
export default updateMessage;