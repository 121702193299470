import { gql } from '@apollo/client';

const query = gql`
  query getRuleCloudPageByParam($reqData: ReqGetRuleCloudPage) {
    getRuleCloudPageByParam(reqData: $reqData) {
      __typename
      first
      number
      numberOfElements
      totalPages
      totalElements
      last
      size
      content {
        __typename
        ... on Cloud {
          cloudId
          cloudKind
          name
          maskCode
          description
          contactName
          contactEmail
          contactMobile
          createdAt
          createdBy
          createdByName
          modifiedAt
          modifiedBy
          modifiedByName
          createdByUserId
          modifiedByUserId
        }
      }
    }
  }
`;

export default query;