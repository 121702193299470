import { gql } from '@apollo/client';

const query = gql`
  mutation removeProjectMember($reqData: ReqDelProjectMember) {
    removeProjectMember(reqData: $reqData) {
      result
      data {
        ... on RespCheckIn {
          userToken
        }
      }
    }
  }
`;
export default query;