import { gql } from '@apollo/client';

const query = gql`mutation sendHistEventAlert($reqData: ReqSendHistEventAlert) {
  sendHistEventAlert(reqData: $reqData) {
    result
    data {
      __typename
      ... on HistAlert {
        id
        histEventId
        sender
        senderName
        sendTypeCode
        sentAt
        receivers
        receiversName
        contents
        title
      }
    }
  }
}`;
export default query;