import { gql } from '@apollo/client';

const query = gql`
query getSubCategoryPageByParam($reqData: ReqGetSubCategoryPage) {
  getSubCategoryPageByParam(reqData: $reqData) {
    __typename
    result
    number
    size
    first
    last
    numberOfElements
    totalPages
    totalElements
    content {
      __typename
      ... on SubCategory {
        id
        categoryId
        name
        maskCode
        description
        title
        template
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
        createdByUserId
        modifiedByUserId
      }
    }
  }
}
`;
export default query;