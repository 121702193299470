import { useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import { checkEmail } from 'utils/v2/ValidCheck';
import LabelTextarea from 'components/v2/LabelTextarea';
import { numberRegex } from 'utils/Regex';

/** 
 * <>영업팀에 문의 하는 페이지</>
 */
const Inquiry = () => {

  const [payload, setPayload] = useState({
    contactName: '',
    contactEmail: '',
    contactMobile: '',
    companyName: '',
    companySize: '',
    text: ''
  });

  const enableSubmitButton = useMemo(() => {
    if (
      payload.contactName === '' ||
      checkEmail(payload.contactEmail) || 
      payload.contactMobile === '' ||
      payload.companyName === '' ||
      payload.companySize === ''
    ) { return true; } else return false;
  }, [payload])

  return (
    <div 
      id="inquiry-page" 
      className='flex j-center a-center'
    >
      <div className='inquiry-wrap'>
        <div className='header flex a-center'>
          <div className='inquiry-icon' />
          영업팀 문의
        </div>
        <div className='contents'>
          <LabelInput 
            title="담당자 이름" 
            placeholder='담당자 이름 입력'
            value={payload.contactName}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                contactName: str
              }))
            }}
          />
          <LabelInput 
            title="담당자 이메일" 
            placeholder='이메일 양식에 맞게 입력'
            value={payload.contactEmail}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                contactEmail: str
              }))
            }}
          />
          <LabelInput 
            title="담당자 연락처" 
            placeholder='담당자 연락처 입력'
            value={payload.contactMobile}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                contactMobile: str.replace(numberRegex, '')
              }))
            }}
          />
          <LabelInput 
            title="회사 이름" 
            placeholder='회사 이름 입력'
            value={payload.companyName}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                companyName: str
              }))
            }}
          />
          <LabelInput 
            title="회사 규모" 
            placeholder='회사 규모 입력'
            value={payload.companySize}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                companySize: str
              }))
            }}
          />
          <LabelTextarea 
            title="문의사항 (선택)" 
            placeholder='문의사항 입력'
            value={payload.text}
            onChangeValue={str => {
              setPayload(prev => ({
                ...prev,
                text: str
              }))
            }}
          />
        </div>
        <div className='btns flex j-end'>
          <button 
            className='big-main-btn'
            disabled={ enableSubmitButton }
            onClick={() => console.log('등록 완료')}
          >
            등록하기
          </button>
        </div>

      </div>
    </div>
  )
}

export default Inquiry;