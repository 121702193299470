import InputAtom from '../atoms/InputAtom';
import './index.scss';
import ErrorIcon from 'assets/svgs/v2/ico_input_error.svg';

interface ILabelInputProps {
  title: string;
  placeholder?: string;
  errorMsg?: string;
  type?: string;
  id?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  noClear?: boolean;
  onChangeValue?: (value: string) => void;
}
const LabelInput = ({ 
  title, 
  placeholder = '', 
  errorMsg, 
  type, 
  id, 
  value, 
  defaultValue, 
  required, 
  disabled, 
  onChangeValue, 
  noClear
}:ILabelInputProps) => {
  return (
    <div className="label-input-comp">
      <label {...(required && { className: 'required' })}>{title}</label>
      <InputAtom
        placeholder={placeholder} 
        className={errorMsg && 'error'} 
        type={type} 
        id={id} 
        error={errorMsg !== undefined && errorMsg !== ''} 
        value={value}
        defaultValue={defaultValue}
        onChangeValue={onChangeValue && onChangeValue}
        required={required}
        disabled={disabled}
        noClear={noClear}
      />
      {errorMsg && <div className="error-wrapper"><img src={ErrorIcon} width={16} height={16} />{errorMsg}</div>}
    </div>
  );
};
export default LabelInput;