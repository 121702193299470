import { gql } from '@apollo/client';

const query = gql`
  query getProjectInfoByOrganId($organId: Int) {
    getProjectInfoByOrganId(organId: $organId) {
      __typename
      result
      messages
      data {
        ... on Project {
            projectId
            organId
            name
            thirdPartTypeCode
            attrKey1
            attrValue1
            attrKey2
            attrValue2
            useYn
            createdAt
            createdBy
            createdByName
            modifiedAt
            modifiedBy
            modifiedByName
        }
      }
    }
  }
`;

export default query;