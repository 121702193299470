import { useLazyQuery, useQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { AllRuleCloudType } from 'graphql/types/AllRuleCloud';

export interface IGetAllRuleCloudResponseData {
  getAllRuleCloud: IGqlResponseData<AllRuleCloudType[]>;
}

const getAllRuleCloud = () => useQuery<IGetAllRuleCloudResponseData>(query, { fetchPolicy: 'no-cache' });
export const lazyGetAllRuleCloud = () => useLazyQuery<IGetAllRuleCloudResponseData>(query, { fetchPolicy: 'no-cache' });
export default getAllRuleCloud;