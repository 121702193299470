import './index.scss';

interface IIsLoadingProps {
  dimmed?:boolean;
};

const IsLoading = ({
  dimmed
}:IIsLoadingProps ) => {
  return (
    <div className={`loading flex j-center a-center ${dimmed && 'dimmed'}`} >
      <div className='progress-gif' />
    </div>
  );
};

export default IsLoading;
