import { useEffect, useId, useState } from 'react';
import './index.scss';

interface IToggleSwitchProps {
  className?: string;
  onChange?: (checked:boolean) => void;
  value: boolean;
  disabled?: boolean;
}

const ToggleSwitchAtom = ({
  className,
  onChange,
  value,
  disabled
}: IToggleSwitchProps) => {
  const id = useId();
  const [checked, setChecked] = useState(value);

  useEffect(() => setChecked(value), [value]);

  return (
    <div className={`toggle-switch-atom ${className}`}>
      <input
        type="checkbox"
        className="toggle-switch-check"
        id={`toggle-switch-atom ${id}`}
        checked={checked}
        disabled={disabled}
        readOnly
        onChange={(e) => {
          setChecked(e.target.checked);

          if (onChange) {
            onChange(e.target.checked);
          }
        }}
      />
      <label 
        htmlFor={`toggle-switch-atom ${id}`} 
        className="toggle-switch-background"
      >
        <span className="toggle-switch-button" />
      </label>
    </div>
  );
};

export default ToggleSwitchAtom;
