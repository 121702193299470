import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUpdatePasswordVariables {
  reqData: {
    userPw: string;
    email:string;
  }
}

export interface IUpdatePasswordResponseData {
  updatePassword: IGqlResponseData<undefined>;
}

/** 비밀번호 수정 */
const updatePassword = () => useMutation<IUpdatePasswordResponseData, IUpdatePasswordVariables>(query);
export default updatePassword;