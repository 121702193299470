import { gql } from '@apollo/client';


const query = gql`
query getMessagePageByParam($reqGetMessagePage: ReqGetMessagePage) {
  getMessagePageByParam(reqGetMessagePage: $reqGetMessagePage) {
    __typename
    result
    first
    number
    numberOfElements
    totalPages
    totalElements
    last
    size
    content {
      ... on Message {
        id
        relObjectId
        relObjectType
        sendTypeCode
        version
        title
        message
        createdAt 
        createdBy
        createdByName
        createdByUserId
        modifiedAt
        modifiedBy
        modifiedByName
        modifiedByUserId
      }
    }
  }
}
`
export default query;