import { ErrorCode } from '@Types/error';
import '../index.scss';
import Button from 'components/UI/Button';
import deleteProject from 'graphql/mutations/deleteProject';
import { useSnackbar } from 'hooks/useSnackbar';

interface IDeleteProjectModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  data: { id: number, name: string };
}
const DeleteProjectModal = ({ open, onClose, onSubmit, data }: IDeleteProjectModalProps) => {
  const [deleteProj] = deleteProject();

  const handleDelete = () => {
    deleteProj({
      variables: {
        reqDelProject: {
          projectId: data.id
        }
      }
    }).then(({ data }) => {
      if (data) {
        if (data.deleteProject.result === ErrorCode.SUCCESS) {
          useSnackbar(data.deleteProject.result);
          onSubmit();
          onClose();
        } else {
          useSnackbar(data.deleteProject.result);
        }
      } else {
        useSnackbar(ErrorCode.UNKNOWN);
      }
    });
  };
 
  return (
    <>
      {open &&
        <div className="modal-bg" onClick={onClose}>
          <div className="delete-wrap" onClick={e => e.stopPropagation()}>
            <div className="header-wrap">
              <span />
              <p className="title">프로젝트 삭제</p>
            </div>
            <div className="content-wrap">
              <div className="p subtitle">{data.name}를 삭제합니다.</div>
              <div className="btn-wrap">
                <Button disabled={false} text={'삭제'} handleClick={handleDelete} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default DeleteProjectModal;