import VerticalSplitLinkGroup from 'components/v2/VerticalSplitLinkGroup';
import { useEffect, useState } from 'react';
import { termLinks } from 'utils/Links';
import BackIcon from 'assets/svgs/v2/ico_back.svg';
import EmailIcon from 'assets/svgs/v2/ico_mail_title.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import { useMutation } from 'react-query';

import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';

const SendEmail = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { state } = useLocation();

  const [timer, setTimer] = useState(0);

  const { mutateAsync: _reqEmailById } = useMutation((data: { userId: string }) => apis.SignUp.reqEmailById(data))

  const sendEmail = () => {
    _reqEmailById(state.userId)
    useToast(ErrorCode.SUCCESS, '인증 메일이 재발송 됐습니다.');
  };

  const startTimer = () => {
    sendEmail();
    setTimer(20);
  };

  //화면 첫 진입 시 이메일 보내야함.
  useEffect(() => {
    if (!state) navigate('/auth');
  },[state]);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {setTimer(prev => prev - 1);},1000);
    }
  },[timer]);


  return (
    <>
      <article id="join-send-email-article">
        <div className="header">
          <button type="button" onClick={() => navigate(-1)}>
            <img src={BackIcon} width={26} height={26} />
          </button>
        </div>
        <div className="content">
          <h3><img src={EmailIcon} width={32} height={32} />마지막으로 이메일을 확인해 주세요</h3>
          <h4>
            회원가입이 거의 다 완료되었습니다.<br />
            이메일 인증을 진행해야 회원가입이 완료됩니다.<br />
            <br />
            인증 메일을 보냈어요.<br />
            {'받은 이메일을 열어 \'인증하기\' 버튼을 클릭하세요.'}
          </h4>
          <h5>
            혹시 인증 메일을 받지 못하셨나요?<br />
            {'1) 가입하지 않은 사용자일 경우 이메일이 발송되지 않아요.'}<br />
            {'2) 스팸 메일함에서 인증 메일을 확인해 보세요.'}
          </h5>
          <div className="button-group">
            <button 
              className="big-sub-btn" 
              type="button" 
              onClick={startTimer} 
              disabled={timer > 0}
            >
              {timer > 0 ? `인증 메일 다시 보내기 (${timer}s)` : '인증 메일 다시 보내기'}
            </button>
            <button
              className="big-main-btn" 
              onClick={() => logout()}
            >
              로그인 하기
            </button>
          </div>
        </div>
      </article>
      <VerticalSplitLinkGroup list={termLinks} />
    </>
  );
};
export default SendEmail;