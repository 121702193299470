import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { CloudInfo } from 'apis/v2/Manage/schema';

export interface IAddRuleCloudVariables {
  reqData: {
    cloudKind: string;
    name: string;
    maskCode: string;
    contacName?: string;
    contactEmail?: string;
    contactMobile?: string;
  }
}

export interface IAddRuleCloudResponseData {
  addRuleCloud: IGqlResponseData<CloudInfo>;
}

const addRuleCloud = () => useMutation<IAddRuleCloudResponseData, IAddRuleCloudVariables>(query);
export default addRuleCloud;