import { gql } from '@apollo/client';

const query = gql`
query getMemberInfos($reqData: [UUID]) {
  getMemberInfos(memberIds: $reqData) {
    result
    data {
      ... on MemberInfo {
        id
        fullName
        email
        organId
        organName
        ranks
        userId
        retryCount
        loginTypeCode
        systemAuthCode
        statusCode
        alertYn
        description
        createdAt
        modifiedAt
        mobileNumber
        __typename
      }
    }
  }
}
`;

export default query;