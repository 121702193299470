import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteProjectVariables {
  reqDelProject: {
    projectId: number;
  }
}

export interface IDeleteProjectResponseData {
  deleteProject: IGqlResponseData<{userToken: string}[]>;
}

/** 프로젝트 삭제 */
const deleteProject = () => useMutation<IDeleteProjectResponseData, IDeleteProjectVariables>(query);
export default deleteProject;