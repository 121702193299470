import { gql } from '@apollo/client';

const query = gql`
  query getHistAlertByHistEventId($histEventId: Long) {
    getHistAlertByHistEventId(histEventId: $histEventId) {
      __typename
      result
      data {
        ... on HistAlert {
          id
          histEventId
          sender
          senderName
          sendTypeCode
          sentAt
          receivers
          receiversName
          contents
          title
        }
      }
    }
  }
`;
export default query;