import { gql } from '@apollo/client';

const query = gql`mutation addProjectMember($reqAddProjectMember: ReqAddProjectMember) {
  addProjectMember(reqAddProjectMember: $reqAddProjectMember) {
    __typename
    result
    messages
    data {
      __typename
      ... on ProjectMember {
        memberId
        projectId
        roleCode
        alertYn
      }
    }
  }
}`;
export default query;