import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteProjectMemberVariables {
  reqDelProjectMember: {
    projectId: number;
    memberIds: string[];
  }
}

export interface IDeleteProjectMemberResponseData {
  deleteProjectMember: IGqlResponseData<undefined>;
}

const deleteProjectMember = () => useMutation<IDeleteProjectMemberResponseData, IDeleteProjectMemberVariables>(query);
export default deleteProjectMember;