import { useRef, useState } from 'react';
import './index.scss';
import classNames from 'classnames';
import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';
import SearchIcon from 'assets/svgs/v2/ico_search_glass.svg';
import { DropdownListDataType } from '../atoms/DropdownAtom';

interface ISearchDropdownProps {
  id: string;
  data: DropdownListDataType[];
  searchValue:string;
  isConfirmed?: boolean;
  resetIsConfirm?: () => void;
  onChangeSearch: (str:string) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  upsideDown?: number;
  handleClick: (val: DropdownListDataType) => void;
}

const SearchDropdown = ({
  id,
  data,
  isConfirmed,
  resetIsConfirm,
  searchValue,
  onChangeSearch,
  className,
  placeholder,
  disabled = false,
  handleClick
}:ISearchDropdownProps) => {
  const listRef = useRef<null | HTMLDivElement>(null);

  const [bg, setBg] = useState({
    width: 0,
    top: 0,
    left: 0
  });

  const openMenu = (parentId: string) => {
    Array.from(document.getElementsByClassName('dropdown-list')).forEach(el => {
      const parentEl = el.parentElement;
      if (parentEl?.id === parentId) {
        if (el.classList.contains('none')) {
          el.classList.remove('none');
        } else {
          el.classList.add('none');
        }
      } else {
        el.classList.add('none');
      }
    });
  };

  return (
    <div 
      className={classNames('search-dropdown-atom', className)} 
      id={`${id}-wrap`}
    >
      <input 
        id={id}
        tabIndex={-1}
        value={searchValue}
        autoComplete='off'
        onChange={(e) => {
          onChangeSearch(e.target.value)
        }}
        disabled={disabled}
        placeholder={placeholder}
        className={`${isConfirmed && 'isConfirmed'}`}
        onClick={(e) => {
          e.stopPropagation();
          openMenu(`${id}-wrap`);
          resetIsConfirm && resetIsConfirm();
          const dropdownAtomEl = document.getElementById(`${id}-wrap`);
          const listWrapEl = document.getElementById(`list-wrap ${id}`);

          if (!dropdownAtomEl || !listWrapEl) return;
          if (window.innerHeight - e.currentTarget.getBoundingClientRect().bottom > dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5) {
            setBg({
              width: e.currentTarget.offsetWidth,
              top: e.currentTarget.getBoundingClientRect().bottom + 5,
              left: e.currentTarget.getBoundingClientRect().left 
            });
          } else {
            setBg({
              width: e.currentTarget.offsetWidth,
              top: e.currentTarget.getBoundingClientRect().bottom - (dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5),
              left: e.currentTarget.getBoundingClientRect().left 
            });
          }
        }}
        
      />
      <span className={`btns ${isConfirmed && 'confirmed'}`}>
        {!disabled && searchValue !== '' &&  
          <img 
          src={isConfirmed ? SearchIcon : ClearIcon} 
          width={24} 
          height={24} 
          className="clear" 
          onClick={() => {
            onChangeSearch('');
            resetIsConfirm && resetIsConfirm();
          }} 
          />
        }
      </span>
      {!disabled && 
      <div className="dropdown-list none" ref={listRef}>
        <div 
          id={`list-wrap ${id}`}
          className="list-wrapper"
          style={{
            top: bg.top,
            left: bg.left,
            width: bg.width
          }}>
            {
              (!data || data.length === 0) 
              ? <div>검색 결과가 없습니다.</div>
              : <ul>
                {data.map((d, idx) => 
                  <li
                    key={`${idx}-${d.value}`}
                    className="list-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick(d);
                      e.currentTarget.parentElement?.parentElement?.parentElement?.classList.add('none');
                      setBg({
                        width: 0,
                        top: 0,
                        left: 0
                      });
                    }}
                  >{d.name}
                  </li>
                )}
              </ul>
            }
        </div>
      </div>
      }
    </div>
  );
};
export default SearchDropdown;