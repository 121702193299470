import { gql } from '@apollo/client';

const query = gql`
mutation addEvent($reqData: ReqAddEvent) {
  addEvent(reqData: $reqData) {
    __typename
    result
    messages
    data {
      ... on Event {
        eventId
        name
        maskCode
        createdAt
        createdBy
        createdByName
        modifiedAt
        modifiedBy
        modifiedByName
      }
    }
  }
}
`;
export default query;