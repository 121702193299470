import { useEffect } from "react";
import './index.scss';
import { useAuth } from "contexts/AuthProvider";
import { Outlet, useNavigate } from "react-router-dom";
import { useToast } from "hooks/v2/useToast";
import { ErrorCode } from "@Types/error";

/** 
 * <>조직소유자만 접근가능한 "요금제, 카드관리, 영수증" 레이아웃</>
 */
const PaymentLayout = () => {

  const navigate = useNavigate();
  const { role } = useAuth();
  
  /* 접근권한 처리 */
  useEffect(() => {
    /* lg운영자라면 접근불가 */
    if (role === 'sy_admin') {
      useToast(ErrorCode.UNKNOWN, '접근권한이 없습니다.');
      navigate('/auth');
    } 
    else {
      if (role !== 'pj_owner') {
        useToast(ErrorCode.UNKNOWN, '접근권한이 없습니다.');
        navigate('/auth');
      }
    }
  }, [role])

  /* 접근이 허용된 조직소유자는 본인의 organId, memberId 등을 활용할 것으로 판단 */
  return (
    <div id="payment-layout">
      <Outlet />
    </div>
  )
}

export default PaymentLayout;