import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { SubCategoryInfo } from 'apis/v2/Manage/schema';

export interface IGetSubCategoryPageByParamVariables {
  reqData: {
    text?: string;
    pageInfo: Paging;
  }
}

export interface IGetSubCategoryPageByParamResponseData {
  getSubCategoryPageByParam: ResponsePage<SubCategoryInfo>;
}

const lazyGetSubCategoryPageByParam = () => useLazyQuery<IGetSubCategoryPageByParamResponseData, IGetSubCategoryPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetSubCategoryPageByParam;