import { gql } from '@apollo/client';

const query = gql`
  query getMemberById($memberId: UUID!) {
    getMemberById(memberId: $memberId) {
      __typename
      result
      data {
        ... on Member {
          id
          fullName
          userId
          email
          organId
          mobileNumber
          ranks
          retryCount
          loginTypeCode
          systemAuthCode
          statusCode
          pictureResourceId
          alertYn
          description
          joinedAt
          resignedAt
          createdAt
          createdBy
          createdByName
          modifiedAt
          modifiedBy
          modifiedByName
          organName
        }
      }
    }
  }
`;
export default query;