import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteRuleCloudVariables {
  reqData: {
    ids: number[]
  }
}

export interface IDeleteRuleCloudResponseData {
  deleteRuleCloud: IGqlResponseData<undefined>;
}

const deleteRuleCloud = () => useMutation<IDeleteRuleCloudResponseData, IDeleteRuleCloudVariables>(query);
export default deleteRuleCloud;