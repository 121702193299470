import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { ReportDetail } from '../../types/ReportDetail';

export interface IAddMonthlyReportVariables {
  report: {
    name: string;
    projectId: number;
    startTime: string;
    endTime: string;
  }
}

export interface IAddMonthlyReportResponseData {
  addMonthlyReport: IGqlResponseData<ReportDetail>;
}

/** 월간 리포트 생성 */
const addMonthlyReport = () => useMutation<IAddMonthlyReportResponseData, IAddMonthlyReportVariables>(query);
export default addMonthlyReport;