import { ErrorCode } from "@Types/error";
import { BaseDummyRes } from "..";
import { IDelegateOwnerRes, IGetOrganByIdRes, IGetPaymentStatusByOrganIdRes, ILeaveOrganReq, ILeaveOrganRes, IRemoveProjectMemberReq, IRemoveProjectMemberRes, ISearchOrganRes } from "./schema";


const DummyGetOrganById = (data: { organId: number; }): Promise<BaseDummyRes<IGetOrganByIdRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetOrganByIdRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            organId: 23,
            name: 'dummy 니모의 꿈 공장',
            description: 'dummy 안녕하세요.',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy-cd07f08c',
            // createdByName: 'dummy 사원',
            createdByUserId: 'dummy123',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy-cd07f08c',
            modifiedByUserId: 'dummy123'
            // modifiedByName: 'dummy 사원'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            organId: 23,
            name: 'dummy 니모의 꿈 공장',
            description: 'dummy 안녕하세요.',
            createdAt: '2024-02-14T05:28:14.404Z',
            createdBy: 'dummy-cd07f08c',
            // createdByName: 'dummy 사원',
            createdByUserId: 'dummy123',
            modifiedAt: '2024-02-14T05:28:14.404Z',
            modifiedBy: 'dummy-cd07f08c',
            modifiedByUserId: 'dummy123'
            // modifiedByName: 'dummy 사원'
          }
        }
      });
    }
  });

  return promise;
};

const DummyLeaveOrgan = (data: ILeaveOrganReq): Promise<BaseDummyRes<ILeaveOrganRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<ILeaveOrganRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            userToken: 'dummy leave Organ token'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            userToken: 'dummy leave Organ token'
          }
        }
      });
    }
  });

  return promise;
};

const DummyGetPaymentStatusByOrganId = (data: { organId: number }): Promise<BaseDummyRes<IGetPaymentStatusByOrganIdRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IGetPaymentStatusByOrganIdRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            paymentYn: true
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            paymentYn: true
          }
        }
      });
    }
  });

  return promise;
};

const DummyDelegateOwner = (data: { memberId: string }): Promise<BaseDummyRes<IDelegateOwnerRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IDelegateOwnerRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            userToken: 'dummy delegate Owner userToken'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            userToken: 'dummy delegate Owner userToken'
          }
        }
      });
    }
  });

  return promise;
};

const DummyRemoveProjectMember = (data: IRemoveProjectMemberReq): Promise<BaseDummyRes<IRemoveProjectMemberRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<IRemoveProjectMemberRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: {
            userToken: 'dummy remove project member userToken'
          }
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: {
            userToken: 'dummy remove project member userToken'
          }
        }
      });
    }
  });

  return promise;
};

const DummySearchOrgan = (data: { text: string; }): Promise<BaseDummyRes<ISearchOrganRes>> => {
  const promise = new Promise((resolve: (value: BaseDummyRes<ISearchOrganRes>) => void) => {
    if (data) {
      resolve({
        data: {
          result: ErrorCode.SUCCESS,
          data: [
            {
              organId: 23,
              name: 'dummy 니모의 꿈 공장',
              description: 'dummy description',
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy-3333',
              // createdByName: 'dummy 사원',
              createdByUserId: 'dummt123',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy-3333',
              modifiedByUserId: 'dummy123'
              // modifiedByName: 'dummy 사원'
            },
            {
              organId: 23,
              name: 'dummy 니모의 꿈 공장 2',
              description: 'dummy description',
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy-4444',
              // createdByName: 'dummy 사원2',
              createdByUserId: 'dummy123',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy-4444',
              modifiedByUserId: 'dummy123'
              // modifiedByName: 'dummy 사원2'
            },
            {
              organId: 23,
              name: 'dummy 니모의 꿈 공장 3',
              description: 'dummy description',
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy-88',
              createdByUserId: 'dummy123',
              // createdByName: 'dummy 사원4',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy-88',
              // modifiedByName: 'dummy 사원4'
              modifiedByUserId: 'dummy123'
            },
          ]
        }
      });
    } else {
      resolve({
        data: {
          result: ErrorCode.UNKNOWN,
          data: [
            {
              organId: 23,
              name: 'dummy 니모의 꿈 공장',
              description: 'dummy description',
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy-3333',
              createdByUserId: 'dummy123',
              // createdByName: 'dummy 사원',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy-3333',
              modifiedByUserId: 'dummy123'
              // modifiedByName: 'dummy 사원'
            },
            {
              organId: 25,
              name: 'dummy 스폰지밥 타운',
              description: 'dummy description',
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy-4444',
              createdByUserId: 'dummy123',
              // createdByName: 'dummy 사원2',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy-4444',
              modifiedByUserId: 'dummy123'
              // modifiedByName: 'dummy 사원2'
            },
            {
              organId: 30,
              name: 'dummy 네모네모',
              description: 'dummy description',
              createdAt: '2024-02-14T05:28:14.404Z',
              createdBy: 'dummy-88',
              createdByUserId: 'dummy123',
              // createdByName: 'dummy 사원4',
              modifiedAt: '2024-02-14T05:28:14.404Z',
              modifiedBy: 'dummy-88',
              modifiedByUserId: 'dummy123'
              // modifiedByName: 'dummy 사원4'
            },
          ]
        }
      });
    }
  });

  return promise;
};



export const Organ = {
  getOrganById: DummyGetOrganById,
  leaveOrgan: DummyLeaveOrgan,
  getPaymentStatusByOrganId: DummyGetPaymentStatusByOrganId,
  delegateOwner: DummyDelegateOwner,
  removeProjectMember: DummyRemoveProjectMember,
  searchOrgan: DummySearchOrgan,
};
export default Organ;