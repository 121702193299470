import { gql } from '@apollo/client';

const query = gql`
query getEventPageByParam($reqData: ReqGetEventPage) {
  getEventPageByParam(reqData: $reqData) {
    __typename
    result
    number
    size
    first
    last
    numberOfElements
    totalPages
    totalElements
    content {
      __typename
      ... on Event {
        eventId
        name
        maskCode
        createdBy
        createdByName
        createdAt
        modifiedBy
        modifiedByName
        modifiedAt
        createdByUserId
        modifiedByUserId
      }
    }
  }
}
`;
export default query;