import './index.scss';
import TablePagination from 'components/v2/dataDisplay/TablePagination';

const NotFound = () => {
  return(

  
  <div id="not-found-page">
      <div className="header flex j-end a-center">
        <button 
          className="setting-btn" 
          disabled
        />
      </div>
      <div className="content">
        <div className="flex col a-center">
          <div className="not-found-icon" />
          <p>해당 프로젝트를 찾을 수 없습니다.</p>
        </div>
      </div>
      <div className="pagination-wrapper flex a-center">
        <p className="flex a-center">전체 <span>0개</span></p>
        <TablePagination 
          currentPage={1}
          totalPage={1} 
        />
      </div>
    </div>
  )
}

export default NotFound;