import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { EventRuleInfo } from 'graphql/types/EventRuleInfo';

export interface IUpdateCategoryVariables {
  reqData: {
    categoryId: number; 
    cloudId?: number;
    name?: string;
    maskCode?: string;
    description?: string;
    title?: string;
    template?: string;
  }
}

export interface IUpdateCategoryResponseData {
  updateCategory: IGqlResponseData<EventRuleInfo>;
}

const updateCategory = () => useMutation<IUpdateCategoryResponseData, IUpdateCategoryVariables>(query);
export default updateCategory;