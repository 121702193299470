import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { OrganInfo } from 'graphql/types/OrganInfo';

export interface IAddOrganVariables {
  reqAddOrgan: {
    name: string;
    description: string;
  }
}

export interface IAddOrganResponseData {
  addOrgan: IGqlResponseData<{
  organId: number;
  name: string;
  description: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  modifiedAt: string;
  modifiedBy: string;
  modifiedByName: string;
  projectCnt: number;
  userToken: string;
  }[]>;
}

/** 조직 추가 */
const addOrgan = () => useMutation<IAddOrganResponseData, IAddOrganVariables>(query);
export default addOrgan;