import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUpdateRuleCloudVariables {
  reqData: {
    cloudId: number;
    cloudKind?: string;
    name?: string;
    maskCode?: string;
    contactName?: string;
    contactMobile?: string;
  }
}

export interface IUpdateRuleCloudResponseData {
  updateRuleCloud: IGqlResponseData<undefined>;
}

const updateRuleCloud = () => useMutation<IUpdateRuleCloudResponseData, IUpdateRuleCloudVariables>(query);
export default updateRuleCloud;