import { PlanTypeEnum } from 'utils/v2/DummyData/Payment';
import './index.scss';

interface IPlanCardProps {
  data: {
    type: PlanTypeEnum
    title: string;
    description: string;
    benefit: {
        useYn: boolean;
        feature: string;
    }[];
  } 
  currentActive: string;
  selected: PlanTypeEnum
  onSelect: (type:PlanTypeEnum) => void;
};

const PlanCard = ({
  data,
  currentActive,
  selected,
  onSelect
}:IPlanCardProps) => {
  return (
    <div 
      className='plan-card'
      onClick={() => onSelect(data.type)}
    >
      <div className='hat' />
      <div className='main-content'>
        <div className='content-header'>
          <h5 className='flex a-center'>
            { selected === data.type && <div className='selected-icon' /> }
            {currentActive === data.type && '(현재) '} {data.title}
          </h5>
          <p>{data.description}</p>
        </div>
        <div className='content-feature'>
          {
            data.benefit.map(val => (
              <div className={`feature-row flex a-center ${val.useYn && 'checked'}`}>
                <div className={`check-icon`} />
                {val.feature}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
export default PlanCard;