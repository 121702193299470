import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteTermsVariables {
  reqData: {
    ids: number[]
  }
}

export interface IDeleteTermsResponseData {
  deleteTerms: IGqlResponseData<undefined>;
}

const deleteTerms = () => useMutation<IDeleteTermsResponseData, IDeleteTermsVariables>(query);
export default deleteTerms;