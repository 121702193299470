import { RowType } from '@Types/v2/Table';
import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import { useGModal } from 'contexts/v2/GlobalModalProvider';
import Register from 'assets/svgs/v2/ico_register.svg';
import Edit from 'assets/svgs/v2/ico_edit.svg';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import UseTypeIcon from 'assets/svgs/v2/ico_category_squares.svg';
import SenderIcon from 'assets/svgs/v2/ico_receive_type.svg';
import MessageTitleIcon from 'assets/svgs/v2/ico_message_title.svg';
import MessageIcon from 'assets/svgs/v2/ico_message.svg';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import updateMessage from 'graphql/mutations/updateMessage';
import prettify from 'html-prettify';
import { SandpackProvider } from '@codesandbox/sandpack-react';
import SandPackCode from 'components/v2/SandPackComp';
import lazyGetMessagesById from 'graphql/queries/getMessagesByIds';

interface IMessageDetailProps {
  msgUseTypeList:{
    name: string;
    value: string;
  }[];
  msgSenderTypeList: {
    name: string;
    value: string;
  }[];
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const MessageDetail = ({
  msgUseTypeList,
  msgSenderTypeList,
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: IMessageDetailProps) => {

  const { tooltip } = useGModal();

  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState({
    relObjectType: '',
    sendTypeCode: '',
    title: '',
    message: ''
  });

  // 메세지 정보 수정
  const [updMessage] = updateMessage();
  // 메세지 id로 조회
  const [getMsgById] = lazyGetMessagesById();

  const origin = useMemo(() => {
    return {
      relObjectType: data.relObjectType,
      sendTypeCode: data.sendTypeCode,
      title: data.title,
      message: data.message
    }
  }, [])

  const enableButton = useMemo(() => {
    if (
      detail.relObjectType === '' ||
      detail.sendTypeCode === '' ||
      detail.title === '' ||
      detail.message === ''
    ) { return true; } else false;
  }, [detail])

  const htmlPre = useMemo(() => {
    try {
      const html = prettify(data.message);

      if (html === '') {
        return data.message;
      } else {
        return html;
      }
    } catch {
      return data.message;
    }
  },[data]);

  useEffect(() => {
    getMsgById({ variables: { messageId: Number(data.id) }}).then(({ data }) => {
      if (data) {
        const result = data.getMessagesById.data[0]

        setDetail({
          relObjectType: result.relObjectType,
          sendTypeCode: result.sendTypeCode,
          title: result.title,
          message: result.message
        });
      }
    })
  }, [data.id])

  return (
    <div className='message-detail'>
      <div className='detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />   
        {!edit ? detail.title : '메세지 수정'}
      </div>
      <div className='detail-contents'>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={UseTypeIcon} 
            />
          } 
          title="메세지 용도" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? enumFormatter(detail.relObjectType)
                : <DropdownAtom 
                  placeholder='선택'
                  id="msg-use"
                  data={msgUseTypeList} 
                  value={{
                    name: msgUseTypeList.find(val => val.value === detail.relObjectType)?.name 
                      ? msgUseTypeList.find(val => val.value === detail.relObjectType)?.name
                      : '',
                    value: detail.relObjectType
                  }} 
                  handleClick={(val) => {
                    setDetail(prev => ({
                      ...prev,
                      relObjectType: String(val.value)
                    }))
                  }} 
              />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={SenderIcon}
            />
          } 
          title="수신 타입" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? enumFormatter(detail.sendTypeCode)
                : <DropdownAtom 
                  id="msg-sender" 
                  data={msgSenderTypeList} 
                  placeholder='선택'
                  value={{
                  name: msgSenderTypeList.find(val => val.value === detail.sendTypeCode)?.name
                  ? msgSenderTypeList.find(val => val.value === detail.sendTypeCode)?.name
                  : '',
                  value: detail.sendTypeCode
                  }}
                  handleClick={(val) => {
                    setDetail(prev => ({
                      ...prev,
                      sendTypeCode: String(val.value)
                    }))
                  }}
              />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={MessageTitleIcon}
            />
          } 
          title="제목" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.title
                : <InputAtom
                    placeholder='제목 입력'
                    value={detail.title}
                    onChangeValue={(str) => {
                      setDetail(prev => ({
                        ...prev,
                        title: str
                      })) 
                    }
                  }
                />
              }
            </>
          } 
        />
        {
          !edit &&
        <>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Register} 
            />
          } 
          title="등록"
          content={() => 
            <>
            <u
              onMouseOver={(e) => {
                tooltip.userTooltip({ 
                  top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                    ? e.currentTarget.getBoundingClientRect().bottom - 115
                    : e.currentTarget.getBoundingClientRect().bottom + 12,
                  left: e.currentTarget.getBoundingClientRect().left, 
                  userInfo: { userId: data.createdByUserId, fullName: data.createdByName, createdAt: !data.createdAt ? '-' : data.createdAt, thumbnail: '' }
                });
              }}
              onMouseLeave={tooltip.close}
            >
            {data.createdByName}({data.createdByUserId})</u> / {!data.createdAt ? '-' : dateFormatter(data.createdAt, 'datetime')}
            </> 
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={Edit} 
            />
          } 
          title="마지막 수정"
          content={() => 
            <>
                <u
                  onMouseOver={(e) => {
                    tooltip.userTooltip({ 
                      top: 100 > window.innerHeight - e.currentTarget.getBoundingClientRect().bottom
                        ? e.currentTarget.getBoundingClientRect().bottom - 115
                        : e.currentTarget.getBoundingClientRect().bottom + 12,
                      left: e.currentTarget.getBoundingClientRect().left, 
                      userInfo: { userId: data.modifiedByUserId, fullName: data.modifiedByName, createdAt: !data.modifiedAt ? '-' : data.modifiedAt, thumbnail: '' }
                    });
                  }}
                  onMouseLeave={tooltip.close}
                >
                  {data.modifiedByName}({data.modifiedByUserId})</u> / {!data.modifiedAt ? '-' : dateFormatter(data.modifiedAt, 'datetime')}
            </> 
          } 
        />
        </>
        }
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={MessageIcon}
            />
          } 
          title="메세지" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? <pre>{htmlPre}</pre>
                : <div className='preview-wrap'>
                    <SandpackProvider template="static"> 
                    <SandPackCode 
                      value={detail.message} 
                      onChangeText={(val:string) => setDetail(prev => ({
                        ...prev,
                        message: val
                      }))}
                      />
                    </SandpackProvider> 
                </div>
              }
            </>
          } 
        />
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                수정
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                삭제
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                취소
              </button>
              <button 
                className="big-main-btn"
                onClick={() => {
                  const updateMessageData = {
                    reqUpdMessage: {
                      ...detail,
                      id: Number(data.id),
                      relObjectId: data.relObjectId,
                      version: data.version
                    }
                  }            
                  updMessage({ variables: updateMessageData }).then(({ data }) => {
                    if (data) {
                      if (data.updateMessage.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, '메세지가 수정되었습니다.');
                        setEdit(false);
                        onEditSuccess();
                      } else {
                        console.log(data.updateMessage.result);
                        useToast(ErrorCode.UNKNOWN, '메세지 수정을 실패했습니다.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, '메세지 수정을 실패했습니다.');
                    }
                  })
                }}
                disabled={ enableButton }
              >
              수정하기
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default MessageDetail;