import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { CategoryInfo } from 'apis/v2/Manage/schema';

export interface IGetCategoryPageByParamVariables {
  reqData: {
    text?: string;
    pageInfo: Paging;
  }
}

export interface IGetCategoryPageByParamResponseData {
  getCategoryPageByParam: ResponsePage<CategoryInfo>;
}

const lazyGetCategoryPageByParam = () => useLazyQuery<IGetCategoryPageByParamResponseData, IGetCategoryPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetCategoryPageByParam;