import { gql } from '@apollo/client';

const query = gql`
mutation updatePassword($reqData: ReqResetPw) {
  updatePassword(reqData: $reqData) {
    result
  }
}
`;

export default query;