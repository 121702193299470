import { gql } from '@apollo/client';

const query = gql`mutation addMyMessage($reqData: ReqAddMessage) {
  addMessage(reqData: $reqData) {
    __typename
    result
    messages
    data {
      ... on Message {
        __typename
        id
        message
        title
        relObjectId
        relObjectType
        sendTypeCode
        createdBy
        createdAt
        modifiedBy
        modifiedAt
      }
    }
  }
}`;
export default query;