import { useEffect, useMemo, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import Icon from 'components/v2/atoms/Icon';
import UserIcon from 'assets/svgs/v2/ico_redcircle_user.svg';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { enumFormatter } from 'utils/Formatter';
import MemoDetail from './components/MemoDetail';
import MemoGrid from './components/MemoGrid';
import DeleteMemoModal from 'components/v2/modals/DeleteMemoModal';
import { useOutletContext } from 'react-router-dom';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import deleteMemo from 'graphql/mutations/deleteMemo';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import lazyGetMemoPageByParam from 'graphql/queries/getMemoPageByParam';

const MemoComp = () => {
  const { projectList } = useOutletContext<{ projectList: ProjectInfo[]}>();

  const [modalIsOpen, setModalIsOpen] = useState({
    delete: false
  }); 
  const [isShowing, setIsShowing] = useState<'DATA_GRID' | 'DETAIL'>('DATA_GRID');
  const [search, setSearch] = useState({
    memoRelObjectType: 'all',
    searchText: ''
  });
  const [total, setTotal] = useState({
    totalPage: 0,
    totalElement: 0
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'createdAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  /* 메모 종류 리스트 */
  const [memoRelObjTypeList, setMemoRelObjTypeList] = useState<{name: string; value:string;}[]>([]);

  /* 메모 정보 조회 */
  const [getMemoPageByParam, {loading: loadingGetMemoPageByParam }] = lazyGetMemoPageByParam();
  /* 메모 삭제 */
  const [delMemo] = deleteMemo(); 
  /* 메모 종류 조회 */
  const [getMemoRelObjectType] = lazyGetEnumTypeCode(); /* MemoRelObjectType */

  const pjList = useMemo(() => {
    return projectList.map(val => ({
      name: val.name,
      value: val.projectId
    }));
  }, [projectList]);

  const getMemoData = useMemo(() => ({
    reqData: {
      memoRelObjectType: search.memoRelObjectType === 'all' ? undefined : search.memoRelObjectType,
      text: search.searchText === '' ? undefined : search.searchText,
      pageInfo: {
        page: tablePagination.currentPage - 1,
        size: tablePagination.limit,
        orders: [{
          target: tablePagination.target,
          direction: tablePagination.direction
        }]
      }
    }
  }), [
    tablePagination, 
    search,
    isShowing
  ]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return <MemoGrid
          memoRelObjTypeList={memoRelObjTypeList}
          onSubmitSearch={(readyToSearch: {
            memoRelObjectType: string;
            searchText: string;
          }) => { 
            setSearch({ ...readyToSearch });
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
          tablePagination={tablePagination}
          updateTablePagination={(key:string, value: number | string | OrderDirection) => setTablePagination(prev => ({
            ...prev,
            [key]: value
          }))}
          total={total}
          rows={rows}
          checkedList={checkedList}
          reportCheckedList={(list:string[]) => setCheckedList(list)}
          reportSelected={(id:string) => {
            setSelected(id);
            setIsShowing('DETAIL');
          }}
          openDeleteModal={() => setModalIsOpen(prev => ({
            ...prev,
            delete: true
          }))}
          isLoading={loadingGetMemoPageByParam}
        />
      case 'DETAIL': 
        return <MemoDetail
          memoRelObjTypeList={memoRelObjTypeList}
          pjList={pjList}
          data={rows.find(val => val.id === Number(selected)) as RowType} 
          convertGridIsShowing={() => {
            setSelected('');
            setIsShowing('DATA_GRID');
          }}
          openDeleteModal={() =>  {
            setCheckedList([selected]);
            setModalIsOpen(prev => ({
              ...prev,
              delete: true
            }))

         }}    
          onEditSuccess={() => {
            getMemoPageByParamRows();
          }}
        />
    }
  }, [ 
    tablePagination,
    isShowing, 
    rows, 
    total,
    checkedList, 
    selected,

    memoRelObjTypeList,
    pjList
  ])

  const getMemoPageByParamRows = () => {
    getMemoPageByParam({ variables: getMemoData }).then(({ data }) => {
      if (data) {
        if (data.getMemoPageByParam) {
          setRows(data.getMemoPageByParam.content)
          setTotal({
            totalPage: data.getMemoPageByParam.totalPages,
            totalElement: data.getMemoPageByParam.totalElements
          })
        }
      }
    })
  }

  /* 메모 rows 받기 */
  useEffect(() => {
    getMemoPageByParamRows();
  }, [getMemoData])

  useEffect(() => {
    getMemoRelObjectType({ variables: {text: 'MemoRelObjectType'}}).then(({ data }) => {
      if (data) {
        setMemoRelObjTypeList(data.getEnumTypeCode.data.map((val) => ({
          name: String(enumFormatter(val.value)),
          value: val.value
        })))
      }
    })
  }, [])

  return (
    <div className='memo-component'>
      {renderShowing}
      <DeleteMemoModal 
        open={modalIsOpen.delete} 
        data={rows.filter(val => checkedList.includes(String(val.id)))} 
        title={() => 
          <>
            <Icon width={32} height={32} src={UserIcon}  />
            메모 삭제
          </>
        } 
        onClose={() => setModalIsOpen(prev => ({
          ...prev,
          delete: false
        }))}
        onDelete={() => {      

          delMemo({ variables: { memo: { ids: checkedList.map(val => Number(val)) } }})
          .then(({ data }) => {
            if (data) {
              if(data.deleteMemo.result === ErrorCode.SUCCESS) {
                useToast(ErrorCode.SUCCESS, '메모가 삭제되었습니다.');
                setModalIsOpen(prev => ({
                  ...prev,
                  delete: false
                }))

                if (isShowing === 'DETAIL') {
                  setIsShowing('DATA_GRID');
                }

                if ((rows.length === checkedList.length) && total.totalPage !== 1) {
                  setTablePagination(prev => ({
                    ...prev,
                    currentPage: tablePagination.currentPage - 1
                  }));
                } else {
                  getMemoPageByParamRows();
                }

                setCheckedList([]);
              } else {
                console.log(data.deleteMemo.result);
                useToast(ErrorCode.UNKNOWN, '메모 삭제를 실패했습니다.');
              }
            } else {
              useToast(ErrorCode.UNKNOWN, '메모 삭제를 실패했습니다.');
            }
          })
        }} 
      />
    </div>
  );
}
export default MemoComp;