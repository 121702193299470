import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';
import { EventRuleInfo } from 'graphql/types/EventRuleInfo';

export interface IUpdateEventRuleVariables {
  reqData: {
    eventId: number;
    name: string;
    maskCode: string;
  }
}

export interface IUpdateEventRuleResponseData {
  updateEvent: IGqlResponseData<EventRuleInfo>;
}

const updateEventRule = () => useMutation<IUpdateEventRuleResponseData, IUpdateEventRuleVariables>(query);
export default updateEventRule;