import { RowType } from '@Types/v2/Table';
import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import InputAtom from 'components/v2/atoms/InputAtom';
import { dateFormatter } from 'utils/Formatter';
import countryCodes from 'country-codes-list';
import { numberRegex } from 'utils/Regex';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import ToggleSwitchAtom from 'components/v2/atoms/ToggleSwitchAtom';
import CameraIcon from 'assets/svgs/v2/ico_camera.svg';
import UserNameIcon from 'assets/svgs/v2/ico_profile_32.svg';
import UserIdIcon from 'assets/svgs/v2/ico_userId.svg';
import EmailIcon from 'assets/svgs/v2/ico_email.svg';
import OrganIcon from 'assets/svgs/v2/ico_members.svg';
import RankIcon from 'assets/svgs/v2/ico_priority.svg';
import PhoneIcon from 'assets/images/v2/ico_phone.png.png';
import DescIcon from 'assets/svgs/v2/ico_message.svg';
import ClockIcon from 'assets/images/v2/ico_clock_icon.png';
import TermIcon from 'assets/svgs/v2/ico_term.svg';
import LoginFailIcon from 'assets/svgs/v2/ico_login_fail.svg';
import LoginIcon from 'assets/svgs/v2/ico_login.svg';
import CalenderIcon from 'assets/svgs/v2/ico_calender.svg';
import LastLogin from 'assets/svgs/v2/ico_last_login.svg';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { MemberTermsInfo } from 'apis/v2/Manage/schema';
import { checkEmail, checkUserName } from 'utils/v2/ValidCheck';
import updateMember from 'graphql/mutations/updateMember';
import { ErrorCode } from '@Types/error';
import { useToast } from 'hooks/v2/useToast';
import lazyGetMemberTermsByMemberId from 'graphql/queries/getMemberTermsByMemberId';
import { useAuth } from 'contexts/AuthProvider';
import { IUploadProfileImageReq } from 'apis/v2/File/schema';
import { Mb } from 'utils/v2/DummyData/File';


interface IUserInfoDetailProps {
  data: RowType;
  convertGridIsShowing: () => void;
  openDeleteModal: () => void;
  onEditSuccess: () => void;
}

const UserInformationDetail = ({
  data,
  convertGridIsShowing,
  openDeleteModal,
  onEditSuccess
}: IUserInfoDetailProps) => {

  const { token } = useAuth();

  const [edit, setEdit] = useState(false);
  const [userThumbnail, setUserThumbnail] = useState('');
  const [fileName, setFileName] = useState('');
  const [termStatusList, setTermStatusList] = useState<MemberTermsInfo[]>([]);
  const [detail, setDetail] = useState({
    id: '', /* 멤버 id */
    pictureResourceId: -1, /* 프로필 사진 */
    fullName: '',
    email: '',
    ranks: '',
    countryCode: '',
    mobileNumber: '',
    description: '',
    alertYn: false
  });

  /* 사용자 정보 수정 */
  const [updateProfile] = updateMember();
  /* 멤버 id로 사용자 - 약관 관계 테이블 조회 */
  const [getMemberTermsByMemberId] = lazyGetMemberTermsByMemberId();
  /* 사용자썸네일 업로드 */
  const { mutateAsync: _uploadProfileImage } = useMutation((data: IUploadProfileImageReq) => apis.File.uploadProfileImage(token, data));
  /* resourceUrl로 프로필 썸네일 이미지 가져오기 */
  const { mutateAsync: _downloadProfileImageByUserId } = useMutation((param:string) => apis.File.downloadProfileImageByUserId(token, param))

  const countryCodesList = countryCodes.all().map(d => 
    ({ callingCode: d.countryCallingCode, countryCode: d.countryCode, languageCode: d.officialLanguageCode })
  );
  const codeDropdownData = useMemo(() => {
    return countryCodesList.map(d => ({ value: `+${d.callingCode}`, name: `+${d.callingCode}` }));
  },[countryCodesList]);

  const enableButton = useMemo(() => {
    if (checkUserName(detail.fullName) || checkEmail(detail.email)) {
      return true;
    } else false;
  }, [detail]);

  const origin = useMemo(() => {
    return {
      id: String(data.id), /* 멤버 id */
      pictureResourceId: data.pictureResourceId, /* 프로필 사진 */
      fullName: data.fullName,
      email: data.email,
      ranks: data.ranks,
      countryCode: '+82', /* todo: 추후 실제값 어떻게 넘어오는지에 따라 작업필요 */
      mobileNumber: data.mobileNumber,
      description: data.description,
      alertYn: data.alertYn
    }
  }, []);

  const getDownloadProfileImgByUserId = () => {
    _downloadProfileImageByUserId(data.userId).then(({ data }) => {
      if (data) {
        if (data.data[0] !== undefined) {
          setUserThumbnail(data.data[0]);
        }
      } else {
        useToast(ErrorCode.UNKNOWN, '유저썸네일을 불러오지 못했습니다.'); 
      }
    })
  }

  useEffect(() => {
    getDownloadProfileImgByUserId();

    getMemberTermsByMemberId({ variables: { memberId: String(data.id) } }).then(({ data }) => {
      if (data) {
        setTermStatusList(data.getMemberTermsByMemberId.data);
      }
    })

    setDetail({
      id: String(data.id), /* 멤버 id */
      pictureResourceId: data.pictureResourceId, /* 프로필 사진 */
      fullName: data.fullName,
      email: data.email,
      ranks: data.ranks,
      countryCode: '+82', /* todo: 추후 실제값 어떻게 넘어오는지에 따라 작업필요 */
      mobileNumber: data.mobileNumber,
      description: data.description,
      alertYn: data.alertYn
    });
  }, [data]);

  return (
    <div className='user-information-detail'>
      <div className='detail-header flex a-center'>
        <div className='back-btn' 
          onClick={convertGridIsShowing}
        />  
        {!edit ? data.userId : '사용자 정보 수정'}
      </div>
      <div className='detail-contents'>
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={CameraIcon} 
            />
          } 
          title="프로필 사진"
          className={`profile-photo ${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? <div className="thumbnail-image"
                    { ...(userThumbnail && userThumbnail !== '' && { 
                        style: { backgroundImage: `url('${userThumbnail}')`, backgroundSize: 'cover' } 
                      })
                    }
                 />
                : <div className='file-name-wrap flex j-between a-center'>
                  <div className='file-value flex a-center j-between'>
                  <div className="file-name">{fileName ? fileName: '파일 선택'}</div>
                  {
                    detail.pictureResourceId !== -1 &&
                    <button 
                      className="cancel-btn" 
                      onClick={() => {
                        setDetail((prev) => ({
                          ...prev,
                          pictureResourceId: -1,
                        }))
                      }}
                    />
                  }
                  </div>

                  <input 
                    id="profile-picture" 
                    type="file"
                    accept=".jpg, .png, .gif"
                    onChange={(e) => {
                      if (e.target.files) {
                        const file = e.target.files[0];
                        if (!file.name.toLowerCase().endsWith('jpg') && !file.name.toLowerCase().endsWith('png') && !file.name.toLowerCase().endsWith('gif')) {
                          useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');
    
                          return e.target.value ='';
                        }
    
                        if (file.size > Mb) {
                          useToast(ErrorCode.UNKNOWN, '1MB이하 파일만 첨부해주세요.');
    
                          return e.target.value = '';
                        }

                        const uploadProfileImageData = {
                          file: file,
                          reqUploadProfileImage : { 
                            kind: 'member', 
                            userId: data.userId
                          }
                        }

                        _uploadProfileImage(uploadProfileImageData).then(({data}) => {
                          if (data) {
                            if(data.result === ErrorCode.SUCCESS) {
                              useToast(ErrorCode.SUCCESS, '사진이 등록되었습니다.');
                              setFileName(file.name);
                              getDownloadProfileImgByUserId();
                            } else {
                              if (data.result === ErrorCode.INVALID_EXTENSION) { useToast(ErrorCode.UNKNOWN, '.jpg, .png, .gif 확장자만 가능합니다.');} 
                              else {useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');}
                              console.log(data.result);
                            }
                          } else {
                            useToast(ErrorCode.UNKNOWN, '사진등록에 실패했습니다.');
                          }
                        })
                      }
                    }}
                  />
                  <label 
                    className='profile-picture-label flex j-center a-center'
                    htmlFor="profile-picture"
                  >
                    업로드
                  </label>
                </div>
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={UserNameIcon}
            />
          } 
          title="사용자 이름" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.fullName
                : <InputAtom
                    value={detail.fullName}
                    placeholder='사용자 이름 입력 (한글, 영대문자, 영소문자, 숫자 조합의 2자 이상 32글자 이하)'
                    onChangeValue={(val) => {
                      setDetail((prev) => ({
                        ...prev,
                        fullName: val
                      }))
                    }
                  }
                />
              }
            </>
          } 
        />
        {
          !edit && 
          <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={UserIdIcon}
            />
          } 
          title="아이디" 
          content={() => <>{data.userId}</>} 
        />
        }
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={EmailIcon}
            />
          } 
          title="이메일" 
          required={edit}
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? detail.email
                : <InputAtom
                    value={detail.email}
                    placeholder='이메일 입력'
                    onChangeValue={(val) => {
                      setDetail((prev) => ({
                        ...prev,
                        email: val
                      }))
                    }
                  }
                />
              }
            </>
          } 
        />
        {
          !edit && 
          <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={OrganIcon}
            />
          } 
          title="조직" 
          content={() => <>{data.organName}</>} 
        />
        }
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={RankIcon}
            />
          } 
          title="직급" 
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? !detail.ranks ? '-' : detail.ranks
                : <InputAtom
                    value={detail.ranks}
                    placeholder='직급 입력'
                    onChangeValue={(val) => {
                      setDetail((prev) => ({
                        ...prev,
                        ranks: val
                      }))
                    }
                  }
                />
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={PhoneIcon}
            />
          } 
          title="연락처" 
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? `${detail.countryCode}${detail.mobileNumber}`
                : <div className="phone-wrap flex">      
                  <DropdownAtom 
                    id='contact'
                    data={codeDropdownData}
                    value={{
                      name: codeDropdownData.find(code => code.value === detail.countryCode)?.name 
                        ? codeDropdownData.find(code => code.value === detail.countryCode)?.name
                        : '',
                      value: detail.countryCode
                    }} 
                    handleClick={(val) => setDetail(prev => ({
                      ...prev,
                      countryCode: String(val.value)
                    }))} 
                  />
                  <InputAtom
                    value={detail.mobileNumber}
                    placeholder="연락처 입력"
                    onChangeValue={(str) => setDetail(prev => ({
                      ...prev,
                      mobileNumber: str.replace(numberRegex, '')
                    }))}
                  />
              </div>
              }
            </>
          } 
        />
        <InfoCard
          icon={
            <Icon 
              width={32} 
              height={32} 
              src={DescIcon}
            />
          } 
          title="소개" 
          className={`${edit && 'edit'}`}
          content={() => 
            <>
              { !edit 
                ? !detail.description ? '-' : detail.description
                : <TextareaAtom
                    value={detail.description}
                    placeholder='소개 입력'
                    onChangeValue={(val) => {
                      setDetail((prev) => ({
                        ...prev,
                        description: val
                      }))
                    }
                  }
                />
              }
            </>
          } 
        />
        <InfoCard
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={ClockIcon}
              />
            } 
            title="알림 설정" 
            content={() => <ToggleSwitchAtom 
                className='fromAdmin' 
                disabled={!edit} 
                value={data.alertYn}
                onChange={(checked:boolean) => setDetail(prev => ({
                  ...prev,
                  alertYn: checked
                }))}
              />
            }  
          />
        {
          !edit &&
          <>
          <InfoCard
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={TermIcon}
              />
            } 
            className='term-agreement-condition'
            title="약관 동의 상태" 
            content={() => 
              <div className="term-condition flex j-between">
                <table>
                  <thead>
                    <tr>
                      <th>약관 종류</th>
                      <th>약관 이름</th> 
                      <th>버전</th>
                      <th>동의 필수 여부</th>
                      <th>사용자 동의 여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      termStatusList.map((val, idx) => (
                        <tr key={idx}>
                          <td>{val.kindCode}</td>
                          <td>{val.title}</td>
                          <td>{val.version}</td>
                          <td>{val.mandatoryYn ? '필수' : '선택'}</td>
                          <td>{val.acceptYn ? '동의' : '미동의'}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            } 
          />
          <InfoCard
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={LoginFailIcon}
              />
            } 
            title="로그인 실패 횟수" 
            content={() => data.retryCount} 
          />
          <InfoCard
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={LoginIcon}
              />
            } 
            title="로그인 유형" 
            content={() => data.loginTypeCode} 
          />
          <InfoCard
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={CalenderIcon}
              />
            } 
            title="가입 일시" 
            content={() => <>{dateFormatter(data.createdAt, 'datetime')}</>} 
          />
          <InfoCard
            icon={
              <Icon 
                width={32} 
                height={32} 
                src={LastLogin}
              />
            } 
            title="마지막 로그인 일시" 
            content={() => <>{dateFormatter(data.createdAt, 'datetime')}</>}  /* mark api에 응답값 없음 */
          />

          </>
        }
      </div>
      <div className="btns flex j-end">
        {
          !edit 
            ? <>
              <button 
                className="big-sub-btn"
                onClick={() => setEdit(true)}
              >
                수정
              </button>
              <button 
                className="big-main-btn"
                onClick={openDeleteModal}
              >
                삭제
              </button> 
            </>
            : <>
              <button
                className="big-sub-btn"
                onClick={() => {
                  setDetail({ ...origin });
                  setEdit(false);
                }}
              >
                취소
              </button>
              <button 
                className="big-main-btn"
                onClick={() => { 
            
                  const updateMemberData = {
                    reqUpdMember: {
                      id: detail.id,
                      fullName: detail.fullName,
                      email: detail.email,
                      ranks: detail.ranks,
                      mobileNumber: detail.mobileNumber,
                      description: detail.description,
                      alertYn: detail.alertYn
                    }
                  }
                  updateProfile({ variables: updateMemberData }).then(({ data }) => {
                    if (data) {
                      if (data.updateMember.result === ErrorCode.SUCCESS) {
                        useToast(ErrorCode.SUCCESS, '사용자 정보가 수정되었습니다.');
                        setEdit(false);
                        onEditSuccess();
                      } else {
                        console.log(data.updateMember.result);
                        useToast(ErrorCode.UNKNOWN, '사용자 정보 수정을 실패했습니다.');
                      }
                    } else {
                      useToast(ErrorCode.UNKNOWN, '사용자 정보 수정을 실패했습니다.');
                    }
                  })
                }}
                disabled={ enableButton }
              >
                수정하기
              </button>
            </>
        }
      </div>
    </div>
  )
}
export default UserInformationDetail;