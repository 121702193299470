export type ColumnType = {
  /** table th에 보일 출력값. th를 단순 텍스트로 출력할 경우 이 label을 사용 */
  label?: string;
  /** table th의 역할군 */
  field: string;
  /** table th의 너비 */
  width?: number;
  /** 해당 table th의 정렬기능을 줄것인지에 대한 여부 */
  sort?: boolean;
  /** table th에 단순 텍스트가 아닌, 커스텀이 필요한 경우 React Node로 만들어 사용 */
  renderHeader?: () => JSX.Element;
  /** (sort와 연계) column이 원하는 value를 가지지 않은 경우 value를 string으로 가공하여 sort에 사용. (화면에 출력되지 않고 sort의 기능을 담당) */
  valueGetter?: (row: RowType) => string;
  /** table th의 field에 해당하는 tr이 단순 텍스트가 아닌, react node로 커스텀이 필요한 경우 사용 */
  renderCell?: (row:RowType) => JSX.Element;
}

/** 
 * id: table row의 id
 * [key]: th field에 해당하는 각 key의 value
*/
export type RowType = {
  id?: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

/* 오름차순, 내림차순 */
export enum OrderDirection {
  DES = 'descending',
  ASC = 'ascending',
}
