import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteDictionaryVariables {
  reqData: {
    ids: number[];
  }
}

export interface IDeleteDictionaryResponseData {
  deleteDictionary: IGqlResponseData<undefined>;
}

const deleteDictionary = () => useMutation<IDeleteDictionaryResponseData, IDeleteDictionaryVariables>(query);
export default deleteDictionary;