import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IDeleteSubCategoryVariables {
  reqData: {
    ids: number[]
  }
}

export interface IDeleteSubCategoryResponseData {
  deleteSubCategory: IGqlResponseData<undefined>;
}

const deleteSubCategory = () => useMutation<IDeleteSubCategoryResponseData, IDeleteSubCategoryVariables>(query);
export default deleteSubCategory;