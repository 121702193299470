import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { CategoryInfo } from 'apis/v2/Manage/schema';

export interface IGetAllCategoryResponseData {
  getAllCategory: IGqlResponseData<CategoryInfo[]>;
}

const getAllCategory = () => useLazyQuery<IGetAllCategoryResponseData>(query, { fetchPolicy: 'no-cache' });
export default getAllCategory;