import { gql } from '@apollo/client';

const query = gql`
  mutation addOrgan($reqAddOrgan: ReqAddOrgan) {
    addOrgan(organ: $reqAddOrgan) {
      __typename
      result
      messages
      data {
        __typename
        ... on Organ {
          organId
          name
          description
          createdBy
          createdByName
          createdAt
          modifiedBy
          modifiedByName
          modifiedAt
          userToken
        }
      }
    }
  }
`;

export default query;