import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { MessageInfo } from 'apis/v2/Manage/schema';

export interface IGetMessagePageByParamVariables {
  reqGetMessagePage: {
    messageType?: string;
    text?: string;
    pageInfo: Paging;
  }
}

export interface IGetMessagePageByParamResponseData {
  getMessagePageByParam: ResponsePage<MessageInfo>; /* MessageInfo */
}

const lazyGetMessagePageByParam = () => useLazyQuery<IGetMessagePageByParamResponseData, IGetMessagePageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetMessagePageByParam;