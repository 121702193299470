import './index.scss';

const FooterComponent = () => {
  return (
    <div className='footer-container flex j-between a-center'> 
        <div className="foot-info flex j-between">
          <div className='foot-logo' />
          <div className='desc'>
            <p>(주)회사이름 | 대표이사 홍길동</p>
            <p>서울시 강남구 테헤란로 123, 길동타워 1층 | 전화번호: 02-123-4567</p>
            <p>사업자등록번호: 123-45-467890 | 통신판매번호: 2020-서울강남-1234 | 문의: abc@domail.com</p>
          </div>
        </div>
        <div className='foot-links flex col a-end'>
          <div className='flex j-between a-center'>
            <a
              href="/terms/secure"
              target="_blank"
            >
              개인정보 정책
            </a>
            <a 
              href="/terms"
              target="_blank"
            >
              서비스 약관
            </a>
            <a 
              // href="/"
              // target="_blank"
            >
              요금제
            </a>
            <a  
              href="/inquiry"
              target="_blank"
            >
              영업팀 문의
            </a>
          </div>
          © 2023 LG CNS, Inc.
        </div>
      </div>
  )
}
export default FooterComponent;