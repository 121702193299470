import { useMemo } from 'react';
import './index.scss';
import Icon from 'components/v2/atoms/Icon';
import RedCircleTag from 'assets/svgs/v2/ico_redcircle_tag.svg';
import RedCircleCard from 'assets/svgs/v2/ico_redcircle_card.svg';
import RedCircleReceipt from 'assets/svgs/v2/ico_redcircle_receipt.svg';

const Payment = () => {
  const cardMenu = useMemo(() => {
    return [
      {
        renderTitle: () => <> <Icon width={32} height={32} src={RedCircleTag} /> 요금제</>,
        renderContent: () => 
          <div className="flex a-center">
            <div className="level basic">
              베이직
            </div>
            사용중
          </div>,
        button: '요금제 변경하기',
        hrefLInk: '/payment/plan'
      },
      {
        renderTitle: () => <> <Icon width={32} height={32} src={RedCircleCard} /> 카드 관리</>,
        renderContent: () => <>1234-xxxx-xxxx-1234 </>,
        button: '카드 정보 변경하기',
        hrefLInk: '/payment/card-management'
      },
      {
        renderTitle: () => <> <Icon width={32} height={32} src={RedCircleReceipt} /> 영수증</>,
        renderContent: () => <>2023년 10월의 영수증이 발급되었습니다.</>,
        button: '영수증 확인하기',
        hrefLInk: '/payment/receipt'
      }
    ];
  }, []);

  return (
    <div id="payment-container">
      {
        cardMenu.map((val, idx) => (
          <div
            key={idx} 
            className="payment-card"
          >
            <h5 className="flex a-center">
              {val.renderTitle()}
            </h5>
            <div className="contents flex">
              {val.renderContent()}
            </div>
            <div className={`btns ${val.button === '영수증 확인하기' && 'disabled'}`}> {/* temp 개발완료전까진 영수증 비활성화 처리하기 */}
              <a 
                href={val.hrefLInk}
                target="_blank"
                className='flex j-center a-center'
              >
              {val.button}
            </a>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default Payment;
