import { gql } from '@apollo/client';

const query = gql`
mutation updateMessage($reqUpdMessage: ReqUpdateMessage) {
  updateMessage(reqData: $reqUpdMessage) {
    __typename
    result
    data {
      ... on Message {
        id
        relObjectId
        relObjectType
        sendTypeCode
        version
        title
        message
        useYn
        
      }
    }
  }
}
`;
export default query;