import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IUpdateSubCategoryVariables {
  reqData: {
    id: number;
    categoryId?: number;
    name?: string;
    maskCode?: string;
    description?: string;
    title?: string;
    template?: string;
  }
}

export interface IUpdateSubCategoryResponseData {
  updateSubCategory: IGqlResponseData<undefined>;
}

const updateSubCategory = () => useMutation<IUpdateSubCategoryResponseData, IUpdateSubCategoryVariables>(query);
export default updateSubCategory;