import './index.scss';
import { useAuth } from 'contexts/AuthProvider';
import { useEffect, useRef, useState } from 'react';

import Logo from 'assets/svgs/v2/header_logo.svg';
import SettingIcon from 'assets/images/v2/organSetting.png';
import OrganSettingModal from '../modals/OrganSettingModal';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import SelectOrganModal from '../modals/SelectOrganModal';
import Icon from '../atoms/Icon';
import SelectOrganIcon from 'assets/svgs/v2/ico_redcircle_organ.svg';
import { useNavigate } from 'react-router-dom';
import { Histories } from '@Types/v2/SelectOrgan';
import { dateFormatter } from 'utils/Formatter';
import NotiModal from '../modals/NotiModal';
import apis from 'apis/v2';
import { useMutation } from 'react-query';
import lazyGetOrganById from 'graphql/queries/getOrganById';
import { OrganById } from 'apis/v2/Organ/schema';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import lazyGetMyMemberInfo from 'graphql/queries/getMyMemberInfo';
import lazyGetProjectMemberByMemberId from 'graphql/queries/getProjectMemberByMemberId';
import { MemberProject } from 'graphql/types/MemberProject';

/* todo: /api/signup/getCountryCode : 국가코드 조회 개발 안 됨. */
export type ProfileType = {
  pictureResourceId: number;
  id: string;
  fullName: string;
  email: string;
  organName: string;
  userId: string;
  ranks: string;
  description: string;
  countryCode: string;
  mobileNumber: string;
  alertYn: boolean;
  statusCode: string;
}

interface IHeaderProps {
  /** 없으면 일반 로고 */
  leftType?: 'ORGAN';
  /** 없으면 아무것도 없음 */
  rightType?: 'PROFILE' | 'AUTHLINK';
  /* 프로젝트 리스트 */
  projectList?: ProjectInfo[]
  /* 프로젝트 추가 */
  openCreatePJModal?: () => void
  /* lg운영자의 조직선택상태인가? */
  selectOrgan?:boolean;
  selectOrganModalIsOpen?: boolean
  closeSelectOrganModal?:() => void;
  organizationId?: number | null
}
const Header = ({  
  leftType, 
  rightType, 
  projectList,
  openCreatePJModal,
  selectOrgan,
  selectOrganModalIsOpen,
  closeSelectOrganModal,
  organizationId
}:IHeaderProps) => {
  const navigate = useNavigate();
  const profileRef = useRef<null | HTMLDivElement>(null);
  const { 
    token,
    userInfo, 
    logout, 
    role 
  } = useAuth();

  const [organThumbnail, setOrganThumbnail] = useState(''); /* 조직 썸네일 */
  const [userThumbnail, setUserThumbnail] = useState(''); /* 유저 썸네일 */
  const [profileDropIsOpen, setProfileDropIsOpen] = useState(false);
  const [organModalIsOpen, setOrganModalIsOpen] = useState({
    open: false,
    profile: false,
  });
  const [selOrganModalIsOpen, setSelOrganModalIsOpen] = useState(false);
  const [notiModalIsOpen, setNotiModalIsOpen] = useState(false);
  const [searchForSelectOrgan, setSearchForSelectOrgan] = useState('');
  const [organInfo, setOrganInfo] = useState<OrganById>({
    organId: -1,
    name: '',
    description: '',
    createdAt: '',
    createdBy: '',
    createdByUserId:'',
    modifiedAt: '',
    modifiedBy: '',
    modifiedByUserId: ''
  });
  const [profileInfo, setProfileInfo] = useState<ProfileType>({
    pictureResourceId: -1,
    id: '',
    fullName: '',
    userId: '',
    email: '',
    organName: '',
    ranks: '',
    description: '',
    countryCode: '',
    mobileNumber: '',
    alertYn: false,
    statusCode: ''
  });
  const [inviteList, setInviteList] = useState<MemberProject[]>([]);

  /* 조직/유저 썸네일 가져오기 */
  const { mutateAsync: _downloadProfileImage } = useMutation((data: any) => apis.File.getDownloadProfileImage(token, data));
  /* 조직정보 조회 */
  const [getOganById] = lazyGetOrganById();
  /* 내 프로필 조회 (gql getMemberInfos) */
  const [getMemberInfo] = lazyGetMyMemberInfo();
  /* 초대된 프로젝트 내역 조회 */
  const [getProjectMemberByMemberId] = lazyGetProjectMemberByMemberId();

  /* 초대된 프로젝트 조회 */
  const getPjMemberByMemberId = (memId: string) => {
    getProjectMemberByMemberId({ variables: { memberId: memId } }).then(({ data }) => {
      if (data) {
        if (data.getProjectMemberByMemberId){
          setInviteList(data.getProjectMemberByMemberId.data);
        }
      }
    })
  };

  const getOrgInfoById = (orgId:number) => {
    getOganById({ variables: { organId: orgId }})
      .then(({ data }) => {
        if (data) {setOrganInfo(data.getOrganById.data[0])}
      }
    );
  }

  const getProfileInfo = (memberId:string) => {
    getMemberInfo({ variables:{ reqData: [memberId]}})
      .then(({ data }) => {
        if (data) {
            const result = data.getMemberInfos.data[0];
            const newData = {
              id: result.id,
              fullName: result.fullName,
              email: result.email,
              organName: result.organName,
              ranks: !result.ranks ?  '' : result.ranks,
              userId: result.userId,
              alertYn: result.alertYn,
              description: !result.description ? '' : result.description,
              countryCode: '+82', /* temp 임시로 국가코드 이렇게 넣어주기. 실제 값이 어떻게 오는 지에 따라 변경필요! */
              mobileNumber: result.mobileNumber,
              statusCode: result.statusCode,
              pictureResourceId: 123,
            }
            setProfileInfo(newData);
        }
      }) 
  }

  /* 조직썸네일 가져오기 */
  const getOrganThumbnail = () => {
    _downloadProfileImage({ kind: 'organ', id: organizationId}).then(({ data }) => {
      if (data) { setOrganThumbnail(data.data[0])} 
      else { useToast(ErrorCode.UNKNOWN, '조직썸네일을 불러오지 못했습니다.'); }}
    );
  }

  /* 유저썸네일 가져오기 */
  const getUserThumbnail = () => {
    _downloadProfileImage({ kind: 'member'}).then(({ data }) => {      
      if (data) {

        if (data.data[0] !== undefined) {
          setUserThumbnail(data.data[0])
        }

       } else { 
        useToast(ErrorCode.UNKNOWN, '유저썸네일을 불러오지 못했습니다.'); 
      }
    });
  }

  useEffect(() => {
    if (userInfo) { 

      getUserThumbnail();
      getProfileInfo(userInfo.nemo.memberId);
      getPjMemberByMemberId(userInfo.nemo.memberId);

      if (organizationId) {
        if (role === 'sy_admin') { getOrgInfoById(organizationId)} 
        else { getOrgInfoById(Number(userInfo?.nemo.organId))}

        getOrganThumbnail();
      }
    }
  }, [organizationId, role]);


  useEffect(() => {
    const closeProfileDrop = (e:MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(e.target as Node)) {
        setProfileDropIsOpen(false);
      }
    };

    window.addEventListener('mousedown', closeProfileDrop);
    return () => {
      window.removeEventListener('mousedown', closeProfileDrop);
    };
  }, [profileRef]);

  return (
    <header className="header-comp">
      {leftType ? 
        /* 조직선택전상태인가? => 조직선택헤더로 보이게하기 */
        selectOrgan
        ? <button 
            className='select-organ-btn flex j-center a-center'
            onClick={() => setSelOrganModalIsOpen(true)}
          >
            <div />
            조직을 선택해주세요
          </button>
        /* 조직선택이 된 상태인가? */
        : <div className="organ-wrap">
          <div 
            className="thumbnail" 
            {...(organThumbnail && organThumbnail !== '' && { 
              style: { backgroundImage: `url('${organThumbnail}')`, backgroundSize: 'cover' } 
            })}
          />
          <h4>{organInfo.name}</h4>
          { role === 'sy_admin' &&  
            <button 
              className="big-main-btn" 
              onClick={() => setSelOrganModalIsOpen(true)}>조직 변경
            </button>
          }
          <button 
            className="setting-btn" 
            onClick={() => setOrganModalIsOpen(prev => ({
              ...prev,
              open: true
            }))}
          >
            <img src={SettingIcon} width={32} height={32} />
          </button>
        </div> 
        : <img src={Logo} width={90} height={26} />
      }
      {rightType && 
      (rightType === 'PROFILE' ?
        <>
        <button 
          className="user-profile-btn"
          onClick={() => setProfileDropIsOpen(true)}
        >
          <div 
            className="thumbnail" 
            {...( userThumbnail && userThumbnail !== '' && { 
                style: { backgroundImage: `url('${userThumbnail}')`, backgroundSize: 'cover' } 
                } 
              )
            }
          />
          { profileInfo.fullName || userInfo?.nemo.fullName}
          { inviteList.length > 0 && <span className='notification-sign' /> }
        </button> 
        {
          profileDropIsOpen && 
          <div 
            ref={profileRef} 
            className='profile-dropdown' 
            id="profile-dropdown"
          >
            <button onClick={() => {
                setNotiModalIsOpen(true);
                setProfileDropIsOpen(false)
              }}
            >
              알림 { inviteList.length > 0 && <span className='notification-sign' /> }
            </button>
            <button onClick={() => {
              setOrganModalIsOpen({
                open: true,
                profile: true
              })
              setProfileDropIsOpen(false)
            }}
            >
              내 프로필
            </button>
            <button onClick={() => {
                logout();
                setProfileDropIsOpen(false);
              }}
            >
              로그아웃
            </button>
          </div>
        }
        </>
        :
        <div className="button-group">
          <button className="big-sub-btn">로그인</button>
          <button className="big-main-btn">회원가입</button>
        </div>
      )
      }
      { 
      /* 조직모달 */
      <OrganSettingModal
          organSettingOpen={organModalIsOpen.open}
          preferenceOpt={organModalIsOpen.profile}
          onClose={() => setOrganModalIsOpen({
            open: false,
            profile: false
          })}
          projectList={projectList as ProjectInfo[]}
          openCreatePJModal={openCreatePJModal}
          organInfo={organInfo}
          onSuccessUpdateOrganInfo={() => { if (organizationId) { getOrgInfoById(organizationId)}}}
          organThumbnail={organThumbnail}
          onSuccessUploadOrgThumbnail={() => getOrganThumbnail()}
          profileInfo={profileInfo}
          onSuccessUpdateProfileInfo={(data:ProfileType) => setProfileInfo(data)}
          userThumbnail={userThumbnail}
          onSuccessUploadUserThumbnail={() => getUserThumbnail()}
        /> 
      }
      { 
      /* 조직선택 모달 */
      <SelectOrganModal 
        open={selectOrganModalIsOpen || selOrganModalIsOpen } 
        title={() => 
          <>
            <Icon width={32} height={32} src={SelectOrganIcon} />
            조직 검색
          </>
        }
        searchForSelectOrgan={searchForSelectOrgan}
        onChangeSearchForSelectOrgan={(str:string) => setSearchForSelectOrgan(str)}
        onClose={() => {
          if (selectOrganModalIsOpen) {
            closeSelectOrganModal && closeSelectOrganModal();
          }
          setSelOrganModalIsOpen(false);
        }}
        onSelect={(organId: number, organName:string ) => {

          /* 최근검색꺼에서 눌렀는가? */
          if (searchForSelectOrgan === '') {
            navigate(`/organ/${organId}`);
            setSelOrganModalIsOpen(false);
          } 
          /* 검색어를 입력해서 눌렀는가? */
          else {
            const getHistories = localStorage.getItem('histories');
            if (!getHistories) {
              localStorage.setItem('histories', JSON.stringify([
                {
                  user: userInfo?.nemo.memberId,
                  list: [{
                    id: organId,
                    name: organName,
                    date: dateFormatter(new Date().toISOString(), 'datetime')
                  }]
                }
              ]));
            } else {
              const histories:Histories = JSON.parse(getHistories);
              const findMine = histories.find(val => val.user === userInfo?.nemo.memberId);
  
              /* histories 배열 내에 내 userInfo가 존재하지 않았다면 */
              if (findMine === undefined) {
                histories.push({
                  user: String(userInfo?.nemo.memberId),
                  list: [{
                    id: organId,
                    name: organName,
                    date: dateFormatter(new Date().toISOString(), 'datetime')
                  }]
                });
                localStorage.setItem('histories', JSON.stringify(histories));
              } 
              /* 내 userInfo께 이미 있다면 */
              else {
                /* 내 list에 방금 클릭한 조직이 있는지 확인하기 */
                const findList = findMine.list.find(val => val.id === organId);
  
                /* 내 list에 없었다면 */
                if (findList === undefined) {

                  findMine.list.push({
                    id: organId,
                    name: organName,
                    date: dateFormatter(new Date().toISOString(), 'datetime')
                  });

                  localStorage.setItem('histories', JSON.stringify(histories));
                } 
                /* 내 list에 있었다면 => 가장 최근기록으로 갱신 유지갱신 필요 */
                else {
                  const left = findMine.list.filter(val => val.id !== organId);
                  left.push({
                    id: organId,
                    name: organName,
                    date: dateFormatter(new Date().toISOString(), 'datetime')
                  })
                  findMine.list = left;
                  localStorage.setItem('histories', JSON.stringify(histories));
                }
              }
            }
            setSearchForSelectOrgan('');
            setSelOrganModalIsOpen(false);
            navigate(`/organ/${organId}`);
          }}
        }
      />
      }

      {
        /* 알림 모달 */
        <NotiModal 
          open={notiModalIsOpen} 
          inviteList={inviteList}
          onClose={() => setNotiModalIsOpen(false)}
          reloadInviteList={() => {
            if ( role !== 'sy_admin' && userInfo?.nemo.memberId ) {
              getPjMemberByMemberId(userInfo.nemo.memberId);
            }
          }}
        />
      }

    </header>
  );
};
export default Header;